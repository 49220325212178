import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router";
import PageLoader from "../../../@jumbo/components/PageComponents/PageLoader";
import {checkRouterUrl} from "../../../@jumbo/utils/commonHelper";

const Tracking = ({match}) => {
  const requestedUrl = match.url.replace(/\/$/, "");

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl("godview", "view") && (
          <Redirect
            exact
            from={`${requestedUrl}/`}
            to={`${requestedUrl}/godview`}
          />
        )}
        {checkRouterUrl("godview", "view") && (
          <Route
            path={`${requestedUrl}/godview`}
            component={lazy(() => import("./GodView"))}
          />
        )}
        {checkRouterUrl("livetracking", "view") && (
          <Route
            path={`${requestedUrl}/livetracking`}
            component={lazy(() => import("./LiveTracking"))}
          />
        )}
        {checkRouterUrl("trackhistory", "view") && (
          <Route
            path={`${requestedUrl}/trackhistory`}
            component={lazy(() => import("./TrackHistory"))}
          />
        )}
        {checkRouterUrl("alerts", "view") && (
          <Route
            path={`${requestedUrl}/alerts/:id`}
            component={lazy(() => import("./Alerts"))}
          />
        )}
        {checkRouterUrl("alerts", "view") && (
          <Route
            path={`${requestedUrl}/alerts`}
            component={lazy(() => import("./Alerts"))}
          />
        )}

        {checkRouterUrl("alerts", "add") && (
          <Route
            path={`${requestedUrl}/addconfiguredAlerts`}
            component={lazy(() => import("./Alerts/AddConfiguredAlerts"))}
          />
        )}
        {checkRouterUrl("alerts", "edit") && (
          <Route
            path={`${requestedUrl}/editconfiguredAlerts/:id`}
            component={lazy(() => import("./Alerts/AddConfiguredAlerts"))}
          />
        )}
        {checkRouterUrl("aoi", "view") && (
          <Route
            path={`${requestedUrl}/aoi`}
            component={lazy(() => import("./Aoi"))}
          />
        )}
        <Route component={lazy(() => import("../404"))} />
      </Switch>
    </Suspense>
  );
};

export default Tracking;
