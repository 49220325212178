import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";

import {makeStyles} from "@material-ui/core/styles";
import AppTextInput from "../../../@jumbo/components/Common/formElements/AppTextInput";
import PageHeaderBack from "../../../@jumbo/components/PageComponents/PageHeaderBack";
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  getData,
  updateData
} from "../../../redux/actions/Common";
import {
  getGenderOptions,
  getCity,
  getCountry,
  getCompany,
  getSuperAdmin
} from "../../../@jumbo/utils/commonHelper";
import {UPDATE_AUTH_USER} from "../../../@jumbo/constants/ActionTypes";
import {imageUpload} from "../../../@jumbo/utils/imageHelper";

import AppMultiSelectBox from "../../../@jumbo/components/Common/formElements/AppMultiSelectBox";
import AppSwitch from "../../../@jumbo/components/Common/formElements/AppSwitch";
import {useHistory} from "react-router-dom";
import api from "../../../services/api";
import CmtImage from "../../../@coremat/CmtImage";

const moduleName = "inhouse";

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {authUser} = useSelector(({auth}) => auth);
  const {editData} = useSelector(({common}) => common);

  const [ company, setCompany ] = useState(getCompany());
  const [ firstName, setFirstName ] = useState("");
  const [ lastName, setLastName ] = useState("");
  const [ gender, setGender ] = useState(null);
  const [ primaryTelephone, setPrimaryTelephone ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ searchBy, setSearchBy ] = useState("");
  const [ photo, setPhoto ] = useState("");
  const [ file, setFile ] = useState("");
  const [ notificationStatus, setNotificationStatus ] = useState({
    email: false,
    web: false,
    sms: false,
    whatsApp: false,
    app: false
  });

  useEffect(
    () => {
      console.log("authUser===", authUser);
      if (authUser) {
        dispatch(getData(moduleName + "/" + authUser.id));
      }
    },
    [ authUser ]
  );

  useEffect(
    () => {
      if (editData) {
        console.log("editData===", editData);
        editData.gender = getGenderOptions().find(
          item => item.value == editData.gender
        );
        editData.gender = editData.gender ? editData.gender : null;

        setCompany(
          editData.company
            ? {label: editData.company.name, value: editData.company.id}
            : null
        );
        setFirstName(editData.firstName ? editData.firstName : "");
        setLastName(editData.lastName ? editData.lastName : "");
        setGender(editData.gender);
        setPrimaryTelephone(editData.primaryTelephone);
        setPassword(editData.password ? editData.password : "");
        setEmail(editData.email);
        setPhoto(editData.photo ? editData.photo : "");
        setNotificationStatus(notification => {
          return {
            email: editData.emailNotificationStatus
              ? editData.emailNotificationStatus
              : notification.email,
            web: editData.webNotificationStatus
              ? editData.webNotificationStatus
              : notification.web,
            sms: editData.smsNotificationStatus
              ? editData.smsNotificationStatus
              : notification.sms,
            whatsApp: editData.whatsAppNotificationStatus
              ? editData.whatsAppNotificationStatus
              : notification.whatsApp,
            app: editData.appNotificationStatus
              ? editData.appNotificationStatus
              : notification.app
          };
        });
      }
    },
    [ editData ]
  );

  const photoOnChange = e => {
    const file = e.target.files[0];
    setFile(file);
    try {
      var reader = new FileReader();
      reader.onload = function(e){
        setPhoto(e.target.result);
      };
      reader.readAsDataURL(file);
    } catch (err) {
      console.log(err);
    } finally {
      //this.setState({ formState: FormState.saved }, () => this.transition.updateChildren());
    }
  };

  const onSubmit = async () => {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (company == null) {
      dispatch(fetchError(<IntlMessages id="error.selectedCompany" />));
    } else if (firstName == "") {
      dispatch(fetchError(<IntlMessages id="error.enter_firstName" />));
    } else if (lastName == "") {
      dispatch(fetchError(<IntlMessages id="error.enter_lastName" />));
    } else if (gender == null) {
      dispatch(fetchError(<IntlMessages id="error.selectGender" />));
    } else if (primaryTelephone == "") {
      dispatch(fetchError(<IntlMessages id="error.primaryTelephone" />));
    } else if (email == "") {
      dispatch(fetchError(<IntlMessages id="error.email" />));
    } else if (reg.test(email) == false) {
      dispatch(fetchError(<IntlMessages id="error.validemail" />));
    } else if (password != "" && password.length <= 5) {
      dispatch(fetchError(<IntlMessages id="error.validpassword" />));
    } else {
      var data = {
        firstName: firstName,
        lastName: lastName,
        gender: gender ? gender.value : null,
        primaryTelephone: primaryTelephone,
        email: email,
        password: password,
        photo: photo,
        emailNotificationStatus: notificationStatus.email,
        webNotificationStatus: notificationStatus.web,
        smsNotificationStatus: notificationStatus.sms,
        whatsAppNotificationStatus: notificationStatus.whatsApp,
        appNotificationStatus: notificationStatus.app
      };
      console.log("data===", data);
      dispatch(fetchStart());
      if (file != "") {
        data.photo = await imageUpload(file);
      }
      let response = await api.putApi(moduleName + "/" + editData.id, data);
      if (response.status == "success") {
        let newUser = {...authUser, ...data};
        dispatch(
          fetchSuccess(<IntlMessages id="actions.data_updated_successfully" />)
        );
        dispatch({
          type: UPDATE_AUTH_USER,
          payload: newUser
        });
      } else {
        dispatch(fetchError(response.message));
      }
    }
  };

  return (
    <div className={classes.root}>
      <PageHeaderBack
        onSubmit={onSubmit}
        moduleName={moduleName}
        Iscancel={false}
      />
      <Grid classes={{root: "forms"}}>
        <Grid container spacing={5}>
          <Grid item item lg={4} md={6} sm={6} xs={12}>
            <AppTextInput
              label={<IntlMessages id="users.first_name" />}
              InputLabelProps={{shrink: true}}
              value={firstName}
              onChange={event => setFirstName(event.target.value)}
            />
          </Grid>
          <Grid item item lg={4} md={6} sm={6} xs={12}>
            <AppTextInput
              label={<IntlMessages id="users.last_name" />}
              InputLabelProps={{shrink: true}}
              value={lastName}
              onChange={event => setLastName(event.target.value)}
            />
          </Grid>
          <Grid item item lg={4} md={6} sm={6} xs={12}>
            <Grid classes={{root: "labels"}}>
              <IntlMessages id="users.gender" />
            </Grid>
            <AppMultiSelectBox
              menuPlacement="auto"
              onChange={value => setGender(value)}
              value={gender}
              isMulti={false}
              key={searchBy}
              options={getGenderOptions()}
            />
          </Grid>
          <Grid item item lg={4} md={6} sm={6} xs={12}>
            <AppTextInput
              type="number"
              min={0}
              label={<IntlMessages id="inhousepersonnel.primary_telephone" />}
              value={primaryTelephone}
              onChange={event => setPrimaryTelephone(event.target.value)}
              InputLabelProps={{shrink: true}}
            />
          </Grid>
          <Grid item item lg={4} md={6} sm={6} xs={12}>
            <AppTextInput
              disabled={true}
              label={<IntlMessages id="users.email" />}
              InputLabelProps={{shrink: true}}
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </Grid>
          <Grid item item lg={4} md={6} sm={6} xs={12}>
            <AppTextInput
              type="password"
              label={<IntlMessages id="login.Password" />}
              InputLabelProps={{shrink: true}}
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <IntlMessages id="profile.email" />
              <AppSwitch
                onChange={() => {
                  setNotificationStatus(notification => {
                    return {
                      ...notification,
                      email: !notificationStatus.email
                    };
                  });
                }}
                checked={notificationStatus.email}
              />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <IntlMessages id="profile.web" />
              <AppSwitch
                onChange={() => {
                  setNotificationStatus(notification => {
                    return {
                      ...notification,
                      web: !notificationStatus.web
                    };
                  });
                }}
                checked={notificationStatus.web}
              />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <IntlMessages id="profile.sms" />
              <AppSwitch
                onChange={() => {
                  setNotificationStatus(notification => {
                    return {
                      ...notification,
                      sms: !notificationStatus.sms
                    };
                  });
                }}
                checked={notificationStatus.sms}
              />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <IntlMessages id="profile.whatsApp" />
              <AppSwitch
                onChange={() => {
                  setNotificationStatus(notification => {
                    return {
                      ...notification,
                      whatsApp: !notificationStatus.whatsApp
                    };
                  });
                }}
                checked={notificationStatus.whatsApp}
              />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row"
              }}
            >
              <IntlMessages id="profile.app" />
              <AppSwitch
                onChange={() => {
                  setNotificationStatus(notification => {
                    return {
                      ...notification,
                      app: !notificationStatus.app
                    };
                  });
                }}
                checked={notificationStatus.app}
              />
            </div>
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12} className={classes.filetype}>
            {photo != "" && <CmtImage src={photo} alt="logo" />}
            <span className={classes.filetype_inner}>
              <AppTextInput
                type="image"
                label={<IntlMessages id="availability.photo" />}
                InputLabelProps={{shrink: true}}
                onChange={event => photoOnChange(event)}
              />
              <span className={classes.filetype_span}>
                <IntlMessages id="sidebar.choose_image" />
              </span>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profile;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#f8f9fa"
  },
  cardButtons: {
    float: "right",
    borderRadius: 30
  },
  forms: {
    padding: 60,
    "& .MuiGrid-container": {
      padding: 20
    }
  },
  filetype: {
    position: "relative",
    "& .MuiInputBase-input": {
      backgroundColor: "transparent",
      opacity: 0,
      zIndex: "99"
    }
  },
  filetype_span: {
    position: "absolute",
    left: 15,
    bottom: 15,
    backgroundColor: "#dbecfa",
    padding: 8,
    borderRadius: 30
  }
}));
