import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import CmtButtons from "../CmtButtons";
import {
  getFormatEnglishSpanishDate,
  isDateValid
} from "../../@jumbo/utils/dateHelper";
import {getStatusClassName} from "../../@jumbo/utils/commonHelper";

import Modal from "@material-ui/core/Modal";

function rand(){
  return Math.round(Math.random() * 14) - 10;
}

function getModalStyle(){
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `50%`,
    transform: `translate(-${top}%, -50%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none!important",
    padding: 0,
    border: "2px solid #fff",
    outline: "none"
  },
  modal: {
    "&:focus": {
      outline: "none"
    }
  },
  modalh2: {
    borderBottom: "solid 1px #e1e8ea",
    textAlign: "center",
    padding: 20,
    fontSize: 16
  },
  dateFiltertitle: {
    textAlign: "center",
    padding: "20px 20px 34px 20px",
    width: "100%",
    fontSize: 16
  },
  buttons: {
    width: "100%",
    textAlign: "center",
    marginBottom: 40,
    "& .MuiBox-root": {
      display: "inline",
      textAlign: "center"
    }
  },
  labels: {
    fontSize: 14,
    color: "#000",
    margin: "3px 0",
    width: "30%",
    float: "left"
  },
  firstrow: {
    padding: "2px!important"
  },
  firstrowspan: {
    fontSize: 14,
    color: "#000",
    margin: "3px 0",
    textTransform: "capitalize",
    display: "block"
    // float: "left"
  },
  colan: {
    fontSize: "27px",
    marginRight: "8px",
    float: "left",
    marginTop: "1px"
  },
  container: {
    padding: "15px!important"
  }
}));

const WorkorderViewModalCard = props => {
  const classes = useStyles(props);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [ modalStyle ] = React.useState(getModalStyle);
  const {
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    required,
    onChange,
    helperText,
    variant,
    error,
    valueKey,
    labelKey,
    backgroundColor,
    label,
    open,
    onDelete,
    onCancel,
    items,
    ...rest
  } = props;
  console.log("items===", items);
  const handleOpen = () => {
    //setOpen(true);
  };

  const handleClose = () => {
    //setOpen(false);
  };
  const buttons = [
    {
      label: <IntlMessages id="actions.close" />,
      color: "fullcancel",
      onClick: onCancel
    }
    // { label: <IntlMessages id="actions.delete" />, color: 'remove',onClick: onDelete},
  ];

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 className={classes.modalh2} id="simple-modal-title">
            <IntlMessages id="workorder.work_order_details" />
          </h2>
          <p id="simple-modal-description">
            <Grid>
              <Grid container spacing={5} className={classes.container}>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="sidebar.warehouse" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.warehouse_name}
                  </span>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="workorder.work_ordernumber" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.wodocumentNo}
                  </span>
                </Grid>

                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="actions.state" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {getStatusClassName(items.type)}
                  </span>
                </Grid>
                {/* <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="vehicle.price" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>{items.price}</span>
                </Grid> */}

                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="sidebar.client" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.client_name}
                  </span>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="sidebar.region" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.region_name}
                  </span>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="sidebar.costcenter" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.costcenter_name}
                  </span>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="users.Mechanic" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.inhouse_name}
                  </span>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="sidebar.equipment" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.equipment_name}
                  </span>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="equipment.kilometers" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.kilometers}
                  </span>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="workorder.total_hours_spend" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>0</span>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="purchaserequests.schedule_date" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.documentDate ? (
                      getFormatEnglishSpanishDate(items.documentDate)
                    ) : (
                      ""
                    )}
                  </span>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.firstrow}
                >
                  <Grid className={classes.labels}>
                    <IntlMessages id="workorder.data_time_commitment" />
                  </Grid>
                  <span className={classes.colan}>:</span>
                  <span className={classes.firstrowspan}>
                    {items.commitmentDate ? (
                      getFormatEnglishSpanishDate(items.commitmentDate)
                    ) : (
                      ""
                    )}
                  </span>
                </Grid>
              </Grid>
            </Grid>

            {/* <Col md="12">
          {fileList.map((file, i) => {     
              // Return the element. Also pass key     
              return (<div className="dragdrop_block" style={{textAlign: 'center',height: 'auto'}} key={i}>
                  <img src={file.url} style={{ float: 'none', margin: 'auto'}}/>
                  <p style={{width:'100%', paddingTop:0}}>{file.name}</p>
                  {file.url&&<a style={{width:'100%', float:'left'}} href={file.url}  download={file.name} target='_blank'>{strings.workorder.click_download}</a>}
                  </div>
              ) 
            })}
          </Col> */}
            <Grid xs={12} className={classes.buttons}>
              <CmtButtons items={buttons} />
            </Grid>
          </p>
        </div>
      </Modal>
    </div>
  );
};

WorkorderViewModalCard.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  backgroundColor: PropTypes.string
};

WorkorderViewModalCard.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: "small",
  border: "none",
  error: false,
  helperText: "",
  valueKey: "id",
  labelKey: "title",
  backgroundColor: "transparent"
};

export default WorkorderViewModalCard;
