import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../../@jumbo/components/PageComponents/PageLoader';
import { checkRouterUrl } from '../../../@jumbo/utils/commonHelper';

const Service = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl('servicedashboard', 'view') && <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/dashboard`} />}
        {checkRouterUrl('servicedashboard', 'view') && <Route path={`${requestedUrl}/dashboard`} component={lazy(() => import('./Dashboard'))} />}
        
        {checkRouterUrl('service', 'view') && <Route path={`${requestedUrl}/service`} component={lazy(() => import('./List'))} />}
        {checkRouterUrl('service', 'add') && <Route path={`${requestedUrl}/addservice`} component={lazy(() => import('./Addservice'))} />}
        {checkRouterUrl('service', 'edit') && <Route path={`${requestedUrl}/editservice/:id`} component={lazy(() => import('./Addservice'))} />}

        {checkRouterUrl('servicecontrol', 'view') && <Route path={`${requestedUrl}/servicecontrol`} component={lazy(() => import('./Control'))} />}
        {checkRouterUrl('servicecontrol', 'add') && <Route path={`${requestedUrl}/addservicecontrol`} component={lazy(() => import('./Addcontrol'))} />}
        {checkRouterUrl('servicecontrol', 'edit') && <Route path={`${requestedUrl}/editservicecontrol/:id`} component={lazy(() => import('./Addcontrol'))} />}


        {checkRouterUrl('servicesettings', 'view') && <Route path={`${requestedUrl}/servicesettings`} component={lazy(() => import('./Settings'))} />}
        {checkRouterUrl('servicesettings', 'add') && <Route path={`${requestedUrl}/addservicesettings`} component={lazy(() => import('./Addsettings'))} />}
        {checkRouterUrl('servicesettings', 'edit') && <Route path={`${requestedUrl}/editservicesettings/:id`} component={lazy(() => import('./Addsettings'))} />}

        <Route component={lazy(() => import('../404'))} /> }

      </Switch>
    </Suspense>
  );
};

export default Service;


