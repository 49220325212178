import React from "react";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_DATA,
  EDIT_DATA,
  RESET_DATA,
  RESET_MODULE_DATA,
  DELETE_DATA,
  SET_TABLE,
  SET_FILTER
} from "../../@jumbo/constants/ActionTypes";
import api from "../../services/api";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import {
  getRouteUrl,
  getCompanyId,
  getUserWarehouseFilterQuery,
  getModuleType,
  getUserRegionFilterQuery,
  getRoleName,
  getRoleId
} from "../../@jumbo/utils/commonHelper";

export const fetchSuccess = (message = "") => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START
    });
  };
};

export const resetData = () => {
  return dispatch => {
    dispatch({
      type: RESET_DATA
    });
  };
};

export const resetModuleData = modulename => {
  return dispatch => {
    dispatch({
      type: RESET_MODULE_DATA,
      modulename: modulename
    });
  };
};

export const deleteData = (modulename, data) => {
  return dispatch => {
    dispatch({
      type: DELETE_DATA,
      payload: data,
      modulename: modulename
    });
  };
};

export const getAllData = (
  modulename,
  page,
  limit,
  search,
  filter,
  sort,
  showLoading = true
) => {
  return async dispatch => {
    try {
      let apiPath = modulename + "?page=" + page + "&limit=" + limit;
      if (search && search != "") {
        apiPath = apiPath + search;
      }
      if (filter && filter.company != null && filter.company.value != -1) {
        apiPath = apiPath + "&filter_companyId=" + filter.company.value;
      } else if (
        getCompanyId() &&
        (modulename !== "geozones" && modulename !== "alertsType")
      ) {
        apiPath = apiPath + "&filter_companyId=" + getCompanyId();
      }

      if (modulename == "existencecard") {
        apiPath = apiPath + "&filter_approvalStatus=approve";
      }
      if (filter && filter.region != null && filter.region.value != -1) {
        apiPath = apiPath + "&filter_regionId=" + filter.region.value;
      } else if (
        getModuleType(modulename, "region") &&
        modulename != "approvepermission"
      ) {
        if (modulename == "region") {
          apiPath = apiPath + getUserRegionFilterQuery("id");
        } else {
          apiPath = apiPath + getUserRegionFilterQuery("regionId");
        }
      }
      if (modulename == "dashboard") {
        apiPath = apiPath + "&roleId=" + getRoleId();
      }
      if (
        (modulename == "approvewarehouse" ||
          modulename == "approvepermission") &&
        getRoleName() != "super_admin" &&
        getRoleName() != "admin"
      ) {
        apiPath = apiPath + "&filter_rolePermissionId=" + getRoleId();
      }
      if (filter && filter.warehouse != null && filter.warehouse.value != -1) {
        if (
          modulename == "admissioninternalguide" ||
          modulename == "depatureinternalguide" ||
          modulename == "depaturetransferdispatch"
        ) {
          apiPath =
            apiPath + "&filter_originWarehouseId=" + filter.warehouse.value;
        } else if (modulename == "admissiontransferdispatch") {
          apiPath =
            apiPath +
            "&filter_destinationWarehouseId=" +
            filter.warehouse.value;
        } else {
          apiPath = apiPath + "&filter_warehouseId=" + filter.warehouse.value;
        }
      } else if (getModuleType(modulename, "warehouse")) {
        if (
          modulename == "admissioninternalguide" ||
          modulename == "depatureinternalguide"
        ) {
          apiPath = apiPath + getUserWarehouseFilterQuery("originWarehouseId");
        } else if (modulename == "admissiontransferdispatch") {
          apiPath =
            apiPath + getUserWarehouseFilterQuery("destinationWarehouseId");
        } else if (modulename == "depaturetransferdispatch") {
          apiPath = apiPath + getUserWarehouseFilterQuery("originWarehouseId");
        } else if (modulename == "inventory") {
          apiPath =
            apiPath + getUserWarehouseFilterQuery("warehouseId", "main");
        } else {
          apiPath = apiPath + getUserWarehouseFilterQuery("warehouseId");
        }
      }
      if (filter && filter.status != null && filter.status.value != -1) {
        apiPath = apiPath + "&filter_status=" + filter.status.value;
      }
      if (filter && filter.city != null && filter.city.value != -1) {
        apiPath = apiPath + "&filter_city=" + filter.city.value;
      }
      if (
        filter &&
        filter.costcenter != null &&
        filter.costcenter.value != -1
      ) {
        apiPath = apiPath + "&filter_costCenterId=" + filter.costcenter.value;
      }
      if (filter && filter.provider != null && filter.provider.value != -1) {
        apiPath = apiPath + "&filter_providerId=" + filter.provider.value;
      }
      if (filter && filter.measure != null && filter.measure.value != -1) {
        apiPath = apiPath + "&filter_measure=" + filter.measure.value;
      }
      if (filter && filter.country != null && filter.country.value != -1) {
        apiPath = apiPath + "&filter_country=" + filter.country.value;
      }
      if (
        filter &&
        filter.regionType != null &&
        filter.regionType.value != -1
      ) {
        apiPath = apiPath + "&filter_type=" + filter.regionType.label;
      }
      if (filter && filter.role != null && filter.role.value != -1) {
        apiPath = apiPath + "&filter_roleId=" + filter.role.id;
      }
      if (filter && filter.user != null && filter.user.value != -1) {
        apiPath = apiPath + "&filter_userId=" + filter.user.id;
      }
      if (filter && filter.client != null && filter.client.value != -1) {
        apiPath = apiPath + "&filter_clientId=" + filter.client.id;
      }
      if (filter && filter.code != null && filter.code.value != -1) {
        if (modulename == "sparessupplies") {
          apiPath = apiPath + "&filter_id=" + filter.code.id;
        } else {
          apiPath = apiPath + "&filter_spareId=" + filter.code.id;
        }
      }
      if (filter && filter.driverId != null && filter.driverId.value != -1) {
        if (filter.oneDriver) {
          apiPath = apiPath + "&filter_id=" + filter.driverId.id;
        } else {
          apiPath = apiPath + "&filter_userId=" + filter.driverId.id;
        }
      }

      if (filter && filter.monthYear) {
        apiPath = apiPath + "&filter_date=" + filter.monthYear;
      }
      if (filter && filter.startdate && filter.enddate) {
        console.log("filter.enddate====", filter.enddate);
        apiPath =
          apiPath +
          "&start_date=" +
          filter.startdate +
          "&end_date=" +
          filter.enddate;
      }
      if (
        filter &&
        filter.warehouseDocument != null &&
        filter.warehouseDocument.value != -1
      ) {
        apiPath =
          apiPath + "&filter_document=" + filter.warehouseDocument.value;
      }
      if (
        filter &&
        filter.approveDocument != null &&
        filter.approveDocument.value != -1
      ) {
        apiPath =
          apiPath + "&filter_moduleName=" + filter.approveDocument.value;
      }
      if (filter && filter.tabStatus != null) {
        if (modulename != "approvepermission") {
          apiPath = apiPath + "&filter_approvalStatus=" + filter.tabStatus;
        } else {
          apiPath = apiPath + "&filter_modifyStatus=" + filter.tabStatus;
        }
      }
      if (
        filter &&
        filter.paymentCondition != null &&
        filter.paymentCondition.value != -1
      ) {
        apiPath =
          apiPath + "&filter_condition=" + filter.paymentCondition.value;
      }
      if (filter && filter.make != null && filter.make.value != -1) {
        apiPath = apiPath + "&filter_make=" + filter.make.value;
      }
      if (filter && filter.model != null && filter.model.value != -1) {
        apiPath = apiPath + "&filter_model=" + filter.model.value;
      }
      if (
        filter &&
        filter.equipmentType != null &&
        filter.equipmentType.value != -1
      ) {
        apiPath = apiPath + "&filter_type=" + filter.equipmentType.value;
      }
      if (
        filter &&
        filter.equipment != null &&
        filter.equipment.value != -1 &&
        (modulename == "servicecontrol" ||
          modulename == "geozones" ||
          modulename == "maintenanceplan")
      ) {
        apiPath = apiPath + "&filter_equipmentId=" + filter.equipment.id;
      } else if (
        filter &&
        filter.equipment != null &&
        filter.equipment.value != -1
      ) {
        apiPath = apiPath + "&filter_registerNumber=" + filter.equipment.value;
      }

      if (
        filter &&
        filter.equipment_id != null &&
        filter.equipment_id.value != -1
      ) {
        apiPath = apiPath + "&filter_id=" + filter.equipment_id.value;
      }
      if (
        filter &&
        filter.roleStatus != null &&
        filter.roleStatus.value != -1
      ) {
        let value = filter.roleStatus.value == "active" ? true : false;
        apiPath = apiPath + "&filter_roleStatus=" + value;
      }

      if (filter && filter.type != null && filter.type.value != "") {
        apiPath = apiPath + "&filter_type=" + filter.type.value;
      }
      if (
        filter &&
        filter.maintenanceType != null &&
        filter.maintenanceType.value != -1
      ) {
        apiPath =
          apiPath + "&filter_maintenanceTypeId=" + filter.maintenanceType.value;
      }
      if (
        filter &&
        filter.maintenanceGroup != null &&
        filter.maintenanceGroup.value != -1
      ) {
        apiPath =
          apiPath +
          "&filter_maintenanceGroupId=" +
          filter.maintenanceGroup.value;
      }
      if (
        filter &&
        filter.maintenanceForm != null &&
        filter.maintenanceForm.value != -1
      ) {
        apiPath =
          apiPath + "&filter_maintenanceFormId=" + filter.maintenanceForm.value;
      }

      if (
        filter &&
        filter.workorderStatus != null &&
        filter.workorderStatus.value != -1
      ) {
        apiPath = apiPath + "&filter_status=" + filter.workorderStatus.value;
      }

      if (
        filter &&
        filter.alertsTypeId != null &&
        filter.alertsTypeId.value != ""
      ) {
        apiPath = apiPath + "&filter_alertsTypeId=" + filter.alertsTypeId.value;
      }
      if (modulename == "warehousestock") {
        apiPath = apiPath + "&filter_approvalStatus=approve";
      }

      if (filter && filter.spareService) {
        apiPath = apiPath + "&service=" + filter.spareService;
      }
      if (filter && filter.service != null && filter.service.value != -1) {
        apiPath = apiPath + "&filter_service=" + filter.service.value;
      }
      if (
        filter &&
        filter.toolsAvailability != null &&
        filter.toolsAvailability.value != -1
      ) {
        apiPath =
          apiPath + "&filter_availability=" + filter.toolsAvailability.label;
      }

      if (
        filter &&
        filter.serviceControl != null &&
        filter.serviceControl.value != -1
      ) {
        apiPath = apiPath + "&filter_serviceId=" + filter.serviceControl.value;
      }

      if (
        filter &&
        filter.equipmentVehicle != null &&
        filter.equipmentVehicle.value != -1
      ) {
        apiPath = apiPath + "&filter_type=" + filter.equipmentVehicle.value;
      }

      if (filter && filter.equipment_id && filter.dateStart != null) {
        apiPath = apiPath + "&dateStart=" + filter.dateStart;
        if (filter.dateEnd != null) {
          apiPath = apiPath + "&dateEnd=" + filter.dateEnd;
        }

        if (filter.typeData != null) {
          apiPath = apiPath + "&type=" + filter.typeData;
        }
      }

      if (filter && filter.equipment_id && filter.interval != null) {
        apiPath = apiPath + "&interval=" + filter.interval;
      }

      if (filter && filter.selectedAlert && filter.selectedAlert !== "") {
        apiPath = apiPath + "&filter_alertsTypeId=" + filter.selectedAlert;
      }

      if (sort) {
        apiPath = apiPath + sort;
      } else {
        apiPath = apiPath + "&sort_updatedAt=" + "desc";
      }

      if (filter && apiPath.includes("notifications")) {
        // console.log(apiPath, "apiPath");
        // console.log("filter", filter);
      }

      if (showLoading) {
        dispatch(fetchStart());
      }
      let response = await api.getApi(apiPath);
      console.log("response===", response);
      if (response.status == "success") {
        if (showLoading) {
          dispatch(fetchSuccess());
        }
        if (modulename == "kanbanworkorders" || modulename == "dashboard") {
          dispatch({
            type: SET_DATA,
            payload: response,
            modulename: modulename
          });
        } else {
          dispatch({
            type: SET_DATA,
            payload: response.data,
            modulename: modulename
          });
        }
      } else {
        dispatch(fetchError(response.message));
      }
    } catch (err) {
      console.log(err);
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };
};

export const getData = url => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      let response = await api.getApi(url);
      console.log("response===", response);
      if (response.status == "success") {
        dispatch(fetchSuccess());
        dispatch({
          type: EDIT_DATA,
          payload: response.data
        });
      } else {
        dispatch(fetchError(response.message));
      }
    } catch (err) {
      console.log(err);
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };
};

export const addData = (url, data, history) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      let response = await api.postApi(url, data);
      if (response.status == "success") {
        dispatch(
          fetchSuccess(<IntlMessages id="actions.data_added_successfully" />)
        );
        if (history) {
          if (url === "configuredAlerts") {
            history.push(getRouteUrl("alerts"));
          } else {
            history.push(getRouteUrl(url));
          }
        }
      } else {
        if (response.message == "unique") {
          let newMessage = "";
          newMessage = <IntlMessages id={response.code} />;
          dispatch(fetchError(newMessage));
        } else {
          dispatch(fetchError(response.message));
        }
      }
    } catch (err) {
      console.log(err);
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };
};

export const updateData = (
  url,
  data,
  id,
  history,
  redirect = true,
  showMessage = true
) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      let response = await api.putApi(url + "/" + id, data);
      console.log("response===", response);
      if (response.status == "success") {
        if (showMessage) {
          dispatch(
            fetchSuccess(
              <IntlMessages id="actions.data_updated_successfully" />
            )
          );
        }
        if (redirect) {
          if (url === "configuredAlers") {
            history.push(getRouteUrl("alerts"));
          } else {
            history.push(getRouteUrl(url));
          }
        }
      } else {
        if (response.message == "unique") {
          let newMessage = "";
          newMessage = <IntlMessages id={response.code} />;
          dispatch(fetchError(newMessage));
        } else {
          dispatch(fetchError(response.message));
        }
        //dispatch(fetchError(response.message));
      }
    } catch (err) {
      console.log(err);
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };
};

export const approveData = (url, data, id, history) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      let response = await api.postApi("approvepermission", data);
      console.log("response===", response);
      if (response.status == "success") {
        // dispatch(fetchSuccess(<IntlMessages id="actions.data_updated_successfully" />));
        history.push(getRouteUrl(url));
      } else {
        dispatch(fetchError(response.message));
      }
    } catch (err) {
      console.log(err);
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };
};

export const deleteApproveData = data => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      console.log(data);
      let response = await api.postApi("approvedeletepermission", data);
      console.log("deleteApproveData===", response);
      if (response.status == "success") {
        dispatch(fetchSuccess(<IntlMessages id="error.approve_delete" />));
      } else {
        dispatch(fetchError(response.message));
      }
    } catch (err) {
      console.log(err);
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };
};

export const deleteWarehouseApproveData = data => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      console.log(data);
      let response = await api.postApi(
        "approvewarehousedeletepermission",
        data
      );
      console.log("deleteApproveData===", response);
      if (response.status == "success") {
        dispatch(fetchSuccess(<IntlMessages id="error.approve_delete" />));
      } else {
        dispatch(fetchError(response.message));
      }
    } catch (err) {
      console.log(err);
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };
};

export const copyResource = (url, data, table) => {
  return async dispatch => {
    try {
      console.log("url, data===", url, data);
      dispatch(fetchStart());
      console.log("response==", url, data);
      let response = await api.postApi("copy" + url, data);
      console.log("response==", response, data, url);
      if (response.status == "success") {
        dispatch(
          fetchSuccess(<IntlMessages id="actions.data_added_successfully" />)
        );
        dispatch(setTable(table));
      } else {
        dispatch(fetchError(response.message));
      }
    } catch (err) {
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };
};

export const deleteAction = (url, data, Isupdate, callback) => {
  return async dispatch => {
    try {
      console.log("url, data===", url, data);
      dispatch(fetchStart());
      console.log("response==", url, data);
      let response = await api.deleteApi(url, data);
      console.log("response==", response, data, url);
      if (response.status == "success") {
        dispatch(fetchSuccess(<IntlMessages id="error.data_deleted" />));
        if (Isupdate != true) {
          dispatch(deleteData(url, data.id));
        }
        if (callback) {
          callback();
        }
      } else {
        dispatch(fetchError(response.message));
      }
    } catch (err) {
      console.log(err);
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };
};

export const setTable = data => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_TABLE,
        payload: data
      });
    } catch (err) {}
  };
};

export const setFilter = data => {
  return async dispatch => {
    try {
      dispatch({
        type: SET_FILTER,
        payload: data
      });
    } catch (err) {}
  };
};
