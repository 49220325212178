import React from "react";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet
} from "@react-pdf/renderer";
import {getNumberFormat, getSixDigit} from "../../utils/commonHelper";
import {getDate} from "../../utils/dateHelper";
// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey"
  },
  watermark: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%"
  },
  watermarkpending: {
    color: "#000",
    fontSize: 80,
    fontWeight: "bold",
    textTransform: "uppercase",
    transform: "rotate(-45deg)",
    userSelect: "none",
    opacity: 0.2
  },
  watermarkrejected: {
    color: "#000",
    fontSize: 80,
    fontWeight: "bold",
    textTransform: "uppercase",
    transform: "rotate(-45deg)",
    userSelect: "none",
    opacity: 0.2
  },

  headersection: {
    flexDirection: "row",
    width: "100%"
  },
  leftheadersection: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  image: {
    objectFit: "cover"
  },
  leftinnerheadersection: {
    width: 80,
    height: 40,
    backgroundColor: "#ccc"
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    paddingTop: 10
  },
  sub_heading: {
    fontSize: 10,
    paddingTop: 10,
    fontWeight: 200
  },
  sub_sub_heading: {
    fontSize: 10,
    paddingTop: 10,
    fontWeight: 200,
    marginBottom: 10
  },
  bars: {
    width: "100%",
    height: 2,
    backgroundColor: "#000",
    marginTop: 1
  },
  rightheadersection: {
    width: "50%"
  },
  rightheadersectionbox: {
    width: "49%",
    marginLeft: "1%",
    marginTop: 5
  },
  header: {},
  leftheadersection: {
    width: "50%"
  },
  leftheadersectionbox: {
    width: "49%",
    marginRight: "1%",
    marginTop: 5
  },
  bars_div: {
    marginTop: 10
  },
  right_text: {
    fontSize: 18,
    paddingLeft: 10,
    fontWeight: "bold"
  },
  right_web: {
    paddingLeft: 35,
    fontSize: 12,
    paddingTop: 20
  },
  border_cover: {
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 10,
    padding: 15,
    height: 170
  },
  border_div: {
    flexDirection: "row",
    marginTop: 2,
    marginBottom: 2
  },
  border_left: {
    width: 70,
    fontSize: 12
  },
  border_left1: {
    width: 120,
    fontSize: 12
  },
  border_right: {
    fontSize: 10,
    marginRight: 50
  },

  border_right1: {
    fontSize: 10,
    marginRight: 50,
    marginTop: 1.5
  },
  bottomBox: {
    flexDirection: "row"
  },
  bottomBoxText: {
    fontSize: 12,
    padding: 5
  },
  headerTable: {
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#ccc",
    marginTop: 10
  },
  bodyTable: {
    width: "100%",
    flexDirection: "row"
  },
  headerTableTd: {
    width: "14.2%",
    fontSize: 12,
    elevation: 5,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  bodyTableTd: {
    width: "14.2%",
    fontSize: 10,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },

  headerDescriptionTd: {
    width: "25%",
    fontSize: 12,
    margin: 2
  },
  headerCostcenterTd: {
    width: "15%",
    fontSize: 12,
    margin: 2
  },
  headerTableTdtext: {
    textAlign: "center"
  },
  bodyDescriptionTd: {
    width: "25%",
    fontSize: 10,
    margin: 2
  },
  bodyCostcenterTd: {
    width: "15%",
    fontSize: 10,
    margin: 2
  },
  subtotal_div: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  sub_total: {
    fontSize: 14,
    marginTop: 10,
    paddingLeft: 15,
    paddingRight: 15
  },
  bottom: {
    width: "100%",
    marginTop: 10,
    marginBottom: 20
  },
  footer: {
    position: "absolute",
    bottom: 5,
    left: 35,
    width: "100%"
  },
  footer_blacktext: {
    fontSize: 10,
    width: "100%"
  },
  footer_redtext: {
    fontSize: 10,
    color: "red",
    width: "100%"
  },

  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey"
  },

  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  headersection: {
    flexDirection: "row",
    width: "100%"
  },
  headersections: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between"
  },
  leftheadersection: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  image: {
    objectFit: "cover"
  },
  leftinnerheadersection: {
    width: 80,
    height: 40,
    backgroundColor: "#ccc"
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    paddingTop: 10
  },
  sub_heading: {
    fontSize: 10,
    paddingTop: 10,
    fontWeight: 200
  },
  sub_sub_heading: {
    fontSize: 10,
    paddingTop: 10,
    fontWeight: 200,
    marginBottom: 10
  },
  bars: {
    width: "100%",
    height: 2,
    backgroundColor: "#000",
    marginTop: 1
  },
  rightheadersection: {
    width: "50%"
  },
  rightheadersectionbox: {
    width: "49%",
    marginLeft: "1%",
    marginTop: 5
  },
  header: {},
  leftheadersection_div: {
    flexDirection: "row"
  },
  leftheadersection: {
    width: "50%"
  },
  leftheadersectionbox: {
    width: "49%",
    marginRight: "1%",
    marginTop: 5
  },
  bars_div: {
    marginTop: 10
  },
  right_text: {
    fontSize: 18,
    paddingLeft: 10,
    fontWeight: "bold"
  },
  right_web: {
    paddingLeft: 35,
    fontSize: 12,
    paddingTop: 20
  },
  border_cover: {
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 10,
    padding: 15,
    height: 170
  },
  border_cover1: {
    padding: 15
  },
  border_div: {
    flexDirection: "row",
    marginTop: 2,
    marginBottom: 2
  },
  border_divs: {
    flexDirection: "column",
    marginTop: 2,
    marginBottom: 2,
    width: "44%",
    marginLeft: "3%",
    marginRight: "3%"
  },
  border_left: {
    width: 70,
    fontSize: 12
  },
  border_left1: {
    width: 120,
    fontSize: 12
  },
  border_left11: {
    width: "100%",
    fontSize: 12,
    textAlign: "center"
  },
  border_left2: {
    width: 120,
    fontSize: 12
  },
  border_left_caps: {
    // textTransform: "uppercase",
    fontSize: 12,
    width: 120
  },
  border_right: {
    fontSize: 10,
    marginRight: 50
  },

  border_right1: {
    fontSize: 10,
    marginRight: 50,
    marginTop: 1.5,
    marginRight: 20,
    width: 150
  },
  headerTableTd1: {
    width: "12.5%",
    fontSize: 10,
    elevation: 5,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  bodyTableTd1: {
    width: "12.5%",
    fontSize: 10,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  headerTableTdtext: {
    textAlign: "center",
    fontSize: 10
  },
  bottom1: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50
  },
  bottom2: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50
  }
});

// Create Document Component
export const Electronicdispatchdocument = props => {
  let {
    photo,
    selectedCompany,
    purchaseorderNo,
    items,
    subTotalPrice,
    selectTax,
    taxPrice,
    totalTaxPrice,
    totalInvoice,
    selectedCondition,
    selectedProvider,
    selectedCostCenter,
    selectedRegion,
    observation,
    emissionDate,
    createdBy,
    days,
    entryDate,
    orderNo,
    documentNo,
    spareData,
    purchaseCreatedBy,
    warehouse,
    receptionCostCenter,
    receiptItems,
    dispatchGuideList,
    ElectronicorderNo,
    electronicItems,
    electronicInvoiceTotal,
    approval_status_label,
    intl
  } = props;

  let categoryItems = [];
  let count = 0;
  for (let data in electronicItems) {
    if (
      electronicItems[data].category &&
      categoryItems.indexOf(electronicItems[data].category) == -1
    ) {
      categoryItems.push(electronicItems[data].category);
    }
  }
  electronicItems = electronicItems.filter(item => 0 < item.tempPurchaseQty);

  return (
    <Document>
      {dispatchGuideList &&
        dispatchGuideList.map((data, i) => {
          return (
            <Page style={styles.body} key={i}>
              {data.isPurchase && (
                <View>
                  <View style={styles.header} fixed>
                    <View style={styles.headersection}>
                      <View style={styles.leftheadersection}>
                        {/* {photo &&
                        photo != "" && (
                          <View style={styles.leftinnerheadersection}>
                            {selectedCompany &&
                            selectedCompany.logo && (
                              <Image
                                style={styles.image}
                                allowDangerousPaths={true}
                                src={photo}
                                alt="images"
                              />
                            )}
                          </View>
                        )} */}
                        <View style={styles.leftinnerheadersection}>
                          <Image
                            style={styles.image}
                            allowDangerousPaths={true}
                            src={"/images/flexlogo.jpg"}
                          />
                        </View>
                        <View style={styles.rightinnerheadersection}>
                          <Text style={styles.heading}>
                            {selectedCompany && selectedCompany.name}
                          </Text>
                          <Text style={styles.sub_heading}>
                            {selectedCompany && selectedCompany.address}
                          </Text>
                          <Text style={styles.sub_heading}>
                            {selectedCompany && selectedCompany.city}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View style={styles.headersection}>
                      <View style={styles.leftheadersection}>
                        <View style={styles.bars_div}>
                          <View style={styles.bars} />
                          <View style={styles.bars} />
                        </View>
                      </View>

                      <View style={styles.rightheadersection}>
                        <Text style={styles.right_text}>
                          {intl.formatMessage({
                            id: "purchaserequests.purchase_orderno"
                          })}{" "}
                          {getSixDigit(
                            data.selectOfficeguideno.purchaseorder.invoiceNo
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.headersection}>
                    <View style={styles.leftheadersectionbox}>
                      <Text style={{fontSize: 14}}>
                        {intl.formatMessage({id: "contractors.supplier"})}
                      </Text>
                      <View style={styles.border_cover}>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({id: "dashboard.name"})}
                          </Text>
                          <Text style={styles.border_right}>
                            : {selectedProvider && selectedProvider.name}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({id: "dashboard.address"})}
                          </Text>
                          <Text style={styles.border_right}>
                            : {selectedProvider && selectedProvider.address}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({id: "dashboard.city"})}
                          </Text>
                          <Text style={styles.border_right}>
                            : {selectedProvider && selectedProvider.city}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({id: "pdf.phone"})}
                          </Text>
                          <Text style={styles.border_right}>
                            : {selectedProvider && selectedProvider.phone}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>R.U.T</Text>
                          <Text style={styles.border_right}>
                            :{" "}
                            {selectedProvider &&
                              selectedProvider.providerNumber}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.rightheadersectionbox}>
                      <Text style={{fontSize: 14}}>
                        {intl.formatMessage({id: "pdf.sendto"})}
                      </Text>
                      <View style={styles.border_cover}>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({id: "dashboard.name"})}
                          </Text>
                          <Text style={styles.border_right1}>
                            : {selectedCompany && selectedCompany.name}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({id: "dashboard.address"})}
                          </Text>
                          <Text style={styles.border_right1}>
                            : {selectedCompany && selectedCompany.address}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({id: "dashboard.city"})}
                          </Text>
                          <Text style={styles.border_right1}>
                            : {selectedCompany && selectedCompany.city}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({id: "pdf.phone"})}
                          </Text>
                          <Text style={styles.border_right1}>
                            : {selectedCompany && selectedCompany.telephone}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>R.U.T </Text>
                          <Text style={styles.border_right1}>
                            : {selectedCompany && selectedCompany.companyRutId}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({id: "sidebar.region"})}
                          </Text>
                          <Text style={styles.border_right1}>
                            : {data.region}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left}>
                            {intl.formatMessage({
                              id: "sidebar.costcenter"
                            })}{" "}
                          </Text>
                          <Text style={styles.border_right1}>
                            : {data.costcenter}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View style={styles.headerTable}>
                    <View style={styles.headerTableTd}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "vehicle.serial_no"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "spares.code"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "pdf.qty"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "company.description"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "pdf.unit_price"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "purchaserequests.subTotal"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "total"})}
                      </Text>
                    </View>
                  </View>
                  {data.selectOfficeguideno.purchaseorder &&
                    data.selectOfficeguideno.purchaseorder.items.map(
                      (item, i) => {
                        return (
                          <View style={styles.bodyTable} key={i}>
                            <View style={styles.bodyTableTd}>
                              <Text style={styles.headerTableTdtext}>
                                {i + 1}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd}>
                              <Text style={styles.headerTableTdtext}>
                                {item.code}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd}>
                              <Text style={styles.headerTableTdtext}>
                                {item.unit}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd}>
                              <Text style={styles.headerTableTdtext}>
                                {item.description}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd}>
                              <Text style={styles.headerTableTdtext}>
                                {getNumberFormat(item.temp_unit_price)}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd}>
                              <Text style={styles.headerTableTdtext}>
                                {getNumberFormat(item.sub_total)}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd}>
                              <Text style={styles.headerTableTdtext}>
                                {getNumberFormat(item.total)}
                              </Text>
                            </View>
                          </View>
                        );
                      }
                    )}

                  <View style={styles.bars_div}>
                    <View style={styles.bars} />
                    <View style={styles.bars} />
                  </View>

                  <View style={styles.headersection}>
                    <View style={styles.leftheadersection}>
                      <Text style={{fontSize: 12, marginTop: 10}}>
                        Obs: {observation}
                      </Text>
                      {selectedCondition &&
                      selectedCondition.value == "debit" && (
                        <Text style={{fontSize: 16, marginTop: 15}}>
                          {intl.formatMessage({id: "pdf.payment_detail"})} :{" "}
                          {selectedCondition && selectedCondition.label}
                        </Text>
                      )}
                      {selectedCondition &&
                      selectedCondition.value == "credit" && (
                        <Text style={{fontSize: 16, marginTop: 15}}>
                          {intl.formatMessage({id: "pdf.payment_detail"})} :{" "}
                          {selectedCondition && selectedCondition.label} {days}{" "}
                          {intl.formatMessage({id: "equipment.days"})}
                        </Text>
                      )}
                    </View>

                    <View style={styles.rightheadersection}>
                      <View style={styles.subtotal_div}>
                        <Text style={styles.sub_total}>
                          {intl.formatMessage({
                            id: "purchaserequests.subTotal"
                          })}
                        </Text>
                        <Text style={styles.sub_total}>
                          {getNumberFormat(
                            data.selectOfficeguideno.purchaseorder.subTotal
                          )}
                        </Text>
                      </View>
                      <View style={styles.subtotal_div}>
                        <Text style={styles.sub_total}>
                          {selectTax && selectTax.label}
                        </Text>
                        <Text style={styles.sub_total}>
                          {getNumberFormat(
                            data.selectOfficeguideno.purchaseorder.taxPrice
                          )}
                        </Text>
                      </View>
                      <View style={styles.subtotal_div}>
                        <Text style={styles.sub_total}>TOTAL</Text>
                        <Text style={styles.sub_total}>
                          {getNumberFormat(
                            data.selectOfficeguideno.purchaseorder.totalTaxPrice
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View style={styles.bars_div}>
                    <View style={styles.bars} />
                    <View style={styles.bars} />
                  </View>

                  <View style={styles.bottom}>
                    <View style={styles.border_div}>
                      <Text style={styles.border_left1}>
                        {intl.formatMessage({id: "pdf.approved_by"})}
                      </Text>
                      <Text
                        style={{
                          borderBottomColor: "#000",
                          borderBottomWidth: 2,
                          width: 200
                        }}
                      />
                    </View>
                    <View style={styles.border_div}>
                      <Text style={styles.border_left1}>
                        {intl.formatMessage({id: "date"})}:
                      </Text>
                      <Text style={styles.border_right}>
                        {getDate(emissionDate)}
                      </Text>
                    </View>
                    <View style={styles.border_div}>
                      <Text style={styles.border_left1}>
                        {intl.formatMessage({id: "pdf.withdraw"})}
                      </Text>
                      <Text style={styles.border_right}>
                        {purchaseCreatedBy}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={styles.footer} fixed>
                <Text style={styles.footer_blacktext}>
                  {intl.formatMessage({id: "pdf.footer_first"})}{" "}
                  dteflex@desis.cl
                </Text>
                <Text style={styles.footer_redtext}>
                  {intl.formatMessage({id: "pdf.footer_second"})}
                </Text>
                <View
                  style={{flexDirection: "row", justifyContent: "flex-end"}}
                >
                  <Text style={{fontSize: 10}}>
                    {intl.formatMessage({id: "pdf.page"})}
                  </Text>
                  <Text
                    style={{fontSize: 10, paddingLeft: 10}}
                    render={({pageNumber}) => ` ${pageNumber} `}
                    fixed
                  />
                  <Text style={{fontSize: 10}}>
                    {intl.formatMessage({id: "pdf.of"})}
                  </Text>
                  <Text
                    style={{fontSize: 10, paddingLeft: 10}}
                    render={({totalPages}) => ` ${totalPages}`}
                    fixed
                  />
                </View>
              </View>

              {!data.isPurchase && (
                <View>
                  <View style={styles.header}>
                    <View style={styles.headersections}>
                      <View style={styles.leftheadersection}>
                        {/* {photo &&
                        photo != "" && (
                          <View style={styles.leftinnerheadersection}>
                            {selectedCompany &&
                            selectedCompany.logo && (
                              <Image
                                style={styles.image}
                                allowDangerousPaths={true}
                                src={photo}
                                alt="images"
                              />
                            )}
                          </View>
                        )} */}
                        <View style={styles.leftinnerheadersection}>
                          <Image
                            style={styles.image}
                            allowDangerousPaths={true}
                            src={"/images/flexlogo.jpg"}
                          />
                        </View>
                        <View style={styles.rightinnerheadersection}>
                          <Text style={styles.heading}>
                            {selectedCompany && selectedCompany.name}
                          </Text>
                          <Text style={styles.sub_heading}>
                            {selectedCompany && selectedCompany.address}
                          </Text>
                          <Text style={styles.sub_heading}>
                            {selectedCompany && selectedCompany.city}
                          </Text>
                        </View>
                      </View>

                      <View>
                        <Text>
                          {intl.formatMessage({id: "pdf.folio_no"})}:{" "}
                          {getSixDigit(data.selectOfficeguideno.invoiceNo)}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{flexDirection: "row", justifyContent: "center"}}
                    >
                      <Text>
                        {intl.formatMessage({id: "pdf.reception_guide"})}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.headersection}>
                    <View style={styles.leftheadersectionbox}>
                      <View style={styles.border_cover1}>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left_caps}>
                            {intl.formatMessage({id: "pdf.date"})}
                          </Text>
                          <Text style={styles.border_right}>
                            : {getDate(entryDate)}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left_caps}>
                            {intl.formatMessage({id: "sidebar.warehouse"})}
                          </Text>
                          <Text style={styles.border_right}>: {warehouse}</Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left_caps}>
                            {intl.formatMessage({id: "pdf.rut_document"})}
                          </Text>
                          <Text style={styles.border_right}>
                            :{" "}
                            {selectedProvider &&
                              selectedProvider.providerNumber}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left_caps}>
                            {intl.formatMessage({
                              id: "electronicbill.provider_name"
                            })}
                          </Text>
                          <Text style={styles.border_right}>
                            : {selectedProvider && selectedProvider.name}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View style={styles.rightheadersectionbox}>
                      <View style={styles.border_cover1}>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left2}>
                            {intl.formatMessage({
                              id: "sidebar.supplierofficeguide"
                            })}
                          </Text>
                          <Text style={styles.border_right1}>
                            : {getSixDigit(data.selectOfficeguideno.label)}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left2}>
                            {intl.formatMessage({id: "pdf.user"})}
                          </Text>
                          <Text style={styles.border_right1}>
                            : {purchaseCreatedBy}
                          </Text>
                        </View>
                        <View style={styles.border_div}>
                          <Text style={styles.border_left2}>
                            {intl.formatMessage({id: "sidebar.costcenter"})}
                          </Text>
                          <Text style={styles.border_right1}>
                            : {data.costcenter}
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={styles.headerTable}>
                    <View style={styles.headerTableTd1}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "vehicle.serial_no"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd1}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "spares.code"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd1}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "company.description"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd1}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "pdf.qty"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd1}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "pdf.unit_price"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd1}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "total"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd1}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "riskmanagement.category"})}
                      </Text>
                    </View>
                    <View style={styles.headerTableTd1}>
                      <Text style={styles.headerTableTdtext}>
                        {intl.formatMessage({id: "spares.subCategory"})}
                      </Text>
                    </View>
                  </View>

                  {data.items &&
                    data.items.map((item, i) => {
                      if (0 < item.tempPurchaseQty) {
                        count = count + 1;
                        return (
                          <View style={styles.bodyTable} key={i}>
                            <View style={styles.bodyTableTd1}>
                              <Text style={styles.headerTableTdtext}>
                                {count}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd1}>
                              <Text style={styles.headerTableTdtext}>
                                {item.code}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd1}>
                              <Text style={styles.headerTableTdtext}>
                                {item.description}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd1}>
                              <Text style={styles.headerTableTdtext}>
                                {item.tempPurchaseQty}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd1}>
                              <Text style={styles.headerTableTdtext}>
                                {getNumberFormat(item.unit_price)}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd1}>
                              <Text style={styles.headerTableTdtext}>
                                {getNumberFormat(item.total)}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd1}>
                              <Text style={styles.headerTableTdtext}>
                                {item.category ? item.category : ""}
                              </Text>
                            </View>
                            <View style={styles.bodyTableTd1}>
                              <Text style={styles.headerTableTdtext}>
                                {item.sub_category ? item.sub_category : ""}
                              </Text>
                            </View>
                          </View>
                        );
                      }
                    })}

                  <View style={styles.bars_div}>
                    <View style={styles.bars} />
                    <View style={styles.bars} />
                  </View>
                  <View style={styles.bottom1}>
                    <View style={styles.border_divs}>
                      <Text
                        style={{
                          borderBottomColor: "#000",
                          borderBottomWidth: 2,
                          width: "100%",
                          marginBottom: 10
                        }}
                      />
                      <Text style={styles.border_left11}>
                        {intl.formatMessage({id: "pdf.received_by_cellar"})}
                      </Text>
                    </View>
                    <View style={styles.border_divs}>
                      <Text
                        style={{
                          borderBottomColor: "#000",
                          borderBottomWidth: 2,
                          width: "100%",
                          marginBottom: 10
                        }}
                      />
                      <Text style={styles.border_left11}>
                        {intl.formatMessage({id: "pdf.internal_control"})}
                      </Text>
                    </View>
                  </View>

                  <View style={styles.bottom2}>
                    <View style={styles.border_divs}>
                      <View style={styles.border_divss}>
                        <Text style={styles.border_left11}>
                          {intl.formatMessage({id: "riskmanagement.category"})}
                        </Text>
                        <Text
                          style={{
                            borderBottomColor: "#000",
                            borderBottomWidth: 2,
                            width: "100%",
                            marginBottom: 10
                          }}
                        />
                      </View>
                      {data.category &&
                        data.category.map((item, i) => {
                          return (
                            <Text key={i} style={styles.border_left11}>
                              {item}
                            </Text>
                          );
                        })}
                    </View>
                    <View style={styles.border_divs}>
                      <View style={styles.border_divss}>
                        <Text style={styles.border_left11}>
                          {intl.formatMessage({id: "total"})}
                        </Text>
                        <Text
                          style={{
                            borderBottomColor: "#000",
                            borderBottomWidth: 2,
                            width: "100%",
                            marginBottom: 10
                          }}
                        />
                      </View>
                      <Text style={styles.border_left11}>
                        {getNumberFormat(data.total_invoice)}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
              <View style={styles.footer} fixed>
                <Text style={styles.footer_blacktext}>
                  {intl.formatMessage({id: "pdf.footer_first"})}{" "}
                  dteflex@desis.cl
                </Text>
                <Text style={styles.footer_redtext}>
                  {intl.formatMessage({id: "pdf.footer_second"})}
                </Text>
                <View
                  style={{flexDirection: "row", justifyContent: "flex-end"}}
                >
                  {/* <Text style={{fontSize:10}}>{intl.formatMessage({ id: "pdf.page" })} 2 {intl.formatMessage({ id: "pdf.of" })} 2 </Text> */}
                  <Text style={{fontSize: 10}}>
                    {intl.formatMessage({id: "pdf.page"})}
                  </Text>
                  <Text
                    style={{fontSize: 10, paddingLeft: 10}}
                    render={({pageNumber}) => ` ${pageNumber} `}
                    fixed
                  />
                  <Text style={{fontSize: 10}}>
                    {intl.formatMessage({id: "pdf.of"})}
                  </Text>
                  <Text
                    style={{fontSize: 10, paddingLeft: 10}}
                    render={({totalPages}) => ` ${totalPages}`}
                    fixed
                  />
                </View>
              </View>
              <View style={styles.watermark}>
                {approval_status_label == "rejected" && (
                  <Text style={styles.watermarkpending}>
                    {intl.formatMessage({id: "pdf.rejected"})}
                  </Text>
                )}
                {approval_status_label == "pending" && (
                  <Text style={styles.watermarkrejected}>
                    {intl.formatMessage({id: "contractors.pending"})}
                  </Text>
                )}
              </View>
            </Page>
          );
        })}
      <Page style={styles.body}>
        <View style={styles.header}>
          <View style={styles.headersections}>
            <View style={styles.leftheadersection}>
              {/* {photo &&
              photo != "" && (
                <View style={styles.leftinnerheadersection}>
                  {selectedCompany &&
                  selectedCompany.logo && (
                    <Image
                      style={styles.image}
                      allowDangerousPaths={true}
                      src={photo}
                      alt="images"
                    />
                  )}
                </View>
              )} */}
              <View style={styles.leftinnerheadersection}>
                <Image
                  style={styles.image}
                  allowDangerousPaths={true}
                  src={"/images/flexlogo.jpg"}
                />
              </View>
              <View style={styles.rightinnerheadersection}>
                <Text style={styles.heading}>
                  {selectedCompany && selectedCompany.name}
                </Text>
                <Text style={styles.sub_heading}>
                  {selectedCompany && selectedCompany.address}
                </Text>
                <Text style={styles.sub_heading}>
                  {selectedCompany && selectedCompany.city}
                </Text>
              </View>
            </View>

            <View>
              <Text>
                {intl.formatMessage({id: "pdf.folio_no"})}:{" "}
                {getSixDigit(ElectronicorderNo)}
              </Text>
            </View>
          </View>
          <View style={{flexDirection: "row", justifyContent: "center"}}>
            <Text>{intl.formatMessage({id: "pdf.reception_guide"})}</Text>
          </View>
        </View>
        <View style={styles.headersection}>
          <View style={styles.leftheadersectionbox}>
            <View style={styles.border_cover1}>
              <View style={styles.border_div}>
                <Text style={styles.border_left_caps}>
                  {intl.formatMessage({id: "pdf.date"})}
                </Text>
                <Text style={styles.border_right}>: {getDate(entryDate)}</Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left_caps}>
                  {intl.formatMessage({id: "sidebar.warehouse"})}
                </Text>
                <Text style={styles.border_right}>: {warehouse}</Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left_caps}>
                  {intl.formatMessage({id: "pdf.rut_document"})}
                </Text>
                <Text style={styles.border_right}>
                  : {selectedProvider && selectedProvider.providerNumber}
                </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left_caps}>
                  {intl.formatMessage({id: "electronicbill.provider_name"})}
                </Text>
                <Text style={styles.border_right}>
                  : {selectedProvider && selectedProvider.name}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.rightheadersectionbox}>
            <View style={styles.border_cover1}>
              <View style={styles.border_div}>
                <Text style={styles.border_left2}>
                  {intl.formatMessage({id: "sidebar.electronicinvoice"})}
                </Text>
                <Text style={styles.border_right1}>
                  : {getSixDigit(documentNo)}
                </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left2}>
                  {intl.formatMessage({id: "pdf.user"})}
                </Text>
                <Text style={styles.border_right1}>: {purchaseCreatedBy}</Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left2}>
                  {intl.formatMessage({id: "sidebar.costcenter"})}
                </Text>
                <Text style={styles.border_right1}>: {selectedCostCenter}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerTable}>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "vehicle.serial_no"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "spares.code"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "company.description"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "pdf.qty"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "pdf.unit_price"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "total"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "riskmanagement.category"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "spares.subCategory"})}
            </Text>
          </View>
        </View>

        {electronicItems &&
          electronicItems.map((item, i) => {
            return (
              <View style={styles.bodyTable} key={i}>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}>{i + 1}</Text>
                </View>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}>{item.code}</Text>
                </View>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}>
                    {item.description}
                  </Text>
                </View>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}>
                    {item.tempPurchaseQty}
                  </Text>
                </View>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}>
                    {getNumberFormat(item.unit_price)}
                  </Text>
                </View>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}>
                    {getNumberFormat(item.total)}
                  </Text>
                </View>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}>
                    {item.category ? item.category : ""}
                  </Text>
                </View>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}>
                    {item.sub_category ? item.sub_category : ""}
                  </Text>
                </View>
              </View>
            );
          })}

        <View style={styles.bars_div}>
          <View style={styles.bars} />
          <View style={styles.bars} />
        </View>
        <View style={styles.bottom1}>
          <View style={styles.border_divs}>
            <Text
              style={{
                borderBottomColor: "#000",
                borderBottomWidth: 2,
                width: "100%",
                marginBottom: 10
              }}
            />
            <Text style={styles.border_left11}>
              {intl.formatMessage({id: "pdf.received_by_cellar"})}
            </Text>
          </View>
          <View style={styles.border_divs}>
            <Text
              style={{
                borderBottomColor: "#000",
                borderBottomWidth: 2,
                width: "100%",
                marginBottom: 10
              }}
            />
            <Text style={styles.border_left11}>
              {intl.formatMessage({id: "pdf.internal_control"})}
            </Text>
          </View>
        </View>

        <View style={styles.bottom2}>
          <View style={styles.border_divs}>
            <View style={styles.border_divss}>
              <Text style={styles.border_left11}>
                {intl.formatMessage({id: "riskmanagement.category"})}
              </Text>
              <Text
                style={{
                  borderBottomColor: "#000",
                  borderBottomWidth: 2,
                  width: "100%",
                  marginBottom: 10
                }}
              />
            </View>
            {categoryItems &&
              categoryItems.map((item, i) => {
                return (
                  <Text key={i} style={styles.border_left11}>
                    {item}
                  </Text>
                );
              })}
          </View>
          <View style={styles.border_divs}>
            <View style={styles.border_divss}>
              <Text style={styles.border_left11}>
                {intl.formatMessage({id: "total"})}
              </Text>
              <Text
                style={{
                  borderBottomColor: "#000",
                  borderBottomWidth: 2,
                  width: "100%",
                  marginBottom: 10
                }}
              />
            </View>
            <Text style={styles.border_left11}>
              {getNumberFormat(electronicInvoiceTotal)}
            </Text>
          </View>
        </View>
        <View style={styles.footer} fixed>
          <Text style={styles.footer_blacktext}>
            {intl.formatMessage({id: "pdf.footer_first"})} dteflex@desis.cl
          </Text>
          <Text style={styles.footer_redtext}>
            {intl.formatMessage({id: "pdf.footer_second"})}
          </Text>
          <View style={{flexDirection: "row", justifyContent: "flex-end"}}>
            <Text style={{fontSize: 10}}>
              {intl.formatMessage({id: "pdf.page"})}
            </Text>
            <Text
              style={{fontSize: 10, paddingLeft: 10}}
              render={({pageNumber}) => ` ${pageNumber} `}
              fixed
            />
            <Text style={{fontSize: 10}}>
              {intl.formatMessage({id: "pdf.of"})}
            </Text>
            <Text
              style={{fontSize: 10, paddingLeft: 10}}
              render={({totalPages}) => ` ${totalPages}`}
              fixed
            />
          </View>
        </View>

        <View style={styles.rightheadersectionbox}>
          <Text style={styles.text} />
        </View>
        <View style={styles.watermark}>
          {approval_status_label == "rejected" && (
            <Text style={styles.watermarkpending}>
              {intl.formatMessage({id: "pdf.rejected"})}
            </Text>
          )}
          {approval_status_label == "pending" && (
            <Text style={styles.watermarkrejected}>
              {intl.formatMessage({id: "contractors.pending"})}
            </Text>
          )}
        </View>
      </Page>
    </Document>
  );
};
