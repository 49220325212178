import React, {useState, useEffect, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";

import Box from "@material-ui/core/Box";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import {useIntl} from "react-intl";
import {getNumberFormat} from "../../../@jumbo/utils/commonHelper";

const Unitscustomermechanic = () => {
  const {dashboard} = useSelector(({common}) => common);
  const [ purchasePieData, setPurchasePieData ] = useState(null);
  const intl = useIntl();

  useEffect(
    () => {
      let totalMechanicEquipment = 0;
      let mechanicPercentageData = [];

      if (dashboard.percentageClientChecked) {
        for (let item of dashboard.percentageClientChecked) {
          if (item.regionId && 0 < item.regionId.length) {
            let regionId = item.regionId[0];
            let regionName = dashboard.regionList.find(
              item => item.id == regionId
            );

            if (regionName) {
              totalMechanicEquipment =
                totalMechanicEquipment + Number(item.count);
              mechanicPercentageData.push({
                name: regionName.name,
                [intl.formatMessage({
                  id: "sidebar.assigned_equipment"
                })]: Number(item.count)
              });
            }
          }
        }
      }
      for (let data in mechanicPercentageData) {
        mechanicPercentageData[data][
          intl.formatMessage({id: "sidebar.assigned_equipment"})
        ] =
          Number(
            mechanicPercentageData[data][
              intl.formatMessage({id: "sidebar.assigned_equipment"})
            ]
          ) /
          Number(totalMechanicEquipment) *
          100;
        mechanicPercentageData[data][
          intl.formatMessage({id: "sidebar.assigned_equipment"})
        ] = Number(
          mechanicPercentageData[data][
            intl.formatMessage({id: "sidebar.assigned_equipment"})
          ]
        ).toFixed(2);
      }
      let purchasepieData = mechanicPercentageData;
      setPurchasePieData(purchasepieData);
    },
    [ dashboard ]
  );

  return (
    <Grid item xs={12}>
      <Box>
        <ResponsiveContainer height={300} width="100%">
          <BarChart
            width={500}
            height={300}
            data={purchasePieData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <XAxis dataKey="name" tick={false} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey={intl.formatMessage({id: "sidebar.assigned_equipment"})}
              fill="#82ca9d"
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Grid>
  );
};

export default Unitscustomermechanic;
