import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import CmtButtons from '../CmtButtons'; 

import Modal from '@material-ui/core/Modal';

function rand() {
  return Math.round(Math.random() * 14) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `50%`,
    transform: `translate(-${top}%, -50%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border:'none!important',
    padding: 0,
    border: '2px solid #fff',
    outline: 'none'
  },
  modal: {
    "&:focus": {
      outline: "none"
    }
  },
  modalh2:{
    borderBottom: 'solid 1px #e1e8ea',
    textAlign: 'center',
    padding: 20,
    fontSize:16
  },
  dateFiltertitle:{
    textAlign: 'center',
    padding: '20px 20px 34px 20px',
    width: '100%',
    fontSize: 16
  },
  buttons:{
    width: '100%',
    textAlign: 'center',
    marginBottom:40,
    '& .MuiBox-root':{
      display: 'inline',
      textAlign: 'center'
    }
  },

}));

const DeleteModalCard = props => {
  const classes = useStyles(props);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const {
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    required,
    onChange,
    helperText,
    variant,
    error,
    valueKey,
    labelKey,
    backgroundColor,
    label,
    open,
    onDelete,
    onCancel,
    ...rest
  } = props;

  const handleOpen = () => {
    //setOpen(true);
  };

  const handleClose = () => {
    //setOpen(false);
  };
  const buttons = [
    { label: <IntlMessages id="actions.cancel" />,  color: 'fullcancel',onClick: onCancel},
    { label: <IntlMessages id="actions.delete" />, color: 'remove',onClick: onDelete},
  ];


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 className={classes.modalh2} id="simple-modal-title"><IntlMessages id="actions.delete" /></h2>
          <p id="simple-modal-description">
          <GridContainer container xs={12} classes={{ root: 'dateFilter' }}>
              <Grid className={classes.dateFiltertitle}><IntlMessages id="alert.delete_confirm" /></Grid>
          </GridContainer>
          <Grid xs={12} className={classes.buttons}>
              <CmtButtons items={buttons} />
          </Grid>
          
          </p>
        </div>
      </Modal>
    </div>
  );
};

DeleteModalCard.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

DeleteModalCard.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: 'small',
  border: 'none',
  error: false,
  helperText: '',
  valueKey: 'id',
  labelKey: 'title',
  backgroundColor: 'transparent',
};

export default DeleteModalCard;
