import React, {useState, useEffect, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {ResponsiveContainer, PieChart, Pie, Cell, Tooltip} from "recharts";
import {useIntl} from "react-intl";
import {getNumberFormat} from "../../../@jumbo/utils/commonHelper";

const COLORS = [ "#0088FE", "#00C49F", "#FFBB28", "#FF8042" ];

const Percentagecompliance = () => {
  const {dashboard} = useSelector(({common}) => common);
  const [ percentageWorkOrder, setPercentageWorkOrder ] = useState(null);
  const intl = useIntl();

  useEffect(
    () => {
      let completeOrder = 0;
      let createdOrder = 0;
      if (dashboard.percentageComplianceChecked) {
        completeOrder = dashboard.percentageComplianceChecked.find(
          item => item.status == "completed"
        );
        createdOrder = dashboard.percentageComplianceChecked.find(
          item => item.status == "in_progress"
        );
        completeOrder = completeOrder ? completeOrder.count : 0;
        createdOrder = createdOrder ? createdOrder.count : 0;
      }

      let percentageWorkOrder = [
        {
          name: intl.formatMessage({id: "dashboard.completed_wo"}),
          value: Number(completeOrder)
        },
        {
          name: intl.formatMessage({id: "dashboard.pending_wo"}),
          value: Number(createdOrder)
        }
      ];
      setPercentageWorkOrder(percentageWorkOrder);
    },
    [ dashboard ]
  );

  return (
    <Grid item xs={12}>
      <Box>
        <ResponsiveContainer height={300} width="100%">
          {/* <PieChart width={800} height={400} onMouseEnter={this.onPieEnter}>
            <Pie
              data={data}
              cx={120}
              cy={200}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              dataKey="value"
            >
              {
                data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
              }
            </Pie>
            </PieChart> */}

          {percentageWorkOrder && (
            <PieChart>
              <Pie
                data={percentageWorkOrder}
                startAngle={220}
                endAngle={-40}
                innerRadius={75}
                outerRadius={95}
                fill="#8884d8"
                paddingAngle={1}
                dataKey="value"
              >
                {percentageWorkOrder.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          )}
        </ResponsiveContainer>
      </Box>
    </Grid>
  );
};

export default Percentagecompliance;
