import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_DATA,
  SET_FILTER,
  EDIT_DATA,
  RESET_DATA,
  RESET_MODULE_DATA,
  DELETE_DATA,
  SET_TABLE
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
  initialURL: "/",
  error: "",
  loading: false,
  company: [],
  modules: [],
  rolespermissions: [],
  editData: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: "", loading: true};
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: "",
        loading: false,
        message: action.payload ? action.payload : ""
      };
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload};
    }
    case RESET_DATA: {
      return {...state, editData: {}};
    }
    case RESET_MODULE_DATA: {
      return {
        ...state,
        [action.modulename]: null
      };
    }
    case SET_DATA: {
      return {
        ...state,
        [action.modulename]: action.payload,
        loading: false
      };
    }
    case SET_TABLE: {
      return {
        ...state,
        table: action.payload
      };
    }
    case SET_FILTER: {
      return {
        ...state,
        [action.payload.filter]: action.payload.value
      };
    }
    case EDIT_DATA: {
      return {
        ...state,
        editData: action.payload,
        loading: false
      };
    }
    case DELETE_DATA: {
      let data = {...state[action.modulename]};
      let rows = [ ...data.rows ];
      let newRows = [];
      for (let item of rows) {
        if (action.payload.indexOf(item.id) == -1) {
          newRows.push(item);
        }
      }
      data.count = Number(data.count) - Number(action.payload.length);
      data.rows = newRows;
      return {
        ...state,
        [action.modulename]: data,
        loading: false
      };
    }
    default:
      return state;
  }
};
