import React from "react";
import {FormControl, InputLabel, MenuItem} from "@material-ui/core";
import PropTypes from "prop-types";
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from "@material-ui/core/styles";
import Select from "react-select";
import clsx from "clsx";
import {AsyncPaginate} from "react-select-async-paginate";
import IntlMessages from "../../../utils/IntlMessages";
import searchHelper from "../../../utils/searchHelper";

const useStyles = makeStyles(theme => ({
  formControlRoot: props => ({
    "& .MuiOutlinedInput-input": {
      paddingTop: 10.5,
      paddingBottom: 10.5,
      backgroundColor: "transparent",
      borderRadius: 4
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.grey[400]
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: 24,
      border: `1px solid ${theme.palette.sidebar.formBorderColor}`,
      padding: 15,
      borderRadius: 30
    },
    "&  .css-yk16xz-control": {
      padding: props.little ? 0 : 4.5,
      borderRadius: 30,
      marginTop: 5,
      border: `1px solid ${theme.palette.sidebar.formBorderColor}`,
      color: "#000",
      fontWeight: 600
    },
    "& .css-1pahdxg-control": {
      padding: "4.5px!important",
      borderRadius: 30,
      marginTop: 5,
      border: `1px solid ${theme.palette.sidebar.formBorderColor}`,
      boxShadow: "none",
      "&:hover": {
        padding: "4.5px!important",
        borderRadius: 30,
        marginTop: 5,
        border: `1px solid ${theme.palette.sidebar.formBorderColor}`,
        boxShadow: "none"
      }
    },
    "& .css-1rhbuit-multiValue": {
      backgroundColor: "#dbecfa",
      margin: 0,
      padding: props.paddingItems,
      borderRadius: 30,
      marginRight: props.marginRightItem,
      marginBottom: props.marginBottomItem,
      marginLeft: "-8px"
    },
    "& .css-g1d714-ValueContainer": {
      padding: "0px 10px!important",
      fontWeight: 600
    }
  })
}));

const AppMultiSelectBox = props => {
  const classes = useStyles(props);
  const {
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    required,
    onChange,
    isMulti,
    helperText,
    variant,
    error,
    valueKey,
    labelKey,
    backgroundColor,
    label,
    options,
    loadOptions,
    key,
    isDisabled,
    ...rest
  } = props;
  if (loadOptions == undefined) {
    return (
      <Select
        {...rest}
        noOptionsMessage={() => <IntlMessages id="no_options" />}
        loadingMessage={() => <IntlMessages id="table.loading" />}
        placeholder={
          <IntlMessages id={placeholder ? placeholder : "sidebar.select"} />
        }
        debounceTimeout={300}
        value={value}
        options={options}
        onChange={onChange}
        isMulti={isMulti}
        isDisabled={isDisabled}
        key={key}
        className={clsx(classes.formControlRoot, "form-control")}
      />
    );
  } else {
    return (
      <AsyncPaginate
        {...rest}
        noOptionsMessage={() => <IntlMessages id="no_options" />}
        loadingMessage={() => <IntlMessages id="table.loading" />}
        placeholder={
          <IntlMessages id={placeholder ? placeholder : "sidebar.search"} />
        }
        debounceTimeout={300}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        key={key}
        isDisabled={isDisabled}
        className={clsx(classes.formControlRoot, "form-control")}
        loadOptions={loadOptions}
      />
    );
  }
};

AppMultiSelectBox.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  backgroundColor: PropTypes.string,
  isMulti: PropTypes.func,
  marginRightItem: PropTypes.number,
  paddingItems: PropTypes.number,
  marginBottomItem: PropTypes.number
};

AppMultiSelectBox.defaultProps = {
  data: [],
  options: [],
  isMulti: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: "small",
  error: false,
  helperText: "",
  valueKey: "id",
  labelKey: "title",
  backgroundColor: "transparent",
  marginRightItem: 3,
  paddingItems: 5,
  marginBottomItem: 0
};

export default AppMultiSelectBox;
