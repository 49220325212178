import moment from "moment";

export const getTodayDate = (format = "DD.MM.YYYY") => {
  return moment().format(format);
};

export const isDateValid = date => {
  return date && moment(date).isValid() && date.split("-").length == 3;
};

export const getMonth = timestamp => {
  var monthArray = [];
  monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  var d = new Date(timestamp); // The 0 there is the key, which sets the date to the epoch
  return monthArray[d.getMonth()];
};

export const getFormatEnglishSpanishDate = date => {
  if (date && date != "") {
    var e = new Date(date);
    return moment(e).format("DD-MM-YYYY hh:mm:ss A");
  } else {
    return "";
  }
};

export const getFormatDate = date => {
  if (date && date != "") {
    var e = new Date(date);
    return moment(e).format("DD-MM-YYYY");
  } else {
    return "";
  }
};

export const getYearFormat = date => {
  if (date && date != "") {
    var e = new Date(date);
    return moment(e).format("YYYY");
  } else {
    return "";
  }
};

export const getTwoDigit = monthOrDate => {
  return monthOrDate < 10 ? "0" + monthOrDate : "" + monthOrDate;
};

export const getTime = timestamp => {
  var d = new Date(timestamp); // The 0 there is the key, which sets the date to the epoch
  return (
    ("0" + d.getHours()).slice(-2) +
    ":" +
    ("0" + (d.getMinutes() + 1)).slice(-2)
  );
};

export const getDiffHours = (startDate, endDate) => {
  var date1 = new Date(startDate);
  var date2 = new Date(endDate);
  var hours = Math.abs(date1 - date2) / 36e5;
  hours = String(hours.toFixed(2));
  let hours1 = hours.split(".")[0];
  let minutes = Number("0." + hours.split(".")[1]);
  minutes = Math.round(minutes * 60);
  return hours1 + ":" + minutes + ":00";
};

export const getYear = timestamp => {
  var d = new Date(timestamp); // The 0 there is the key, which sets the date to the epoch
  return d.getFullYear();
};

export const getYesterdayDate = () => {
  return moment().subtract(1, "day").format("DD.MM.YYYY");
};

export const timeFromNow = date => {
  const timestamp = moment(date).format("X");
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

export const isToday = date => {
  return moment().isSame(date, "day");
};

export const getNewDate = (noOfDays, format = "DD MMM, YYYY") => {
  return moment().add(noOfDays, "days").format(format);
};

export const getDate = timestamp => {
  if (timestamp !== "-" && timestamp !== "") {
    var d = new Date(timestamp); // The 0 there is the key, which sets the date to the epoch
    return (
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear()
    );
  } else {
    return "-";
  }
};

export const getDateElements = date => {
  const dateString = moment(date).format("dddd, MMMM DD YYYY, hh:mm A");
  const dateSections = dateString.split(",");
  const day = dateSections[0];
  const time = dateSections[2];
  const datePart = dateSections[1].trim().split(" ");
  return {
    day,
    time,
    date: {
      dateString: dateSections[1],
      month: datePart[0],
      date: datePart[1],
      year: datePart[2]
    }
  };
};

export const isDatesSame = (dateA, dateB) => {
  return moment(dateA).isSame(dateB, "day");
};

export const isDateAfter = date => {
  const todayDate = getTodayDate("dddd, MMMM DD YYYY, hh:mm a");
  return moment(todayDate).isAfter(date);
};

export const getDateinDesiredFormat = (date, format) => {
  return moment(date).format(format);
};

export const makeJSDateObject = date => {
  if (date) {
    return new Date(date.getTime());
  }
  return date;
};

/**
 * Get Formatted Date
 * @param date
 * @param format
 * @returns {string}
 */
export const getFormattedDate = (date, format = "YYYY-MM-DD") => {
  if (moment(date, "YYYY-MM-DD").isValid()) {
    return moment(date).format(format);
  }

  return "";
};

export const getFormattedMonth = (date, format = "YYYY-MM") => {
  if (moment(date, "YYYY-MM").isValid()) {
    return moment(date).format(format);
  }

  return "";
};

/**
 * Check Is dateTime of Tomorrow
 * @param inputDateTime
 * @returns {boolean}
 */
export const isTomorrow = inputDateTime => {
  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");

  return moment(inputDateTime).isSame(tomorrow, "day");
};

/**
 * Check Is dateTime of Yesterday
 * @param inputDateTime
 * @returns {boolean}
 */
export const isYesterday = inputDateTime => {
  const yesterday = moment().subtract(1, "days").format("YYYY-MM-DD");

  return moment(inputDateTime).isSame(yesterday, "day");
};

/**
 * Get Custom Date Time
 * @param value
 * @param unit
 * @param format
 * @returns {string}
 */
export const getCustomDateTime = (
  value = 0,
  unit = "days",
  format = "YYYY-MM-DD"
) => {
  if (value === 0) {
    return moment().format(format);
  } else {
    return moment().add(value, unit).format(format);
  }
};

export const getDateText = date => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else if (isTomorrow(date)) {
    return "Tomorrow";
  } else {
    return date;
  }
};

export const getTimeDiffString = date => {
  const postDate = moment(date, "ddd MMM DD YYYY kk:mm:ss Z");
  const currentDate = moment(new Date());
  const duration = moment.duration(currentDate.diff(postDate));
  const minutes = duration.asMinutes() | 0;
  const hours = duration.asHours() | 0;

  switch (true) {
    case minutes === 0:
      return "Just now";
    case minutes < 60:
      return `${minutes} min`;
    case hours < 24:
      return `${hours} hours`;
    default:
      return postDate.format("DD MMM, YYYY");
  }
};
