import React from "react";
import IntlMessages from "../../../../utils/IntlMessages";
import PersonIcon from "@material-ui/icons/Person";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
export const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us"
  },
  // {
  //   languageId: 'chinese',
  //   locale: 'zh',
  //   name: 'Chinese',
  //   icon: 'cn',
  // },
  {
    languageId: "spanish",
    locale: "es",
    name: "Español",
    icon: "es"
  }
  // {
  //   languageId: 'french',
  //   locale: 'fr',
  //   name: 'French',
  //   icon: 'fr',
  // },
  // {
  //   languageId: 'italian',
  //   locale: 'it',
  //   name: 'Italian',
  //   icon: 'it',
  // },
  // {
  //   languageId: 'saudi-arabia',
  //   locale: 'ar',
  //   name: 'Arabic',
  //   icon: 'sa',
  // },
];

export const settingsData = [
  // {
  //   name: <IntlMessages id={'sidebar.profile'} />,
  //   icon: <PersonIcon/>,
  //   link:'/'
  // },
  {
    name: <IntlMessages id={"sidebar.logout"} />,
    icon: <LockOutlinedIcon />,
    link: "/"
  }
];

export const flags = {
  en: "/images/flag/icons8-usa.png",
  // zh: '/images/flag/icons8-china.png',
  es: "/images/flag/icons8-spain.png"
  // fr: '/images/flag/icons8-france.png',
  // it: '/images/flag/icons8-italy.png',
  // ar: '/images/flag/icons8-saudi_arabia.png',
};

export const settings = {
  profile: <PersonIcon />,
  logout: <LockOutlinedIcon />
};
