import React from "react";
import {useSelector} from "react-redux";
import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical";
import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IntlMessages from "../../../../utils/IntlMessages";
import {checkMenu} from "../../../../../@jumbo/utils/commonHelper";

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: "calc(100% - 62px)",
    transition: "all 0.3s ease",
    ".Cmt-miniLayout &": {
      "& .Cmt-sidebar-content": {
        display: "none"
      }
    }
  }
}));

const SideBar = () => {
  const classes = useStyles();
  const {authUser} = useSelector(({auth}) => auth);

  const items = [
    {
      name: <IntlMessages id={"sidebar.dashboard"} />,
      type: "item",
      icon: "dashboard",
      tourl: "dashboard",
      link: "/dashboard"
    },
    {
      name: <IntlMessages id={"sidebar.tracking"} />,
      type: "collapse",
      icon: "tracking",
      children: [
        {
          type: "item",
          icon: "godsview",
          name: <IntlMessages id={"sidebar.godview"} />,
          tourl: "godview",
          link: "/tracking/godview"
        },
        {
          type: "item",
          icon: "livetracking",
          name: <IntlMessages id={"sidebar.livetracking"} />,
          tourl: "livetracking",
          link: "/tracking/livetracking"
        },
        {
          type: "item",
          icon: "trackhistory",
          name: <IntlMessages id={"sidebar.trackhistory"} />,
          tourl: "trackhistory",
          link: "/tracking/trackhistory"
        },
        {
          type: "item",
          icon: "alerts",
          name: <IntlMessages id={"sidebar.alerts"} />,
          tourl: "alerts",
          link: "/tracking/alerts"
        },
        {
          type: "item",
          icon: "geozone",
          name: <IntlMessages id={"sidebar.aoi"} />,
          tourl: "aoi",
          link: "/tracking/aoi"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.assets"} />,
      type: "collapse",
      icon: "assets",
      children: [
        {
          type: "item",
          icon: "equipment",
          name: <IntlMessages id={"sidebar.equipment"} />,
          tourl: "equipment",
          link: "/assets/equipment"
        },
        {
          type: "item",
          icon: "tools",
          name: <IntlMessages id={"sidebar.tools"} />,
          tourl: "tools",
          link: "/assets/tools"
        },
        {
          type: "item",
          icon: "spares",
          name: <IntlMessages id={"sidebar.sparessupplies"} />,
          tourl: "sparessupplies",
          link: "/assets/sparessupplies"
        },
        {
          type: "item",
          icon: "existencecard",
          name: <IntlMessages id={"actions.existencecard"} />,
          tourl: "existencecard",
          link: "/assets/existencecard"
        },
        {
          type: "item",
          icon: "inventory",
          name: <IntlMessages id={"spares.inventory"} />,
          tourl: "inventory",
          link: "/assets/inventory"
        },
        {
          type: "item",
          icon: "warehouselist",
          name: <IntlMessages id={"sidebar.warehouselist"} />,
          tourl: "warehouselist",
          link: "/assets/warehouselist"
        },
        {
          type: "item",
          icon: "digital",
          name: <IntlMessages id={"sidebar.digital"} />,
          tourl: "digital",
          link: "/assets/digital"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.team"} />,
      type: "collapse",
      icon: "team",
      children: [
        {
          type: "item",
          icon: "in-housepersonnel",
          name: <IntlMessages id={"sidebar.inhouse"} />,
          tourl: "inhouse",
          link: "/team/inhouse"
        },
        {
          type: "item",
          icon: "providers",
          name: <IntlMessages id={"sidebar.providers"} />,
          tourl: "providers",
          link: "/team/providers"
        },
        {
          type: "item",
          icon: "clients",
          name: <IntlMessages id={"sidebar.client"} />,
          tourl: "client",
          link: "/team/client"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.settings"} />,
      type: "collapse",
      icon: "settings",
      children: [
        {
          type: "item",
          name: <IntlMessages id={"sidebar.region"} />,
          icon: "region",
          tourl: "region",
          link: "/settings/region"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.groups"} />,
          icon: "groups",
          tourl: "groups",
          link: "/settings/groups"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.costcenter"} />,
          icon: "costcenter",
          tourl: "costcenter",
          link: "/settings/costcenter"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.commands"} />,
          icon: "commands",
          tourl: "commands",
          link: "/settings/commands"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.createoption"} />,
          icon: "maintenanceform",
          tourl: "createoption",
          link: "/settings/createoption"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.warehouse"} />,
          icon: "warehouse",
          tourl: "warehouse",
          link: "/settings/warehouse"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.warehouseadmission"} />,
      type: "collapse",
      icon: "warehouse",
      children: [
        {
          type: "item",
          name: <IntlMessages id={"sidebar.purchaseorder"} />,
          icon: "purchaseorder",
          tourl: "purchaseorder",
          link: "/warehouseadmission/purchaseorder"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.electronicinvoice"} />,
          icon: "electronicinvoice",
          tourl: "electronicinvoice",
          link: "/warehouseadmission/electronicinvoice"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.supplierofficeguide"} />,
          icon: "providerdispatchguide",
          tourl: "supplierofficeguide",
          link: "/warehouseadmission/supplierofficeguide"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.professionalfees"} />,
          icon: "feesbill",
          tourl: "professionalfees",
          link: "/warehouseadmission/professionalfees"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.creditnote"} />,
          icon: "creditnote",
          tourl: "creditnote",
          link: "/warehouseadmission/creditnote"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.debitnote"} />,
          icon: "debitnote",
          tourl: "debitnote",
          link: "/warehouseadmission/debitnote"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.admissiontransferdispatch"} />,
          icon: "dispatchguide",
          tourl: "admissiontransferdispatch",
          link: "/warehouseadmission/admissiontransferdispatch"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.admissioninternalguide"} />,
          icon: "departureinternalguide",
          tourl: "admissioninternalguide",
          link: "/warehouseadmission/admissioninternalguide"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.adjustment"} />,
          icon: "adjustment",
          tourl: "adjustment",
          link: "/warehouseadmission/adjustment"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.warehousedepartures"} />,
      type: "collapse",
      icon: "warehouse",
      children: [
        {
          type: "item",
          name: <IntlMessages id={"sidebar.workorders"} />,
          icon: "workorders",
          tourl: "workorders",
          link: "/warehousedepartures/workorders"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.depaturetransferdispatch"} />,
          icon: "departuretransferguide",
          tourl: "depaturetransferdispatch",
          link: "/warehousedepartures/depaturetransferdispatch"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.depatureinternalguide"} />,
          icon: "internalguide",
          tourl: "depatureinternalguide",
          link: "/warehousedepartures/depatureinternalguide"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.wastage"} />,
          icon: "wastage",
          tourl: "wastage",
          link: "/warehousedepartures/wastage"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.rolespermissions"} />,
      type: "collapse",
      icon: "rolespermisions",
      children: [
        {
          type: "item",
          name: <IntlMessages id={"sidebar.modules"} />,
          icon: "modulesettings",
          tourl: "modules",
          link: "/roles/modules"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.rolespermissions"} />,
          icon: "rolespermissions",
          tourl: "rolespermissions",
          link: "/roles/rolespermissions"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.approve"} />,
      type: "item",
      icon: "approvalmanagement",
      tourl: "approval",
      link: "/approve"
    },

    {
      name: <IntlMessages id={"sidebar.intelligen"} />,
      type: "collapse",
      icon: "service",
      children: [
        {
          name: <IntlMessages id={"sidebar.vehicleperformance"} />,
          type: "item",
          icon: "costingreport",
          tourl: "vehicleperformance",
          link: "/intelligen/vehicleperformance"
        },
        {
          type: "item",
          icon: "clients",
          name: <IntlMessages id={"sidebar.driverevaluation"} />,
          tourl: "driverevaluation",
          link: "/intelligen/driverevaluation"
        },
        {
          name: <IntlMessages id={"sidebar.riskmanagement"} />,
          type: "item",
          icon: "riskmanagement",
          tourl: "riskmanagement",
          link: "/intelligen/riskmanagement"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.service_management"} />,
      type: "collapse",
      icon: "service",
      children: [
        {
          type: "item",
          name: <IntlMessages id={"servicecontrol.servicedashboard"} />,
          icon: "servicedashboard",
          tourl: "servicedashboard",
          link: "/service/dashboard"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.service"} />,
          icon: "serviceservice",
          tourl: "service",
          link: "/service/service"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.servicecontrol"} />,
          icon: "servicecontrol",
          tourl: "servicecontrol",
          link: "/service/servicecontrol"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.servicesettings"} />,
          icon: "servicesettings",
          tourl: "servicesettings",
          link: "/service/servicesettings"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.maintenance"} />,
      type: "collapse",
      icon: "maintenance",
      children: [
        {
          type: "item",
          name: <IntlMessages id={"sidebar.maintenancetype"} />,
          icon: "maintenancetype",
          tourl: "maintenancetype",
          link: "/maintenance/maintenancetype"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.maintenancegroup"} />,
          icon: "maintenancegroup",
          tourl: "maintenancegroup",
          link: "/maintenance/maintenancegroup"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.resourcegroup"} />,
          icon: "resourcegroup",
          tourl: "resourcegroup",
          link: "/maintenance/resourcegroup"
        },

        {
          type: "item",
          name: <IntlMessages id={"sidebar.maintenanceform"} />,
          icon: "maintenanceform",
          tourl: "maintenanceform",
          link: "/maintenance/maintenanceform"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.maintenanceplan"} />,
          icon: "maintenanceplan",
          tourl: "maintenanceplan",
          link: "/maintenance/maintenanceplan"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.businessintelligence"} />,
      type: "collapse",
      icon: "businessinteligence",
      children: [
        // {
        //   type: "item",
        //   name: <IntlMessages id={"sidebar.vehicleperformancereport"} />,
        //   icon: "costingreport",
        //   tourl: "godview",
        //   link: "/businessintelligence/vehicleperformancereport"
        // },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.routereport"} />,
          icon: "costingreport",
          tourl: "godview",
          link: "/businessintelligence/routereport"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.costingreport"} />,
          icon: "costingreport",
          tourl: "godview",
          link: "/businessintelligence/costingreport"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.usagereport"} />,
          icon: "usagereport",
          tourl: "godview",
          link: "/businessintelligence/usagereport"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.maintenancereport"} />,
          icon: "maintenancereport",
          tourl: "godview",
          link: "/businessintelligence/maintenancereport"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.insurancereport"} />,
          icon: "insurancereport",
          tourl: "godview",
          link: "/businessintelligence/insurancereport"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.detailedreport"} />,
          icon: "detailedreport",
          link: "/businessintelligence/detailedreport"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.purchasereport"} />,
          icon: "workorderreport",
          tourl: "godview",
          link: "/businessintelligence/purchasereport"
        },
        {
          type: "item",
          name: <IntlMessages id={"sidebar.workorderreport"} />,
          icon: "workorderreport",
          tourl: "godview",
          link: "/businessintelligence/workorderreport"
        }
      ]
    },
    {
      name: <IntlMessages id={"sidebar.company"} />,
      type: "item",
      icon: "tracking",
      tourl: "company",
      link: "/company"
    },
    {
      name: <IntlMessages id={"sidebar.profile"} />,
      type: "item",
      icon: "tracking",
      tourl: "profile",
      link: "/profile"
    }
  ];

  let newItemsData = [];
  if (authUser) {
    let userRole = authUser.role ? authUser.role.value : "";
    let rolesPermission = authUser.rolespermissions
      ? authUser.rolespermissions
      : [];
    let companyId = authUser.companyId;
    if (userRole != "super_admin") {
      for (let data in items) {
        if (
          items[data].children == undefined ||
          items[data].children.length == 0
        ) {
          if (checkMenu(rolesPermission, items[data], userRole)) {
            if (items[data].tourl == "company") {
              newItemsData.push({
                name: <IntlMessages id={"sidebar.company"} />,
                type: "item",
                icon: "tracking",
                link: "/editcompany/" + companyId
              });
            } else {
              newItemsData.push(items[data]);
            }
          }
        } else {
          let newContent = [];
          if (items[data].children) {
            for (let data1 in items[data].children) {
              if (
                checkMenu(
                  rolesPermission,
                  items[data].children[data1],
                  userRole
                )
              ) {
                newContent.push(items[data].children[data1]);
              }
            }
          }
          items[data].children = newContent;
          if (0 < newContent.length) {
            newItemsData.push(items[data]);
          }
        }
      }
    } else {
      // let index = items.findIndex(item => item.link == "/editcompany");
      // items.splice(index, 1);
      newItemsData = items;
    }
  }

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={newItemsData} />
    </PerfectScrollbar>
  );
};

export default SideBar;
