import {makeStyles} from "@material-ui/core";
import {hexToRgba} from "../CmtHelpers/JssHelper";
import {
  amber,
  blue,
  brown,
  deepOrange,
  green,
  grey,
  purple,
  red,
  yellow
} from "@material-ui/core/colors";

const hoverOpacity = 0.7;
const useStyles = makeStyles(theme => ({
  cardButtons: {
    float: "left",
    marginTop: 5,
    "& .CmtButtons-wrapper": {
      "@media screen and (max-width: 500px)": {
        marginRight: "1px!important"
      }
    }
  },
  previuosBtn: {
    "& .CmtButtons-wrapper": {
      "& .MuiButtonBase-root": {
        marginTop: "7px!important",
        marginRight: "15px!important"
      }
    }
  },
  nextBtn: {
    "& .CmtButtons-wrapper": {
      "& .MuiButtonBase-root": {
        marginTop: "5px!important"
      }
    }
  },
  pagination_text: {
    float: "left",
    padding: "28px 0px",
    margin: "0px 0px",
    fontSize: 13,
    "@media screen and (max-width: 500px)": {
      fontSize: 11,
      margin: "0px 0px"
    }
  },
  pagination_input: {
    "& .MuiInput-formControl": {
      marginTop: 0,
      width: 50,
      height: 37,
      marginLeft: 10,
      marginTop: "-1px",
      paddingTop: "17px",
      paddingLeft: 0,
      paddingRight: 0,
      "@media screen and (max-width: 500px)": {
        marginLeft: 0
      },
      "& .MuiInputBase-input": {
        textAlign: "center",
        padding: 2,
        margin: 0,
        fontSize: 13,
        "@media screen and (max-width: 500px)": {
          fontSize: 11
        }
      }
    },
    float: "left",
    padding: "17px 7px",
    marginTop: 1,
    "@media screen and (max-width: 500px)": {
      fontSize: 11,
      padding: "17px 2px"
    }
  },
  pagination_select: {
    position: "relative",
    "& .MuiInput-formControl": {
      marginTop: 0,
      width: 100,
      height: 30,
      marginRight: 10,
      "& .MuiInputBase-input": {
        textAlign: "center",
        padding: "10px 10px 1px 5px!important",
        margin: 0,
        marginTop: 5,
        fontSize: 13,
        // zIndex: 999,
        height: 24,
        backgroundColor: "transparent",
        zIndex: "99",
        "@media screen and (max-width: 500px)": {
          fontSize: 11,
          padding: "10px 0px 0px 5px",
          textAlign: "left"
        }
      },
      "& .MuiSelect-icon": {
        // color: "transparent",
        zIndex: "9",
        border: " solid #757575",
        borderWidth: "0px 2px 2px 0px",
        display: "inline-block",
        padding: "0px",
        transform: "rotate(45deg)",
        width: "7px",
        height: "7px",
        marginTop: "10px",
        marginRight: "10px"
      },
      "@media screen and (max-width: 500px)": {
        width: "69px"
      }
    },
    float: "left",
    padding: "17px 5px",
    marginTop: 1,
    marginRight: 0,

    "@media screen and (max-width: 500px)": {
      width: "76px"
    }
  },
  pagination_div: {
    margin: "0 auto!important",
    display: "inherit",
    "& .MuiMenu-paper": {
      "& .MuiMenuItem-root": {
        fontSize: 14
      }
    }
  },
  pagination_btn_div: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: "-20px",
    display: "flex"
  },
  pagination_arrow: {
    position: "absolute",
    right: "18px",
    top: "22px",
    color: "#757575",
    "@media screen and (max-width: 500px)": {
      right: "3px"
    }
  }
}));
export default useStyles;
