import {BaseUrl} from "../../@jumbo/constants/AppConstants";
const baseUrl = BaseUrl;

let api = {
  getHeaders() {
    let user = JSON.parse(localStorage.getItem("user"));
    let headers = {
      "Content-Type": "application/json",
      auth: user ? user.token : ""
    };
    return headers;
  },
  postApi(path, data) {
    let url = baseUrl + path;
    let headers = api.getHeaders();
    let options = {
      method: "POST",
      headers,
      body: JSON.stringify(data)
    };

    return new Promise(function(resolve, reject){
      fetch(url, options)
        .then(function(response){
          if (response.ok) {
            let responseText = response.json();
            resolve(responseText);
          } else {
            console.log("error1", response, response.status);
            reject(response.statusText);
            //reject(new Error(`Unable to retrieve events.\nInvalid response received - (${response.status}).`));
          }
        })
        .catch(function(error){
          console.log("error2", error);
          reject(error);
        });
    });
  },
  putApi(path, data) {
    let url = baseUrl + path;
    let headers = api.getHeaders();
    let options = {
      method: "PUT",
      headers,
      body: JSON.stringify(data)
    };

    return new Promise(function(resolve, reject){
      fetch(url, options)
        .then(function(response){
          if (response.ok) {
            let responseText = response.json();
            resolve(responseText);
          } else {
            console.log("error1", response, response.status);
            reject(response.statusText);
            //reject(new Error(`Unable to retrieve events.\nInvalid response received - (${response.status}).`));
          }
        })
        .catch(function(error){
          console.log("error2", error);
          reject(error);
          //  reject(new Error(`Unable to retrieve events.\n${error.message}`));
        });
    });
  },
  deleteApi(path, data) {
    let url = baseUrl + path;
    let headers = api.getHeaders();
    let options = {
      method: "DELETE",
      headers,
      body: JSON.stringify(data)
    };

    return new Promise(function(resolve, reject){
      fetch(url, options)
        .then(function(response){
          if (response.ok) {
            //alert(JSON.stringify(response));
            let responseText = response.json();

            resolve(responseText);
          } else {
            //alert(JSON.stringify(response));
            console.log("error1", response);
            reject(response.statusText);
          }
        })
        .catch(function(error){
          console.log("error2", error);
          reject(error);
          //  reject(new Error(`Unable to retrieve events.\n${error.message}`));
        });
    });

    //return fetch(url,options).then((res) => res.json())
  },
  getApi(path) {
    let url = baseUrl + path;

    let headers = api.getHeaders();
    let options = {
      method: "GET",
      headers
    };

    console.log("url==", url);
    return new Promise(function(resolve, reject){
      fetch(url, options)
        .then(function(response){
          if (response.ok) {
            let responseText = response.json();
            resolve(responseText);
          } else {
            console.log("error1", response, response.status);
            reject(response.statusText);
            //reject(new Error(`Unable to retrieve events.\nInvalid response received - (${response.status}).`));
          }
        })
        .catch(function(error){
          console.log("error2", error);
          reject(error);
        });
    });
  }
};

export default api;
// module.exports = api
