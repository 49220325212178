import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../../@jumbo/components/PageComponents/PageLoader';
import { checkRouterUrl } from '../../../@jumbo/utils/commonHelper';

const Settings = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl('region', 'view') && <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/regions`} />}
        {checkRouterUrl('region', 'view') && <Route path={`${requestedUrl}/region`} component={lazy(() => import('./Regions'))} />}
        {checkRouterUrl('region', 'add') && <Route path={`${requestedUrl}/addregion`} component={lazy(() => import('./Addregions'))} />}
        {checkRouterUrl('region', 'edit') && <Route path={`${requestedUrl}/editregion/:id`} component={lazy(() => import('./Addregions'))} />}

        {checkRouterUrl('groups', 'view') && <Route path={`${requestedUrl}/groups`} component={lazy(() => import('./Groups'))} />}
        {checkRouterUrl('groups', 'add') && <Route path={`${requestedUrl}/addgroups`} component={lazy(() => import('./Addgroups'))} />}
        {checkRouterUrl('groups', 'edit') && <Route path={`${requestedUrl}/editgroups/:id`} component={lazy(() => import('./Addgroups'))} />}

        {checkRouterUrl('costcenter', 'view') && <Route path={`${requestedUrl}/costcenter`} component={lazy(() => import('./CostCenter'))} />}
        {checkRouterUrl('costcenter', 'add') && <Route path={`${requestedUrl}/addcostcenter`} component={lazy(() => import('./Addcostcenter'))} />}
        {checkRouterUrl('costcenter', 'edit') && <Route path={`${requestedUrl}/editcostcenter/:id`} component={lazy(() => import('./Addcostcenter'))} />}

        {checkRouterUrl('warehouse', 'view') && <Route path={`${requestedUrl}/warehouse`} component={lazy(() => import('./Warehouse'))} />}
        {checkRouterUrl('warehouse', 'add') && <Route path={`${requestedUrl}/addwarehouse`} component={lazy(() => import('./Addwarehouse'))} />}
        {checkRouterUrl('warehouse', 'edit') && <Route path={`${requestedUrl}/editwarehouse/:id`} component={lazy(() => import('./Addwarehouse'))} />}

        {checkRouterUrl('createoption', 'view') && <Route path={`${requestedUrl}/createoption`} component={lazy(() => import('./Createoptions'))} />}
        {checkRouterUrl('createoption', 'add') && <Route path={`${requestedUrl}/addcreateoption`} component={lazy(() => import('./Addcreateoptions'))} />}
        {checkRouterUrl('createoption', 'edit') && <Route path={`${requestedUrl}/editcreateoption/:id`} component={lazy(() => import('./Addcreateoptions'))} />}

        {checkRouterUrl('commands', 'view') && <Route path={`${requestedUrl}/commands`} component={lazy(() => import('./Commands'))} />}

        <Route component={lazy(() => import('../404'))} />

      </Switch>
    </Suspense>
  );
};

export default Settings;

