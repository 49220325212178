import React, {useState, useEffect} from "react";
import {Box, Button, IconButton, Tooltip} from "@material-ui/core";
import {componentColors} from "../CmtHelpers/JssHelper";
import PropTypes from "prop-types";
import clsx from "clsx";
import useStyles from "./index.style";
import {Link} from "react-router-dom";
import CmtButtons from "./../CmtButtons";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import {Grid} from "@material-ui/core";
import AppTextInput from "../../@jumbo/components/Common/formElements/AppTextInput";
import AppSelectBox from "../../@jumbo/components/Common/formElements/AppSelectBox";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const CmtTableFooter = ({
  count,
  page,
  rowsPerPage,
  onChangeRowsPerPage,
  rowsPerPageOptions,
  onChangePage,
  responsivePagination,
  arrowByText,
  fixArrowText,
  ...rest
}) => {
  const classes = useStyles();
  const [ rowPage, setRowPage ] = useState(10);
  const [ pageNumber, setPageNumber ] = useState(10);

  let currentPage = 0 < count ? page + 1 : 1;
  let totalPage = 0 < count ? Math.ceil(count / rowsPerPage) : 1;
  let timer_id = undefined;

  useEffect(
    () => {
      let currentPage = 0 < count ? page + 1 : 1;
      setPageNumber(currentPage);
    },
    [ count, rowsPerPage, page ]
  );
  const onChangeRowPage = event => {
    setRowPage(event.target.value);
    onChangeRowsPerPage(event.target.value);
  };

  const onNextPage = () => {
    if (currentPage < totalPage) {
      onChangePage(currentPage);
    }
  };
  const onPreviousPage = () => {
    if (1 < currentPage) {
      onChangePage(currentPage - 2);
    }
  };

  const onChangePageNumber = pageNumber => {
    clearTimeout(timer_id);
    setPageNumber(pageNumber);
    timer_id = setTimeout(() => {
      if (0 < pageNumber && pageNumber <= totalPage) {
        onChangePage(pageNumber - 1);
      }
    }, 500);
  };

  let nextButtons = [
    {
      label: <IntlMessages id="calander.next" />,
      color: "next",
      onClick: onNextPage
    }
  ];
  let previousButtons = [
    {
      label: <IntlMessages id="approve_management.previous" />,
      color: "next",
      onClick: onPreviousPage
    }
  ];

  if (arrowByText) {
    nextButtons = [
      {
        label: <ArrowForwardIcon />,
        color: "next",
        onClick: onNextPage
      }
    ];
    previousButtons = [
      {
        label: <ArrowBackIcon />,
        color: "next",
        onClick: onPreviousPage
      }
    ];
  }

  return (
    <React.Fragment>
      <Grid className={classes.pagination_div}>
        {/* test {count} */}
        {!responsivePagination && (
          <CmtButtons
            items={previousButtons}
            className={
              fixArrowText ? (
                clsx(classes.cardButtons, classes.previuosBtn)
              ) : (
                classes.cardButtons
              )
            }
          />
        )}

        <Grid className={classes.pagination_text}>
          <IntlMessages id="pdf.page" />
        </Grid>
        <Grid className={classes.pagination_input}>
          <AppTextInput
            value={pageNumber}
            onChange={event => onChangePageNumber(event.target.value)}
          />
        </Grid>
        <Grid className={classes.pagination_text}>
          <IntlMessages id="pdf.of" /> {totalPage}
        </Grid>
        <Grid className={classes.pagination_select}>
          <AppSelectBox
            data={rowsPerPageOptions}
            valueKey="value"
            labelKey="label"
            value={rowPage}
            onChange={onChangeRowPage}
          />
          {/* <KeyboardArrowDownOutlinedIcon className={classes.pagination_arrow} /> */}
        </Grid>
        {!responsivePagination && (
          <CmtButtons
            items={nextButtons}
            className={
              fixArrowText ? (
                clsx(classes.cardButtons, classes.nextBtn)
              ) : (
                classes.cardButtons
              )
            }
          />
        )}
      </Grid>
      {responsivePagination && (
        <Grid className={classes.pagination_btn_div}>
          <Grid>
            <CmtButtons
              items={previousButtons}
              className={classes.cardButtons}
            />
          </Grid>
          <Grid>
            <CmtButtons items={nextButtons} className={classes.cardButtons} />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

CmtTableFooter.prototype = {
  items: PropTypes.array.isRequired,
  type: PropTypes.oneOf([ "default", "icon-button" ]),
  itemProps: PropTypes.object,
  onItemClick: PropTypes.func,
  variant: PropTypes.oneOf([ "text", "outlined", "contained" ]),
  size: PropTypes.oneOf([ "small", "medium", "large" ]),
  color: PropTypes.oneOf(componentColors),
  enableTooltip: PropTypes.bool,
  tooltipProps: PropTypes.object
};

CmtTableFooter.defaultProps = {
  items: [], // format: [{icon: "text or node", label: "button name", onClick: functionHandler, tooltip, ...}]
  type: "default",
  variant: "text",
  size: "medium",
  color: "",
  enableTooltip: false
};

export default CmtTableFooter;
