import React, {useState, useEffect, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";

import Box from "@material-ui/core/Box";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import {useIntl} from "react-intl";
import {getNumberFormat} from "../../../@jumbo/utils/commonHelper";

const Status = () => {
  const {dashboard} = useSelector(({common}) => common);
  const [ purchasePieData, setPurchasePieData ] = useState(null);
  const intl = useIntl();

  useEffect(
    () => {
      let leased = 0;
      let available = 0;
      let notAvailable = 0;

      if (dashboard.statechecked) {
        available = dashboard.statechecked.find(
          item => item.availability == "available"
        );
        leased = dashboard.statechecked.find(
          item => item.availability == "leased"
        );
        notAvailable = dashboard.statechecked.find(
          item => item.availability == "not_available"
        );

        available = available ? available.count : 0;
        leased = leased ? leased.count : 0;
        notAvailable = notAvailable ? notAvailable.count : 0;
      }
      let purchasepieData = [
        {
          name: "Equipment",
          [intl.formatMessage({id: "sidebar.leased"})]: Number(leased),
          [intl.formatMessage({id: "availability.available"})]: Number(
            available
          ),
          [intl.formatMessage({id: "availability.not_available"})]: Number(
            notAvailable
          )
        }
      ];
      console.log("purchasepieData===", purchasepieData);
      setPurchasePieData(purchasepieData);
    },
    [ dashboard ]
  );

  return (
    <Grid item xs={12}>
      <Box>
        <ResponsiveContainer height={300} width="100%">
          <BarChart
            data={purchasePieData}
            barGap={30}
            barCategoryGap={15}
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 5
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend iconType="circle" wrapperStyle={{bottom: 0}} />
            <Bar
              dataKey={intl.formatMessage({id: "sidebar.leased"})}
              fill="#213a52"
              radius={15}
            />
            <Bar
              dataKey={intl.formatMessage({id: "availability.available"})}
              fill="#29939b"
              radius={15}
            />
            <Bar
              dataKey={intl.formatMessage({id: "availability.not_available"})}
              fill="#dfe5ed"
              radius={15}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Grid>
  );
};

export default Status;
