import React, {useContext, useState} from "react";
import {IconButton, Popover, useTheme, Tooltip} from "@material-ui/core";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AppContext from "../../../contextProvider/AppContextProvider/AppContext";
import LanguageItem from "./LanguageItem";
import SettingsItem from "./SettingsItem";

import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtCard from "../../../../../@coremat/CmtCard";
import CmtCardHeader from "../../../../../@coremat/CmtCard/CmtCardHeader";
import CmtList from "../../../../../@coremat/CmtList";
import PerfectScrollbar from "react-perfect-scrollbar";
import CmtImage from "../../../../../@coremat/CmtImage";
import {flags, languageData, settingsData} from "./data";
import {useDispatch} from "react-redux";
import {AuhMethods} from "../../../../../services/auth";
import {CurrentAuthMethod} from "../../../../constants/AppConstants";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 14,
      paddingBottom: 14
    }
  },
  perfectScrollbarLanguage: {
    height: "auto",
    "& .ps__rail-x": {
      overflow: "hidden",
      height: 0
    },
    "& .ps__rail-y": {
      overflow: "hidden",
      height: "0!important"
    }
  },
  menuItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,

    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  popoverRoot: {
    backgroundColor: "rgba(0,0,0,0.5)",
    "& .MuiPopover-paper": {
      width: 120,
      backgroundColor: "transparent",
      boxShadow: "none"
    }
  },
  arrowup: {
    width: 0,
    height: 0,
    borderLeft: "18px solid transparent",
    borderRight: "18px solid transparent",
    borderBottom: "14px solid white",
    marginLeft: 43
  }
}));

const LanguageSwitcher = () => {
  const classes = useStyles();
  const {locale, updateLocale} = useContext(AppContext);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();

  React.useEffect(() => {
    getLanguage();
  }, []);

  const getLanguage = () => {
    if (localStorage.getItem("language")) {
      console.log(
        "Type saved in localstorage",
        typeof localStorage.getItem("language")
      );
      if (typeof localStorage.getItem("language") === "object") {
        const language = localStorage.getItem("language");
        updateLocale(language);
      } else if (typeof localStorage.getItem("language") === "object") {
        const language = JSON.parse(localStorage.getItem("language"));
        updateLocale(language);
      }
    }
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "language" : undefined;

  const switchLanguage = item => {
    updateLocale(item);
    console.log("item==", item);
    console.log("How its saved in localstorage", JSON.stringify(item));
    localStorage.setItem("language", JSON.stringify(item));
    handleClose();
  };

  const onItemClick = item => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  return (
    <React.Fragment>
      <Tooltip title="Settings">
        <IconButton onClick={handleClick} data-tut="reactour__localization">
          <SettingsOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Popover
        className={classes.popoverRoot}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div className={classes.arrowup} />

        <CmtCard className={classes.cardRoot}>
          {/* <CmtCardHeader
            title="Language"
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          /> */}

          <PerfectScrollbar className={classes.perfectScrollbarLanguage}>
            <CmtList
              data={languageData}
              renderRow={(item, index) => (
                <LanguageItem
                  key={index}
                  language={item}
                  onClick={switchLanguage}
                />
              )}
            />
            <CmtList
              data={settingsData}
              renderRow={(item, index) => (
                <SettingsItem
                  key={index}
                  language={item}
                  onClick={onItemClick}
                />
              )}
            />
          </PerfectScrollbar>
        </CmtCard>
      </Popover>
    </React.Fragment>
  );
};

export default LanguageSwitcher;
