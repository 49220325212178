import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MessageIcon from "@material-ui/icons/Message";
import ShareIcon from "@material-ui/icons/Share";
import EmailIcon from "@material-ui/icons/Email";
import CakeIcon from "@material-ui/icons/Cake";
import CmtMediaObject from "../../../../../../@coremat/CmtMediaObject";
import CmtAvatar from "../../../../../../@coremat/CmtAvatar";
import CmtImage from "../../../../../../@coremat/CmtImage";
import {getDateElements} from "../../../../../utils/dateHelper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {fade} from "@material-ui/core";
import {flags} from "./data";
import IntlMessages from "../../../../../utils/IntlMessages";

const useStyles = makeStyles(theme => ({
  feedItemRoot: {
    padding: "10px 0",
    position: "relative",
    borderBottom: `1px solid ${fade(theme.palette.common.dark, 0.035)}`,
    width: "100%",
    float: "left",
    "& .Cmt-media-object": {
      alignItems: "center"
    },
    "& .Cmt-media-image": {
      alignSelf: "flex-start",
      width: 56
    },
    "& .Cmt-media-body": {
      width: "calc(100% - 56px)",
      flex: "inherit"
    }
  },
  loadMore: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    width: "100%"
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: "pointer"
  },
  title: {
    color: "#3ca0ed",
    fontSize: 15,
    fontWeight: props => (props.seen ? "inherit" : "bold")
  },
  boxText: {
    width: "70%",
    float: "left"
  },
  boxClock: {
    width: "30%",
    float: "right"
  },
  clock: {
    color: "#666",
    fontSize: 12,
    float: "right"
  },
  clockicon: {
    color: "#666",
    fontSize: 10,
    float: "left"
  },
  description: {
    fontSize: 11,
    color: "#666",
    fontWeight: props => (props.seen ? "inherit" : "bold")
  },
  leftNotification: {
    width: "85%",
    float: "right"
  },
  profileimg: {
    width: "15%",
    float: "left",
    padding: 10
  }
}));

const icons = {
  POSTING: <MessageIcon style={{color: "#836AFF"}} />,
  SHARED_POST: <ShareIcon style={{color: "#0795F4"}} />,
  INVITATION: <EmailIcon style={{color: "#00C4B4"}} />,
  BIRTHDAY: <CakeIcon style={{color: "#EF933C"}} />
};

const getPostContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="primary.main">
      {item.user.name}
    </Box>
    <Box component="span" ml={1}>
      has recently posted an
    </Box>
    <Box component="span" ml={1}>
      {item.metaData.post.type}
    </Box>
  </Typography>
);

const getSharedContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="primary.main">
      {item.user.name}
    </Box>
    <Box component="span" ml={1}>
      has shared
    </Box>
    <Box component="span" ml={1} color="primary.main">
      {`${item.metaData.post.owner.name}'s`}
    </Box>
    <Box component="span" ml={1}>
      post.
    </Box>
  </Typography>
);

const getBirthdayContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="blue">
      {item.user.name}
    </Box>
    <Box component="span" ml={1}>
      has birthday today.
    </Box>
  </Typography>
);

const getInvitationContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="blue">
      {item.metaData.user.name}
    </Box>
    <Box component="span" ml={1}>
      has sent you a group invitation for
    </Box>
    <Box component="span" color="blue" ml={1}>
      {item.metaData.group.name}
    </Box>
  </Typography>
);

const NotificationItem = ({
  item,
  notification,
  sendNotification,
  readNotification,
  isLast = false,
  showLoadMore,
  loadMore
}) => {
  const classes = useStyles({seen: notification.seen});

  const getTitle = (item, classes) => {
    switch (item.type) {
      case "POSTING":
        return getPostContent(item, classes);
      case "SHARED_POST":
        return getSharedContent(item, classes);
      case "INVITATION":
        return getInvitationContent(item, classes);
      case "BIRTHDAY":
        return getBirthdayContent(item, classes);
      default:
        return "";
    }
  };

  const getSubTitle = () => (
    <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
      <Box fontSize={16} clone>
        {icons[item.type]}
      </Box>
      <Box ml={2}>{getDateElements(item.date).time}</Box>
    </Box>
  );

  const {title, description, image, clockicon, clock, id, seen} = notification;

  return (
    <React.Fragment>
      <Box
        className={classes.feedItemRoot}
        onClick={() => {
          readNotification(id, seen);
        }}
      >
        <Box className={classes.profileimg} md={2}>
          {image === "alert" || image === "clock" ? (
            flags[image]
          ) : (
            <CmtImage src={flags[image]} />
          )}
        </Box>

        <Box className={classes.leftNotification}>
          <Box className={classes.boxText}>
            <Box className={classes.title} md={2}>
              {title}
            </Box>

            <Box className={classes.description} md={2}>
              {description}
            </Box>
          </Box>

          <Box className={classes.boxClock}>
            <Box className={classes.clock} md={2}>
              <Box className={classes.clockicon} md={2}>
                {flags[clockicon]}
              </Box>
              {clock}
            </Box>
          </Box>
        </Box>
      </Box>
      {isLast &&
      showLoadMore && (
        <Box
          className={[ classes.feedItemRoot, classes.loadMore ]}
          onClick={() => loadMore()}
        >
          <Box className={classes.title} md={2}>
            <Typography variant="body2">
              <IntlMessages id={`notification.load_more`} />
            </Typography>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default NotificationItem;
