import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import AppTextInput from "../../../@jumbo/components/Common/formElements/AppTextInput";
import AppMultiSelectBox from "../../../@jumbo/components/Common/formElements/AppMultiSelectBox";
import AppSwitch from "../../../@jumbo/components/Common/formElements/AppSwitch";
import {
  getLanguageOptions,
  getCity,
  getEquipmentFormat,
  getSuperAdmin
} from "../../../@jumbo/utils/commonHelper";
import searchHelper from "../../../@jumbo/utils/searchHelper";
import {imageUpload} from "../../../@jumbo/utils/imageHelper";

import CmtImage from "../../../@coremat/CmtImage";
import {
  fetchError,
  fetchStart,
  getData,
  addData,
  updateData
} from "../../../redux/actions/Common";
import PageHeaderBack from "../../../@jumbo/components/PageComponents/PageHeaderBack";

const moduleName = "company";

const Addcompany = () => {
  let widgetStatusList = {
    percentageComplianceChecked: false,
    createdFinishedChecked: false,
    creditVsDebitChecked: false,
    statechecked: false,
    openVsClosedChecked: false,
    percentageClientChecked: false,
    percentageClientNonmechanicalChecked: false,
    listAssetsChecked: false,
    listkmsAssetsChecked: false,
    freightChecked: false,
    insuranceChecked: false,
    circulationpermitChecked: false,
    electronicbillChecked: false,
    providerguideChecked: false,
    professionalfeesChecked: false,
    creditnoteChecked: false,
    debitnoteChecked: false,
    transferguideChecked: false,
    internalGuideChecked: false,
    adjustmentChecked: false
  };

  const classes = useStyles();
  const [ name, setName ] = useState("");
  const [ language, setLanguage ] = useState(null);
  const [ timeZone, setTimeZone ] = useState(null);
  const [ companyRutId, setCompanyRutId ] = useState("");
  const [ city, setCity ] = useState(null);
  const [ telephone, setTelephone ] = useState("");
  const [ equipmentFormat, setEquipmentFormat ] = useState(null);
  const [ description, setDescription ] = useState("");
  const [ address, setAddress ] = useState("");
  const [ photo, setPhoto ] = useState("");
  const [ file, setFile ] = useState("");
  const [ widgetStatus, setWidgetStatus ] = useState(widgetStatusList);

  const dispatch = useDispatch();
  const history = useHistory();
  const {editData} = useSelector(({common}) => common);
  const {id} = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getData(moduleName + "/" + id));
    }
  }, []);

  useEffect(
    () => {
      if (id) {
        let selectFormat = getEquipmentFormat().find(
          item => item.value == editData.format
        );
        let selectLanguage = getLanguageOptions().find(
          item => item.value == editData.language
        );
        let selectTimezone = editData.timeZone
          ? {label: editData.timeZone, value: editData.timeZone}
          : null;
        let selectCity = getCity().find(item => item.value == editData.city);

        setWidgetStatus(
          editData ? {...editData.widgetStatus} : {...widgetStatusList}
        );
        setCity(selectCity);
        setTimeZone(selectTimezone);
        setLanguage(selectLanguage);
        setEquipmentFormat(selectFormat);
        setName(editData ? editData.name : "");
        setCompanyRutId(editData ? editData.companyRutId : "");
        setDescription(editData ? editData.description : "");
        setTelephone(editData ? editData.telephone : "");
        setAddress(editData ? editData.address : "");
        setPhoto(editData && editData.photo ? editData.photo : "");
      }
    },
    [ editData ]
  );

  const photoOnChange = e => {
    const file = e.target.files[0];
    console.log(file);
    setFile(file);
    try {
      var reader = new FileReader();
      reader.onload = function(e){
        setPhoto(e.target.result);
      };
      reader.readAsDataURL(file);
    } catch (err) {
      console.log(err);
    } finally {
      //this.setState({ formState: FormState.saved }, () => this.transition.updateChildren());
    }
  };

  const onSubmit = async () => {
    if (name == "") {
      dispatch(fetchError(<IntlMessages id="error.companyname" />));
    } else if (language == null) {
      dispatch(fetchError(<IntlMessages id="error.language" />));
    } else if (companyRutId == "") {
      dispatch(fetchError(<IntlMessages id="error.idNumber" />));
    } else if (city == null) {
      dispatch(fetchError(<IntlMessages id="error.city" />));
    } else if (telephone == "") {
      dispatch(fetchError(<IntlMessages id="error.telephone" />));
    } else if (equipmentFormat == null) {
      dispatch(fetchError(<IntlMessages id="error.select_equipment_format" />));
    } else if (description == "") {
      dispatch(fetchError(<IntlMessages id="error.description" />));
    } else if (address == "") {
      dispatch(fetchError(<IntlMessages id="error.address" />));
    } else {
      var data = {
        name: name,
        language: language ? language.value : "",
        timeZone: timeZone ? timeZone.label : "",
        description: description,
        address: address,
        city: city ? city.value : "",
        format: equipmentFormat ? equipmentFormat.value : "",
        companyRutId: companyRutId,
        telephone: telephone,
        widgetStatus: widgetStatus,
        photo: photo
      };
      dispatch(fetchStart());
      if (file != "") {
        data.photo = await imageUpload(file);
      }
      console.log("data.photo===", data.photo);
      if (id) {
        dispatch(updateData(moduleName, data, id, history, false));
      } else {
        dispatch(addData(moduleName, data, history));
      }
    }
  };

  return (
    <div className={classes.root}>
      <PageHeaderBack
        onSubmit={onSubmit}
        moduleName={moduleName}
        Iscancel={getSuperAdmin()}
      />

      <Grid classes={{root: "forms"}}>
        <Grid container spacing={5}>
          <Grid item lg={4} md={6} sm={6} xs={12} classes={{root: "firstrow"}}>
            <AppTextInput
              label={
                <span>
                  <IntlMessages id="dashboard.name" />*
                </span>
              }
              InputLabelProps={{shrink: true}}
              onChange={event => setName(event.target.value)}
              value={name}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12} classes={{root: "firstrow"}}>
            <Grid classes={{root: "labels"}}>
              <IntlMessages id="users.alert_language" />
              <span>*</span>
            </Grid>
            <AppMultiSelectBox
              menuPlacement="auto"
              onChange={value => setLanguage(value)}
              value={language}
              isMulti={false}
              options={getLanguageOptions()}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Grid classes={{root: "labels"}}>
              <IntlMessages id="users.time_zone" />
            </Grid>
            <AppMultiSelectBox
              menuPlacement="auto"
              onChange={value => setTimeZone(value)}
              value={timeZone}
              isMulti={false}
              loadOptions={(search, prevOptions) =>
                searchHelper.getOptionsSearchDetails(
                  search,
                  prevOptions,
                  "time_zone",
                  null,
                  false
                )}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <AppTextInput
              label={
                <span>
                  <IntlMessages id="inhousepersonnel.idNumber" />*
                </span>
              }
              InputLabelProps={{shrink: true}}
              onChange={event => setCompanyRutId(event.target.value)}
              value={companyRutId}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Grid classes={{root: "labels"}}>
              <IntlMessages id="dashboard.city" />*
            </Grid>
            <AppMultiSelectBox
              menuPlacement="auto"
              onChange={value => setCity(value)}
              value={city}
              isMulti={false}
              options={getCity()}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <AppTextInput
              onChange={event => setTelephone(event.target.value)}
              value={telephone}
              label={
                <span>
                  <IntlMessages id="riskmanagement.telephone" />*
                </span>
              }
              InputLabelProps={{shrink: true}}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <Grid classes={{root: "labels"}}>
              <IntlMessages id="company.select_equipment_format" />*
            </Grid>
            <AppMultiSelectBox
              menuPlacement="auto"
              onChange={value => setEquipmentFormat(value)}
              value={equipmentFormat}
              isMulti={false}
              options={getEquipmentFormat()}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <AppTextInput
              onChange={event => setDescription(event.target.value)}
              value={description}
              label={
                <span>
                  <IntlMessages id="company.description" />*
                </span>
              }
              InputLabelProps={{shrink: true}}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <AppTextInput
              onChange={event => setAddress(event.target.value)}
              value={address}
              label={
                <span>
                  <IntlMessages id="dashboard.address" />*
                </span>
              }
              InputLabelProps={{shrink: true}}
            />
          </Grid>
          <Grid item lg={4} md={6} sm={6} xs={12} className={classes.filetype}>
            {photo != "" && <CmtImage src={photo} alt="logo" />}
            <span className={classes.filetype_inner}>
              <AppTextInput
                type="image"
                label={<IntlMessages id="availability.photo" />}
                InputLabelProps={{shrink: true}}
                onChange={event => photoOnChange(event)}
              />
              <span className={classes.filetype_span}>
                <IntlMessages id="sidebar.choose_image" />
              </span>
            </span>
          </Grid>

          {/* {getSuperAdmin() && (
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.togglediv}
              spacing={3}
            >
              <Grid item xs={12} className={classes.widgetHeading}>
                <IntlMessages
                  id="company.widget_dashboard"
                  className={classes.togglediv_head}
                />
              </Grid>
              <Grid item lg={4} md={6} sm={6} xs={12} container>
                <Grid item xs={9}>
                  <IntlMessages id="sidebar.percentage_compliance" />
                </Grid>
                <Grid item xs={3}>
                  <AppSwitch
                    checked={widgetStatus.percentageComplianceChecked}
                    onChange={value =>
                      setWidgetStatus({
                        ...widgetStatus,
                        percentageComplianceChecked: !widgetStatus.percentageComplianceChecked
                      })}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12} container>
                <Grid item xs={9}>
                  <IntlMessages id="sidebar.created_finished" />
                </Grid>
                <Grid item xs={3}>
                  <AppSwitch
                    checked={widgetStatus.createdFinishedChecked}
                    onChange={value =>
                      setWidgetStatus({
                        ...widgetStatus,
                        createdFinishedChecked: !widgetStatus.createdFinishedChecked
                      })}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12} container>
                <Grid item xs={9}>
                  <IntlMessages id="sidebar.credit_vs_debit" />
                </Grid>
                <Grid item xs={3}>
                  <AppSwitch
                    checked={widgetStatus.creditVsDebitChecked}
                    onChange={value =>
                      setWidgetStatus({
                        ...widgetStatus,
                        creditVsDebitChecked: !widgetStatus.creditVsDebitChecked
                      })}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12} container>
                <Grid item xs={9}>
                  <IntlMessages id="sidebar.state" />
                </Grid>
                <Grid item xs={3}>
                  <AppSwitch
                    checked={widgetStatus.statechecked}
                    onChange={value =>
                      setWidgetStatus({
                        ...widgetStatus,
                        statechecked: !widgetStatus.statechecked
                      })}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12} container>
                <Grid item xs={9}>
                  <IntlMessages id="sidebar.open_vs_closed" />
                </Grid>
                <Grid item xs={3}>
                  <AppSwitch
                    checked={widgetStatus.openVsClosedChecked}
                    onChange={value =>
                      setWidgetStatus({
                        ...widgetStatus,
                        openVsClosedChecked: !widgetStatus.openVsClosedChecked
                      })}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12} container>
                <Grid item xs={9}>
                  <IntlMessages id="sidebar.percentage_client" />
                </Grid>
                <Grid item xs={3}>
                  <AppSwitch
                    checked={widgetStatus.percentageClientChecked}
                    onChange={value =>
                      setWidgetStatus({
                        ...widgetStatus,
                        percentageClientChecked: !widgetStatus.percentageClientChecked
                      })}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12} container>
                <Grid item xs={9}>
                  <IntlMessages id="sidebar.percentage_client_nonmechanic" />
                </Grid>
                <Grid item xs={3}>
                  <AppSwitch
                    checked={widgetStatus.percentageClientNonmechanicalChecked}
                    onChange={value =>
                      setWidgetStatus({
                        ...widgetStatus,
                        percentageClientNonmechanicalChecked: !widgetStatus.percentageClientNonmechanicalChecked
                      })}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12} container>
                <Grid item xs={9}>
                  <IntlMessages id="sidebar.assets_list_hours" />
                </Grid>
                <Grid item xs={3}>
                  <AppSwitch
                    checked={widgetStatus.listAssetsChecked}
                    onChange={value =>
                      setWidgetStatus({
                        ...widgetStatus,
                        listAssetsChecked: !widgetStatus.listAssetsChecked
                      })}
                  />
                </Grid>
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12} container>
                <Grid item xs={9}>
                  <IntlMessages id="sidebar.assets_list_kms" />
                </Grid>
                <Grid item xs={3}>
                  <AppSwitch
                    checked={widgetStatus.listkmsAssetsChecked}
                    onChange={value =>
                      setWidgetStatus({
                        ...widgetStatus,
                        listkmsAssetsChecked: !widgetStatus.listkmsAssetsChecked
                      })}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} className={classes.widgetHeading}>
              <IntlMessages
                id="company.modules_company"
                className={classes.togglediv_head}
              />
            </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.freight" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.freightChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      freightChecked: !widgetStatus.freightChecked
                    })}
                />
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.insurance" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.insuranceChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      insuranceChecked: !widgetStatus.insuranceChecked
                    })}
                />
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.circulation_permit" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.circulationpermitChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      circulationpermitChecked: !widgetStatus.circulationpermitChecked
                    })}
                />
              </Grid>
            </Grid>

              <Grid item xs={12} className={classes.widgetHeading}>
              <IntlMessages
                id="company.warehouse_modules_company"
                className={classes.togglediv_head}
              />
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.electronicinvoice" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.electronicbillChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      electronicbillChecked: !widgetStatus.electronicbillChecked
                    })}
                />
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.supplierofficeguide" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.providerguideChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      providerguideChecked: !widgetStatus.providerguideChecked
                    })}
                />
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.professionalfees" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.professionalfeesChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      professionalfeesChecked: !widgetStatus.professionalfeesChecked
                    })}
                />
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.creditnote" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.creditnoteChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      creditnoteChecked: !widgetStatus.creditnoteChecked
                    })}
                />
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.debitnote" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.debitnoteChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      debitnoteChecked: !widgetStatus.debitnoteChecked
                    })}
                />
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.admissioninternalguide" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.internalGuideChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      internalGuideChecked: !widgetStatus.internalGuideChecked
                    })}
                />
              </Grid>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12} container>
              <Grid item xs={9}>
                <IntlMessages id="sidebar.adjustment" />
              </Grid>
              <Grid item xs={3}>
                <AppSwitch
                  checked={widgetStatus.adjustmentChecked}
                  onChange={value =>
                    setWidgetStatus({
                      ...widgetStatus,
                      adjustmentChecked: !widgetStatus.adjustmentChecked
                    })}
                />
              </Grid>
            </Grid>
            </Grid>
          )} */}
        </Grid>
      </Grid>
    </div>
  );
};

export default Addcompany;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#f8f9fa"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  filetype_inner: {
    position: "relative"
  },
  filetype: {
    position: "relative",
    "& .MuiInputBase-input": {
      backgroundColor: "transparent",
      opacity: 0,
      zIndex: "99"
    }
  },
  filetype_span: {
    position: "absolute",
    backgroundColor: "#dbecfa",
    padding: 8,
    borderRadius: 30,
    height: 37,
    left: 6,
    bottom: -49
  },
  filetype_inner: {
    position: "relative"
  },
  add_div: {
    backgroundColor: "#f7fdff!important"
  },
  togglediv: {
    backgroundColor: "#f7fdff!important",
    width: "100%",
    "& .MuiGrid-container": {
      padding: "8px!important",
      backgroundColor: "#f7fdff",
      float: "left",
      "& .MuiGrid-item": {
        backgroundColor: "#fff",
        padding: 4,
        lineHeight: "35px"
      }
    }
  },
  togglediv_head: {
    fontSize: 18
  },
  widgetHeading: {
    float: "left",
    width: "100%",
    padding: 10,
    fontSize: 20,
    marginTop: 15
  }
}));
