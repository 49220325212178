import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Box from "@material-ui/core/Box";
import IntlMessages from "./../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";
import CmtTable from "./../../../@coremat/CmtTable";
import ModificationapprovalModalCard from "./../../../@coremat/ModificationapprovalModalCard";
import ApprovestatusModalCard from "./../../../@coremat/ApprovestatusModalCard";
import ObservationModalCard from "./../../../@coremat/ObservationModalCard";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";

import {makeStyles} from "@material-ui/core/styles";
import FilterCard from "./../../../@jumbo/components/Common/FilterCard";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {
  roleApprovePermission,
  getKeyNameList,
  getModuleName,
  getRoleName,
  getRoleId
} from "../../../@jumbo/utils/commonHelper";

import {
  fetchError,
  fetchStart,
  fetchSuccess,
  getData,
  addData,
  updateData,
  getAllData
} from "../../../redux/actions/Common";
import {useIntl} from "react-intl";
import api from "../../../services/api";
import {getSixDigit} from "../../../@jumbo/utils/commonHelper";

const columns = [
  {
    label: "approve_management.module",
    name: "document_name",
    key: "document",
    search: true,
    sort: true
  },
  {label: "purchaserequests.dateEmission", name: "emissionDate", sort: true},
  {label: "purchaserequests.dateRevision", name: "revisionDate", sort: true},
  {label: "sidebar.region", name: "region_name"},
  {label: "sidebar.warehouse", name: "warehouse_name"},
  {label: "sidebar.created_by", name: "created_by"},
  {label: "actions.state", name: "modify_approval_status"},
  {label: "actions.pdf", name: "approve_pdf"},
  {label: "sidebar.observation", name: "view_observation"}
];

const assets_modification_columns = [
  {
    label: "approve_management.module",
    name: "document_name",
    search: true,
    key: "moduleName",
    sort: true
  },
  {
    label: "approve_management.date_modification",
    name: "createdAt",
    sort: true
  },
  {label: "purchaserequests.dateRevision", name: "updatedAt", sort: true},
  {label: "sidebar.region", name: "region_name"},
  {label: "sidebar.warehouse", name: "warehouse_name"},
  {label: "sidebar.created_by", name: "created_by"},
  {label: "actions.state", name: "modify_approval_status"},
  {label: "sidebar.observation", name: "view_observation"}
];

const moduleName1 = "approvewarehouse";
const moduleName2 = "approvepermission";

const keyNameList = getKeyNameList();

function TabPanel(props){
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index){
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
let editIndex = "";
const Approve = () => {
  const [ tab, setTab ] = React.useState(0);
  const [ pendingItems, setPendingItems ] = React.useState([]);
  const [ observationArray, setObservationArray ] = React.useState([]);

  const [ assetsapproveModal, setAssetsapproveModal ] = React.useState(false);
  const [ approveModal, setApproveModal ] = React.useState(false);
  const [ observeModal, setObserveModal ] = React.useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const {authUser} = useSelector(({auth}) => auth);

  const {approvewarehouse} = useSelector(({common}) => common);
  const {approvepermission} = useSelector(({common}) => common);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  if (approvewarehouse && approvewarehouse.rows) {
    for (let item of approvewarehouse.rows) {
      item.region_name = item.region ? item.region.name : null;
      item.warehouse_name = item.warehouse ? item.warehouse.name : null;
      //item.document = item.document == null ? "PDG" : item.document;
      item.document_name =
        intl.formatMessage({id: "spares." + item.document}) +
        "-" +
        getSixDigit(item.documentNo);
      item.modify_approval_status = item.approvalStatus
        ? item.approvalStatus
        : "pending";
      item.created_by = item.user ? item.user.firstName : "";
    }
  }

  const onSaveModify = async params => {
    if (params.action == "save") {
      let items = params.items;
      let pendingItems = approvepermission.rows;
      let pendingCount = 0;
      let rejectCount = 0;
      let approveCount = 0;
      let status = "pending";

      for (let item in items) {
        if (items[item].approval_status == "approve") {
          approveCount = approveCount + 1;
        } else if (items[item].approval_status == "pending") {
          pendingCount = pendingCount + 1;
        } else if (items[item].approval_status == "rejected") {
          rejectCount = rejectCount + 1;
        }
        pendingItems[editIndex].modify[item].status =
          items[item].approval_status;
      }
      if (approveCount == items.length) {
        status = "approve";
      } else if (rejectCount == items.length) {
        status = "rejected";
      } else if (pendingCount == items.length) {
        status = "pending";
      } else if (0 < approveCount) {
        status = "partially_approve";
      } else if (0 < rejectCount) {
        status = "partially_reject";
      }

      let data = {
        modify: pendingItems[editIndex].modify,
        modifyStatus: status,
        moduleName: pendingItems[editIndex].moduleName,
        updatedUid: authUser.id,
        userRole: getRoleName(),
        userRoleId: getRoleId(),
        type: "edit",
        observation: params.observation ? params.observation : []
      };

      dispatch(fetchStart());
      let response = await api.putApi(
        "approvepermission" + "/" + pendingItems[editIndex].id,
        data
      );
      if (response.status == "success") {
        dispatch(fetchSuccess());
        dispatch(getAllData(moduleName2, 0, 10, ""));
      } else {
        dispatch(fetchError(response.message));
      }
    }
    setAssetsapproveModal(false);
  };

  const onRowsApprove = item => {
    editIndex = item[0].index;
    onApprovalAction(editIndex);
  };

  const onApprovalAction = async (rowIndex, showModal = true) => {
    if (tab == 0) {
      let item = approvewarehouse.rows[rowIndex];
      if (
        item.approvalStatus != "approve" &&
        item.approvalStatus != "delete" &&
        item.approvalStatus != "rejected"
      ) {
        if (showModal) {
          editIndex = rowIndex;
          setApproveModal(true);
        }
      } else {
        dispatch(
          fetchError(
            intl.formatMessage({id: "error.already_" + item.approvalStatus})
          )
        );
      }
    } else {
      if (approvepermission.rows && approvepermission.rows[rowIndex]) {
        let items = approvepermission.rows[rowIndex];
        let moduleName = items.moduleName;
        editIndex = rowIndex;
        if (
          items.modifyStatus == "delete" ||
          items.modifyStatus == "approve" ||
          items.modifyStatus == "rejected"
        ) {
          dispatch(
            fetchError(
              intl.formatMessage({id: "error.already_" + items.modifyStatus})
            )
          );
        } else if (items.modifyStatus == "request_delete") {
          let isApprovePermission = roleApprovePermission(
            items.moduleName,
            "remove"
          );
          isApprovePermission = true;
          if (isApprovePermission) {
            setApproveModal(true);
          } else {
            dispatch(
              fetchError(intl.formatMessage({id: "error.permisson_error"}))
            );
          }
        } else {
          let isApprovePermission = roleApprovePermission(
            items.moduleName,
            "edit"
          );
          isApprovePermission = true;
          if (isApprovePermission) {
            let pendingItems = [];
            let alreadyApprove = false;
            for (let item in items.modify) {
              let previous = items.modify[item].previous;
              let key = items.modify[item].key;
              let keyName =
                keyNameList[moduleName] && keyNameList[moduleName][key]
                  ? keyNameList[moduleName][key]
                  : key;
              keyName = intl.formatMessage({id: keyName});

              let data = {
                id: item,
                key: item,
                modify_changes: keyName,
                modify_key: items.modify[item].key,
                previous: previous ? previous : "",
                alreadyApprove: alreadyApprove,
                modified: items.modify[item].value,
                approval_status: items.modify[item].status
                  ? items.modify[item].status
                  : "pending"
              };
              pendingItems.push(data);
              if (0 < pendingItems.length) {
                setPendingItems([ ...pendingItems ]);
                setAssetsapproveModal(true);
              }
            }
          } else {
            dispatch(
              fetchError(intl.formatMessage({id: "error.permisson_error"}))
            );
          }
        }
      }
    }
  };

  const onRowsObservation = item => {
    if (item.index != undefined) {
      editIndex = item.index;
    } else {
      editIndex = item[0].index;
    }

    if (tab == 0) {
      let itemRow = approvewarehouse.rows[editIndex];
      setObservationArray(itemRow.observation);
    } else {
      let itemRow = approvepermission.rows[editIndex];
      setObservationArray(itemRow.observation);
    }
    setObserveModal(true);
  };

  const onCancel = async () => {
    setObserveModal(false);
  };

  const onSaveWarehouse = async params => {
    try {
      setApproveModal(false);
      if (params.action == "save") {
        if (tab == 0) {
          let item = approvewarehouse.rows[editIndex];
          let newModuleName = getModuleName(item.document);
          if (item.approvalStatus != "approve") {
            let type = "";
            if (item.approvalStatus == "request_delete") {
              type = "remove";
            } else {
              type = "edit";
            }
            if (type == "remove") {
              let modifyStatus = "";
              if (params.status && params.status.value == "approve") {
                modifyStatus = "delete";
              } else {
                modifyStatus = "rejected";
              }
              let data = {
                approvalStatus: modifyStatus,
                moduleName: newModuleName,
                updatedUid: authUser.id,
                userRole: getRoleName(),
                userRoleId: getRoleId(),
                id: item.id,
                type: type,
                observation: params.observation
              };
              dispatch(fetchStart());
              let response = await api.postApi(
                "checkwarehousedeletepermission" + "/" + item.id,
                data
              );
              if (response.status == "success") {
                if (modifyStatus == "delete") {
                  dispatch(
                    fetchSuccess(
                      intl.formatMessage({id: "error.delete_request_approved"})
                    )
                  );
                } else {
                  dispatch(
                    fetchSuccess(
                      intl.formatMessage({id: "error.delete_request_rejected"})
                    )
                  );
                }
                dispatch(getAllData(moduleName1, 0, 10, ""));
              } else {
                dispatch(fetchError(response.message));
              }
            } else {
              let data = {
                approvalStatus: params.status ? params.status.value : "pending",
                updatedUid: authUser.id,
                observation: params.observation
              };

              dispatch(fetchStart());
              let checkPermissionData = {
                updatedUid: authUser.id,
                id: item.id,
                moduleId: item.orderId,
                moduleName: newModuleName,
                userRole: getRoleName(),
                userRoleId: getRoleId(),
                type: type
              };

              console.log(
                "checkPermissionData===",
                checkPermissionData,
                "item===",
                item
              );
              let permissionResponse = await api.postApi(
                "checkpermissiondocument",
                checkPermissionData
              );
              console.log("permissionResponse==s==", permissionResponse);
              if (
                newModuleName == "purchaseorder" &&
                permissionResponse &&
                permissionResponse.data == true &&
                permissionResponse.IsApprove == false
              ) {
                if (permissionResponse.IsWatingApprove) {
                  dispatch(
                    fetchError(
                      intl.formatMessage({id: "error.waiting_approve"})
                    )
                  );
                } else if (permissionResponse.IsAlreadyApprove) {
                  dispatch(
                    fetchError(
                      intl.formatMessage({id: "error.already_approve"})
                    )
                  );
                } else {
                  dispatch(
                    fetchSuccess(
                      intl.formatMessage({
                        id: "purchaserequests.purchase_one_approve"
                      })
                    )
                  );
                }
              } else if (
                permissionResponse &&
                permissionResponse.data == true
              ) {
                let response = await api.putApi(
                  newModuleName + "/" + item.orderId,
                  data
                );
                console.log("response==s===============", data.approvalStatus);
                if (data.approvalStatus == "approve") {
                  dispatch(
                    fetchSuccess(
                      intl.formatMessage({id: "error.update_request_approved"})
                    )
                  );
                } else {
                  dispatch(
                    fetchSuccess(
                      intl.formatMessage({id: "error.update_request_rejected"})
                    )
                  );
                }
                //dispatch(fetchSuccess());
                dispatch(getAllData(moduleName1, 0, 10, ""));
              } else {
                dispatch(
                  fetchError(intl.formatMessage({id: "error.permisson_error"}))
                );
              }
            }
          } else {
            dispatch(
              fetchError(intl.formatMessage({id: "error.already_approve"}))
            );
          }
        } else {
          let item = approvepermission.rows[editIndex];
          let isApprovePermission = true;
          let type = "";
          if (item.modifyStatus == "request_delete") {
            type = "remove";
          } else {
            type = "edit";
          }
          if (isApprovePermission) {
            if (item.approvalStatus != "approve") {
              let modifyStatus = "";
              if (params.status && params.status.value == "approve") {
                modifyStatus = "delete";
              } else {
                modifyStatus = "rejected";
              }
              let data = {
                modifyStatus: modifyStatus,
                moduleName: item.moduleName,
                updatedUid: authUser.id,
                userRole: getRoleName(),
                userRoleId: getRoleId(),
                type: type,
                observation: params.observation
              };
              console.log("data====", data, item.id);
              dispatch(fetchStart());
              let response = await api.putApi(
                "approvepermission" + "/" + item.id,
                data
              );
              if (response.status == "success") {
                if (modifyStatus == "delete") {
                  dispatch(
                    fetchSuccess(
                      intl.formatMessage({id: "error.delete_request_approved"})
                    )
                  );
                } else {
                  dispatch(
                    fetchSuccess(
                      intl.formatMessage({id: "error.delete_request_rejected"})
                    )
                  );
                }
                dispatch(getAllData(moduleName2, 0, 10, ""));
              } else {
                dispatch(fetchError(response.message));
              }
            } else {
              dispatch(
                fetchError(intl.formatMessage({id: "error.already_approve"}))
              );
            }
          } else {
            dispatch(
              fetchError(intl.formatMessage({id: "error.permisson_error"}))
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
      dispatch(fetchError(<IntlMessages id="error.something_went_wrong" />));
    }
  };

  if (approvepermission && approvepermission.rows) {
    for (let item of approvepermission.rows) {
      item.created_by = item.user ? item.user.firstName : "";
      let name = "sidebar." + item.moduleName;

      item.document_name = item.moduleName
        ? intl.formatMessage({id: name}) + "-" + item.moduleId
        : "";
      item.modify_approval_status = item.modifyStatus;

      let warehouseName = "";
      let regionName = "";

      if (item.warehouse) {
        for (let item1 of item.warehouse) {
          if (warehouseName == "") {
            warehouseName = item1.name;
          } else {
            warehouseName = warehouseName + "," + item1.name;
          }
        }
        item.warehouse_name =
          warehouseName == "" ? <IntlMessages id="table.all" /> : warehouseName;
      }

      if (item.region) {
        for (let item1 of item.region) {
          if (regionName == "") {
            regionName = item1.name;
          } else {
            regionName = regionName + "," + item1.name;
          }
        }
        item.region_name =
          regionName == "" ? <IntlMessages id="table.all" /> : regionName;
      }
    }
  }
  return (
    <div className={classes.root}>
      {tab == 0 && (
        <FilterCard
          moduleName={moduleName1}
          Iscompany={true}
          Isregion={true}
          Iswarehouse={true}
          Iswarehousedocument={true}
          IsstatusTab={true}
        />
      )}
      {tab == 1 && (
        <FilterCard
          moduleName={moduleName2}
          Iscompany={true}
          Isapprovedocument={true}
          IsstatusTab={true}
        />
      )}
      <AppBar position="static">
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label={<IntlMessages id="sidebar.approve_tab" />}
            icon={<WorkOutlineIcon />}
            {...a11yProps(0)}
          />
          <Tab
            label={<IntlMessages id="sidebar.assets_modification" />}
            icon={<BuildOutlinedIcon />}
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <Grid>
        <TabPanel value={tab} index={0}>
          <Grid xs={12} className={classes.table}>
            <CmtTable
              title="sidebar.approve_tab"
              isAdd={false}
              isEdit={false}
              onRowsObservation={onRowsObservation}
              onRowsApprove={onRowsApprove}
              isApprove={true}
              isDelete={false}
              onApprovalAction={onApprovalAction}
              moduleName={moduleName1}
              data={approvewarehouse}
              columns={columns}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Grid xs={12} className={classes.table}>
            <CmtTable
              title="sidebar.assets_modification"
              isApprove={true}
              onRowsObservation={onRowsObservation}
              onRowsApprove={onRowsApprove}
              isAdd={false}
              isEdit={false}
              isDelete={false}
              onApprovalAction={onApprovalAction}
              moduleName={moduleName2}
              data={approvepermission}
              columns={assets_modification_columns}
            />
          </Grid>
        </TabPanel>
      </Grid>
      {assetsapproveModal && (
        <ModificationapprovalModalCard
          pendingItems={pendingItems}
          onSaveModify={onSaveModify}
        />
      )}
      {approveModal && <ApprovestatusModalCard onAction={onSaveWarehouse} />}
      {observeModal && (
        <ObservationModalCard
          onCancel={onCancel}
          observationArray={observationArray}
        />
      )}
    </div>
  );
};
export default Approve;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    "& .MuiGrid-item": {
      // padding: "0!important"
    },
    "& .CmtSearch-root": {
      width: "100%",
      "& .MuiBox-root": {
        width: "100%"
      }
    },
    "& .MuiCollapse-container": {
      width: "100%",
      float: "left"
    },
    "& .MuiButtonBase-root": {
      float: "right"
    },
    "& .MuiAppBar-positionStatic": {
      color: "#000",
      backgroundColor: "#fff",
      boxShadow: "none",
      borderTop: `1px solid ${theme.palette.sidebar.borderColor}`,
      "& .MuiTab-textColorInherit": {
        maxWidth: "100%",
        color: "#999",
        fontWeight: 600
      },
      "& .Mui-selected": {
        color: theme.palette.sidebar.textActiveColor
      },
      "& .MuiTab-wrapper": {
        textTransform: "capitalize"
      }
    },
    // "& .MuiTabs-fixed": {
    //   height: "68px!important"
    // },
    "& .MuiTab-wrapper": {
      display: "block"
    },
    "& .MuiTab-root": {
      paddingLeft: "40px!important"
    },
    "& .MuiTab-wrapper > .MuiSvgIcon-root": {
      position: "absolute",
      top: "14px",
      left: "8px"
    },
    "& .MuiTab-labelIcon": {
      minHeight: "50px!important",
      paddingTop: "9px"
    }
  },

  menu_icon: {
    width: 15,
    marginRight: 7
  },
  table: {
    padding: "0px!important"
  },

  dateFilter: {
    backgroundColor: "#fff",
    padding: "10px 15px",
    width: "100%",
    float: "left",
    zIndex: 9
  },
  datePicker: {
    backgroundColor: "#fff",
    padding: 10,
    width: "27%",
    float: "left"
  },
  datePickers: {
    backgroundColor: "#fff",
    padding: 10,
    width: "44%",
    float: "left"
  },
  filterBoxHead: {
    color: theme.palette.text.black
  },
  filterButton: {
    backgroundColor: theme.palette.sidebar.textActiveColor,
    borderRadius: 30,
    color: "#fff",
    padding: "10px 20px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.sidebar.textActiveColor
    }
  },
  cardBackButtons: {
    float: "left",
    color: theme.palette.sidebar.textActiveColor,
    fontSize: 14,
    "& .MuiButton-root": {
      width: "30px!important",
      minWidth: 30,
      height: 30,
      paddingLeft: 11,
      float: "left",
      marginTop: 11
    }
  }
}));
