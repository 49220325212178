import React from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../@coremat/CmtImage';
// import CircularProgress from '@material-ui/core/CircularProgress';
const loading = '/images/loading.gif';


const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 999999,
    width: '100%',
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading:{
    width:40,
    height:40
  }
}));

const PageLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.circularProgressRoot}>
      {/* <CircularProgress /> */}
      <CmtImage src={loading} className={classes.loading}/>
    </Box>
  );
};

export default PageLoader;
