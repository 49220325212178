import React from "react";
import SidebarToggleHandler from "../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler";
import Toolbar from "@material-ui/core/Toolbar";
import {Box, fade, Typography} from "@material-ui/core";
import LanguageSwitcher from "../LanguageSwitcher";
import makeStyles from "@material-ui/core/styles/makeStyles";
import HeaderNotifications from "./HeaderNotifications";
import {NavLink} from "react-router-dom";

import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

import CmtImage from "../../../../../@coremat/CmtImage";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 64,
    [theme.breakpoints.up("md")]: {
      minHeight: 72
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  },
  searchRoot: {
    position: "relative",
    width: 260,
    [theme.breakpoints.up("md")]: {
      width: 350
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      left: 18,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1
    },
    "& .MuiInputBase-root": {
      width: "100%"
    },
    "& .MuiInputBase-input": {
      height: 48,
      borderRadius: 30,
      backgroundColor: fade(theme.palette.common.dark, 0.38),
      color: fade(theme.palette.common.white, 0.7),
      boxSizing: "border-box",
      padding: "5px 15px 5px 50px",
      transition: "all 0.3s ease",
      "&:focus": {
        backgroundColor: fade(theme.palette.common.dark, 0.58),
        color: fade(theme.palette.common.white, 0.7)
      }
    }
  },
  langRoot: {
    borderLeft: `solid 1px ${fade(theme.palette.common.dark, 0.15)}`,
    minHeight: 72,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      minHeight: 64
    }
  },
  iconBtn: {
    color: fade(theme.palette.common.white, 0.38),
    "&:hover, &:focus": {
      color: theme.palette.common.white
    }
  },
  root: {
    padding: "4px 35px 4px 14px",
    width: "100%",
    "@media screen and (max-width: 500px)": {
      float: "right",
      display: "block"
    }
    // borderBottom: 'solid 1px #ccc'
    // borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
  },
  userInfo: {
    transition: "all 0.1s ease",
    marginLeft: 15,
    opacity: 1,
    display: "contents",
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease"
    },
    "@media screen and (max-width: 500px)": {
      display: "none"
    }
  },

  userTitle: {
    color: "#000"
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25
  },
  menuBar: {
    "@media screen and (max-width: 500px)": {
      float: "right"
    }
  },
  header: {
    color: "#000",
    fontSize: 18,
    marginRight: 0,
    "@media screen and (max-width: 500px)": {
      // display: 'none'
      float: "right",
      width: "50px"
    }
  },
  header_none: {
    "@media screen and (max-width: 500px)": {
      display: "none"
    }
  },
  leftheader: {
    color: "#acb2bc",
    fontSize: 18,
    marginRight: 20,
    marginTop: 5,
    "@media screen and (max-width: 500px)": {
      display: "none"
    }
  },
  rightheader: {
    color: "#000",
    fontSize: 18,
    marginRight: 0,
    marginTop: 7,
    "@media screen and (max-width: 500px)": {
      display: "none"
    }
  },
  rightMenu: {
    width: "100%",
    float: "left",
    display: "flex",
    position: "relative",
    alignItems: "center",
    "@media screen and (max-width: 500px)": {
      width: 165,
      float: "right",
      display: "block"
    }
  },
  menuBar: {
    "& .MuiIconButton-label": {
      "& .MuiSvgIcon-root": {
        fontSize: "29px",
        color: "#000"
      }
    }
  },
  leftMenu: {
    width: 120,
    float: "left",
    marginTop: 3,
    paddingTop: 5,
    "@media screen and (min-width: 500px)": {
      display: "none"
    }
  }
}));

const Header = () => {
  const classes = useStyles();
  const {authUser} = useSelector(({auth}) => auth);
  const location = useLocation();
  let locationName = location.pathname.replace("/", "");
  locationName = locationName.split("/");
  let location1 = "";
  let location2 = "";
  locationName[0] = locationName[0].replace("add", "");
  locationName[0] = locationName[0].replace("edit", "");

  if (locationName[0] == "service") {
    locationName[0] = "service_management";
  }
  if (locationName.length == 1) {
    location1 = "sidebar." + locationName[0];
  } else if (locationName.length == 2 || locationName.length == 3) {
    location1 = "sidebar." + locationName[0];
    if (Number(locationName[1])) {
    } else {
      locationName[1] = locationName[1].replace("add", "");
      if (locationName[1] != "creditnote") {
        locationName[1] = locationName[1].replace("edit", "");
      }
      location2 = "sidebar." + locationName[1];
    }
  }

  const logoSymbolUrl = "/images/logo-symbol.png";

  return (
    <Toolbar className={classes.root}>
      <NavLink to="/">
        <Box className={classes.leftMenu}>
          <CmtImage src={logoSymbolUrl} alt="logo" />
        </Box>
      </NavLink>
      <Box className={classes.rightMenu}>
        <SidebarToggleHandler
          edge="start"
          color="inherit"
          aria-label="menu"
          className={classes.menuBar}
        />

        {location2 == "" &&
        location1 != "" && (
          <Box mr={10} className={classes.rightheader}>
            <IntlMessages id={location1} />
          </Box>
        )}
        {location2 != "" &&
        location1 != "" && (
          <Box mr={10} className={classes.leftheader}>
            <IntlMessages id={location1} />
          </Box>
        )}
        {location2 != "" &&
        location1 != "" && (
          <Box mr={10} className={classes.rightheader}>
            <IntlMessages id={location2} />
          </Box>
        )}
        <Box flex={1} className={classes.header_none} />
        <HeaderNotifications className={classes.userNotification} />
        <Box mr={10} className={classes.header}>
          <LanguageSwitcher />
        </Box>
        <Box className={classes.userInfo}>
          <Box mr={2} style={{marginLeft: 15, marginRight: 0}}>
            <CmtImage
              src={"/images/profile_img.png"}
              style={{
                width: 28,
                float: "left",
                height: 28,
                border: "solid 2px #ccc",
                borderRadius: 25
              }}
            />
          </Box>
          <Box
            mr={10}
            style={{color: "#000", margin: 0, float: "right", padding: 7}}
          >
            {authUser ? authUser.firstName : ""}
          </Box>
        </Box>
      </Box>
    </Toolbar>
  );
};

export default Header;
