import React, {useState, useEffect, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import {useIntl} from "react-intl";
import {getNumberFormat} from "../../../@jumbo/utils/commonHelper";

const COLORS = [ "#213a52", "#29939b" ];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Creditdebit = () => {
  const {dashboard} = useSelector(({common}) => common);
  const [ purchasePieData, setPurchasePieData ] = useState(null);
  const intl = useIntl();

  useEffect(
    () => {
      let creditAmount = 0;
      let debitAmount = 0;
      if (dashboard.creditVsDebitChecked) {
        creditAmount = dashboard.creditVsDebitChecked.find(
          item => item.condition == "credit"
        );
        debitAmount = dashboard.creditVsDebitChecked.find(
          item => item.condition == "debit"
        );
        creditAmount = creditAmount ? creditAmount.total : 0;
        debitAmount = debitAmount ? debitAmount.total : 0;
      }
      let purchasepieData = [
        {
          name: intl.formatMessage({id: "equipment.credit"}),
          value: creditAmount
        },
        {name: intl.formatMessage({id: "equipment.debit"}), value: debitAmount}
      ];
      setPurchasePieData(purchasepieData);
    },
    [ dashboard ]
  );

  return (
    <Grid item xs={12}>
      <Box>
        <ResponsiveContainer height={230} width="100%">
          {purchasePieData && (
            <PieChart>
              <Pie
                data={purchasePieData}
                cx={170}
                cy={100}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {purchasePieData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          )}
        </ResponsiveContainer>
        <span
          className="piecharts"
          style={{
            float: "left",
            width: "100%"
          }}
        >
          {purchasePieData &&
          0 < purchasePieData.length && (
            <span
              style={{
                float: "left",
                width: "90%",
                marginLeft: "10%",
                marginBottom: "15px"
              }}
            >
              <span
                className="workorder_color"
                style={{
                  backgroundColor: "#213a52",
                  float: "left",
                  width: "15px",
                  height: "15px",
                  marginRight: "7px"
                }}
              />{" "}
              <span
                style={{
                  float: "left"
                }}
              >
                {intl.formatMessage({id: "equipment.credit"})} :{" "}
                {getNumberFormat(purchasePieData[0].value)}
              </span>
            </span>
          )}
          {purchasePieData &&
          0 < purchasePieData.length && (
            <span
              style={{
                float: "left",
                width: "90%",
                marginLeft: "10%",
                marginBottom: "15px"
              }}
            >
              <span
                className="workorder_color"
                style={{
                  backgroundColor: "#29939b",
                  float: "left",
                  width: "15px",
                  height: "15px",
                  marginRight: "7px"
                }}
              />{" "}
              <span
                style={{
                  float: "left"
                }}
              >
                {intl.formatMessage({id: "equipment.debit"})} :{" "}
                {getNumberFormat(purchasePieData[1].value)}
              </span>
            </span>
          )}
        </span>
      </Box>
    </Grid>
  );
};

export default Creditdebit;

const useStyles = makeStyles(theme => ({
  workorder_color: {
    float: "left",
    width: "15px",
    height: "15px",
    marginRight: "7px"
  }
}));
