import api from "../../services/api";

/* 
  To send a general notification to all users
  notificationHelper.send("Message to send" (required), "Subtitle of message" or "" (optional));

  To send a general notification to all users of a company
  (If the company its not provided the notification its sended to all users);
  notificationHelper.send("Message to send" (required), "Subtitle of message" or "" (optional), companyId (required));
  
  To send a general notification to specific roles
  notificationHelper.send("Message to send" (required), "Subtitle of message" or "" (optional), companyId (required), [roleId1, roleId2, ...roleIdN] o roleId);

  To send a general notification to specific user(s)
  notificationHelper.send("Message to send", "Subtitle of message" or "", companyId (required), [] or null, [userID1, ...userIDN] or userID);
*/

let notificationHelper = {
  async send(
    title,
    subTitle = "",
    companyId = null,
    rolesId = null,
    userId = null
  ) {
    try {
      let body = {
        title,
        subTitle,
        typeNotification: "general_notification",
        companyId,
        rolesId,
        userId
      };
      Object.keys(body).forEach(key => {
        if (!!!body[key]) {
          delete body[key];
        }
      });

      let response = await api.postApi("notifications", body);
      if (response.status == "success") {
        return {success: true, ...response};
      } else {
        return {success: false, ...response};
      }
    } catch (err) {
      console.log(err);
      return {success: false, data: null};
    }
  },
  async sendWithType(
    title,
    subTitle = "",
    typeNotification = "general_notification",
    companyId = null,
    rolesId = null,
    userId = null
  ) {
    try {
      let body = {
        title,
        subTitle,
        typeNotification,
        companyId,
        rolesId,
        userId
      };
      Object.keys(body).forEach(key => {
        if (!!!body[key]) {
          delete body[key];
        }
      });

      let response = await api.postApi("notifications", body);
      if (response.status == "success") {
        return {success: true, ...response};
      } else {
        return {success: false, ...response};
      }
    } catch (err) {
      console.log(err);
      return {success: false, data: null};
    }
  }
};

export default notificationHelper;
