import React from "react";
import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import Box from "@material-ui/core/Box";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const data = [
  {
    name: "Openclosedwo",
    [<IntlMessages id="contractors.in_progress" />]: 1000,
    [<IntlMessages id="contractors.completed" />]: 3000,
    [<IntlMessages id="contractors.pending" />]: 2000
  }
];

const Openclosedwo = () => {
  return (
    <Grid item xs={12}>
      <Box>
        <ResponsiveContainer height={300} width="100%">
          <BarChart
            data={data}
            barGap={30}
            barCategoryGap={15}
            margin={{
              top: 5,
              right: 0,
              left: 0,
              bottom: 5
            }}
          >
            {/* <XAxis dataKey="name" /> */}
            <YAxis />
            <Tooltip />
            <Legend iconType="circle" wrapperStyle={{bottom: 0}} />
            <Bar
              dataKey={<IntlMessages id="contractors.in_progress" />}
              fill="#213a52"
              radius={15}
            />
            <Bar
              dataKey={<IntlMessages id="contractors.completed" />}
              fill="#29939b"
              radius={15}
            />
            <Bar
              dataKey={<IntlMessages id="contractors.pending" />}
              fill="#dfe5ed"
              radius={15}
            />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Grid>
  );
};

export default Openclosedwo;
