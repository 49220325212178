import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError,fetchSuccess } from '../../../redux/actions';
import PageLoader from '../PageComponents/PageLoader';
import { getMessage } from '../../../@jumbo/utils/commonHelper';
import { useIntl } from 'react-intl';

import { toast } from 'react-toastify';

const ContentLoader = () => {
  const { error, loading, message } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const intl = useIntl();

  if(error){
    let errorMsg = getMessage(error);
    if(typeof errorMsg =='object'){
      if(errorMsg.props&&errorMsg.props.id){
        errorMsg =intl.formatMessage({ id: errorMsg.props.id })
      }
    }
    toast.error(errorMsg);
    dispatch(fetchError(''));
  }
  if(message){
    let successMsg = getMessage(message);
    if(typeof successMsg =='object'){
      if(successMsg.props&&successMsg.props.id){
        successMsg =intl.formatMessage({ id: successMsg.props.id })
      }
    }
    toast.success(successMsg)
    dispatch(fetchSuccess(''));
  }
  return (
    <React.Fragment>
      {loading && <PageLoader />}
    </React.Fragment>
  );
};

export default ContentLoader;
