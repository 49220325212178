import makeStyles from "@material-ui/core/styles/makeStyles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

let paddingClasses = {};
for (let count = 1; count <= 8; count++) {
  paddingClasses[".pt-" + count] = {paddingTop: count * 4 + "px !important"};
  paddingClasses[".pb-" + count] = {
    paddingBottom: count * 4 + "px !important"
  };
}

const globalStyles = makeStyles(theme => ({
  breakpoints,
  "@global": {
    "*": {
      boxSizing: "border-box",
      margin: 0,
      padding: 0
    },
    ".MuiMenu-paper": {
      "& .MuiListItem-button": {
        fontSize: "14px!important"
      }
    },
    ".MuiTooltip-tooltip": {
      padding: "5px 20px!important",
      textIndent: "10px!important"
    },
    ".MuiGrid-container": {
      // backgroundColor:`${theme.palette.sidebar.tablebgColor}!important`,
      flex: "1!important",
      padding: "10px 10px 10px 10px!important",
      width: "100%!important",
      margin: "0px!important",
      // zIndex: '2!important',
      "& .MuiGrid-container": {
        backgroundColor: `${theme.palette.sidebar.bgColor}`
      },
      "& .form-control": {
        fontSize: "14px!important",
        fontWeight: 600
      },
      "@media screen and (max-width: 500px)": {
        padding: "5px 5px 5px 5px!important"
      }
    },
    ".widgetBox": {
      padding: 10,
      float: "left",
      height: 370,
      marginBottom: 13
    },
    ".MuiInput-input": {
      fontWeight: 600
    },
    ".widgetBox_text": {
      backgroundColor: theme.palette.sidebar.bgColor,
      // height:370,
      padding: 10
    },
    ".widgetBoxHead": {
      padding: 0,
      // paddingBottom: 15,
      fontSize: "12px!important",
      fontWeight: "bold!important",
      width: "100%",
      display: "flow-root",
      "& .MuiIconButton-root": {
        padding: 5,
        float: "right"
      }
    },
    ".widgetBoxHead_text": {
      float: "left",
      padding: 8
    },
    ".refreshIcon": {
      color: theme.palette.sidebar.textActiveColor
    },
    ".minusIcon": {
      color: theme.palette.sidebar.textActiveColor
    },

    ".filterContainerheader": {
      width: "calc(100% - 250px)!important",
      position: "fixed",
      margin: "0!important",
      backgroundColor: "#ffffff!important",
      display: "flow-root!important",
      padding: "10px 17px!important",
      zIndex: "1000!important",
      "& .MuiGrid-container": {
        padding: "0px!important"
      },
      "@media screen and (max-width: 1000px)": {
        width: "100%!important",
        padding: "10px 0px 10px 3px!important"
      }
    },
    ".filterContainer": {
      width: "100%!important",
      margin: "0!important",
      backgroundColor: "#ffffff!important",
      display: "flow-root!important",
      padding: "10px 17px!important",
      zIndex: "4!important",
      "& .MuiGrid-container": {
        padding: "0px!important",
        "& .css-26l3qy-menu": {
          zIndex: "9991!important"
        }
      }
    },
    ".filterContainerRoles": {
      width: "100%!important",
      margin: "80px 0px 0px 0px!important",
      backgroundColor: "#ffffff!important",
      display: "flow-root!important",
      padding: "10px 17px!important",
      zIndex: "4!important",
      "& .MuiGrid-container": {
        padding: "0px!important"
      }
    },
    ".filtercollapse": {
      float: "left",
      padding: "0px 10px 0px 0px!important"
    },
    ".css-yk16xz-control": {
      padding: 4.5,
      borderRadius: "30px!important",
      marginTop: "-4px!important",
      border: `1px solid ${theme.palette.sidebar.formBorderColor}`,
      color: "#000",
      fontWeight: 600
    },
    ".css-1fhf3k1-control": {
      padding: 4.5,
      borderRadius: "30px!important",
      marginTop: "-4px!important",
      border: `1px solid ${theme.palette.sidebar.formBorderColor}`
    },
    ".css-1pahdxg-control": {
      padding: 4.5,
      borderRadius: 30,
      marginTop: "-4px!important",
      border: `1px solid ${theme.palette.sidebar.formBorderColor}`,
      boxShadow: "none",
      "&:hover": {
        padding: 4.5,
        borderRadius: 30,
        marginTop: "-4px!important",
        border: `1px solid ${theme.palette.sidebar.formBorderColor}`,
        boxShadow: "none"
      }
    },
    ".css-1rhbuit-multiValue": {
      backgroundColor: "#dbecfa",
      margin: 0,
      padding: 5,
      borderRadius: 30,
      marginRight: 3
    },
    ".css-g1d714-ValueContainer": {
      padding: "0px!important",
      fontWeight: 600
    },
    ".MuiCollapse-container": {
      width: "100%",
      // float: 'left',
      display: "flow-root"
    },
    ".mobile_header": {
      float: "left",
      fontSize: 18,
      lineHeight: 2,
      "@media screen and (min-width: 500px)": {
        display: "none"
      }
    },
    ".mobile_filter_text": {
      "@media screen and (max-width: 500px)": {
        display: "none"
      }
    },

    ".filterButton": {
      backgroundColor: `${theme.palette.sidebar.textActiveColor}!important`,
      borderRadius: "30px!important",
      color: "#fff!important",
      padding: "10px 20px!important",
      textTransform: "capitalize!important",
      float: "right!important",
      "&:hover": {
        backgroundColor: theme.palette.sidebar.textActiveColor
      },
      "@media screen and (max-width: 500px)": {
        minWidth: 36,
        padding: 6,
        height: 36
      }
    },

    ".filterButtons": {
      backgroundColor: `${theme.palette.sidebar.textActiveColor}!important`,
      borderRadius: "30px!important",
      color: "#fff!important",
      padding: "10px 20px!important",
      textTransform: "capitalize!important",
      float: "left!important",
      marginTop: "27px!important",
      "&:hover": {
        backgroundColor: theme.palette.sidebar.textActiveColor
      },
      "@media screen and (max-width: 500px)": {
        minWidth: 36,
        padding: 6,
        height: 36
      }
    },
    ".filterHeader": {
      float: "left",
      padding: "0px 8px!important",
      width: "100%",
      backgroundColor: theme.palette.sidebar.bgColor
    },
    ".daterangePickers": {
      backgroundColor: theme.palette.sidebar.bgColor,
      float: "left",
      padding: 12
    },
    ".datePicker": {
      backgroundColor: theme.palette.sidebar.bgColor,
      padding: 10,
      float: "left",
      zIndex: "999"
    },
    ".filterBoxHead": {
      color: theme.palette.text.black,
      marginBottom: 9,
      fontWeight: 600
    },
    ".filterBoxHeads": {
      color: "#a2a2a2",
      marginBottom: 9,
      fontWeight: 600
    },
    ".dateFilter": {
      width: "100%!important",
      backgroundColor: `${theme.palette.sidebar.bgColor}!important`,
      float: "left!important",
      zIndex: "9!important",
      padding: "0!important"
    },
    ".fromDate": {
      float: "left",
      padding: 10
    },
    ".addButton": {
      float: "right"
    },
    ".addButtonPhoto": {
      float: "right",
      "& .CmtButtons-wrapper": {
        width: "108px",
        float: "left"
      }
    },
    ".sendService": {
      float: "left",
      marginTop: "23px"
    },

    ".totaldiv": {
      width: "100%",
      float: "left"
    },
    ".totaldivright": {
      float: "right",
      width: "50%",
      backgroundColor: "#fff",
      padding: "5px",
      marginTop: "20px",
      "@media screen and (max-width: 500px)": {
        width: "100%"
      }
    },
    ".totalinnerleft": {
      float: "left",
      width: "50%",
      // paddingTop:10,
      lineHeight: "46px",
      "& .MuiGrid-root": {
        float: "left",
        width: "100%"
      }
    },
    ".totalinnerleft_tax": {
      float: "left",
      width: "50%",
      paddingTop: "10px",
      fontSize: "13px",
      "& .MuiGrid-root": {
        float: "left",
        width: "100%"
      }
    },

    ".totalinnerleft_2": {
      float: "left",
      width: "100%",
      zIndex: "999!important",
      "& .css-1w9j89e-menu": {
        zIndex: "999!important"
        // height: '200px!important'
      }
    },
    ".totalinnerright": {
      float: "right",
      width: "50%"
    },
    ".topborder": {
      backgroundColor: "#e7f6fd",
      margin: "2px 0px!important",
      padding: "8px!important",
      height: "56px!important"
    },
    ".topbordertax": {
      backgroundColor: "#e7f6fd",
      margin: "2px 0px!important",
      padding: "8px 8px 8px 0px!important",
      height: "56px!important"
    },
    ".totalinnerleftcorner": {
      float: "right!important",
      textAlign: "right",
      width: "auto!important",
      backgroundColor: "#fff",
      padding: "10px 5px",
      fontSize: "14px"
    },
    ".labelsspan": {
      float: "right",
      color: "#3ca0ed",
      fontSize: "15px"
    },
    ".dragdrop_block": {
      float: "left",
      margin: 15,
      padding: 5,
      width: 150,
      border: "solid 1px #d8e1e4!important",
      height: 107,
      textAlign: "center",
      borderRadius: 5,
      backgroundColor: "#d8e1e4",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      position: "relative",
      "& p": {
        fontSize: "12px!important",
        width: "100%",
        display: "block",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      },
      "& img": {
        maxHeight: 74
      }
    },
    ".closebutton": {
      position: "absolute",
      top: 0,
      right: 0,
      cursor: "pointer",
      color: "#da0000"
    },
    "span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
      paddingTop: 5
    },
    ".cardButtons": {
      borderRadius: 30,
      float: "right",
      "@media screen and (max-width: 500px)": {
        "& .CmtButtons-wrapper": {
          width: 65
        }
      },
      "& .MuiBox-root": {
        float: "right"
      },
      "& .MuiButton-text": {
        paddingTop: "8px!important"
      }
    },
    ".CmtButtons-wrapper": {
      flex: "none"
    },
    ".add_Btn_Icon": {
      fontSize: 24
    },
    ".media_active_icon": {
      fontSize: 34,
      fill: "#203953"
    },
    ".media_deactive_icon": {
      fontSize: 34,
      fill: "#acb2bc"
    },
    ".cardBackButtons": {
      float: "left",
      color: theme.palette.sidebar.textActiveColor,
      fontSize: 14,
      "& .MuiButton-root": {
        width: "30px!important",
        minWidth: 30,
        height: 30,
        paddingLeft: 11,
        float: "left",
        marginTop: 11
      }
    },
    ".MUIDataTable-responsiveStacked-202": {
      width: "100%"
    },
    ".MuiPickersDay-daySelected": {
      color: "#fff"
    },
    ".date_selector": {
      // zIndex:'9999!important',
      "& .react-datepicker-wrapper": {
        width: "100%"
        // zIndex:'9899!important'
      },
      "& input": {
        width: "100%",
        border: "solid 1px #d8e1e4",
        padding: "12.5px 16px",
        borderRadius: 30,
        marginTop: "-4px!important",
        outline: "none",
        fontSize: 14,
        fontWeight: 600
      },
      "& .react-datepicker-ignore-onclickoutside": {
        // zIndex:'999!important'
      }
    },
    ".date_selector_filter": {
      // zIndex:'9999!important',
      "& .react-datepicker-wrapper": {
        width: "98%",
        margin: "0px 1%"
        // zIndex:'9899!important'
      },
      "& .MuiGrid-item": {
        width: "49%",
        float: "left"
      },
      "& input": {
        width: "100%",
        border: "solid 1px #d8e1e4",
        padding: "12.5px 10px",
        borderRadius: 30,
        marginTop: "-4px!important"
      },
      "& .react-datepicker-ignore-onclickoutside": {
        // zIndex:'999!important'
      }
    },

    ".date_selector1": {
      // zIndex:'9998!important',
      "& .react-datepicker-wrapper": {
        width: "100%"
        // zIndex:'9899!important'
      },
      "& input": {
        width: "100%",
        border: "solid 1px #d8e1e4",
        padding: "12.5px 10px",
        borderRadius: 30,
        marginTop: "-4px"
      },
      "& .react-datepicker-ignore-onclickoutside": {
        // zIndex:'999!important'
      }
    },
    ".date_selector2": {
      // zIndex:'9997!important',
      "& .react-datepicker-wrapper": {
        width: "100%"
        // zIndex:'9899!important'
      },
      "& input": {
        width: "100%",
        border: "solid 1px #d8e1e4",
        padding: "12.5px 10px",
        borderRadius: 30,
        marginTop: "-4px"
      },
      "& .react-datepicker-ignore-onclickoutside": {
        // zIndex:'999!important'
      }
    },
    ".date_selector3": {
      // zIndex:'9996!important',
      "& .react-datepicker-wrapper": {
        width: "100%"
        // zIndex:'9899!important'
      },
      "& input": {
        width: "100%",
        border: "solid 1px #d8e1e4",
        padding: "12.5px 10px",
        borderRadius: 30,
        marginTop: "-4px"
      },
      "& .react-datepicker-ignore-onclickoutside": {
        // zIndex:'999!important'
      }
    },
    // '.zindexback':{
    //   zIndex:'99!important'
    // },
    ".selectontable": {
      zIndex: "99999!important"
    },
    ".closeforms": {
      padding: "30px 30px 30px 30px!important",
      fontSize: "14px!important",
      backgroundColor: "#fff",
      margin: "30px 30px 0 30px",
      "& .MuiGrid-container": {
        padding: "0px!important"
      },
      "& .MuiGrid-item": {
        padding: "2px!important"
      }
    },
    ".forms": {
      padding: "90px 10px 10px 10px!important",
      "@media screen and (max-width: 500px)": {
        padding: "75px 0px 0px 0px!important"
      },
      "& .firstrow": {
        // zIndex:'999!important'
      },
      "& .tworow": {
        // zIndex:'998!important'
      },
      "& .threerow": {
        // zIndex:'997!important'
      },
      "& .fourrow": {
        // zIndex:'996!important'
      },
      "& .fiverow": {
        // zIndex:'995!important'
      },
      "& .sixrow": {
        zIndex: "994!important"
      },
      "& .sevenrow": {
        zIndex: "993!important"
      },
      "& .eightrow": {
        zIndex: "992!important"
      },
      "& .ninerow": {
        zIndex: "991!important"
      },
      "& .MuiGrid-container": {
        padding: "20px!important",
        backgroundColor: theme.palette.sidebar.bgColor,
        "& .MuiGrid-container": {
          padding: "5px!important"
          // zIndex:'888!important'
        },
        "& .MuiGrid-item": {
          "& .css-2b097c-container": {
            // zIndex: '9999!important',
            "& .css-26l3qy-menu": {
              zIndex: "9991!important"
            }
          },
          "& .css-1n7v3ny-option": {
            // zIndex: '889!important',
          },
          "& .form-control": {
            // zIndex: '889!important',
            "& .css-26l3qy-menu": {
              // zIndex:'88889!important'
            }
          }
        }
      }
    },

    ".formswo": {
      padding: "10px 10px 10px 10px!important",
      "@media screen and (max-width: 500px)": {
        padding: "75px 0px 0px 0px!important"
      },
      "& .firstrow": {
        // zIndex:'999!important'
      },
      "& .tworow": {
        // zIndex:'998!important'
      },
      "& .threerow": {
        // zIndex:'997!important'
      },
      "& .fourrow": {
        // zIndex:'996!important'
      },
      "& .fiverow": {
        // zIndex:'995!important'
      },
      "& .sixrow": {
        zIndex: "994!important"
      },
      "& .sevenrow": {
        zIndex: "993!important"
      },
      "& .eightrow": {
        zIndex: "992!important"
      },
      "& .ninerow": {
        zIndex: "991!important"
      },
      "& .MuiGrid-container": {
        padding: "20px!important",
        backgroundColor: theme.palette.sidebar.bgColor,
        "& .MuiGrid-container": {
          padding: "5px!important"
          // zIndex:'888!important'
        },
        "& .MuiGrid-item": {
          "& .css-2b097c-container": {
            // zIndex: '9999!important',
            "& .css-26l3qy-menu": {
              zIndex: "9991!important"
            }
          },
          "& .css-1n7v3ny-option": {
            // zIndex: '889!important',
          },
          "& .form-control": {
            // zIndex: '889!important',
            "& .css-26l3qy-menu": {
              // zIndex:'88889!important'
            }
          }
        }
      }
    },
    ".forms_div": {
      padding: "10px 10px 10px 10px!important",
      "@media screen and (max-width: 500px)": {
        padding: "75px 0px 0px 0px!important"
      },
      "& .firstrow": {
        // zIndex:'999!important'
      },
      "& .tworow": {
        // zIndex:'998!important'
      },
      "& .threerow": {
        // zIndex:'997!important'
      },
      "& .fourrow": {
        // zIndex:'996!important'
      },
      "& .fiverow": {
        // zIndex:'995!important'
      },
      "& .sixrow": {
        zIndex: "994!important"
      },
      "& .sevenrow": {
        zIndex: "993!important"
      },
      "& .eightrow": {
        zIndex: "992!important"
      },
      "& .ninerow": {
        zIndex: "991!important"
      },
      "& .MuiGrid-container": {
        padding: "20px!important",
        backgroundColor: theme.palette.sidebar.bgColor,
        "& .MuiGrid-container": {
          padding: "5px!important"
          // zIndex:'888!important'
        },
        "& .MuiGrid-item": {
          "& .css-2b097c-container": {
            // zIndex: '9999!important',
            "& .css-26l3qy-menu": {
              zIndex: "9991!important"
            }
          },
          "& .css-1n7v3ny-option": {
            // zIndex: '889!important',
          },
          "& .form-control": {
            // zIndex: '889!important',
            "& .css-26l3qy-menu": {
              // zIndex:'88889!important'
            }
          }
        }
      }
    },
    ".forms_wo": {
      padding: "10px!important",
      "& .MuiGrid-container": {
        padding: "20px!important",
        backgroundColor: theme.palette.sidebar.bgColor,
        "& .MuiGrid-container": {
          padding: "5px!important"
          // zIndex:'888!important'
        }
      },
      "@media screen and (max-width: 500px)": {
        padding: 5
      }
    },
    ".forms_wo2": {
      padding: "40px!important"
    },
    ".workorder_label": {
      fontSize: "20px",
      color: "#000"
    },
    ".labels": {
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.text.black,
      marginBottom: 9
    },
    ".innerlabels": {
      marginTop: 5,
      // marginLeft: 5,
      "@media screen and (max-width: 500px)": {
        fontSize: 11
      }
    },
    ".innerlabels1": {
      marginTop: 5
    },
    ".cardCsvButtons": {
      float: "right",
      "& .CmtButtons-wrapper": {
        marginRight: 0,
        "@media screen and (max-width: 500px)": {
          flex: "none",
          width: "50px",
          float: "left"
        }
      },
      "@media screen and (max-width: 500px)": {
        float: "right",
        display: "inline-block!important",
        width: "100px",
        height: "10px"
        // display: "inline-block!important"
      }
    },
    ".filetype": {
      position: "relative",
      "& .MuiInputBase-input": {
        backgroundColor: "transparent",
        opacity: 0,
        zIndex: "99"
      }
    },
    ".filetype_span": {
      position: "absolute",
      left: 15,
      bottom: 15,
      backgroundColor: "#dbecfa",
      padding: 8,
      borderRadius: 30
    },
    ".color_bg": {
      backgroundColor: "#e7f6fd!important",
      flex: "unset!important",
      padding: "10px!important",
      marginTop: "20px!important",
      // zIndex:'0!important',
      marginBottom: "30px!important",
      borderRadius: 6,
      position: "relative"
    },
    ".non_editable": {
      height: "97%",
      width: "99%",
      backgroundColor: "rgba(0,0,0,0.6)",
      zIndex: "9",
      textAlign: "center",
      color: "white",
      fontSize: "20px",
      padding: "140px 0px 0px 0px",
      position: "absolute"
    },
    ".displayGreen": {
      backgroundColor: "#e3fde7!important",
      width: "120px",
      display: "block",
      margin: "0px auto",
      padding: "8px 5px",
      color: "#000!important",
      textAlign: "center",
      borderRadius: 8
    },

    ".displayRed": {
      backgroundColor: "#ffd4d4!important",
      width: "120px",
      display: "block",
      margin: "0px auto",
      padding: "8px 5px",
      color: "#000!important",
      textAlign: "center",
      borderRadius: 8
    },
    ".displayWarning": {
      backgroundColor: "#fff3ce!important",
      width: "120px",
      display: "block",
      margin: "0px auto",
      padding: "8px 5px",
      color: "#000!important",
      textAlign: "center",
      borderRadius: 8
    },
    ".displayBlack": {
      backgroundColor: "#7b7b7b!important",
      width: "120px",
      display: "block",
      margin: "0px auto",
      padding: "8px 5px",
      color: "#000!important",
      textAlign: "center",
      borderRadius: 8
    },

    ".displayOrange": {
      backgroundColor: "#ffe292!important",
      width: "120px",
      display: "block",
      margin: "0px auto",
      padding: "8px 5px",
      color: "#000!important",
      textAlign: "center",
      borderRadius: 8
    },
    ".react-datepicker-popper": {
      zIndex: "999"
    },
    a: {
      textDecoration: "none",
      "&:hover,&:focus": {
        textDecoration: "none"
      },
      "&.disable-link": {
        pointerEvents: "none",
        cursor: "default",
        [theme.breakpoints.down("xs")]: {
          pointerEvents: "inherit",
          cursor: "pointer"
        }
      }
    },
    img: {
      maxWidth: "100%"
    },
    ".pointer": {
      cursor: "pointer"
    },
    ".rounded": {
      borderRadius: "50%"
    },
    ".Cmt-table-responsive": {
      minHeight: ".01%",
      overflowX: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: 15,
        overflowY: "hidden",
        border: `1px solid ${theme.palette.borderColor.main}`,
        "& > table": {
          marginBottom: 0,
          "& > thead > tr > th": {
            paddingTop: 8
          },
          "& > thead > tr > th, > tbody > tr > th, > tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td": {
            whiteSpace: "nowrap"
          }
        }
      }
    },
    ".dropzone": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(10, 5),
      border: `2px dashed ${theme.palette.borderColor.main}`,
      borderRadius: 2,
      backgroundColor: theme.palette.background.default,
      outline: "none",
      transition: "border .24s ease-in-out"
    },
    ".swal2-shown .swal2-container": {
      zIndex: 1104
    },
    ".swal2-container .swal2-popup, .swal2-container .swal2-popup.swal2-toast": {
      backgroundColor: theme.palette.popupColor.main,
      color: theme.palette.text.primary
    },
    ".swal2-container .swal2-content, .swal2-container .swal2-title": {
      color: theme.palette.text.primary
    },
    ".swal2-container .swal2-footer a": {
      color: theme.palette.primary.main
    },
    ".swal2-container .swal2-close:focus": {
      outline: "none"
    },
    ".errortoast": {
      backgroundColor: "#ffe9e9!important",
      borderRadius: 10,
      padding: 10,
      marginBottom: 10,
      textAlign: "center!important",
      position: "relative!important",
      "& .Toastify__close-button": {
        position: "absolute!important",
        right: 10,
        top: 10,
        color: "#ff7f6f!important"
      },
      "& .Toastify__toast-body": {
        padding: "14px 14px 14px 0!important",
        color: "#ff7f6f!important",
        fontWeight: "bold!important"
      },
      "& .Toastify__progress-bar": {
        backgroundColor: "#ff7f6f!important"
      }
    },
    ".successtoast": {
      backgroundColor: "#e3fce7",
      borderRadius: 10,
      padding: 10,
      marginBottom: 10,
      textAlign: "center",
      position: "relative",
      "& .Toastify__close-button": {
        position: "absolute",
        right: 10,
        top: 10,
        color: "#6b966e"
      },
      "& .Toastify__toast-body": {
        padding: "14px 14px 14px 0",
        color: "#6b966e",
        fontWeight: "bold"
      },
      "& .Toastify__progress-bar": {
        backgroundColor: "#6b966e"
      }
    },
    ".fix-popper": {
      top: "30px!important"
    },
    ...paddingClasses
  }
}));

export default globalStyles;
