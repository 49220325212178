import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import GridContainer from "../../GridContainer";
import CmtImage from "../../../../@coremat/CmtImage";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "../../../utils/IntlMessages";
import Button from "@material-ui/core/Button";
import searchHelper from "../../../utils/searchHelper";
import {
  getCompany,
  getSuperAdmin,
  getStatusType,
  getServiceType,
  getCity,
  getUnit,
  getCountry,
  getDocumentList,
  getConditionType,
  getDocumentApproveList,
  getEquipmentType,
  assignCompany,
  getConductorRoleId,
  findConductorRoleId
} from "../../../utils/commonHelper";

import {getFormattedDate, getFormattedMonth} from "../../../utils/dateHelper";
import AppTextInput from "../../../../@jumbo/components/Common/formElements/AppTextInput";
import AppCheckBox from "../../../../@jumbo/components/Common/formElements/AppCheckBox";

import AppMultiSelectBox from "../formElements/AppMultiSelectBox";
import Collapse from "@material-ui/core/Collapse";
import {useDispatch, useSelector} from "react-redux";
import {
  getAllData,
  setTable,
  setFilter
} from "../../../../redux/actions/Common";
import StatusTabCard from "../StatusTabCard";
import PerformanceTabCard from "../PerformanceTabCard";
import {useIntl} from "react-intl";

const useStyles = makeStyles(theme => ({
  containerRoot: {
    display: "flex",
    alignItems: "center"
  },
  containerRootCenter: {
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 16
  },
  contentRoot: {
    position: "relative"
  },
  contentRootCenter: {
    marginLeft: 0,
    marginTop: 13,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center"
  },
  titleRoot: {
    marginBottom: 2
  },
  datePicker: {
    backgroundColor: theme.palette.sidebar.bgColor,
    // padding: "0!important",
    float: "left"
    // zIndex: '999'
  },
  datePicker1: {
    backgroundColor: theme.palette.sidebar.bgColor,
    // padding: "0!important",
    float: "left",
    marginTop: "22px!important"
  },
  datePickerinner: {
    padding: "12px!important"
  },
  datePickerinner: {
    "& .MuiTabs-fixed": {
      "& .MuiButtonBase-root": {
        paddingLeft: "0px!important"
      }
    }
  },
  datePickerinner1: {
    padding: "12px!important",
    marginTop: "20!important"
  },
  datePickerinner1: {
    "& .MuiTabs-fixed": {
      "& .MuiButtonBase-root": {
        paddingLeft: "0px!important"
      }
    }
  },
  datecal: {
    "& .react-datepicker-popper": {
      zIndex: "9999!important"
    }
  }
}));

const filter = "/images/othericon/filter_icon.png";
const all = {value: -1, label: <IntlMessages id="table.all" />};
const FilterCard = ({
  icon,
  number,
  numberProps,
  label,
  labelProps,
  counterProps,
  color,
  gradientDirection,
  alignCenter,
  moduleName,
  Iscompany,
  Isallgrouplabel,
  Isgroup,
  Ismultidatepicker,
  Isdatepicker,
  Isregion,
  Iswarehouse,
  Isdocument,
  Isstatus,
  IsstatusTab,
  IsperformanceTab,
  Iscity,
  Iscostcenter,
  Isprovider,
  Isstockonly,
  Ismeasure,
  Isregiontype,
  Iscountry,
  Isrole,
  Isrolestatus,
  Isclient,
  IstoolsAvailabilty,
  Ismaintenancetype,
  Ismaintenancegroup,
  Ismaintenanceform,
  Isresourcegroup,
  Isspare,
  Isservice,
  Iswarehousedocument,
  Ispaymentcondition,
  Isequipmenttype,
  Isequipmentvehicle,
  Isequipment,
  Isresetbutton,
  Ismake,
  Ismodel,
  Isbrand,
  Isyear,
  Isdriver,
  Isapprovedocument,
  Isequipmentpurchase,
  IsSpareservice,
  Isservicecontrol,
  Ismonthyear,
  Isfilter,
  companyId,
  oneDriver = false,
  ...rest
}) => {
  let newCompany = getCompany();
  if (newCompany == null) {
    if (companyId) {
      newCompany = companyId;
    } else {
      newCompany = all;
    }
  }
  let date1 = new Date();
  if (Ismultidatepicker) {
    date1.setDate(date1.getDate() - 30);
  }
  const [ expanded, setExpanded ] = React.useState(false);
  const [ company, setCompany ] = useState(newCompany);
  const [ region, setRegion ] = useState(all);
  const [ warehouse, setWarehouse ] = useState(all);
  const [ costCenter, setCostCenter ] = useState(all);
  const [ status, setStatus ] = useState(all);
  const [ city, setCity ] = useState(all);
  const [ country, setCountry ] = useState(all);
  const [ provider, setProvider ] = useState(all);
  const [ measure, setMeasure ] = useState(all);
  const [ regionType, setRegionType ] = useState(all);
  const [ role, setRole ] = useState(all);
  const [ client, setClient ] = useState(all);
  const [ maintenanceType, setMaintenanceType ] = useState(all);
  const [ maintenanceGroup, setMaintenanceGroup ] = useState(all);
  const [ maintenanceForm, setMaintenanceForm ] = useState(all);
  const [ resourceGroup, setResourceGroup ] = useState(all);
  const [ drivers, setDrivers ] = useState(null);

  const [ code, setCode ] = useState(all);
  const [ service, setService ] = useState(all);
  const [ spareService, setSpareService ] = useState(
    IsSpareservice ? IsSpareservice : null
  );
  const [ roleStatus, setRoleStatus ] = useState(all);
  const [ date, setDate ] = useState(
    moduleName == "inventory" ? new Date() : null
  );

  const [ make, setMake ] = useState(all);
  const [ model, setModel ] = useState(all);
  const [ equipmentType, setEquipmentType ] = useState(all);
  const [ equipment, setEquipment ] = useState(all);
  const [ toolsAvailability, setToolsAvailability ] = useState(all);
  const [ group, setGroup ] = useState(all);

  const [ startDate, setStartDate ] = useState(
    Ismultidatepicker || moduleName == "inventory" ? date1 : null
  );
  const [ endDate, setEndDate ] = useState(
    Ismultidatepicker || moduleName == "inventory" ? new Date() : null
  );

  const [ changeDate, setChangeDate ] = useState(null);

  const [ stockonly, setStockonly ] = useState(false);

  const [ warehouseDocument, setWarehouseDocument ] = useState(all);
  const [ approveDocument, setApproveDocument ] = useState(all);

  const [ paymentCondition, setPaymentCondition ] = useState(all);
  const [ tabStatus, setTabStatus ] = useState(null);
  const [ tabPerformance, setTabPerformance ] = useState(null);
  const [ serviceControl, setServiceControl ] = useState(all);
  const [ driverId, setDriverId ] = useState(oneDriver ? null : all);
  const [ monthYear, setMonthYear ] = useState(null);
  const [ equipmentVehicle, setEquipmentVehicle ] = useState(all);

  const [ searchBy, setSearchBy ] = useState("");
  const [ searchBy1, setSearchBy1 ] = useState("");

  const classes = useStyles();
  const dispatch = useDispatch();

  const intl = useIntl();
  const SUValue = intl.formatMessage({id: "servicecontrol.s/u"});
  const {table} = useSelector(({common}) => common);

  const resetFilter = () => {
    setCompany(getCompany() == null ? all : getCompany());
    setRegion(null);
    setWarehouse(null);
    setCostCenter(null);
    setStatus(null);
    setCity(null);
    setCountry(null);
    setProvider(null);
    setMeasure(null);
    setRegionType(null);
    setRole(null);
    setCode(all);
    setService(all);
    setRoleStatus(all);
    setDate(null);
    setMake(all);
    setModel(all);
    setEquipmentType(all);
    setEquipment(all);
    setStartDate(null);
    setEndDate(null);
    setChangeDate(null);
    setStockonly(false);
    setWarehouseDocument(all);
    setApproveDocument(all);
    setDriverId(oneDriver ? null : all);
    setPaymentCondition(all);
    setTabStatus(null);
    setTabPerformance(null);
    setMaintenanceType(null);
    setServiceControl(all);
    setMonthYear(null);
    setToolsAvailability(all);
    setGroup(all);
    setEquipmentVehicle(all);
    setMaintenanceGroup(all);
    setMaintenanceForm(all);
    setResourceGroup(all);
  };

  const allFillter = () => {
    let endNewDate = new Date(endDate);
    // if (moduleName != "inventory" && endNewDate) {
    //   endNewDate.setDate(endNewDate.getDate() + 1);
    // }
    console.log("endNewDate======", endNewDate);
    endNewDate.setDate(endNewDate.getDate() + 1);
    let filter = {
      company: company,
      region: region,
      warehouse: warehouse,
      status: status,
      city: city,
      costcenter: costCenter,
      provider: provider,
      measure: measure,
      country: country,
      regionType: regionType,
      role: role,
      client: client,
      maintenanceType: maintenanceType,
      startdate: startDate ? getFormattedDate(startDate) : null,
      enddate: endNewDate ? getFormattedDate(endNewDate) : null,
      code: code,
      service: service,
      serviceControl: serviceControl,
      warehouseDocument: warehouseDocument,
      tabStatus: tabStatus,
      tabPerformance: tabPerformance,
      paymentCondition: paymentCondition,
      roleStatus: roleStatus,
      make: make,
      model: model,
      equipmentType: equipmentType,
      equipment: equipment,
      approveDocument: approveDocument,
      maintenanceType: maintenanceType,
      equipmentVehicle: equipmentVehicle,
      spareService: spareService,
      driverId: driverId,
      monthYear: getFormattedDate(monthYear),
      toolsAvailability: toolsAvailability,
      maintenanceGroup: maintenanceGroup,
      maintenanceForm: maintenanceForm,
      resourceGroup: resourceGroup,
      oneDriver: oneDriver
    };

    let sort = "";

    let moduleFind = moduleName;

    if (moduleFind === "driver") {
      moduleFind = "inhouse";
    }

    if (table) {
      if (table.sort == "" || table.sort == undefined || table.sort == null) {
        if (moduleFind == "sparessupplies") {
          sort = "&sort_code=" + "asc";
        } else if (moduleFind == "purchaseorder") {
          sort = "&sort_invoiceNo=desc";
        } else {
          sort = "&sort_updatedAt=" + "desc";
        }
      } else {
        sort = table.sort;
      }
      if (table.filter && table.filter.workorderStatus) {
        filter.workorderStatus = {
          label: table.filter.workorderStatus,
          value: table.filter.workorderStatus
        };
      }

      if (table.moduleName) {
        moduleFind = table.moduleName;
      }

      if (moduleName === "driver" && getConductorRoleId()) {
        let roleId = getConductorRoleId();
        filter.role = {value: roleId, id: roleId};
      }

      dispatch(
        getAllData(
          moduleFind,
          table.page,
          table.rowsPerPage,
          table.search,
          filter,
          sort
        )
      );
    } else {
      if (moduleFind == "sparessupplies") {
        sort = "&sort_code=" + "asc";
      } else if (moduleFind == "purchaseorder") {
        sort = "&sort_invoiceNo=desc";
      } else {
        sort = "&sort_updatedAt=" + "desc";
      }

      if (moduleName === "driver" && getConductorRoleId()) {
        let roleId = getConductorRoleId();
        filter.role = {value: roleId, id: roleId};
      }

      dispatch(getAllData(moduleFind, 0, 10, "", filter, sort));
    }
  };

  useEffect(
    () => {
      allFillter();
    },
    [ table ]
  );

  useEffect(
    () => {
      dispatch(
        setTable({
          page: 0,
          rowsPerPage: table ? table.rowsPerPage : 10,
          search: table ? table.search : "",
          sort: ""
        })
      );
    },
    [
      company,
      region,
      warehouse,
      status,
      city,
      provider,
      costCenter,
      measure,
      country,
      regionType,
      role,
      client,
      maintenanceType,
      changeDate,
      code,
      service,
      warehouseDocument,
      tabStatus,
      tabPerformance,
      paymentCondition,
      roleStatus,
      make,
      model,
      equipmentType,
      equipment,
      approveDocument,
      spareService,
      serviceControl,
      driverId,
      monthYear,
      toolsAvailability,
      equipmentVehicle,
      maintenanceGroup,
      maintenanceForm,
      resourceGroup
    ]
  );

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const selectDate = value => {
    dispatch(setFilter({value: value, filter: "date"}));
    setDate(value);
    setStartDate(value);
    setEndDate(value);
    setChangeDate(value);
  };

  const selectStartDate = value => {
    setStartDate(value);
    setEndDate(null);
  };

  const selectEndDate = value => {
    setEndDate(value);
    setChangeDate(value);
  };

  const selectCompany = value => {
    setSearchBy(Math.random());
    setCompany(value);
    assignCompany(value);
    setRegion(all);
    setWarehouse(all);
    setStatus(all);
    setCity(all);
    setCostCenter(all);
    setCountry(all);
    setMeasure(all);
    setRegionType(all);
    setRole(all);
    setProvider(all);
    setClient(all);
    setMaintenanceType(all);
    setCode(all);
    setService(all);
    setServiceControl(all);
    setPaymentCondition(all);
    setRoleStatus(all);
    setMake(all);
    setModel(all);
    setEquipment(all);
    setEquipmentType(all);
    setDriverId(oneDriver ? null : all);
    setMonthYear(null);
    setToolsAvailability(all);
    setGroup(all);
    setMaintenanceGroup(all);
    setMaintenanceForm(all);
    setResourceGroup(all);
    setDrivers(null);
  };

  const changeStatusTab = value => {
    if (value == 0) {
      setTabStatus("approve");
    } else if (value == 1) {
      setTabStatus("pending");
    } else if (value == 2) {
      setTabStatus("rejected");
    }
  };

  const changePerformanceTab = value => {
    if (value == 0) {
      setTabPerformance("overview");
    } else if (value == 1) {
      setTabPerformance("Co2 Emission");
    } else if (value == 2) {
      setTabPerformance("Fuel Performance");
    }
  };

  const handleWarehouse = value => {
    dispatch(setFilter({value: value, filter: "warehouseFilter"}));
    setWarehouse(value);
  };

  return (
    <GridContainer classes={{root: "filterContainer"}}>
      <Grid container spacing={5}>
        <Grid
          item
          lg={10}
          md={10}
          sm={10}
          xs={12}
          classes={{root: "filtercollapse"}}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <GridContainer container xs={12} classes={{root: "dateFilter"}}>
              {Isallgrouplabel && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      className={classes.datePickerinner}
                      style={{float: "left", padding: "28px 28px 0px 0px"}}
                    >
                      <Grid classes={{root: "filterBoxHead"}}>
                        <IntlMessages id="reports.all_groups" />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      md={6}
                      sm={6}
                      xs={6}
                      className={classes.datePickerinner}
                      style={{float: "left", padding: "28px 28px 0px 0px"}}
                    >
                      <Grid classes={{root: "filterBoxHeads"}}>May</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {Iscompany &&
              getSuperAdmin() && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.datePickerinner}
                    >
                      <Grid classes={{root: "filterBoxHead"}}>
                        <IntlMessages id="vehicle.select_company" />
                      </Grid>
                      <AppMultiSelectBox
                        menuPlacement="auto"
                        onChange={value => selectCompany(value)}
                        value={company}
                        isMulti={false}
                        loadOptions={(search, prevOptions) =>
                          searchHelper.getCompanySearchDetails(
                            search,
                            prevOptions,
                            null,
                            null,
                            true
                          )}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
              {Isgroup && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.datePickerinner}
                    >
                      <Grid classes={{root: "filterBoxHead"}}>
                        <IntlMessages id="sidebar.groups" />
                      </Grid>
                      <AppMultiSelectBox
                        menuPlacement="auto"
                        onChange={value => setGroup(value)}
                        value={toolsAvailability}
                        isMulti={false}
                        cacheUniqs={[ company ]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {Ismultidatepicker && (
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  classes={{root: "date_selector_filter"}}
                >
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Grid classes={{root: "labels"}}>
                      <IntlMessages id="trackhistory.from_date" />
                    </Grid>

                    <AppTextInput
                      className={classes.datecal}
                      type="date"
                      value={startDate}
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      onChange={date => selectStartDate(date)}
                      InputLabelProps={{shrink: true}}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Grid classes={{root: "labels"}}>
                      <IntlMessages id="trackhistory.to_date" />
                    </Grid>
                    <AppTextInput
                      type="date"
                      value={endDate}
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy"
                      minDate={startDate}
                      maxDate={new Date()}
                      onChange={date => selectEndDate(date)}
                      InputLabelProps={{shrink: true}}
                    />
                  </Grid>
                </Grid>
              )}

              {Isdatepicker && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "date_selector"}}
                >
                  <Grid classes={{root: "labels"}}>
                    <IntlMessages id="filter.date_filter" />
                  </Grid>
                  <AppTextInput
                    type="date"
                    value={date}
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    onChange={date => selectDate(date)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
              )}

              {Ismonthyear && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "date_selector"}}
                >
                  <Grid classes={{root: "labels"}}>
                    <IntlMessages id="filter.date_filter" />
                  </Grid>
                  <AppTextInput
                    type="date"
                    value={monthYear}
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    onChange={date => setMonthYear(date)}
                    InputLabelProps={{shrink: true}}
                  />
                </Grid>
              )}

              {IstoolsAvailabilty && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "datePicker"}}
                >
                  <Grid classes={{root: "filterBoxHead"}}>
                    <IntlMessages id="availability.availability" />
                  </Grid>
                  <AppMultiSelectBox
                    menuPlacement="auto"
                    onChange={value => setToolsAvailability(value)}
                    value={toolsAvailability}
                    isMulti={false}
                    cacheUniqs={[ company ]}
                    loadOptions={(search, prevOptions) =>
                      searchHelper.getOptionsSearchDetails(
                        search,
                        prevOptions,
                        "availablty",
                        company,
                        true
                      )}
                  />
                </Grid>
              )}

              {Isdriver &&
              getConductorRoleId() && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "datePicker"}}
                >
                  <Grid classes={{root: "filterBoxHead"}}>
                    <IntlMessages id="vehicle.driver" />
                  </Grid>
                  <AppMultiSelectBox
                    menuPlacement="auto"
                    onChange={value => setDriverId(value)}
                    value={driverId}
                    isMulti={false}
                    key={searchBy}
                    loadOptions={(search, prevOptions) =>
                      searchHelper.getInhouseSearchDetails(
                        search,
                        prevOptions,
                        getConductorRoleId(), //conductor //5
                        company,
                        !oneDriver
                      )}
                  />
                </Grid>
              )}

              {Isequipmentvehicle && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "datePicker"}}
                >
                  <Grid classes={{root: "filterBoxHead"}}>
                    <IntlMessages id="equipment.type1" />
                  </Grid>
                  <AppMultiSelectBox
                    menuPlacement="auto"
                    onChange={value => {
                      setEquipmentVehicle(value);
                    }}
                    value={equipmentVehicle}
                    isMulti={false}
                    key={searchBy}
                    options={getEquipmentType(true)}
                  />
                </Grid>
              )}

              {Isequipmenttype && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "datePicker"}}
                >
                  <Grid classes={{root: "filterBoxHead"}}>
                    <IntlMessages id="reports.type" />
                  </Grid>
                  <AppMultiSelectBox
                    menuPlacement="auto"
                    onChange={value => {
                      setEquipmentType(value);
                      setMake(all);
                      setModel(all);
                      setEquipment(all);
                      setSearchBy1(Math.random());
                    }}
                    value={equipmentType}
                    isMulti={false}
                    key={searchBy}
                    loadOptions={(search, prevOptions) =>
                      searchHelper.getEquipmentFilterDetails(
                        search,
                        prevOptions,
                        null,
                        company,
                        true,
                        "type"
                      )}
                  />
                </Grid>
              )}
              {Ismake && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "datePicker"}}
                >
                  <Grid classes={{root: "filterBoxHead"}}>
                    <IntlMessages id="equipment.make" />
                  </Grid>
                  <AppMultiSelectBox
                    menuPlacement="auto"
                    onChange={value => {
                      setMake(value);
                      setSearchBy1(Math.random());
                    }}
                    value={make}
                    isMulti={false}
                    key={searchBy1}
                    loadOptions={(search, prevOptions) =>
                      searchHelper.getEquipmentFilterDetails(
                        search,
                        prevOptions,
                        {equipmentType: equipmentType, model: model},
                        company,
                        true,
                        "make"
                      )}
                  />
                </Grid>
              )}
              {Ismodel && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "datePicker"}}
                >
                  <Grid classes={{root: "filterBoxHead"}}>
                    <IntlMessages id="vehicle.model" />
                  </Grid>
                  <AppMultiSelectBox
                    menuPlacement="auto"
                    onChange={value => {
                      setModel(value);
                      setSearchBy1(Math.random());
                    }}
                    value={model}
                    isMulti={false}
                    key={searchBy1}
                    loadOptions={(search, prevOptions) =>
                      searchHelper.getEquipmentFilterDetails(
                        search,
                        prevOptions,
                        {equipmentType: equipmentType, make: make},
                        company,
                        true,
                        "model"
                      )}
                  />
                </Grid>
              )}
              {Isequipment && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "datePicker"}}
                >
                  <Grid classes={{root: "filterBoxHead"}}>
                    {company && company.format ? (
                      intl.formatMessage({id: "format." + company.format})
                    ) : (
                      intl.formatMessage({id: "format.name"})
                    )}
                  </Grid>
                  <AppMultiSelectBox
                    menuPlacement="auto"
                    onChange={value => {
                      setEquipment(value);
                      setSearchBy1(Math.random());
                    }}
                    value={equipment}
                    isMulti={false}
                    key={searchBy1}
                    loadOptions={(search, prevOptions) =>
                      searchHelper.getEquipmentFilterDetails(
                        search,
                        prevOptions,
                        {
                          equipmentType: equipmentType,
                          make: make,
                          model: model
                        },
                        company,
                        true,
                        company && company.format ? company.format : "name"
                      )}
                  />
                </Grid>
              )}

              {Isregion && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.region_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => {
                        setRegion(value);
                        setSearchBy(Math.random());
                        setWarehouse(all);
                        dispatch(
                          setFilter({value: value, filter: "regionFilter"})
                        );
                        dispatch(
                          setFilter({value: all, filter: "warehouseFilter"})
                        );
                      }}
                      value={region}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getRegionSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}
              {Iswarehouse && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.warehouse_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => handleWarehouse(value)}
                      value={warehouse}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getWarehouseSearchDetails(
                          search,
                          prevOptions,
                          {region: region},
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}

              {Isprovider && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.provider_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setProvider(value)}
                      value={provider}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getProviderSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}
              {Isrole && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="users.role" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setRole(value)}
                      value={role}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getRolePermissionsSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}

              {Ispaymentcondition && (
                <Grid item lg={3} md={3} sm={3} xs={12}>
                  <Grid classes={{root: "labels"}}>
                    <IntlMessages id="equipment.condition" />*
                  </Grid>
                  <AppMultiSelectBox
                    menuPlacement="auto"
                    onChange={value => setPaymentCondition(value)}
                    value={paymentCondition}
                    isMulti={false}
                    options={getConditionType(true)}
                  />
                </Grid>
              )}
              {Iscity && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="dashboard.city" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setCity(value)}
                      value={city}
                      isMulti={false}
                      options={getCity(true)}
                    />
                  </div>
                </Grid>
              )}
              {Iscountry && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="inhousepersonnel.country" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setCountry(value)}
                      value={country}
                      isMulti={false}
                      options={getCountry(true)}
                    />
                  </div>
                </Grid>
              )}

              {Isservicecontrol && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="sidebar.service" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setServiceControl(value)}
                      value={serviceControl}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getServiceSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}
              {Isspare && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.code_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setCode(value)}
                      value={code}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getSpareCodeSearchDetails(
                          search,
                          prevOptions,
                          {services: spareService},
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}

              {Isservice && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.service_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setService(value)}
                      value={service}
                      isMulti={false}
                      options={getServiceType(true)}
                    />
                  </div>
                </Grid>
              )}

              {Isclient && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="sidebar.client" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setClient(value)}
                      value={client}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getClientSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}

              {Ismaintenancetype && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.maintenance_type_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setMaintenanceType(value)}
                      value={maintenanceType}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getMaintenanceTypeSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}

              {Isresourcegroup && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.resource_group_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setResourceGroup(value)}
                      value={resourceGroup}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getResourceGroupSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}
              {Ismaintenanceform && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.maintenance_form_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setMaintenanceForm(value)}
                      value={maintenanceForm}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getMaintenanceFormSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}
              {Ismaintenancegroup && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.maintenance_group_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setMaintenanceGroup(value)}
                      value={maintenanceGroup}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getMaintenanceGroupSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}

              {Isbrand && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.brand_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setClient(value)}
                      value={client}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getClientSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}
              {Isyear && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.year_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setClient(value)}
                      value={client}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getClientSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}

              {Iscostcenter && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.costcenter_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setCostCenter(value)}
                      value={costCenter}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getCostCenterSearchDetails(
                          search,
                          prevOptions,
                          null,
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}
              {Ismeasure && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="pdf.unit" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setMeasure(value)}
                      value={measure}
                      isMulti={false}
                      options={getUnit(true, SUValue)}
                    />
                  </div>
                </Grid>
              )}
              {Isregiontype && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="equipment.type1" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setRegionType(value)}
                      value={regionType}
                      isMulti={false}
                      key={searchBy}
                      loadOptions={(search, prevOptions) =>
                        searchHelper.getOptionsSearchDetails(
                          search,
                          prevOptions,
                          "region_type",
                          company,
                          true
                        )}
                    />
                  </div>
                </Grid>
              )}
              {Isrolestatus && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="actions.state" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setRoleStatus(value)}
                      value={roleStatus}
                      isMulti={false}
                      options={getStatusType(true)}
                    />
                  </div>
                </Grid>
              )}
              {Isstatus && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="actions.state" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setStatus(value)}
                      value={status}
                      isMulti={false}
                      options={getStatusType(true)}
                    />
                  </div>
                </Grid>
              )}
              {Iswarehousedocument && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.document_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setWarehouseDocument(value)}
                      value={warehouseDocument}
                      isMulti={false}
                      options={getDocumentList(true)}
                    />
                  </div>
                </Grid>
              )}
              {Isapprovedocument && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.document_filter" />
                    </Grid>
                    <AppMultiSelectBox
                      menuPlacement="auto"
                      onChange={value => setApproveDocument(value)}
                      value={approveDocument}
                      isMulti={false}
                      options={getDocumentApproveList(true)}
                    />
                  </div>
                </Grid>
              )}

              {Isdocument && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <Grid classes={{root: "filterBoxHead"}}>
                      <IntlMessages id="filter.document_filter" />
                    </Grid>
                    <AppMultiSelectBox menuPlacement="auto" />
                  </div>
                </Grid>
              )}
              {IsstatusTab && (
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.datePicker1}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <StatusTabCard changeStatusTab={changeStatusTab} />
                  </div>
                </Grid>
              )}
              {IsperformanceTab && (
                <Grid
                  item
                  lg={8}
                  md={6}
                  sm={6}
                  xs={12}
                  className={classes.datePicker1}
                >
                  <div
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.datePickerinner}
                  >
                    <PerformanceTabCard
                      changePerformanceTab={changePerformanceTab}
                    />
                  </div>
                </Grid>
              )}

              {Isresetbutton && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  classes={{root: "datePicker"}}
                >
                  <Button
                    classes={{root: "filterButtons"}}
                    onClick={resetFilter}
                  >
                    <Grid>
                      <IntlMessages id="filter.reset_filter" />
                    </Grid>
                  </Button>
                </Grid>
              )}

              {Isstockonly && (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={12}
                  className={classes.datePicker}
                  style={{marginTop: 24, paddingLeft: "40px!important"}}
                >
                  <AppCheckBox
                    label={<IntlMessages id="spares.stock_only" />}
                    checked={stockonly}
                    onChange={event => setStockonly(!stockonly)}
                  />
                </Grid>
              )}
            </GridContainer>
          </Collapse>
        </Grid>
        <Grid
          item
          lg={2}
          md={2}
          sm={2}
          xs={12}
          classes={{root: "filterHeader"}}
        >
          {/* <Grid classes={{root: "mobile_header"}}>
            <IntlMessages id="sidebar.equipment" />
          </Grid> */}
          {Isfilter != false && (
            <Button
              className={clsx(classes.expand, {[classes.expandOpen]: expanded})}
              classes={{root: "filterButton"}}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <CmtImage src={filter} />
              <Grid classes={{root: "mobile_filter_text"}}>
                <IntlMessages id="filter.filter" />
              </Grid>
            </Button>
          )}
        </Grid>
      </Grid>
    </GridContainer>
  );
};

FilterCard.prototype = {
  icon: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]),
  number: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  numberProps: PropTypes.object,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  counterProps: PropTypes.object,
  color: PropTypes.string,
  gradientDirection: PropTypes.string,
  alignCenter: PropTypes.bool
};

FilterCard.defaultProps = {
  color: "common.white",
  gradientDirection: "180deg",
  alignCenter: false
};

export default FilterCard;
