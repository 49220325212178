import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../../@jumbo/components/PageComponents/PageLoader';
import { checkRouterUrl} from '../../../@jumbo/utils/commonHelper';

const Assets = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
      {checkRouterUrl('equipment','view')&&<Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/equipment`} />}
        {checkRouterUrl('equipment','view')&&<Route path={`${requestedUrl}/equipment`} component={lazy(() => import('./Equipment'))} />}
        {checkRouterUrl('equipment','add')&&<Route path={`${requestedUrl}/addequipment`} component={lazy(() => import('./Addequipment'))} />}
        {checkRouterUrl('equipment','edit')&&<Route path={`${requestedUrl}/editequipment/:id`} component={lazy(() => import('./Addequipment'))} />}

        {checkRouterUrl('tools','view')&&<Route path={`${requestedUrl}/tools`} component={lazy(() => import('./Tools'))} />}
        {checkRouterUrl('tools','add')&&<Route path={`${requestedUrl}/addtools`} component={lazy(() => import('./Addtools'))} />}
        {checkRouterUrl('tools','edit')&&<Route path={`${requestedUrl}/edittools/:id`} component={lazy(() => import('./Addtools'))} />}

        {checkRouterUrl('sparessupplies','view')&&<Route path={`${requestedUrl}/sparessupplies`} component={lazy(() => import('./SparesSupplies'))} />}
        {checkRouterUrl('sparessupplies','add')&&<Route path={`${requestedUrl}/addsparessupplies`} component={lazy(() => import('./AddSparesSupplies'))} />}  
        {checkRouterUrl('sparessupplies','edit')&&<Route path={`${requestedUrl}/editsparessupplies/:id`} component={lazy(() => import('./AddSparesSupplies'))} />}

        {checkRouterUrl('existencecard','view')&&<Route path={`${requestedUrl}/existencecard`} component={lazy(() => import('./ExistenceCard'))} />}
        {checkRouterUrl('inventory','view')&&<Route path={`${requestedUrl}/inventory`} component={lazy(() => import('./Inventory'))} />}
        {checkRouterUrl('warehouselist','view')&&<Route path={`${requestedUrl}/warehouselist`} component={lazy(() => import('./WarehouseList'))} />}

        {checkRouterUrl('digital','view')&&<Route path={`${requestedUrl}/digital`} component={lazy(() => import('./Digital'))} />}
        {checkRouterUrl('digital','add')&&<Route path={`${requestedUrl}/adddigital`} component={lazy(() => import('./Adddigital'))} />}
        {checkRouterUrl('digital','edit')&&<Route path={`${requestedUrl}/editdigital/:id`} component={lazy(() => import('./Adddigital'))} />}

        <Route component={lazy(() => import('../404'))} />
      </Switch>
    </Suspense>
  );
};

export default Assets;

