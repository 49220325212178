import {getRoles} from "@testing-library/react";
import React from "react";
import IntlMessages from "./IntlMessages";
import api from "../../services/api";

let language = "English";
let languageCode = "en-US";
let currenySymbol = "$";

export const getMapIcon = type => {
  switch (type) {
    case "bus":
    case "taxi_buses":
      return "/images/newmap/bus.png";
    case "sprinter":
      return "/images/newmap/sprinter.png";
    case "tractor":
      return "/images/newmap/tractor.png";
    case "truck":
      return "/images/newmap/truck.png";
    case "carro":
      return "/images/newmap/car.png";
    case "non_mechanical":
    case "nebulizador":
    case "cpb":
      return "/images/map/non_mechanical_270.png";
    default:
      return "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png";
  }
};

export const getIconSize = type => {
  switch (type) {
    case "bus":
    case "taxi_buses":
      return [ 20, 50 ];
    case "sprinter":
      return [ 26, 50 ];
    case "tractor":
      return [ 20, 50 ];
    case "truck":
      return [ 30, 43 ];
    case "carro":
      return [ 27, 50 ];
    case "non_mechanical":
    case "nebulizador":
    case "cpb":
      return [ 50, 50 ];
    default:
      return [ 30, 30 ];
  }
};

export const getMapIconData = type => {
  switch (type) {
    case "bus":
    case "taxi_buses":
      return {
        useImg: true,
        imgUrl: "/images/newmap/bus.png",
        width: 20,
        height: 50
      };
    case "sprinter":
      return {
        useImg: true,
        imgUrl: "/images/newmap/sprinter.png",
        width: 26,
        height: 50
      };
    case "tractor":
      return {
        useImg: true,
        imgUrl: "/images/newmap/tractor.png",
        width: 20,
        height: 50
      };
    case "truck":
      return {
        useImg: true,
        imgUrl: "/images/newmap/truck.png",
        width: 30,
        height: 43
      };
    case "carro":
      return {
        useImg: true,
        imgUrl: "/images/newmap/car.png",
        width: 27,
        height: 50
      };
    case "non_mechanical":
    case "nebulizador":
    case "cpb":
      return {
        useImg: true,
        imgUrl: "/images/map/non_mechanical_270.png",
        width: 50,
        height: 50
      };
    default:
      return {
        useImg: true,
        imgUrl:
          "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-grey.png",
        width: 30,
        height: 30
      };
  }
};

export const capitalizeFLetter = string => {
  return string[0].toUpperCase() + string.slice(1);
};

export const isValidEmail = value => {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i.test(value);
};

export const idGenerator = () => {
  return Math.floor(Math.random() * 100000);
};

export const linkify = inputText => {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(
    replacePattern1,
    '<a href="$1" target="_blank">$1</a>'
  );

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a href="http://$2" target="_blank">$2</a>'
  );

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z0-9\-]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a href="mailto:$1">$1</a>'
  );

  return replacedText;
};

export const geValidUrl = (url, ubSecureUrl = false) => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    if (ubSecureUrl) {
      return "http://" + url;
    }
    return "https://" + url;
  }

  return url;
};
export const isUrlValid = url => {
  var res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
};
export const getFilterQuery = (key, list) => {
  let query = "";
  if (list.constructor === Array) {
    for (let item in list) {
      if (list[item].value) {
        query = query == "" ? list[item].value : query + "," + list[item].value;
      }
    }
  } else if (list.value != -1 && list.value != undefined) {
    query = list.value;
  } else if (list != undefined) {
    query = list;
  }

  if (query != "") {
    return "&filter_" + key + "=" + query;
  } else {
    return "";
  }
};

export const getUserRegionFilterQuery = (key, type) => {
  let query = "";
  let queryId = "";
  let userRole = getRoleName();
  if (userRole != "super_admin" && userRole != "admin") {
    let user = getUser();
    if (user) {
      console.log("user===", user);
      if (type == undefined) {
        queryId = [
          ...(user.otherRegionId ? user.otherRegionId : []),
          ...(user.regionId ? user.regionId : [])
        ]
          .filter((regionId, index, regions) => {
            return regions.indexOf(regionId) === index;
          })
          .toString();
      } else if (type == "other") {
        queryId =
          queryId == ""
            ? user.otherRegionId ? user.otherRegionId.toString() : ""
            : queryId +
              "," +
              (user.otherRegionId ? user.otherRegionId.toString() : "");
      } else if (type == "main") {
        queryId =
          queryId == ""
            ? user.regionId ? user.regionId.toString() : ""
            : queryId + "," + (user.regionId ? user.regionId.toString() : "");
      }
    }
    if (queryId != "") {
      query = "&filter_" + key + "=" + queryId;
    }
  }

  return query;
};

export const getUserWarehouseFilterQuery = (key, type) => {
  let query = "";
  let queryId = "";
  let userRole = getRoleName();
  if (userRole != "super_admin" && userRole != "admin") {
    let user = getUser();
    if (user) {
      if (type == undefined) {
        queryId = [
          ...(user.warehouseId ? user.warehouseId : []),
          ...(user.otherWarehouseId ? user.otherWarehouseId : [])
        ]
          .filter((wareHouseId, index, wareHouses) => {
            return wareHouses.indexOf(wareHouseId) === index;
          })
          .toString();
      }

      if (type == "other") {
        queryId =
          queryId == ""
            ? user.otherWarehouseId ? user.otherWarehouseId.toString() : ""
            : queryId +
              "," +
              (user.otherWarehouseId ? user.otherWarehouseId.toString() : "");
      }
      if (type == "main") {
        queryId =
          queryId == ""
            ? user.warehouseId ? user.warehouseId.toString() : ""
            : queryId +
              "," +
              (user.warehouseId ? user.warehouseId.toString() : "");
      }
    }
    if (queryId != "") {
      query = "&filter_" + key + "=" + queryId;
    }
  }
  return query;
};

export const checkDateType = key => {
  let keyList = [
    "startDate",
    "serviceDate",
    "startTime",
    "endTime",
    "created_date",
    "vehicledate",
    "maintancedate",
    "carinsurancedate",
    "month_year",
    "service_date",
    "starttime_date",
    "endtime_date",
    "purchaseDate",
    "vehicleDate",
    "maintanceDate",
    "carinsuranceDate",
    "dateEmission",
    "entryDate",
    "expirationDate",
    "createdAt",
    "updatedAt",
    "emissionDate",
    "revisionDate",
    "yearPurchase",
    "request_date",
    "depature_date",
    "departure_date",
    "documentDate",
    "commitmentDate",
    "scheduleDate"
  ];
  return keyList.indexOf(key) != -1 ? true : false;
};

export const getModuleDeletePermission = (moduleName, itemList) => {
  let Ischeck = false;
  let permissionCount = 0;
  let userRole = getRoleName();
  if (userRole != "super_admin" && userRole != "admin") {
    let user = getUser();
    if (user) {
      Ischeck = true;
      for (let item of itemList) {
        if (
          item.regionId == null ||
          item.regionId == undefined ||
          item.regionId == "" ||
          user.regionId == null
        ) {
          Ischeck = true;
          permissionCount = Ischeck ? permissionCount + 1 : permissionCount;
        } else if (
          typeof item.regionId == "string" ||
          typeof item.regionId == "number"
        ) {
          console.log("number===========", item.regionId);
          Ischeck =
            user.regionId.indexOf(Number(item.regionId)) != -1 ? true : false;
          permissionCount = Ischeck ? permissionCount + 1 : permissionCount;
        } else if (typeof item.regionId == "object") {
          for (let data of item.regionId) {
            let checkModule =
              user.regionId.indexOf(Number(data)) != -1 ? true : false;
            if (checkModule) {
              Ischeck = true;
            }
          }
          permissionCount = Ischeck ? permissionCount + 1 : permissionCount;
        }
      }
      console.log(
        "permissionCount==",
        permissionCount,
        "itemList.length==",
        itemList.length
      );
      if (permissionCount == itemList.length) {
        Ischeck = true;
      } else {
        Ischeck = false;
      }
    }
  } else {
    Ischeck = true;
  }
  return Ischeck;
};

export const getModulePermission = (moduleName, item) => {
  let Ischeck = false;
  let userRole = getRoleName();
  if (userRole != "super_admin" && userRole != "admin") {
    let user = getUser();
    let moduleList = getmoduleList().find(item => item.value === moduleName);
    console.log("moduleList===", moduleList);
    if (user && moduleList) {
      if (moduleName == "region") {
        item.regionId = item.id;
      }
      if (moduleList.region) {
        if (
          item.regionId == null ||
          item.regionId == undefined ||
          item.regionId == "" ||
          user.regionId == null
        ) {
          Ischeck = true;
        } else if (
          typeof item.regionId == "string" ||
          typeof item.regionId == "number"
        ) {
          Ischeck =
            user.regionId.indexOf(Number(item.regionId)) != -1 ? true : false;
        } else if (typeof item.regionId == "object") {
          for (let data of item.regionId) {
            let checkModule =
              user.regionId.indexOf(Number(data)) != -1 ? true : false;
            if (checkModule) {
              Ischeck = true;
            }
          }
        }
      } else if (moduleList.warehouse) {
        if (
          moduleName == "depatureinternalguide" ||
          moduleName == "admissioninternalguide" ||
          moduleName == "depaturetransferdispatch"
        ) {
          item.warehouseId = item.originWarehouseId;
        } else if (moduleName == "admissiontransferdispatch") {
          item.warehouseId = item.destinationWarehouseId;
        }
        if (
          item.warehouseId == null ||
          item.warehouseId == undefined ||
          item.warehouseId == "" ||
          user.warehouseId == null
        ) {
          Ischeck = true;
        } else if (
          typeof item.warehouseId == "string" ||
          typeof item.warehouseId == "number"
        ) {
          Ischeck =
            user.warehouseId.indexOf(Number(item.warehouseId)) != -1
              ? true
              : false;
        } else if (typeof item.warehouseId == "object") {
          for (let data of item.warehouseId) {
            let checkModule =
              user.warehouseId.indexOf(Number(data)) != -1 ? true : false;
            if (checkModule) {
              Ischeck = true;
            }
          }
        }
      } else {
        Ischeck = true;
      }
    }
  } else {
    Ischeck = true;
  }
  return Ischeck;
};

export const getSearchQuery = (value, list) => {
  let query = "";
  for (let item of list) {
    if (item.search == true) {
      let key = item.key ? item.key : item.name;
      if (query == "") {
        query = "&search_" + key + "=" + value;
      } else {
        query = query + "&search_" + key + "=" + value;
      }
    }
  }
  return query;
};

export const getSortQuery = (name, value, list) => {
  let query = "";
  for (let item of list) {
    if (item.sort == true && item.name == name) {
      let key = item.key ? item.key : item.name;
      if (query == "") {
        query = "&sort_" + key + "=" + value;
      } else {
        query = query + "&sort_" + key + "=" + value;
      }
    }
  }
  return query;
};

export const getLanguageOptions = () => {
  let languageoptions = [
    {value: "english", label: <IntlMessages id="util.English" />},
    {value: "spanish", label: <IntlMessages id="util.Spanish" />}
  ];
  return languageoptions;
};

export const getEquipmentFormat = () => {
  let formatList = [
    {value: "name", label: <IntlMessages id="format.name" />},
    {
      value: "registerNumber",
      label: <IntlMessages id="format.registerNumber" />
    }
  ];
  return formatList;
};

export const getTable = (company, columns) => {
  let data = [];
  for (let item of company) {
    let newData = {id: item.id};
    for (let item1 of columns) {
      newData = {
        ...newData,
        [item1.name]: item[item1.name]
      };
    }
    data.push(newData);
  }
  return data;
};

export const getAvailableClassName = text => {
  var displayClassName = "";
  if (
    text === "available" ||
    text === "new" ||
    text === "complete" ||
    text === "closed" ||
    text === "completed" ||
    text === "confirmed" ||
    text == "received"
  ) {
    displayClassName = "displayGreen";
  } else if (text === "rented" || text == "progress" || text == "In Progress") {
    displayClassName = "displayOrange";
  } else if (text === "out_of_service") {
    displayClassName = "displayRed";
  } else if (text === "not_available") {
    displayClassName = "displayBlack";
  } else if (text === "resolved") {
    displayClassName = "displayGreen";
  } else if (text === "not_resolved") {
    displayClassName = "displayRed";
  } else if (text === "pending" || text === "Pending" || text === "open") {
    displayClassName = "displayWarning";
  } else if (
    text === "rejected" ||
    text === "Rejected" ||
    text === "delete_reject" ||
    text === "partially_reject" ||
    text === "request_delete" ||
    text === "delete" ||
    text === "deleted"
  ) {
    displayClassName = "displayRed";
  } else if (
    text === "approve" ||
    text === "Approved" ||
    text === "partially_approve"
  ) {
    displayClassName = "displayGreen";
  } else if (text === "cancel" || text === "close") {
    displayClassName = "displayBlack";
  }
  return displayClassName;
};

export const getStatusClassName = (text, intl) => {
  var displayName = "";
  if (text === "new") {
    displayName = "contractors.new";
  } else if (text === "pending") {
    displayName = "contractors.pending";
  } else if (text === "open") {
    displayName = "actions.open";
  } else if (text === "closed") {
    displayName = "actions.closed";
  } else if (text === "rejected" || text === "Rejected") {
    displayName = "pdf.rejected";
  } else if (text === "partially_reject") {
    displayName = "actions.partially_reject";
  } else if (text === "partially_approve") {
    displayName = "actions.partially_approve";
  } else if (text === "approve" || text === "Approved") {
    displayName = "actions.approve";
  } else if (text === "complete" || text === "completed") {
    displayName = "actions.Completed";
  } else if (text === "confirmed") {
    displayName = "purchaserequests.confirm";
  } else if (text === "received") {
    displayName = "actions.received";
  } else if (text === "cancel") {
    displayName = "actions.cancel";
  } else if (text === "close") {
    displayName = "actions.close";
  } else if (
    text === "progress" ||
    text === "In Progress" ||
    text === "in_progress"
  ) {
    displayName = "actions.progress";
  } else if (text === "delete") {
    displayName = "actions.delete_approved";
  } else if (text === "deleted") {
    displayName = "actions.deleted";
  } else if (text === "request_delete") {
    displayName = "actions.delete_approve";
  } else if (text === "delete_reject") {
    displayName = "actions.delete_rejected";
  }
  if (displayName != "") {
    displayName = intl ? (
      intl.formatMessage({id: displayName})
    ) : (
      <IntlMessages id={displayName} />
    );
  }

  return displayName;
};

export const getMessage = message => {
  if (typeof message === "object") {
    let errorMsg = "";
    for (let data in message) {
      if (message[data] && message[data].message) {
        errorMsg = errorMsg + message[data].message;
      }
    }
    return errorMsg == "" ? message : errorMsg;
  } else {
    return message;
  }
};

export const getTypeList = () => {
  const typeList = [
    {
      value: "maintenance_guideline",
      label: <IntlMessages id="maintenance.maintenance_guideline" />
    },
    {value: "review_form", label: <IntlMessages id="maintenance.review_form" />}
  ];

  return typeList;
};
export const getOptionList = () => {
  const optionList = [
    {value: "manual_entry", label: <IntlMessages id="sidebar.manual_entry" />},
    {value: "load_excel", label: <IntlMessages id="sidebar.load_excel" />}
  ];

  return optionList;
};

export const getWorkOrderOptions = () => {
  let statusOption = [
    {value: "new", label: <IntlMessages id="contractors.new" />},
    {value: "in_progress", label: <IntlMessages id="actions.progress" />},
    {value: "completed", label: <IntlMessages id="actions.Completed" />}
  ];
  return statusOption;
};

export const getRiskClassificaitonType = () => {
  let riskclassificaitonList = [
    {value: "extraordinaria", label: "extraordinaria"},
    {value: "fatalidad", label: "fatalidad"},
    {value: "tiempo", label: "tiempo"},
    {value: "tratamiento", label: "tratamiento"},
    {value: "lesion", label: "lesion"},
    {value: "perdida", label: "perdida"}
  ];
  return riskclassificaitonList;
};

export const getSpareOptionList = () => {
  const optionList = [
    {label: <IntlMessages id="workorder.manual" />, value: "manual"},
    {
      label: <IntlMessages id="workorder.maintenance_id" />,
      value: "maintanance"
    }
  ];

  return optionList;
};
export const getOilLevel = () => {
  const optionList = [
    {label: "1/1", value: "1/1"},
    {label: "1/8", value: "1/8"},
    {label: "1/4", value: "1/4"},
    {label: "3/8", value: "3/8"},
    {label: "1/2", value: "1/2"},
    {label: "5/8", value: "5/8"},
    {label: "3/4", value: "3/4"},
    {label: "7/8", value: "7/8"}
  ];

  return optionList;
};

export const getCity = filter => {
  let cityList = [
    {value: "ANGOL", label: "ANGOL"},
    {value: "ANTOFAGASTA", label: "ANTOFAGASTA"},
    {value: "ARICA", label: "ARICA"},
    {value: "CALAMA", label: "CALAMA"},
    {value: "CHILLAN", label: "CHILLAN"},
    {value: "CONCEPCION", label: "CONCEPCION"},
    {value: "COPIAPO", label: "COPIAPO"},
    {value: "COQUIMBO", label: "COQUIMBO"},
    {value: "COLINA", label: "COLINA"},
    {value: "COYHAIQUE", label: "COYHAIQUE"},
    {value: "CURICO", label: "CURICO"},
    {value: "ILLAPEL", label: "ILLAPEL"},
    {value: "IQUIQUE", label: "IQUIQUE"},
    {value: "LA SERENA", label: "LA SERENA"},
    {value: "LOS ANDES", label: "LOS ANDES"},
    {value: "LOS ANGELES", label: "LOS ANGELES"},
    {value: "OSORNO", label: "OSORNO"},
    {value: "OVALLE", label: "OVALLE"},
    {value: "PUERTO MONTT", label: "PUERTO MONTT"},
    {value: "PUNTA ARENAS", label: "PUNTA ARENAS"},
    {value: "QUILLOTA", label: "QUILLOTA"},
    {value: "RANCAGUA", label: "RANCAGUA"},
    {value: "SAN FELIPE", label: "SAN FELIPE"},
    {value: "SANTIAGO", label: "SANTIAGO"},
    {value: "SAN FERNANDO", label: "SAN FERNANDO"},
    {value: "TEMUCO", label: "TEMUCO"},
    {value: "VALLENAR", label: "VALLENAR"},
    {value: "VALDIVIA", label: "VALDIVIA"},
    {value: "VALPARAISO", label: "VALPARAISO"},
    {value: "TALCA", label: "TALCA"},
    {value: "TOCOPILLA", label: "TOCOPILLA"}
  ];
  if (filter == true) {
    cityList = [ {value: -1, label: <IntlMessages id="table.all" />} ].concat(
      cityList
    );
  }
  return cityList;
};

export const getParseOptions = () => {
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
  };

  return papaparseOptions;
};

export const getDocumentApproveList = filter => {
  let documentList = [
    {label: <IntlMessages id="sidebar.equipment" />, value: "equipment"},
    {label: <IntlMessages id="sidebar.tools" />, value: "tools"},
    {
      label: <IntlMessages id="sidebar.sparessupplies" />,
      value: "sparessupplies"
    },
    {label: <IntlMessages id="sidebar.digital" />, value: "digital"},
    {label: <IntlMessages id="sidebar.inhouse" />, value: "inhouse"},
    {label: <IntlMessages id="sidebar.providers" />, value: "providers"},
    {label: <IntlMessages id="sidebar.clients" />, value: "clients"},
    {label: <IntlMessages id="sidebar.region" />, value: "region"},
    {label: <IntlMessages id="sidebar.groups" />, value: "groups"},
    {label: <IntlMessages id="sidebar.costcenter" />, value: "costcenter"},
    {label: <IntlMessages id="sidebar.warehouse" />, value: "warehouse"},
    {label: <IntlMessages id="sidebar.service" />, value: "service"},
    {
      label: <IntlMessages id="sidebar.servicecontrol" />,
      value: "servicecontrol"
    },
    {
      label: <IntlMessages id="sidebar.servicesettings" />,
      value: "servicesettings"
    }
  ];

  if (filter == true) {
    documentList = [
      {value: -1, label: <IntlMessages id="table.all" />}
    ].concat(documentList);
  }
  return documentList;
};

export const getDocumentList = filter => {
  let documentList = [
    {
      label: <IntlMessages id="spares.PO" />,
      value: "PO",
      name: "purchaseorder"
    },
    {
      label: <IntlMessages id="spares.EI" />,
      value: "EI",
      name: "electronicinvoice"
    },
    {
      label: <IntlMessages id="spares.PDG" />,
      value: "PDG",
      name: "supplierofficeguide"
    },
    {
      label: <IntlMessages id="spares.PFB" />,
      value: "PFB",
      name: "professionalfees"
    },
    {label: <IntlMessages id="spares.CN" />, value: "CN", name: "creditnote"},
    {label: <IntlMessages id="spares.DN" />, value: "DN", name: "debitnote"},
    {
      label: <IntlMessages id="spares.IG(A)" />,
      value: "IG(A)",
      name: "admissioninternalguide"
    },
    {
      label: <IntlMessages id="spares.TDG(A)" />,
      value: "TDG(A)",
      name: "admissiontransferdispatch"
    },
    {label: <IntlMessages id="spares.ADJ" />, value: "ADJ", name: "adjustment"},
    {
      label: <IntlMessages id="spares.IG(D)" />,
      value: "IG(D)",
      name: "depatureinternalguide"
    },
    {
      label: <IntlMessages id="spares.TDG(D)" />,
      value: "TDG(D)",
      name: "depaturetransferdispatch"
    },
    {label: <IntlMessages id="spares.WS" />, value: "WS", name: "wastage"}
    // {label: <IntlMessages id="spares['WO(WO)'],value:'WO(WO)'},
    // {label: <IntlMessages id="spares['WO(S&P)'],value:'WO(S&P)'},
    // {label: <IntlMessages id="spares['WO(WD)'],value:'WO(WD)'},
    // {label: <IntlMessages id="spares['WO(CWO)'],value:'WO(CWO)'},

    // {label: <IntlMessages id="spares.WS,value:'WS'}
  ];
  if (filter == true) {
    documentList = [
      {value: -1, label: <IntlMessages id="table.all" />}
    ].concat(documentList);
  }
  return documentList;
};

export const getCountry = filter => {
  let countryList = [
    {label: "Afghanistan", value: "AF"},
    {label: "Åland Islands", value: "AX"},
    {label: "Albania", value: "AL"},
    {label: "Algeria", value: "DZ"},
    {label: "American Samoa", value: "AS"},
    {label: "AndorrA", value: "AD"},
    {label: "Angola", value: "AO"},
    {label: "Anguilla", value: "AI"},
    {label: "Antarctica", value: "AQ"},
    {label: "Antigua and Barbuda", value: "AG"},
    {label: "Argentina", value: "AR"},
    {label: "Armenia", value: "AM"},
    {label: "Aruba", value: "AW"},
    {label: "Australia", value: "AU"},
    {label: "Austria", value: "AT"},
    {label: "Azerbaijan", value: "AZ"},
    {label: "Bahamas", value: "BS"},
    {label: "Bahrain", value: "BH"},
    {label: "Bangladesh", value: "BD"},
    {label: "Barbados", value: "BB"},
    {label: "Belarus", value: "BY"},
    {label: "Belgium", value: "BE"},
    {label: "Belize", value: "BZ"},
    {label: "Benin", value: "BJ"},
    {label: "Bermuda", value: "BM"},
    {label: "Bhutan", value: "BT"},
    {label: "Bolivia", value: "BO"},
    {label: "Bosnia and Herzegovina", value: "BA"},
    {label: "Botswana", value: "BW"},
    {label: "Bouvet Island", value: "BV"},
    {label: "Brazil", value: "BR"},
    {label: "British Indian Ocean Territory", value: "IO"},
    {label: "Brunei Darussalam", value: "BN"},
    {label: "Bulgaria", value: "BG"},
    {label: "Burkina Faso", value: "BF"},
    {label: "Burundi", value: "BI"},
    {label: "Cambodia", value: "KH"},
    {label: "Cameroon", value: "CM"},
    {label: "Canada", value: "CA"},
    {label: "Cape Verde", value: "CV"},
    {label: "Cayman Islands", value: "KY"},
    {label: "Central African Republic", value: "CF"},
    {label: "Chad", value: "TD"},
    {label: "Chile", value: "CL"},
    {label: "China", value: "CN"},
    {label: "Christmas Island", value: "CX"},
    {label: "Cocos (Keeling) Islands", value: "CC"},
    {label: "Colombia", value: "CO"},
    {label: "Comoros", value: "KM"},
    {label: "Congo", value: "CG"},
    {label: "Congo, The Democratic Republic of the", value: "CD"},
    {label: "Cook Islands", value: "CK"},
    {label: "Costa Rica", value: "CR"},
    {label: "Cote D'Ivoire", value: "CI"},
    {label: "Croatia", value: "HR"},
    {label: "Cuba", value: "CU"},
    {label: "Cyprus", value: "CY"},
    {label: "Czech Republic", value: "CZ"},
    {label: "Denmark", value: "DK"},
    {label: "Djibouti", value: "DJ"},
    {label: "Dominica", value: "DM"},
    {label: "Dominican Republic", value: "DO"},
    {label: "Ecuador", value: "EC"},
    {label: "Egypt", value: "EG"},
    {label: "El Salvador", value: "SV"},
    {label: "Equatorial Guinea", value: "GQ"},
    {label: "Eritrea", value: "ER"},
    {label: "Estonia", value: "EE"},
    {label: "Ethiopia", value: "ET"},
    {label: "Falkland Islands (Malvinas)", value: "FK"},
    {label: "Faroe Islands", value: "FO"},
    {label: "Fiji", value: "FJ"},
    {label: "Finland", value: "FI"},
    {label: "France", value: "FR"},
    {label: "French Guiana", value: "GF"},
    {label: "French Polynesia", value: "PF"},
    {label: "French Southern Territories", value: "TF"},
    {label: "Gabon", value: "GA"},
    {label: "Gambia", value: "GM"},
    {label: "Georgia", value: "GE"},
    {label: "Germany", value: "DE"},
    {label: "Ghana", value: "GH"},
    {label: "Gibraltar", value: "GI"},
    {label: "Greece", value: "GR"},
    {label: "Greenland", value: "GL"},
    {label: "Grenada", value: "GD"},
    {label: "Guadeloupe", value: "GP"},
    {label: "Guam", value: "GU"},
    {label: "Guatemala", value: "GT"},
    {label: "Guernsey", value: "GG"},
    {label: "Guinea", value: "GN"},
    {label: "Guinea-Bissau", value: "GW"},
    {label: "Guyana", value: "GY"},
    {label: "Haiti", value: "HT"},
    {label: "Heard Island and Mcdonald Islands", value: "HM"},
    {label: "Holy See (Vatican City State)", value: "VA"},
    {label: "Honduras", value: "HN"},
    {label: "Hong Kong", value: "HK"},
    {label: "Hungary", value: "HU"},
    {label: "Iceland", value: "IS"},
    {label: "India", value: "IN"},
    {label: "Indonesia", value: "ID"},
    {label: "Iran, Islamic Republic Of", value: "IR"},
    {label: "Iraq", value: "IQ"},
    {label: "Ireland", value: "IE"},
    {label: "Isle of Man", value: "IM"},
    {label: "Israel", value: "IL"},
    {label: "Italy", value: "IT"},
    {label: "Jamaica", value: "JM"},
    {label: "Japan", value: "JP"},
    {label: "Jersey", value: "JE"},
    {label: "Jordan", value: "JO"},
    {label: "Kazakhstan", value: "KZ"},
    {label: "Kenya", value: "KE"},
    {label: "Kiribati", value: "KI"},
    {label: "Korea, Democratic People's Republic of", value: "KP"},
    {label: "Korea, Republic of", value: "KR"},
    {label: "Kuwait", value: "KW"},
    {label: "Kyrgyzstan", value: "KG"},
    {label: "Lao People's Democratic Republic", value: "LA"},
    {label: "Latvia", value: "LV"},
    {label: "Lebanon", value: "LB"},
    {label: "Lesotho", value: "LS"},
    {label: "Liberia", value: "LR"},
    {label: "Libyan Arab Jamahiriya", value: "LY"},
    {label: "Liechtenstein", value: "LI"},
    {label: "Lithuania", value: "LT"},
    {label: "Luxembourg", value: "LU"},
    {label: "Macao", value: "MO"},
    {label: "Macedonia, The Former Yugoslav Republic of", value: "MK"},
    {label: "Madagascar", value: "MG"},
    {label: "Malawi", value: "MW"},
    {label: "Malaysia", value: "MY"},
    {label: "Maldives", value: "MV"},
    {label: "Mali", value: "ML"},
    {label: "Malta", value: "MT"},
    {label: "Marshall Islands", value: "MH"},
    {label: "Martinique", value: "MQ"},
    {label: "Mauritania", value: "MR"},
    {label: "Mauritius", value: "MU"},
    {label: "Mayotte", value: "YT"},
    {label: "Mexico", value: "MX"},
    {label: "Micronesia, Federated States of", value: "FM"},
    {label: "Moldova, Republic of", value: "MD"},
    {label: "Monaco", value: "MC"},
    {label: "Mongolia", value: "MN"},
    {label: "Montserrat", value: "MS"},
    {label: "Morocco", value: "MA"},
    {label: "Mozambique", value: "MZ"},
    {label: "Myanmar", value: "MM"},
    {label: "Namibia", value: "NA"},
    {label: "Nauru", value: "NR"},
    {label: "Nepal", value: "NP"},
    {label: "Netherlands", value: "NL"},
    {label: "Netherlands Antilles", value: "AN"},
    {label: "New Caledonia", value: "NC"},
    {label: "New Zealand", value: "NZ"},
    {label: "Nicaragua", value: "NI"},
    {label: "Niger", value: "NE"},
    {label: "Nigeria", value: "NG"},
    {label: "Niue", value: "NU"},
    {label: "Norfolk Island", value: "NF"},
    {label: "Northern Mariana Islands", value: "MP"},
    {label: "Norway", value: "NO"},
    {label: "Oman", value: "OM"},
    {label: "Pakistan", value: "PK"},
    {label: "Palau", value: "PW"},
    {label: "Palestinian Territory, Occupied", value: "PS"},
    {label: "Panama", value: "PA"},
    {label: "Papua New Guinea", value: "PG"},
    {label: "Paraguay", value: "PY"},
    {label: "Peru", value: "PE"},
    {label: "Philippines", value: "PH"},
    {label: "Pitcairn", value: "PN"},
    {label: "Poland", value: "PL"},
    {label: "Portugal", value: "PT"},
    {label: "Puerto Rico", value: "PR"},
    {label: "Qatar", value: "QA"},
    {label: "Reunion", value: "RE"},
    {label: "Romania", value: "RO"},
    {label: "Russian Federation", value: "RU"},
    {label: "RWANDA", value: "RW"},
    {label: "Saint Helena", value: "SH"},
    {label: "Saint Kitts and Nevis", value: "KN"},
    {label: "Saint Lucia", value: "LC"},
    {label: "Saint Pierre and Miquelon", value: "PM"},
    {label: "Saint Vincent and the Grenadines", value: "VC"},
    {label: "Samoa", value: "WS"},
    {label: "San Marino", value: "SM"},
    {label: "Sao Tome and Principe", value: "ST"},
    {label: "Saudi Arabia", value: "SA"},
    {label: "Senegal", value: "SN"},
    {label: "Serbia and Montenegro", value: "CS"},
    {label: "Seychelles", value: "SC"},
    {label: "Sierra Leone", value: "SL"},
    {label: "Singapore", value: "SG"},
    {label: "Slovakia", value: "SK"},
    {label: "Slovenia", value: "SI"},
    {label: "Solomon Islands", value: "SB"},
    {label: "Somalia", value: "SO"},
    {label: "South Africa", value: "ZA"},
    {label: "South Georgia and the South Sandwich Islands", value: "GS"},
    {label: "Spain", value: "ES"},
    {label: "Sri Lanka", value: "LK"},
    {label: "Sudan", value: "SD"},
    {label: "Suriname", value: "SR"},
    {label: "Svalbard and Jan Mayen", value: "SJ"},
    {label: "Swaziland", value: "SZ"},
    {label: "Sweden", value: "SE"},
    {label: "Switzerland", value: "CH"},
    {label: "Syrian Arab Republic", value: "SY"},
    {label: "Taiwan, Province of China", value: "TW"},
    {label: "Tajikistan", value: "TJ"},
    {label: "Tanzania, United Republic of", value: "TZ"},
    {label: "Thailand", value: "TH"},
    {label: "Timor-Leste", value: "TL"},
    {label: "Togo", value: "TG"},
    {label: "Tokelau", value: "TK"},
    {label: "Tonga", value: "TO"},
    {label: "Trinidad and Tobago", value: "TT"},
    {label: "Tunisia", value: "TN"},
    {label: "Turkey", value: "TR"},
    {label: "Turkmenistan", value: "TM"},
    {label: "Turks and Caicos Islands", value: "TC"},
    {label: "Tuvalu", value: "TV"},
    {label: "Uganda", value: "UG"},
    {label: "Ukraine", value: "UA"},
    {label: "United Arab Emirates", value: "AE"},
    {label: "United Kingdom", value: "GB"},
    {label: "United States", value: "US"},
    {label: "United States Minor Outlying Islands", value: "UM"},
    {label: "Uruguay", value: "UY"},
    {label: "Uzbekistan", value: "UZ"},
    {label: "Vanuatu", value: "VU"},
    {label: "Venezuela", value: "VE"},
    {label: "Viet Nam", value: "VN"},
    {label: "Virgin Islands, British", value: "VG"},
    {label: "Virgin Islands, U.S.", value: "VI"},
    {label: "Wallis and Futuna", value: "WF"},
    {label: "Western Sahara", value: "EH"},
    {label: "Yemen", value: "YE"},
    {label: "Zambia", value: "ZM"},
    {label: "Zimbabwe", value: "ZW"}
  ];
  if (filter == true) {
    countryList = [
      {value: -1, label: <IntlMessages id="table.all" />}
    ].concat(countryList);
  }
  return countryList;
};

export const getRouteUrl = (moduleName, suffix) => {
  suffix = suffix ? suffix : "";
  let suffixRoute = getmoduleList().find(
    item => item.value === moduleName && item.route && item.route != ""
  );
  let backUrl = suffixRoute
    ? "/" + suffixRoute.route + "/" + (suffix + moduleName)
    : "/" + (suffix + moduleName);
  return backUrl;
};
export const getStatusList = () => {
  const statusList = [
    {value: "approve", label: <IntlMessages id="actions.approve" />},
    {value: "rejected", label: <IntlMessages id="pdf.rejected" />}
  ];
  return statusList;
};

export const getOpenStatusList = () => {
  const statusList = [
    {value: "open", label: <IntlMessages id="actions.open" />},
    {value: "closed", label: <IntlMessages id="actions.closed" />}
  ];
  return statusList;
};

export const getKeyNameList = () => {
  let keyList = {
    equipment: {
      name: "format.name",
      type: "reports.type",
      make: "equipment.make",
      model: "vehicle.model",
      series: "vehicle.serial_no",
      serialNumber: "vehicle.serial_no",
      registerNumber: "format.registerNumber",
      motorNumber: "equipment.motor_number",
      chasisNumber: "equipment.chasis_number",
      imei: "vehicle.IMEI",
      totalHours: "equipment.total_hours",
      totalKmsHours: "equipment.total_kms",
      purchaseDate: "equipment.purchaseDate",
      startdate: "availability.year_purchase",
      availability: "availability.availability",
      vehicleDate: "equipment.vehicle_permition",
      maintanceDate: "equipment.maintance_date",
      carinsuranceDate: "equipment.car_insurance_date",
      odometer: "equipment.odometer",
      horometer: "equipment.horometer",
      kilometers: "equipment.kilometers",
      costCenterId: "sidebar.costcenter",
      regionId: "sidebar.region",
      file_list: "vehicle.upload_document",
      imageItems: "equipment.image"
    },
    tools: {
      name: "dashboard.name",
      make: "equipment.make",
      model: "vehicle.model",
      serialNumber: "vehicle.serial_no",
      groupId: "equipment.group",
      client: "sidebar.client",
      region: "sidebar.region",
      yearPurchase: "availability.year_purchase",
      availability: "availability.availability",
      description: "company.description",
      file_list: "vehicle.upload_image",
      imageItems: "equipment.image"
    },
    sparessupplies: {
      code: "spares.code",
      description: "company.description",
      price: "vehicle.price",
      address: "dashboard.address",
      discount: "spares.discount",
      supplier: "spares.supplier_contractor",
      article: "spares.item",
      service: "sidebar.service",
      criticalStock: "spares.critical_stock",
      measure: "unit",
      category: "riskmanagement.category",
      subCategory: "spares.subCategory",
      family: "spares.family",
      class: "spares.class",
      providerId: "electronicbill.provider_name",
      regionId: "sidebar.region",
      warehouseId: "sidebar.warehouse",
      customizable: "spares.customizable"
    },
    digital: {
      name: "dashboard.name",
      make: "equipment.make",
      model: "vehicle.model",
      serialNumber: "vehicle.serial_no",
      group: "equipment.group",
      assetCode: "equipment.asset_code",
      regionId: "sidebar.region",
      supplier: "equipment.supplier",
      description: "company.description",
      yearPurchase: "availability.year_purchase",
      photo: "availability.photo",
      providersId: "electronicbill.provider_name"
    },
    inhouse: {
      firstName: "users.first_name",
      lastName: "users.last_name",
      inHouseNumber: "inhousepersonnel.idNumber",
      gender: "users.gender",
      address: "dashboard.address",
      country: "inhousepersonnel.country",
      city: "dashboard.city",
      pin_code: "inhousepersonnel.pincode",
      hourlyRate: "inhousepersonnel.hourly_rate",
      primaryTelephone: "inhousepersonnel.primary_telephone",
      workDays: "servicecontrol.work_days",
      daysOff: "servicecontrol.days_off",
      costcenterId: "sidebar.costcenter",
      roleId: "users.role",
      equipmentId: "sidebar.equipment",
      email: "users.email",
      warehouseId: "sidebar.mainwarehhouse",
      regionId: "sidebar.mainregion",
      otherWarehouseId: "sidebar.otherwarehouse",
      otherRegionId: "sidebar.otherregion"
    },
    providers: {
      name: "dashboard.name",
      type: "reports.type",
      address: "dashboard.address",
      city: "dashboard.city",
      website: "contractors.website",
      phone: "riskmanagement.telephone",
      condition: "equipment.condition",
      email: "users.email",
      mobile: "client.mobile",
      id_no: "inhousepersonnel.idNumber",
      discount: "spares.discount_percentage",
      contactName: "contractors.contactName",
      accountNumber: "contractors.accountNo",
      bankName: "contractors.bankName",
      status: "actions.state",
      accountType: "reports.accounttype",
      password: "login.Password",
      providerNumber: "electronicbill.provider_id",
      days: "equipment.days",
      fax: "client.fax"
    },
    client: {
      clientNumber: "inhousepersonnel.idNumber",
      name: "dashboard.name",
      address: "dashboard.address",
      phone: "riskmanagement.telephone",
      city: "dashboard.city",
      mobile: "client.mobile",
      contactPerson: "client.contact_person",
      website: "contractors.website",
      costCenterId: "sidebar.costcenter",
      email: "users.email",
      status: "actions.state",
      type: "reports.type",
      regionId: "sidebar.region",
      photo: "sidebar.choose_image",
      password: "login.Password"
    },
    region: {
      startDate: "regions.startdate",
      name: "dashboard.name",
      address: "dashboard.address",
      city: "dashboard.city",
      pinCode: "inhousepersonnel.pincode",
      country: "inhousepersonnel.country",
      type: "reports.type",
      role: "users.Manager"
    },
    groups: {
      name: "dashboard.name",
      device_type: "sidebar.equipment",
      toolId: "sidebar.tools",
      digitalId: "sidebar.digital",
      description: "company.description"
    },
    costcenter: {
      code: "spares.code",
      name: "dashboard.name"
    },
    warehouse: {
      code: "spares.code",
      name: "dashboard.name",
      regionId: "sidebar.region"
    },
    service: {
      code: "spares.code",
      name: "dashboard.name",
      rate: "servicecontrol.rate_km",
      type: "servicecontrol.type_service",
      regionId: "sidebar.region",
      work_days: "servicecontrol.work_days",
      days_off: "servicecontrol.days_off"
    },
    servicecontrol: {
      service: "sidebar.service",
      region: "sidebar.region",
      vehicle_type: "servicecontrol.vehicle_type",
      team: "sidebar.team",
      destination_service: "servicecontrol.destination_service",
      accidents: "servicecontrol.accidents",
      service_date: "servicecontrol.service_date",
      starttime_date: "servicecontrol.start_time",
      endtime_date: "servicecontrol.end_time",
      equipment: "sidebar.equipment",
      km_traveled: "servicecontrol.km_traveled",
      observation: "sidebar.observation",
      seats_occupied: "servicecontrol.seats_occupied",
      seats_available: "servicecontrol.seats_available",
      time_tours: "servicecontrol.time_tours",
      initial_km: "servicecontrol.initial_km",
      km_final: "servicecontrol.km_final",
      driver: "vehicle.driver",
      work_days: "servicecontrol.work_days",
      days_off: "servicecontrol.days_off",
      id_no: "servicecontrol.driver_id",
      vechicle_status: "availability.availability",
      client: "workorder.client_name"
    },
    servicesettings: {
      budget: "servicecontrol.budget",
      date: "servicecontrol.month_year",
      regionId: "sidebar.region"
    },
    maintenancetype: {
      name: "dashboard.name"
    },
    maintenancegroup: {
      name: "dashboard.name",
      maintenanceTypeId: "sidebar.maintenancetype"
    },
    maintenanceplan: {},
    alerts: {},
    configuredAlerts: {
      equipmentsIds: "alert.equipmentsIds",
      rolesIds: "alert.rolesIds"
    }
  };

  return keyList;
};

export const getModuleName = document => {
  let modulename = getmoduleList().find(item => item.document === document);
  return modulename ? modulename.value : "";
};
export const getModuleType = (name, type) => {
  let modulename = getmoduleList().find(
    item => item.value === name && item[type]
  );
  return modulename ? modulename.value : "";
};

export const getmoduleList = () => {
  let moduleList = [
    {value: "dashboard", label: "sidebar.dashboard", menu: "sidebar.dashboard"},
    {
      route: "tracking",
      value: "godview",
      label: "sidebar.godview",
      menu: "sidebar.tracking",
      region: false
    },
    {
      route: "tracking",
      value: "livetracking",
      label: "sidebar.livetracking",
      menu: "sidebar.tracking",
      region: false
    },
    {
      route: "tracking",
      value: "trackhistory",
      label: "sidebar.trackhistory",
      menu: "sidebar.tracking",
      region: false
    },
    {
      route: "tracking",
      value: "alerts",
      label: "sidebar.alerts",
      menu: "sidebar.tracking",
      region: false
    },
    {
      route: "tracking",
      value: "configuredAlerts",
      label: "sidebar.alerts",
      menu: "sidebar.tracking",
      region: false
    },
    {
      route: "tracking",
      value: "aoi",
      label: "sidebar.aoi",
      menu: "sidebar.tracking",
      region: false
    },
    {
      route: "assets",
      value: "equipment",
      label: "sidebar.equipment",
      menu: "sidebar.assets",
      region: true
    },
    {
      route: "assets",
      value: "tools",
      label: "sidebar.tools",
      menu: "sidebar.assets",
      region: true
    },
    {
      route: "assets",
      value: "sparessupplies",
      label: "sidebar.sparessupplies",
      menu: "sidebar.assets",
      region: true
    },
    {
      route: "assets",
      value: "existencecard",
      label: "sidebar.existencecard",
      menu: "sidebar.assets",
      region: false,
      warehouse: true
    },
    {
      route: "assets",
      value: "inventory",
      label: "sidebar.inventory",
      menu: "sidebar.assets",
      region: false,
      warehouse: false
    },
    {
      route: "assets",
      value: "warehouselist",
      label: "sidebar.warehouselist",
      menu: "sidebar.assets",
      region: false,
      warehouse: false
    },
    {
      route: "assets",
      value: "digital",
      label: "sidebar.digital",
      menu: "sidebar.assets",
      region: true
    },
    {
      route: "team",
      value: "inhouse",
      label: "sidebar.inhouse",
      menu: "sidebar.team",
      region: true
    },
    {
      route: "team",
      value: "providers",
      label: "sidebar.providers",
      menu: "sidebar.team",
      region: false
    },
    {
      route: "team",
      value: "client",
      label: "sidebar.client",
      menu: "sidebar.team",
      region: true
    },
    {
      route: "settings",
      value: "region",
      label: "sidebar.region",
      menu: "sidebar.settings",
      region: true
    },
    {
      route: "settings",
      value: "groups",
      label: "sidebar.groups",
      menu: "sidebar.settings",
      region: false
    },
    {
      route: "settings",
      value: "costcenter",
      label: "sidebar.costcenter",
      menu: "sidebar.settings",
      region: false
    },
    {
      route: "settings",
      value: "commands",
      label: "sidebar.commands",
      menu: "sidebar.settings",
      region: false
    },
    {
      route: "settings",
      value: "createoption",
      label: "sidebar.createoption",
      menu: "sidebar.settings",
      region: false
    },
    {
      route: "settings",
      value: "warehouse",
      label: "sidebar.warehouse",
      menu: "sidebar.settings",
      region: true
    },
    {
      value: "profile",
      label: "sidebar.profile",
      menu: "sidebar.profile",
      region: false
    },
    {
      route: "roles",
      value: "modules",
      label: "sidebar.modules",
      menu: "sidebar.roles_permit",
      region: false
    },
    {
      route: "roles",
      value: "rolespermissions",
      label: "sidebar.rolespermissions",
      menu: "sidebar.roles_permit",
      region: false
    },
    {
      route: "warehouseadmission",
      value: "purchaseorder",
      label: "sidebar.purchaseorder",
      menu: "sidebar.warehouseadmission",
      document: "PO",
      region: true
    },
    {
      route: "warehouseadmission",
      value: "electronicinvoice",
      label: "sidebar.electronicinvoice",
      menu: "sidebar.warehouseadmission",
      document: "EI",
      region: true,
      warehouse: true
    },
    {
      route: "warehouseadmission",
      value: "supplierofficeguide",
      label: "sidebar.supplierofficeguide",
      menu: "sidebar.warehouseadmission",
      document: "PDG",
      region: true,
      warehouse: true
    },
    {
      route: "warehouseadmission",
      value: "professionalfees",
      label: "sidebar.professionalfees",
      menu: "sidebar.warehouseadmission",
      document: "PFB",
      region: true,
      warehouse: true
    },
    {
      route: "warehouseadmission",
      value: "creditnote",
      label: "sidebar.creditnote",
      menu: "sidebar.warehouseadmission",
      document: "CN",
      region: true,
      warehouse: true
    },
    {
      route: "warehouseadmission",
      value: "debitnote",
      label: "sidebar.debitnote",
      menu: "sidebar.warehouseadmission",
      document: "DN",
      region: true,
      warehouse: true
    },
    {
      route: "warehouseadmission",
      value: "admissiontransferdispatch",
      label: "sidebar.transferdispatchguide_wa",
      menu: "sidebar.warehouseadmission",
      document: "TDG(A)",
      region: false,
      warehouse: true
    },
    {
      route: "warehouseadmission",
      value: "admissioninternalguide",
      label: "sidebar.internal_guide_wa",
      menu: "sidebar.warehouseadmission",
      document: "IG(A)",
      region: false,
      warehouse: true
    },
    {
      route: "warehouseadmission",
      value: "adjustment",
      label: "sidebar.adjustment",
      menu: "sidebar.warehouseadmission",
      document: "ADJ",
      region: false,
      warehouse: true
    },
    {
      route: "warehousedepartures",
      value: "workorders",
      label: "sidebar.workorders",
      menu: "sidebar.workorders",
      document: "WO(WO)",
      region: true,
      warehouse: true
    },
    {
      route: "warehousedepartures",
      value: "kanbanworkordersdetails",
      label: "sidebar.workorders",
      menu: "sidebar.workorders",
      document: "WO(WO)",
      region: true,
      warehouse: true
    },
    {
      route: "warehousedepartures",
      value: "kanbanworkorders",
      label: "sidebar.workorders",
      menu: "sidebar.workorders",
      document: "WO(WO)",
      region: true,
      warehouse: true
    },
    {
      route: "warehousedepartures",
      value: "depaturetransferdispatch",
      label: "sidebar.transferdispatchguide_wd",
      menu: "sidebar.warehousedepartures",
      document: "TDG(D)",
      region: false,
      warehouse: true
    },
    {
      route: "warehousedepartures",
      value: "depatureinternalguide",
      label: "sidebar.internal_guide_wd",
      menu: "sidebar.warehousedepartures",
      document: "IG(D)",
      region: false,
      warehouse: true
    },
    {
      route: "warehousedepartures",
      value: "wastage",
      label: "sidebar.wastage",
      menu: "sidebar.warehousedepartures",
      document: "WS",
      region: false,
      wastage: true,
      warehouse: true
    },
    {
      value: "approval",
      label: "sidebar.approve",
      menu: "sidebar.approve",
      region: true
    },
    {
      route: "intelligen",
      value: "riskmanagement",
      label: "sidebar.riskmanagement",
      menu: "sidebar.intelligen",
      region: false
    },
    {
      route: "intelligen",
      value: "vehicleperformance",
      label: "sidebar.vehicleperformance",
      menu: "sidebar.intelligen",
      region: false
    },
    {
      route: "intelligen",
      value: "driverevaluation",
      label: "sidebar.driverevaluation",
      menu: "sidebar.intelligen",
      region: false
    },
    {
      route: "maintenance",
      value: "maintenanceform",
      label: "sidebar.guidelines",
      menu: "sidebar.maintenance",
      region: false
    },
    {
      route: "maintenance",
      value: "resourcegroup",
      label: "sidebar.resourcegroup",
      menu: "sidebar.maintenance",
      region: false
    },
    {
      route: "maintenance",
      value: "maintenanceplan",
      label: "sidebar.maintenanceplan",
      menu: "sidebar.maintenance",
      region: false
    },
    {
      route: "maintenance",
      value: "maintenancegroup",
      label: "sidebar.maintenancegroup",
      menu: "sidebar.maintenance",
      region: false
    },
    {
      route: "maintenance",
      value: "maintenancetype",
      label: "sidebar.maintenancetype",
      menu: "sidebar.maintenance",
      region: false
    },
    // {
    //   value: "vehicleperformancereport",
    //   label: "sidebar.vehicleperformancereport",
    //   menu: "sidebar.businessintelligence",
    //   region: false
    // },
    {
      value: "routereport",
      label: "sidebar.routereport",
      menu: "sidebar.businessintelligence",
      region: false
    },
    {
      value: "costingreport",
      label: "sidebar.costingreport",
      menu: "sidebar.businessintelligence",
      region: false
    },
    {
      value: "usagereport",
      label: "sidebar.usagereport",
      menu: "sidebar.businessintelligence",
      region: false
    },
    {
      value: "maintenancereport",
      label: "sidebar.maintenancereport",
      menu: "sidebar.businessintelligence",
      region: false
    },
    {
      value: "insurancereport",
      label: "sidebar.insurancereport",
      menu: "sidebar.businessintelligence",
      region: false
    },
    {
      value: "detailedreport",
      label: "sidebar.detailedreport",
      menu: "sidebar.businessintelligence",
      region: false
    },
    {
      value: "purchasereport",
      label: "sidebar.purchasereport",
      menu: "sidebar.businessintelligence",
      region: false
    },
    {
      value: "workorderreport",
      label: "sidebar.workorderreport",
      menu: "sidebar.businessintelligence",
      region: false
    },
    {
      value: "company",
      label: "sidebar.mycompany",
      menu: "sidebar.company",
      region: false
    },
    {
      route: "service",
      value: "service",
      label: "sidebar.service",
      menu: "sidebar.service",
      region: true
    },
    {
      route: "service",
      value: "servicecontrol",
      label: "sidebar.servicecontrol",
      menu: "sidebar.service",
      region: true
    },
    {
      route: "service",
      value: "servicedashboard",
      label: "sidebar.servicedashboard",
      menu: "sidebar.service",
      region: false
    },
    {
      route: "service",
      value: "servicesettings",
      label: "sidebar.servicesettings",
      menu: "sidebar.service",
      region: true
    }
  ];
  return moduleList;
};

export const getOperationList = () => {
  let operationsList = [
    {value: "add", label: <IntlMessages id="sidebar.add" />},
    {value: "edit", label: <IntlMessages id="sidebar.edit" />},
    {value: "remove", label: <IntlMessages id="sidebar.remove" />},
    // { value: "save", label: <IntlMessages id="sidebar.save" /> },
    // { value: "update", label: <IntlMessages id="sidebar.update" /> },
    {value: "view", label: <IntlMessages id="sidebar.view" />},
    {value: "download", label: <IntlMessages id="sidebar.download" />},
    {value: "regionlimit", label: <IntlMessages id="sidebar.region_limit" />},
    {value: "maintenance", label: <IntlMessages id="sidebar.maintenance" />},
    {
      value: "sparepartsrequest",
      label: <IntlMessages id="sidebar.spareparts_request" />
    },
    {
      value: "warehousedepartures",
      label: <IntlMessages id="sidebar.warehousedepartures" />
    },
    {value: "closewo", label: <IntlMessages id="sidebar.close_WO" />},
    {value: "devolution", label: <IntlMessages id="sidebar.devolution" />}
  ];
  return operationsList;
};
export const getHomePageOptions = () => {
  let homepageList = [
    {value: "Dashboard", label: <IntlMessages id="sidebar.dashboard" />},
    {
      value: "Servicedashboard",
      label: <IntlMessages id="servicecontrol.servicedashboard" />
    },
    {
      value: "Companydashboard",
      label: <IntlMessages id="servicecontrol.companydashboard" />
    }
  ];

  return homepageList;
};

export const getOptions = () => {
  let options = [
    {value: "time_zone", label: "Time Zone"},
    // { value: 'provider_type', label: 'Provider Type' },
    // { value: 'equipment_type', label: 'Type of Equipment' },
    {value: "availablty", label: "Tools Availablity"},
    {value: "maintenance_type", label: "Type of Maintenance"},
    {value: "asset_maintenance_type", label: "Type of Asset Maintenance"},
    {value: "cycle_maintenance", label: "Cycle of Maintenance"},
    {value: "alerts_maintenance", label: "Alerts in Maintenance"},
    // { value: 'purchase_request_type', label: 'Purchase Request Type' },
    {value: "region_type", label: "Type of Regions"},
    {value: "department_riskmanagement", label: "Department of Riskmanagement"},
    {value: "category_riskmanagement", label: "Category of Riskmanagement"},
    {
      value: "classificaiton_riskmanagement",
      label: "Classificaiton of Riskmanagement"
    },
    {value: "status_riskmanagement", label: "Status of Riskmanagement"},
    {value: "supplies_type", label: "Supplies Type"},
    {value: "supplies_subtype", label: "Supplies SubType"},
    {value: "category_supplies", label: "Category of Supplies"},
    {value: "subcategory_supplies", label: "SubCategory of Supplies"},
    {value: "work_type", label: "Work Type"}
  ];

  return options;
};

export const getavailability = () => {
  let availabilityOption = [
    {value: "available", label: <IntlMessages id="availability.available" />},
    {value: "rented", label: <IntlMessages id="availability.rented" />},
    {
      value: "out_of_service",
      label: <IntlMessages id="availability.out_of_service" />
    },
    {
      value: "not_available",
      label: <IntlMessages id="availability.not_available" />
    }
  ];
  return availabilityOption;
};

export const getGenderOptions = () => {
  const genderOptions = [
    {value: "male", label: <IntlMessages id="util.male" />},
    {value: "female", label: <IntlMessages id="util.female" />}
  ];

  return genderOptions;
};

export const getPercentCalculation = (a, b) => {
  var c = parseFloat(a) * parseFloat(b) / 100;
  return parseFloat(c).toFixed(2);
};

export const getTaxCalculation = (selectTax, total) => {
  let totalTax = 0;
  if (selectTax && (selectTax.value == "19%" || selectTax == "19%")) {
    totalTax = Number(getPercentCalculation(total, 19));
  } else if (
    selectTax &&
    (selectTax.value == "11.75%" || selectTax == "11.75%")
  ) {
    totalTax = Number(getPercentCalculation(total, 11.75));
  } else {
    totalTax = 0;
  }
  return totalTax;
};

export const getSinglePurchaseCalculation = (
  item,
  totalDiscount,
  selectTax,
  unit
) => {
  item.temp_unit_price = Number(item.unit_price);

  let total = Number(unit) * Number(item.unit_price);
  let unitDiscountPrice = getPercentCalculation(Number(total), item.discount);
  item.sub_total = total - Math.round(Number(unitDiscountPrice));

  let totalUnitDiscountPrice = getPercentCalculation(
    Number(item.sub_total),
    totalDiscount
  );
  total = item.sub_total - Math.round(Number(totalUnitDiscountPrice));

  item.total = total + Number(getTaxCalculation(selectTax, total));
  item.total = Math.round(item.total);
  return item;
};

export const getPurchaseCalculation = (items, totalDiscount, selectTax) => {
  for (let data in items) {
    items[data].item = Number(data) + 1;
    items[data].key = Number(data) + 1;

    items[data].temp_unit_price = Number(items[data].unit_price);

    let total = Number(items[data].unit) * Number(items[data].unit_price);
    let unitDiscountPrice = Math.round(
      getPercentCalculation(Number(total), items[data].discount)
    );

    items[data].sub_total = Number(items[data].sub_total).toFixed(2);
    items[data].discount_price = Number(unitDiscountPrice);
    items[data].sub_total = total - Number(unitDiscountPrice).toFixed(2);

    let totalUnitDiscountPrice = getPercentCalculation(
      Number(items[data].sub_total),
      totalDiscount
    );
    totalUnitDiscountPrice = Math.round(totalUnitDiscountPrice);
    total = items[data].sub_total - Number(totalUnitDiscountPrice);
    items[data].discount_sub_total = Number(total.toFixed(2));

    items[data].total =
      total + Number(getTaxCalculation(selectTax, items[data].sub_total));

    items[data].total = Math.round(items[data].total);
  }
  return items;
};

export const getNumberFormat = (number, Iscurrency) => {
  if (
    number &&
    number !== Infinity &&
    number !== "Infinity" &&
    !isNaN(number)
  ) {
    number = Math.round(number);
    number = new Number(number);
  } else {
    number = new Number(0);
  }
  if (Iscurrency == undefined || Iscurrency == true) {
    return currenySymbol + number.toLocaleString(languageCode);
  } else {
    return number.toLocaleString(languageCode);
  }
};

export const getConditionType = filter => {
  let conditiontypeList = [
    {value: "credit", label: <IntlMessages id="equipment.credit" />},
    {value: "debit", label: <IntlMessages id="equipment.debit" />}
  ];
  if (filter == true) {
    conditiontypeList = [
      {value: -1, label: <IntlMessages id="table.all" />}
    ].concat(conditiontypeList);
  }
  return conditiontypeList;
};

export const getStatusType = filter => {
  let statustypeList = [
    {value: "active", label: <IntlMessages id="status.active" />},
    {value: "not_active", label: <IntlMessages id="status.not_active" />}
  ];

  if (filter == true) {
    statustypeList = [
      {value: -1, label: <IntlMessages id="table.all" />}
    ].concat(statustypeList);
  }
  return statustypeList;
};

export const getServiceType = filter => {
  let servicetypeList = [
    {value: "yes", label: <IntlMessages id="actions.yes" />},
    {value: "no", label: <IntlMessages id="actions.no" />}
  ];
  if (filter == true) {
    servicetypeList = [
      {value: -1, label: <IntlMessages id="table.all" />}
    ].concat(servicetypeList);
  }
  return servicetypeList;
};

export const getServiceDocumentType = filter => {
  let serviceDocumentList = [
    {label: <IntlMessages id="spares.EI" />, value: "EI"},
    {label: <IntlMessages id="spares.PDG" />, value: "PDG"},
    {label: <IntlMessages id="spares.PFB" />, value: "PFB"}
  ];

  return serviceDocumentList;
};

export const getEquipmentType = filter => {
  let equipmentTypeList = [
    {value: "bus", label: <IntlMessages id="equipment.bus" />},
    {
      value: "non_mechanical",
      label: <IntlMessages id="equipment.non_mechanical" />
    },
    {value: "sprinter", label: <IntlMessages id="equipment.sprinter" />},
    {value: "taxi_buses", label: <IntlMessages id="equipment.taxi_buses" />},
    {value: "tractor", label: <IntlMessages id="equipment.tractor" />},
    {value: "truck", label: <IntlMessages id="equipment.truck" />},
    {value: "nebulizador", label: <IntlMessages id="equipment.nebulizador" />},
    {value: "cpb", label: <IntlMessages id="equipment.cpb" />},
    {value: "carro", label: <IntlMessages id="equipment.carro" />}
  ];

  if (filter == true) {
    equipmentTypeList = [
      {value: -1, label: <IntlMessages id="table.all" />}
    ].concat(equipmentTypeList);
  }

  return equipmentTypeList;
};

export const getAccountOption = () => {
  let accountOption = [
    {
      value: "current_account",
      label: <IntlMessages id="reports.current_account" />
    },
    {
      value: "vista_account",
      label: <IntlMessages id="reports.vista_account" />
    },
    {value: "rut_account", label: <IntlMessages id="reports.rut_account" />}
  ];
  return accountOption;
};

export const getMaintenanceOptions = () => {
  let maintenanceOptions = [
    {value: "corrective", label: <IntlMessages id="maintenance.corrective" />},
    {value: "preventive", label: <IntlMessages id="maintenance.preventive" />},
    {value: "scheduled", label: <IntlMessages id="maintenance.scheduled" />}
  ];
  return maintenanceOptions;
};

export const getRecurringOptions = () => {
  let recurringOptions = [
    {value: "yes", label: <IntlMessages id="actions.yes" />},
    {value: "no", label: <IntlMessages id="actions.no" />}
  ];
  return recurringOptions;
};

export const getPurchaseOrderType = () => {
  let number = [
    {
      value: "purchase_order",
      label: <IntlMessages id="sidebar.purchaseorder" />
    },
    {
      value: "supplier_guide",
      label: <IntlMessages id="sidebar.supplierofficeguide" />
    }
  ];
  return number;
};

export const getMarkItems = (items, rows) => {
  for (let item in items) {
    items[item].marked = false;
  }
  for (let item in rows) {
    items[rows[item]].marked =
      items[rows[item]].marked == undefined ? true : !items[rows[item]].marked;
  }
  return items;
};

export const getRowsSelected = items => {
  let indexArray = [];
  console.log("items====", items);
  for (let item in items) {
    indexArray.push(Number(item));
  }
  console.log("indexArray===", indexArray);
  return indexArray;
};

export const getTwoDigit = monthOrDate => {
  return monthOrDate < 10 ? "0" + monthOrDate : "" + monthOrDate;
};
export const getSixDigit = value => {
  var digit = "" + value;
  while (digit.length < 6) {
    digit = "0" + digit;
  }

  return digit;
};

export const getTaxOptions = () => {
  const taxOptions = [
    {value: "19%", label: "19% IVA"},
    {value: "11.75%", label: "11.75% Retencion"},
    {value: "ExtentoIVA", label: "Excento de IVA"}
  ];
  return taxOptions;
};

export const getTotalInvoice = (items, rows) => {
  let totalInvoice = 0;
  for (let data in items) {
    if (rows.indexOf(Number(data)) != -1) {
      totalInvoice = totalInvoice + Number(items[data].total);
    }
  }

  return totalInvoice;
};

export const getSparesCategory = () => {
  let sparesCategoryList = [
    {value: "ACCESORIOS", label: "ACCESORIOS"},
    {value: "INSUMOS TALLER", label: "INSUMOS TALLER"},
    {value: "NO INVENTARIABLES", label: "NO INVENTARIABLES"},
    {value: "EQUIPOS", label: "EQUIPOS"},
    {value: "HERRAMIENTAS", label: "HERRAMIENTAS"},
    {value: "RPTO. ALTERNATIVO", label: "RPTO. ALTERNATIVO  "},
    {value: "CU", label: "CU"},
    {value: "REPUESTO ORIGINAL", label: "REPUESTO ORIGINAL"},
    {value: "EQUIPO COMPUTACIONAL", label: "EQUIPO COMPUTACIONAL"},
    {value: "INSTALACIONES", label: "INSTALACIONES"},
    {value: "MUEBLES Y UTILES", label: "MUEBLES Y UTILES"},
    {value: "SIN INFORMACION", label: "SIN INFORMACION"},
    {value: "STOCK BODEGA", label: "STOCK BODEGA"},
    {value: "REPUESTOS DESARMADURIA", label: "REPUESTOS DESARMADURIA"},
    {value: "REPUESTO USADO", label: "REPUESTO USADO"},
    {value: "ACCESORIOS USADOS", label: "ACCESORIOS USADOS"},
    {value: "EQUIPO PERSONAL", label: "EQUIPO PERSONAL"},
    {value: "NEUMATICOS", label: "NEUMATICOS"},
    {value: "MAQUINARIAS", label: "MAQUINARIAS"},
    {value: "SERVICIOS", label: "SERVICIOS"},
    {value: "RPTO.ALTERNATIVO", label: "RPTO.ALTERNATIVO"},
    {value: "LUBRICANTES", label: "LUBRICANTES"},
    {value: "LLANTAS Y TAPAS NUEVAS", label: "LLANTAS Y TAPAS NUEVAS"},
    {value: "REPUESTO OBSOLETO", label: "REPUESTO OBSOLETO"}
  ];

  return sparesCategoryList;
};

export const getSparesSubcategory = () => {
  let sparesSubcategoryList = [
    {value: "IMPL. BUSES", label: "IMPL. BUSES"},
    {value: "UNIVERSAL", label: "UNIVERSAL"},
    {value: "INSUMOS TALLER", label: "INSUMOS TALLER"},
    {value: "IMPL. TALLER", label: "IMPL. TALLER"},
    {value: "OTRAS MARCAS", label: "OTRAS MARCAS"},
    {value: "ACCESORIOS", label: "ACCESORIOS"},
    {value: "IMPL. AUTOMOTRIZ", label: "IMPL. AUTOMOTRIZ"},
    {value: "VOLKSWAGEN", label: "VOLKSWAGEN"},
    {value: "OTROS", label: "OTROS"},
    {value: "EPP", label: "EPP"},
    {value: "MERCEDES BENZ", label: "MERCEDES BENZ"},
    {value: "ADMINISTRACION - OFICINA", label: "ADMINISTRACION - OFICINA"},
    {
      value: "ARTICULOS Y EQUIPOS DE OFICINA",
      label: "ARTICULOS Y EQUIPOS DE OFICINA"
    },
    {value: "CONTROL DE UNIDADES", label: "CONTROL DE UNIDADES"},
    {value: "EPYSA", label: "EPYSA"},
    {value: "REPUESTO USADO", label: "REPUESTO USADO"},
    {value: "LUBRICANTE ENVASADO", label: "LUBRICANTE ENVASADO"},
    {value: "FIRESTONE", label: "FIRESTONE"},
    {value: "HERRAMIENTAS", label: "HERRAMIENTAS"},
    {value: "HERRAMIENTA", label: "HERRAMIENTA"},
    {value: "REPUESTOS", label: "REPUESTOS"},
    {value: "INSUMOS", label: "INSUMOS"},
    {value: "ASEO - MANTENCION", label: "ASEO - MANTENCION"},
    {value: "SERVICIOS", label: "SERVICIOS"},
    {value: "PEUGEOT", label: "PEUGEOT"},
    {value: "CATERPILLAR", label: "CATERPILLAR"},
    {value: "LINDE", label: "LINDE"},
    {value: "MARCO POLO", label: "MARCO POLO"},
    {value: "TOYOTA", label: "TOYOTA"},
    {value: "DODGE", label: "DODGE"},
    {value: "MITSUBISHI", label: "MITSUBISHI"},
    {value: "COOPER", label: "COOPER"},
    {value: "RPTO. ALTERNATIVO", label: "RPTO. ALTERNATIVO"},
    {value: "GOODYEAR", label: "GOODYEAR"},
    {value: "BRIDGESTONE", label: "BRIDGESTONE"},
    {value: "BOSCH", label: "BOSCH"}
  ];

  return sparesSubcategoryList;
};

export const getSparesClass = () => {
  let sparesClassList = [
    {value: "ACCESORIOS", label: "ACCESORIOS"},
    {value: "IMPL. BUSES", label: "IMPL. BUSES"},
    {value: "UNIVERSAL", label: "UNIVERSAL"},
    {value: "INSUMOS TALLER", label: "INSUMOS TALLER"},
    {value: "IMPL. TALLER", label: "IMPL. TALLER"},
    {value: "OTRAS MARCAS", label: "OTRAS MARCAS"},
    {value: "IMPL. AUTOMOTRIZ", label: "IMPL. AUTOMOTRIZ"},
    {value: "VOLKSWAGEN", label: "VOLKSWAGEN"},
    {value: "OTROS", label: "OTROS"},
    {value: "EPP", label: "EPP"},
    {value: "MERCEDES BENZ", label: "MERCEDES BENZ"},
    {value: "ADMINISTRACION - OFICINA", label: "ADMINISTRACION - OFICINA"},
    {
      value: "ARTICULOS Y EQUIPOS DE OFICINA",
      label: "ARTICULOS Y EQUIPOS DE OFICINA"
    },
    {value: "CONTROL DE UNIDADES", label: "CONTROL DE UNIDADES"},
    {value: "EPYSA", label: "EPYSA"},
    {value: "REPUESTO USADO", label: "REPUESTO USADO"},
    {value: "LUBRICANTE ENVASADO", label: "LUBRICANTE ENVASADO"},
    {value: "FIRESTONE", label: "FIRESTONE"},
    {value: "HERRAMIENTAS", label: "HERRAMIENTAS"},
    {value: "HERRAMIENTA", label: "HERRAMIENTA"},
    {value: "REPUESTOS", label: "REPUESTOS"},
    {value: "INSUMOS", label: "INSUMOS"},
    {value: "ASEO - MANTENCION", label: "ASEO - MANTENCION"},
    {value: "SERVICIOS", label: "SERVICIOS"},
    {value: "PEUGEOT", label: "PEUGEOT"},
    {value: "CATERPILLAR", label: "CATERPILLAR"},
    {value: "LINDE", label: "LINDE"},
    {value: "MARCO POLO", label: "MARCO POLO"},
    {value: "TOYOTA", label: "TOYOTA"},
    {value: "DODGE", label: "DODGE"},
    {value: "MITSUBISHI", label: "MITSUBISHI"},
    {value: "COOPER", label: "COOPER"},
    {value: "RPTO. ALTERNATIVO", label: "RPTO. ALTERNATIVO"},
    {value: "GOODYEAR", label: "GOODYEAR"},
    {value: "BRIDGESTONE", label: "BRIDGESTONE"},
    {value: "BOSCH", label: "BOSCH"}
  ];

  return sparesClassList;
};

export const getSparesFamily = () => {
  let sparesFamilyList = [
    {value: "ACCESORIOS", label: "ACCESORIOS"},
    {value: "INSUMOS TALLER", label: "INSUMOS TALLER"},
    {value: "NO INVENTARIABLES", label: "NO INVENTARIABLES"},
    {value: "EQUIPOS", label: "EQUIPOS"},
    {value: "HERRAMIENTAS", label: "HERRAMIENTAS"},
    {value: "RPTO. ALTERNATIVO", label: "RPTO. ALTERNATIVO"},
    {value: "CU", label: "CU"},
    {value: "REPUESTO ORIGINAL", label: "REPUESTO ORIGINAL"},
    {value: "EQUIPO COMPUTACIONAL", label: "EQUIPO COMPUTACIONAL"},
    {value: "INSTALACIONES", label: "INSTALACIONES"},
    {value: "MUEBLES Y UTILES", label: "MUEBLES Y UTILES"},
    {value: "SIN INFORMACION", label: "SIN INFORMACION"},
    {value: "STOCK BODEGA", label: "STOCK BODEGA"},
    {value: "REPUESTOS DESARMADURIA", label: "REPUESTOS DESARMADURIA"},
    {value: "REPUESTO USADO", label: "REPUESTO USADO"},
    {value: "ACCESORIOS USADOS", label: "ACCESORIOS USADOS"},
    {value: "EQUIPO PERSONAL", label: "EQUIPO PERSONAL"},
    {value: "NEUMATICOS", label: "NEUMATICOS"},
    {value: "MAQUINARIAS", label: "MAQUINARIAS"},
    {value: "SERVICIOS", label: "SERVICIOS"},
    {value: "RPTO.ALTERNATIVO", label: "RPTO.ALTERNATIVO"},
    {value: "LUBRICANTES", label: "LUBRICANTES"},
    {value: "LLANTAS Y TAPAS NUEVAS", label: "LLANTAS Y TAPAS NUEVAS"},
    {value: "REPUESTO OBSOLETO", label: "REPUESTO OBSOLETO"}
  ];

  return sparesFamilyList;
};

export const getUser = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user;
};

export const getUserId = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    return user.id;
  }
  return null;
};

export const getRoleName = () => {
  let role = "";
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.role) {
    role = user.role.value;
  }
  return role;
};

export const getSuperAdmin = () => {
  let role = "";
  let superAdmin = false;
  let user = JSON.parse(localStorage.getItem("user"));
  if (user.role) {
    role = user.role.value;
  }
  if (role == "super_admin") {
    superAdmin = true;
  }
  return superAdmin;
};

export const getRoleId = () => {
  let role = "";
  if (localStorage.getItem("user")) {
    let user = JSON.parse(localStorage.getItem("user"));
    return user.roleId;
  } else {
    return "";
  }
};

export const getRolesPermission = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  return user && user.rolespermissions ? user.rolespermissions : {};
};

export const getCompanyId = () => {
  let companyId = null;
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.role && user.role.value != "super_admin") {
    companyId = user.companyId;
  }
  return companyId;
};

export const getSelectCompanyId = () => {
  let companyId = null;
  if (getSuperAdmin()) {
    let user = JSON.parse(localStorage.getItem("select_company"));
    if (user) {
      companyId = user.id;
    }
  } else {
    companyId = getCompany() ? getCompany().id : null;
  }
  return companyId;
};

export const assignCompany = company => {
  localStorage.setItem("select_company", JSON.stringify(company));
};

export const getCompany = () => {
  let companyId = null;
  let user = JSON.parse(localStorage.getItem("user"));
  if (user.role && user.role.value != "super_admin") {
    companyId = {
      ...user.company,
      label: user.company.name,
      value: user.company.id
    };
  }
  return companyId;
};

export const findConductorRoleId = async () => {
  let apiPath = "role?page=0&limit=999&search_value=driver";
  let {status, data} = await api.getApi(apiPath);
  if (status && status === "success" && data && data.count >= 1) {
    localStorage.setItem("conductorRoleId", data.rows[0].id.toString());
  }
};

export const getConductorRoleId = () => {
  let conductorRoleId;
  let data = localStorage.getItem("conductorRoleId");
  if (data) {
    conductorRoleId = parseInt(data);
  }

  return conductorRoleId;
};

export const getFiltersTracking = () => {
  return [
    {value: "fuel", label: <IntlMessages id="trackhistory.fuel" />},
    {
      value: "speed",
      label: <IntlMessages id="trackhistory.speed" />
    },
    {
      value: "alerts",
      label: <IntlMessages id="trackhistory.alerts" />
    }
  ];
};

export const getUnitTimes = () => {
  return [
    {value: "s", label: <IntlMessages id="livetracking.seconds" />},
    {
      value: "m",
      label: <IntlMessages id="livetracking.minutes" />
    },
    {
      value: "h",
      label: <IntlMessages id="livetracking.hours" />
    }
  ];
};

export const getTimes = unit => {
  if (unit) {
    switch (unit.value) {
      case "s":
        let seconds = [];
        for (let i = 30; i < 610; i += 10) {
          seconds.push({value: i.toString(), label: i.toString()});
        }
        return seconds;
      case "m":
        let minutes = [];
        for (let i = 1; i < 121; i++) {
          minutes.push({value: i.toString(), label: i.toString()});
        }
        return minutes;
      case "h":
        let hours = [];
        for (let i = 1; i < 25; i++) {
          hours.push({value: i.toString(), label: i.toString()});
        }
        return hours;

      default:
        return [];
    }
  }
  return [];
};

export const getUnit = (filter, SUValue) => {
  let unitList = [
    {value: "CU", label: "CU"},
    {value: "MT", label: "MT"},
    {value: "JG", label: "JG"},
    {value: "PR", label: "PR"},
    {value: "LT", label: "LT"},
    {value: "KT", label: "KT"},
    {value: "ST", label: "ST"},
    {value: "GL", label: "GL"},
    {value: "KG", label: "KG"},
    {value: "CJ", label: "CJ"},
    {
      value: SUValue,
      label: <IntlMessages id="servicecontrol.no_unit" />
    }
  ];
  if (filter == true) {
    unitList = [ {value: -1, label: <IntlMessages id="table.all" />} ].concat(
      unitList
    );
  }
  return unitList;
};

export const getArticle = () => {
  const articleOptions = [
    {value: "INVENTARIABLE", label: "INVENTARIABLE"},
    {value: "NO INVENTARIABLE", label: "NO INVENTARIABLE"}
  ];
  return articleOptions;
};

export const getTeamList = () => {
  const teamList = [
    {
      value: "collaborator",
      label: <IntlMessages id="servicecontrol.collaborator" />
    },
    {value: "cmp", label: <IntlMessages id="servicecontrol.cmp" />},
    {value: "mixed", label: <IntlMessages id="servicecontrol.mixed" />}
  ];
  return teamList;
};

export const getDestinationserviceList = () => {
  const destinationserviceList = [
    {value: "to_region", label: <IntlMessages id="servicecontrol.toregion" />},
    {value: "to_home", label: <IntlMessages id="servicecontrol.tohome" />}
  ];
  return destinationserviceList;
};

export const getServiceOption = () => {
  const serviceOptions = [
    {value: "yes", label: <IntlMessages id="actions.yes" />},
    {value: "no", label: <IntlMessages id="actions.no" />}
  ];
  return serviceOptions;
};

export const getServiceAvailabiltyOption = () => {
  const serviceAvailabiltyOptions = [
    {value: "yes", label: <IntlMessages id="actions.yes" />},
    {value: "no", label: <IntlMessages id="actions.no" />}
  ];
  return serviceAvailabiltyOptions;
};

export const getServiceTypeList = () => {
  const serviceOptions = [
    {value: "normal", label: <IntlMessages id="servicecontrol.normal" />},
    {value: "especial", label: <IntlMessages id="servicecontrol.special" />},
    {
      value: "acercamiento",
      label: <IntlMessages id="servicecontrol.closeup" />
    },
    {value: "emergencia", label: <IntlMessages id="servicecontrol.emergency" />}
  ];
  return serviceOptions;
};

export const getSparesType = () => {
  let sparestypeList = [
    {value: "ACCESORIOS", label: "ACCESORIOS"},
    {value: "ACCESORIOS USADOS", label: "ACCESORIOS USADOS"},
    {value: "CU", label: "CU"},
    {value: "EQUIPO COMPUTACIONAL", label: "EQUIPO COMPUTACIONAL"},
    {value: "EQUIPO PERSONAL", label: "EQUIPO PERSONAL"},
    {value: "EQUIPOS", label: "EQUIPOS"},
    {value: "HERRAMIENTAS", label: "HERRAMIENTAS"},
    {value: "INSTALACIONES", label: "INSTALACIONES"},
    {value: "INSUMOS TALLER", label: "INSUMOS TALLER"},
    {value: "LLANTAS Y TAPAS NUEVAS", label: "LLANTAS Y TAPAS NUEVAS"},
    {value: "LUBRICANTES", label: "LUBRICANTES"},
    {value: "MAQUINARIAS", label: "MAQUINARIAS"},
    {value: "MUEBLES Y UTILES", label: "MUEBLES Y UTILES"},
    {value: "NEUMATICOS", label: "NEUMATICOS"},
    {value: "NO INVENTARIABLES", label: "NO INVENTARIABLES"},
    {value: "REPUESTO OBSOLETO", label: "REPUESTO OBSOLETO"},
    {value: "REPUESTO ORIGINAL", label: "REPUESTO ORIGINAL"},
    {value: "REPUESTO USADO", label: "REPUESTO USADO"},
    {value: "REPUESTOS DESARMADURIA", label: "REPUESTOS DESARMADURIA"},
    {value: "RPTO. ALTERNATIVO", label: "RPTO. ALTERNATIVO"},
    {value: "RPTO.ALTERNATIVO", label: "RPTO.ALTERNATIVO"},
    {value: "SERVICIOS", label: "SERVICIOS"},
    {value: "SIN INFORMACION", label: "SIN INFORMACION"},
    {value: "STOCK BODEGA", label: "STOCK BODEGA"}
  ];

  return sparestypeList;
};

export const checkMenu = (rolesPermission, name, userRole) => {
  let Ischeck = false;
  if (rolesPermission && rolesPermission.moduleItems) {
    for (let data1 in rolesPermission.moduleItems) {
      if (
        rolesPermission.moduleItems[data1].name &&
        rolesPermission.moduleItems[data1].name.value == name.tourl
      ) {
        let selectView = rolesPermission.moduleItems[data1].operations.find(
          item => "view" == item.value && item.roleStatusChecked
        );
        if (selectView) {
          Ischeck = true;
        }
      }
    }
  }

  return Ischeck;
};

export const getRolesPermissionId = (pagename, type) => {
  console.log("pagename, type==", pagename, type);
  let rolesPermission = getRolesPermission();
  let userRoleId = getRoleId();
  let roleListId = [];

  for (let data1 in rolesPermission.moduleItems) {
    if (rolesPermission.moduleItems[data1].name.value == pagename) {
      let selectView = rolesPermission.moduleItems[data1].operations.find(
        item => type == item.value
      );
      console.log("selectView==", selectView);
      if (selectView && selectView.selectPermissionRoles && type == "edit") {
        for (let item of selectView.selectPermissionRoles) {
          roleListId.push(item.id);
        }
      }
      if (
        selectView &&
        selectView.selectDeletePermissionRoles &&
        type == "remove"
      ) {
        for (let item of selectView.selectDeletePermissionRoles) {
          roleListId.push(item.id);
        }
      }
    }
  }

  return roleListId;
};

export const roleApprovePermission = (pagename, type) => {
  let Ischeck = false;
  let userRole = getRoleName();
  let rolesPermission = getRolesPermission();
  let userRoleId = getRoleId();

  if (userRole != "super_admin" && userRole != "admin") {
    for (let data1 in rolesPermission.moduleItems) {
      if (rolesPermission.moduleItems[data1].name.value == pagename) {
        console.log(
          "rolesPermission.moduleItems[data1]==",
          rolesPermission.moduleItems[data1],
          "userRole==",
          userRole
        );
        let selectView = rolesPermission.moduleItems[data1].operations.find(
          item => type == item.value
        );
        if (selectView && selectView.selectPermissionRoles && type == "edit") {
          selectView = selectView.selectPermissionRoles.find(
            item => userRoleId == item.value
          );
          if (selectView) {
            Ischeck = true;
          }
        }
        if (
          selectView &&
          selectView.selectDeletePermissionRoles &&
          type == "remove"
        ) {
          selectView = selectView.selectDeletePermissionRoles.find(
            item => userRoleId == item.value
          );
          if (selectView) {
            Ischeck = true;
          }
        }
      }
    }
  } else {
    Ischeck = true;
  }
  //Ischeck = true;
  return Ischeck;
};

export const checkTab = (pagename, type, tabname) => {
  let Ischeck = false;
  let userRole = getRoleName();
  let rolesPermission = getRolesPermission();
  if (pagename == "warehousestock") {
    pagename = "warehouselist";
  }
  if (userRole != "super_admin") {
    for (let data1 in rolesPermission.moduleItems) {
      if (
        rolesPermission.moduleItems[data1] &&
        rolesPermission.moduleItems[data1].name &&
        rolesPermission.moduleItems[data1].name.value == pagename
      ) {
        if (pagename == "company") {
          // console.log('rolesPermission.moduleItems[data1].operations===',rolesPermission.moduleItems[data1].operations);
        }
        let selectView = rolesPermission.moduleItems[data1].operations.find(
          item => type == item.value && item.roleStatusChecked
        );
        // console.log('selectView===',selectView);
        if (selectView && selectView[tabname]) {
          Ischeck = true;
        }
      }
    }
  } else {
    Ischeck = true;
  }
  return Ischeck;
};

export const checkRouterUrl = (pagename, type) => {
  let Ischeck = false;
  let userRole = getRoleName();
  let rolesPermission = getRolesPermission();

  if (pagename == "warehousestock") {
    pagename = "warehouselist";
  }
  if (userRole != "super_admin") {
    for (let data1 in rolesPermission.moduleItems) {
      if (
        rolesPermission.moduleItems[data1] &&
        rolesPermission.moduleItems[data1].name &&
        rolesPermission.moduleItems[data1].name.value == pagename
      ) {
        if (pagename == "company") {
          // console.log('rolesPermission.moduleItems[data1].operations===',rolesPermission.moduleItems[data1].operations);
        }
        let selectView = rolesPermission.moduleItems[data1].operations.find(
          item => type == item.value && item.roleStatusChecked
        );

        if (selectView) {
          Ischeck = true;
        }
      }
    }
  } else {
    Ischeck = true;
  }

  return Ischeck;
};

export const getPermissionModule = pagename => {
  let module = null;
  let userRole = getRoleName();
  let rolesPermission = getRolesPermission();
  if (userRole != "super_admin") {
    for (let data1 in rolesPermission.moduleItems) {
      if (rolesPermission.moduleItems[data1].name.value == pagename) {
        module = rolesPermission.moduleItems[data1];
      }
    }
  }
  return module;
};

export const requestPermission = (pagename, type) => {
  let Ischeck = false;
  let userRoleId = getRoleId();
  let userRole = getRoleName();
  let rolesPermission = getRolesPermission();
  if (userRole != "super_admin") {
    for (let data1 in rolesPermission.moduleItems) {
      if (rolesPermission.moduleItems[data1].name.value == pagename) {
        console.log(
          "rolesPermission.moduleItems[data1].operations==",
          rolesPermission.moduleItems[data1].operations
        );
        let selectView = rolesPermission.moduleItems[data1].operations.find(
          item => type == item.value && item.appRequest
        );
        if (selectView) {
          Ischeck = true;
        }
      }
    }
  } else {
    Ischeck = false;
  }
  //Ischeck = true;
  return Ischeck;
};

const orderItem = (items, menu) => {
  let newOrderItem = new Array(items.length).fill(null);
  switch (menu) {
    case "tracking".toLowerCase():
      items.forEach(i => {
        switch (i.name.value) {
          case "godview":
            newOrderItem[0] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.godview"} />}
            };
            break;
          case "livetracking":
            newOrderItem[1] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.livetracking"} />
              }
            };
            break;
          case "trackhistory":
            newOrderItem[2] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.trackhistory"} />
              }
            };
            break;
          case "alerts":
            newOrderItem[3] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.alerts"} />}
            };
            break;
          case "aoi":
            newOrderItem[4] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.aoi"} />}
            };
            break;
          default:
            break;
        }
      });
      return newOrderItem.filter(i => i);
    case "assets".toLowerCase():
      items.forEach(i => {
        switch (i.name.value) {
          case "equipment":
            newOrderItem[0] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.equipment"} />
              }
            };
            break;
          case "tools":
            newOrderItem[1] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.tools"} />}
            };
            break;
          case "sparessupplies":
            newOrderItem[2] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.sparessupplies"} />
              }
            };
            break;
          case "existencecard":
            newOrderItem[3] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"actions.existencecard"} />
              }
            };
            break;
          case "inventory":
            newOrderItem[4] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"spares.inventory"} />}
            };
            break;
          case "warehouselist":
            newOrderItem[5] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.warehouselist"} />
              }
            };
            break;
          case "digital":
            newOrderItem[6] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.digital"} />}
            };
            break;

          default:
            break;
        }
      });
      return newOrderItem.filter(i => i);
    case "team".toLowerCase():
      items.forEach(i => {
        switch (i.name.value) {
          case "inhouse":
            newOrderItem[0] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.inhouse"} />}
            };
            break;
          case "providers":
            newOrderItem[1] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.providers"} />
              }
            };
            break;
          case "client":
            newOrderItem[2] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.client"} />}
            };
            break;

          default:
            break;
        }
      });
      return newOrderItem.filter(i => i);
    case "settings".toLowerCase():
      newOrderItem = new Array(6).fill(null);
      items.forEach(i => {
        switch (i.name.value) {
          case "region":
            newOrderItem[0] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.region"} />}
            };
            break;
          case "groups":
            newOrderItem[1] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.groups"} />}
            };
            break;
          case "costcenter":
            newOrderItem[2] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.costcenter"} />
              }
            };
            break;
          case "commands":
            newOrderItem[3] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.commands"} />}
            };
            break;
          case "createoption":
            newOrderItem[4] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.createoption"} />
              }
            };
            break;
          case "warehouse":
            newOrderItem[5] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.warehouse"} />
              }
            };
            break;

          default:
            break;
        }
      });
      return newOrderItem.filter(i => i);
    case "warehouseadmission".toLowerCase():
      items.forEach(i => {
        switch (i.name.value) {
          case "purchaseorder":
            newOrderItem[0] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.purchaseorder"} />
              }
            };
            break;
          case "electronicinvoice":
            newOrderItem[1] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.electronicinvoice"} />
              }
            };
            break;
          case "supplierofficeguide":
            newOrderItem[2] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.supplierofficeguide"} />
              }
            };
            break;
          case "professionalfees":
            newOrderItem[3] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.professionalfees"} />
              }
            };
            break;
          case "creditnote":
            newOrderItem[4] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.creditnote"} />
              }
            };
            break;
          case "debitnote":
            newOrderItem[5] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.debitnote"} />
              }
            };
            break;
          case "admissiontransferdispatch":
            newOrderItem[6] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.admissiontransferdispatch"} />
              }
            };
            break;
          case "admissioninternalguide":
            newOrderItem[7] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.admissioninternalguide"} />
              }
            };
            break;
          case "adjustment":
            newOrderItem[8] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.adjustment"} />
              }
            };
            break;

          default:
            break;
        }
      });
      return newOrderItem.filter(i => i);
    case "warehousedepartures".toLowerCase():
      newOrderItem = new Array(4).fill(null);
      items.forEach(i => {
        switch (i.name.value) {
          case "workorders":
            newOrderItem[0] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.workorders"} />
              }
            };
            break;
          case "depaturetransferdispatch":
            newOrderItem[1] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.depaturetransferdispatch"} />
              }
            };
            break;
          case "depatureinternalguide":
            newOrderItem[2] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.depatureinternalguide"} />
              }
            };
            break;
          case "wastage":
            newOrderItem[3] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.wastage"} />}
            };
            break;

          default:
            break;
        }
      });
      return newOrderItem.filter(i => i);
    case "service".toLowerCase():
      items.forEach(i => {
        switch (i.name.value) {
          case "servicedashboard":
            newOrderItem[0] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"servicecontrol.servicedashboard"} />
              }
            };
            break;
          case "service":
            newOrderItem[1] = {
              ...i,
              name: {...i.name, label: <IntlMessages id={"sidebar.service"} />}
            };
            break;
          case "servicecontrol":
            newOrderItem[2] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.servicecontrol"} />
              }
            };
            break;
          case "servicesettings":
            newOrderItem[3] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.servicesettings"} />
              }
            };
            break;

          default:
            break;
        }
      });
      return newOrderItem.filter(i => i);
    case "maintenance".toLowerCase():
      items.forEach(i => {
        switch (i.name.value) {
          case "maintenancetype":
            newOrderItem[0] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.maintenancetype"} />
              }
            };
            break;
          case "maintenancegroup":
            newOrderItem[1] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.maintenancegroup"} />
              }
            };
            break;
          case "resourcegroup":
            newOrderItem[2] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.resourcegroup"} />
              }
            };
            break;
          case "maintenanceform":
            newOrderItem[3] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.maintenanceform"} />
              }
            };
            break;
          case "maintenanceplan":
            newOrderItem[4] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.maintenanceplan"} />
              }
            };
            break;

          default:
            break;
        }
      });
      return newOrderItem.filter(i => i);
    case "businessintelligence".toLowerCase():
      items.forEach(i => {
        switch (i.name.value) {
          case "routereport":
            newOrderItem[0] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.routereport"} />
              }
            };
            break;
          case "costingreport":
            newOrderItem[1] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.costingreport"} />
              }
            };
            break;
          case "usagereport":
            newOrderItem[2] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.usagereport"} />
              }
            };
            break;
          case "maintenancereport":
            newOrderItem[3] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.maintenancereport"} />
              }
            };
            break;
          case "insurancereport":
            newOrderItem[4] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.insurancereport"} />
              }
            };
            break;
          case "detailedreport":
            newOrderItem[5] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.detailedreport"} />
              }
            };
            break;
          case "purchasereport":
            newOrderItem[6] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.purchasereport"} />
              }
            };
            break;
          case "workorderreport":
            newOrderItem[7] = {
              ...i,
              name: {
                ...i.name,
                label: <IntlMessages id={"sidebar.workorder_report"} />
              }
            };
            break;

          default:
            break;
        }
      });
      return newOrderItem.filter(i => i);

    default:
      return items;
  }
};

export const orderModulesLikeSidebar = modules => {
  let newModulesOrder = new Array(modules.length).fill(null);

  modules.forEach(mod => {
    switch (mod.name.toLowerCase().replace(" ", "")) {
      case "dashboard".toLowerCase():
        newModulesOrder[0] = {
          ...mod,
          name: <IntlMessages id={"sidebar.dashboard"} />
        };
        break;
      case "tracking".toLowerCase():
        newModulesOrder[1] = {
          ...mod,
          name: <IntlMessages id={"sidebar.tracking"} />,
          items: orderItem(mod.items, "tracking")
        };
        break;
      case "assets".toLowerCase():
        newModulesOrder[2] = {
          ...mod,
          name: <IntlMessages id={"sidebar.assets"} />,
          items: orderItem(mod.items, "assets")
        };
        break;
      case "team".toLowerCase():
        newModulesOrder[3] = {
          ...mod,
          name: <IntlMessages id={"sidebar.team"} />,
          items: orderItem(mod.items, "team")
        };
        break;
      case "settings".toLowerCase():
        newModulesOrder[4] = {
          ...mod,
          name: <IntlMessages id={"sidebar.settings"} />,
          items: orderItem(mod.items, "settings")
        };
        break;
      case "warehouseadmission".toLowerCase():
        newModulesOrder[5] = {
          ...mod,
          name: <IntlMessages id={"sidebar.warehouseadmission"} />,
          items: orderItem(mod.items, "warehouseadmission")
        };
        break;
      case "warehousedepartures".toLowerCase():
        newModulesOrder[6] = {
          ...mod,
          name: <IntlMessages id={"sidebar.warehousedepartures"} />,
          items: orderItem(mod.items, "warehousedepartures")
        };
        break;
      case "sidebar.roles_permit".toLowerCase():
        newModulesOrder[7] = {
          ...mod,
          name: <IntlMessages id={"sidebar.rolespermissions"} />
        };
        break;
      case "workorders".toLowerCase():
        newModulesOrder[8] = {
          ...mod,
          name: <IntlMessages id={"sidebar.workorders"} />,
          items: orderItem(mod.items, "workorders")
        };
        break;
      case "approvalmanagement".toLowerCase():
        newModulesOrder[9] = {
          ...mod,
          name: <IntlMessages id={"sidebar.approve"} />,
          items: orderItem(mod.items, "approve")
        };
        break;
      case "riskmanagement".toLowerCase():
        newModulesOrder[10] = {
          ...mod,
          name: <IntlMessages id={"sidebar.riskmanagement"} />,
          items: orderItem(mod.items, "riskmanagement")
        };
        break;
      case "service".toLowerCase():
        newModulesOrder[11] = {
          ...mod,
          name: <IntlMessages id={"sidebar.service"} />,
          items: orderItem(mod.items, "service")
        };
        break;
      case "maintenance".toLowerCase():
        newModulesOrder[12] = {
          ...mod,
          name: <IntlMessages id={"sidebar.maintenance"} />,
          items: orderItem(mod.items, "maintenance")
        };
        break;
      case "businessintelligence".toLowerCase():
        newModulesOrder[13] = {
          ...mod,
          name: <IntlMessages id={"sidebar.businessintelligence"} />,
          items: orderItem(mod.items, "businessintelligence")
        };
        break;
      case "company".toLowerCase():
        newModulesOrder[14] = {
          ...mod,
          name: <IntlMessages id={"sidebar.company"} />,
          items: orderItem(mod.items, "company")
        };
        break;
      case "myprofile".toLowerCase():
        newModulesOrder[15] = {
          ...mod,
          name: <IntlMessages id={"sidebar.profile"} />,
          items: orderItem(mod.items, "profile")
        };
        break;
      default:
        var idx = newModulesOrder.indexOf(null);
        let indices = [];
        while (idx != -1) {
          indices.push(idx);
          idx = newModulesOrder.indexOf(null, idx + 1);
        }
        newModulesOrder[indices[indices.length - 1]] = mod;

        break;
    }
  });
  return newModulesOrder;
};

export const getIdsRowsOnlyShow = (
  objectData,
  collectionIds,
  key,
  isObject = false,
  keyObject = ""
) => {
  let idRows = [];
  // console.log("collectionIds", collectionIds);
  if (
    objectData &&
    objectData.rows &&
    collectionIds &&
    collectionIds.length > 0
  ) {
    for (let index = 0; index < objectData.rows.length; index++) {
      let item = objectData.rows[index];
      let itemKey = !isObject
        ? parseInt(item[key])
        : parseInt(item[key][keyObject]);
      // console.log("itemKey", itemKey);
      // console.log(
      //   "collectionIds.indexOf(itemKey)",
      //   collectionIds.indexOf(itemKey)
      // );
      if (collectionIds.indexOf(itemKey) >= 0) {
        idRows.push(index);
      }
    }
  }
  return idRows;
};
