import React from "react";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import CmtImage from "../../../../@coremat/CmtImage";
import DeleteModalCard from "../../../../@coremat/DeleteModalCard";
import WorkorderViewModalCard from "../../../../@coremat/WorkorderViewModalCard";
import IntlMessages from "../../../utils/IntlMessages";
import {useIntl} from "react-intl";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import {getFormatEnglishSpanishDate} from "../../../../@jumbo/utils/dateHelper";
import {deleteAction} from "../../../../redux/actions/Common";
import {useDispatch} from "react-redux";
import {
  getModulePermission,
  requestPermission
} from "../../../../@jumbo/utils/commonHelper";

import {fetchError} from "../../../../redux/actions/Common";
let editIndex = 0;
let editItem = "";

const moduleName = "workorders";

const KanbanCard = props => {
  const classes = useStyles();
  const [ Isdelete, setDelete ] = React.useState(false);
  const [ Isview, setView ] = React.useState(false);
  const [ items, setItems ] = React.useState({});
  const dispatch = useDispatch();
  const intl = useIntl();

  const onDeleteAction = () => {
    console.log("delete===", editItem);
    if (getModulePermission(moduleName, editItem)) {
      if (editItem.laneId == "new") {
        let isAssetsobservationModel = requestPermission(moduleName, "remove");
        console.log("props=1111111111=", props);
        if (isAssetsobservationModel) {
          setDelete(false);
          //props.onDelete();
        } else {
          setDelete(false);
          //props.onDelete();
        }
      } else {
        // dispatch(
        //   fetchError(
        //     intl.formatMessage({
        //       id: "error.permisson_error"
        //     })
        //   )
        // );
        setDelete(false);
      }
    } else {
      // dispatch(
      //   fetchError(
      //     intl.formatMessage({
      //       id: "error.permisson_region_error"
      //     })
      //   )
      // );
      setDelete(false);
    }
  };

  const handleDeleteAction = (id, item) => {
    editIndex = id;
    editItem = item;
    setDelete(true);
  };

  const onCancel = () => {
    setDelete(false);
  };

  const onViewAction = () => {
    setView(false);
  };

  const onViewCancel = () => {
    setView(false);
  };

  const onViewWorkOrder = () => {
    setItems({...props});
    setView(true);
  };

  let percentage = "0%";
  if (props.type == "in_progress") {
    percentage = props.percentage + "%";
  } else if (props.type == "completed") {
    percentage = "100%";
  }
  return (
    <Grid
      container
      spacing={3}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      className={classes.workorder_container}
    >
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.cardcontainer}
        >
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.cardcontainercard}
            container
          >
            <Grid container xs={9} spacing={3}>
              <Grid container xs={12} spacing={3}>
                <Grid item xs={12}>
                  {" "}
                  {intl.formatMessage({id: "spares.WO"})}-{props.wodocumentNo}
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={3} className={classes.carddate}>
                <Grid item xs={12}>
                  {" "}
                  {getFormatEnglishSpanishDate(props.documentDate)}
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={3} className={classes.action}>
                <Grid item xs={6}>
                  <div className="action_icon">
                    <img
                      className="size-30 menu_icon"
                      src={"/images/othericon/workorder_assets.svg"}
                    />Assets
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={3} spacing={3}>
              <Grid item xs={6} className={classes.actionicon}>
                <a
                  title="Edit"
                  href="javascript:void(0)"
                  onClick={props.onClick}
                >
                  <img
                    className="size-30 menu_icon_edit"
                    src={"/images/menuicons/hovermenu/hovermaintenanceform.svg"}
                  />
                  {/* <BorderColorIcon className={classes.iconedits} /> */}
                </a>
              </Grid>
              <Grid container xs={6} spacing={3}>
                {/* <Grid item xs={6}>
                  <CmtImage
                    src={"/images/profile_img.png"}
                    className={classes.cardimg}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <a
                    title="View"
                    href="javascript:void(0)"
                    onClick={event => {
                      onViewWorkOrder();
                    }}
                  >
                    <img
                      className="size-30 menu_icon_view"
                      src={"/images/othericon/view.svg"}
                    />
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <a
                    title="Remove"
                    href="javascript:void(0)"
                    onClick={event => {
                      handleDeleteAction(props.id, props);
                    }}
                  >
                    <img
                      className="size-30 menu_icon_remove"
                      src={"/images/othericon/delete.svg"}
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.progress}>
              <span className={classes.progree_percentage}>{percentage}</span>

              <Grid
                style={{
                  width: percentage,
                  backgroundColor: "#3ca0ed",
                  float: "left",
                  borderRadius: 20,
                  height: 20
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {Isdelete && (
        <DeleteModalCard
          open={true}
          onDelete={onDeleteAction}
          onCancel={onCancel}
        />
      )}
      {Isview && (
        <WorkorderViewModalCard
          open={Isview}
          items={items}
          onDelete1={onViewAction}
          onCancel={onViewCancel}
        />
      )}
    </Grid>
  );
};

export default KanbanCard;

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  cardButtons: {
    float: "right",
    borderRadius: 30
  },
  cardcontainer: {
    border: "solid 1px #e8eff5",
    borderRadius: 5,
    float: "left",
    padding: "10px!important",
    width: "100%",
    "& .MuiGrid-container": {
      padding: "0px!important",
      margin: 0,
      flex: "auto!important",
      "& .MuiGrid-item": {
        fontSize: 12,
        fontWeight: "bold",
        "& .menu_icon": {
          width: 17,
          marginLeft: 5,
          float: "right"
        },
        "& .menu_icon_view": {
          width: 25,
          marginLeft: 5,
          float: "right"
        },
        "& .menu_icon_edit": {
          width: "40px",
          height: "62px",
          float: "left"
        },
        "& .menu_icon_remove": {
          width: 20,
          marginLeft: 5,
          marginRight: "3px",
          float: "right"
        }
      }
    },
    "& .MuiGrid-root": {
      padding: "0px!important"
    }
  },
  // cardcontainercard: {
  //   padding: 10
  // },
  action: {
    margin: "5px 0!important",
    "& .action_icon": {
      float: "left"
    }
  },
  actionicon: {
    "& .menu_icon": {
      width: 15,
      height: 15,
      float: "right",
      marginLeft: 10
    }
  },
  progress: {
    width: "100%",
    backgroundColor: "#d7f1ff",
    float: "left",
    borderRadius: 20,
    height: 20
  },
  activeprogress: {
    // width: "20%!important",
    // backgroundColor: "#3ca0ed",
    // float: "left",
    // borderRadius: 20,
    // height: 20
  },
  progree_percentage: {
    position: "absolute",
    fontSize: 14,
    padding: "2px 12px",
    color: "#000"
  },
  carddate: {
    "& .MuiGrid-item": {
      color: theme.palette.text.black
    }
  },
  cardimg: {
    width: 24,
    float: "right",
    borderRadius: 30
  },
  workorder_container: {
    padding: "0px!important"
  },
  iconedits: {
    fontSize: "44px",
    marginTop: "10px",
    color: "#213a52"
  }
}));
