import React, {useState, useEffect, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@material-ui/core/Box";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Sector,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";
import {useIntl} from "react-intl";

const Createcompletewo = () => {
  const {dashboard} = useSelector(({common}) => common);
  const [ workOrderBarData, setWorkOrderBarData ] = useState(null);
  const intl = useIntl();

  useEffect(
    () => {
      let progressOrder = 0;
      let createdOrder = 0;
      let completeOrder = 0;

      if (dashboard.percentageComplianceChecked) {
        progressOrder = dashboard.percentageComplianceChecked.find(
          item => item.status == "in_progress"
        );
        createdOrder = dashboard.percentageComplianceChecked.find(
          item => item.status == "new"
        );
        createdOrder = dashboard.percentageComplianceChecked.find(
          item => item.status == "completed"
        );

        completeOrder = completeOrder ? completeOrder.count : 0;
        createdOrder = createdOrder ? createdOrder.count : 0;
        progressOrder = progressOrder ? progressOrder.count : 0;
      }

      let workOrderBarData = [
        {
          name: "Status",
          [intl.formatMessage({id: "contractors.new"})]: Number(createdOrder),
          [intl.formatMessage({id: "contractors.in_progress"})]: Number(
            progressOrder
          ),
          [intl.formatMessage({id: "contractors.completed"})]: Number(
            completeOrder
          )
        }
      ];
      setWorkOrderBarData(workOrderBarData);
    },
    [ dashboard ]
  );

  return (
    <Grid item xs={12}>
      <Box>
        {workOrderBarData && (
          <ResponsiveContainer height={300} width="100%">
            <BarChart
              data={workOrderBarData}
              barGap={30}
              barCategoryGap={15}
              margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5
              }}
            >
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend iconType="circle" wrapperStyle={{bottom: 0}} />
              <Bar
                dataKey={intl.formatMessage({id: "contractors.new"})}
                fill="#dfe5ed"
                radius={15}
              />
              <Bar
                dataKey={intl.formatMessage({id: "contractors.in_progress"})}
                fill="#213a52"
                radius={15}
              />
              <Bar
                dataKey={intl.formatMessage({id: "contractors.completed"})}
                fill="#29939b"
                radius={15}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Grid>
  );
};

export default Createcompletewo;
