import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../../@jumbo/components/PageComponents/PageLoader';
import { checkRouterUrl } from '../../../@jumbo/utils/commonHelper';

const WarehouseDepartures = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl('workorders', 'view') && <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/workorders`} />}
        {checkRouterUrl('workorders', 'view') && <Route path={`${requestedUrl}/workorders`} component={lazy(() => import('./WorkOrders'))} />}
        {checkRouterUrl('workorders', 'add') && <Route path={`${requestedUrl}/addworkorders`} component={lazy(() => import('./AddWorkOrders'))} />}
        {checkRouterUrl('workorders', 'edit') && <Route path={`${requestedUrl}/editworkorders/:id`} component={lazy(() => import('./AddWorkOrders'))} />}

        {checkRouterUrl('depaturetransferdispatch', 'view') && <Route path={`${requestedUrl}/depaturetransferdispatch`} component={lazy(() => import('./TransferDispatchGuide'))} />}
        {checkRouterUrl('depaturetransferdispatch', 'add') && <Route path={`${requestedUrl}/adddepaturetransferdispatch`} component={lazy(() => import('./AddTransferDispatchGuide'))} />}
        {checkRouterUrl('depaturetransferdispatch', 'edit') && <Route path={`${requestedUrl}/editdepaturetransferdispatch/:id`} component={lazy(() => import('./AddTransferDispatchGuide'))} />}

        {checkRouterUrl('depatureinternalguide', 'view') && <Route path={`${requestedUrl}/depatureinternalguide`} component={lazy(() => import('./InternalGuide'))} />}
        {checkRouterUrl('depatureinternalguide', 'add') && <Route path={`${requestedUrl}/adddepatureinternalguide`} component={lazy(() => import('./AddInternalGuide'))} />}
        {checkRouterUrl('depatureinternalguide', 'edit') && <Route path={`${requestedUrl}/editdepatureinternalguide/:id`} component={lazy(() => import('./AddInternalGuide'))} />}

        {checkRouterUrl('wastage', 'view') && <Route path={`${requestedUrl}/wastage`} component={lazy(() => import('./Wastage'))} />}
        {checkRouterUrl('wastage', 'add') && <Route path={`${requestedUrl}/addwastage`} component={lazy(() => import('./AddWastage'))} />}
        {checkRouterUrl('wastage', 'edit') && <Route path={`${requestedUrl}/editwastage/:id`} component={lazy(() => import('./AddWastage'))} />}

        <Route component={lazy(() => import('../404'))} /> 

      </Switch>
    </Suspense>
  );
};

export default WarehouseDepartures;


