import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import CmtButtons from "../CmtButtons";

import Modal from "@material-ui/core/Modal";

function rand(){
  return Math.round(Math.random() * 14) - 16;
}

function getModalStyle(){
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `50%`,
    transform: `translate(-${top}%, -50%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none!important",
    padding: 0,
    border: "2px solid #fff",
    outline: "none"
  },
  modal: {
    "&:focus": {
      outline: "none"
    }
  },
  modalh2: {
    borderBottom: "solid 1px #e1e8ea",
    textAlign: "center",
    padding: 20,
    fontSize: 16
  },
  dateFiltertitle: {
    textAlign: "center",
    padding: "20px 20px 34px 20px",
    width: "100%",
    fontSize: 16
  },
  buttons: {
    width: "100%",
    textAlign: "center",
    marginBottom: 40,
    marginTop: 20,
    "& .MuiBox-root": {
      display: "inline",
      textAlign: "center"
    }
  },

  observation: {
    width: "100%",
    margin: "5px 10px",
    color: "#fff"
  },
  observation_p0: {
    background: "#8BC34A",
    float: "left",
    lineHeight: 2,
    padding: "0px 8px",
    borderRadius: 10,
    fontSize: 12
  },
  observation_p1: {
    background: "#2196F3",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  observation_p2: {
    background: "#009688",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  observation_p3: {
    background: "#FFC107",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  observation_p4: {
    background: "#ff9800",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  observation_p5: {
    background: "#ff5722",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  observation_p6: {
    background: "#8BC34A",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  observation_p7: {
    background: "#2196F3",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  observation_p8: {
    background: "#009688",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  observation_p9: {
    background: "#FFC107",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  observation_p10: {
    background: "#ff5722",
    float: "left",
    padding: 8,
    borderRadius: 10,
    fontSize: 12
  },
  modalh2inner: {
    maxHeight: "400px",
    overflow: "auto"
  }
}));

const ObservationModalCard = props => {
  const classes = useStyles(props);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [ modalStyle ] = React.useState(getModalStyle);
  const {
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    required,
    onChange,
    helperText,
    variant,
    error,
    valueKey,
    labelKey,
    backgroundColor,
    label,
    open,
    onDelete,
    onCancel,
    observationArray,
    ...rest
  } = props;
  let observationArray1 = [];
  if (observationArray) {
    observationArray1 = observationArray.filter(function(item, pos){
      return observationArray.indexOf(item) == pos;
    });
  }

  const handleOpen = () => {
    //setOpen(true);
  };

  const handleClose = () => {
    //setOpen(false);
  };
  const buttons = [
    {
      label: <IntlMessages id="actions.close" />,
      color: "fullcancel",
      onClick: onCancel
    }
  ];

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 className={classes.modalh2} id="simple-modal-title">
            <IntlMessages id="sidebar.observation" />
          </h2>
          <p id="simple-modal-description" className={classes.modalh2inner}>
            <GridContainer container xs={12} classes={{root: "dateFilter"}}>
              {observationArray1 &&
                observationArray1.map((item, i) => {
                  if (item != "" && item != null) {
                    return (
                      <Grid key={i} className={classes.observation}>
                        {" "}
                        <Grid
                          style={{width: "100%"}}
                          className={classes.observation_p0}
                        >
                          {item}
                        </Grid>
                      </Grid>
                      // <Grid className={classes.observation}> <Grid className={"classes.observation_p" + i}>{item}</Grid> </Grid>
                    );
                  }
                })}
            </GridContainer>
            <Grid xs={12} className={classes.buttons}>
              <CmtButtons items={buttons} />
            </Grid>
          </p>
        </div>
      </Modal>
    </div>
  );
};

ObservationModalCard.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  backgroundColor: PropTypes.string
};

ObservationModalCard.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: "small",
  border: "none",
  error: false,
  helperText: "",
  valueKey: "id",
  labelKey: "title",
  backgroundColor: "transparent"
};

export default ObservationModalCard;
