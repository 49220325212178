import React from "react";
import S3 from "react-aws-s3";
import {AmazonConfig} from "../constants/AppConstants";

const ReactS3Client = new S3(AmazonConfig);

export const imageUpload = file => {
  let timeStamp = new Date().getTime();
  console.log("ReactS3Client===", file, timeStamp);
  return ReactS3Client.uploadFile(file, timeStamp + ".png")
    .then(data => {
      console.log("data===", data);
      if (data.status == "204") {
        return data.location;
      } else {
        return "";
      }
    })
    .catch(err => {
      console.log("err===", err);
      return "";
    });
};

export const toDataUrl = url => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function(){
      var reader = new FileReader();
      reader.onloadend = function(){
        return resolve(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });
};
