import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import KanbanCard from "../../../../@jumbo/components/Common/KanbanCard";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Grid from "@material-ui/core/Grid";
import Board from "react-trello";
import CmtImage from "../../../../@coremat/CmtImage";

const active = "/images/othericon/tab_pending.svg";
const normal = "/images/othericon/tab_rejected.svg";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: 0,
    flexGrow: 1,
    borderRadius: 30,
    "& .knhPos": {
      backgroundColor: "#f8f9fa"
    },
    "& .eBJJos": {
      backgroundColor: "#ffffff"
    },
    "& .MuiAppBar-positionStatic": {
      color: "#000",
      backgroundColor: "#fff"
    },
    "& .MuiTabs-fixed": {
      borderTop: "solid 1px #f1f4f5"
    },
    "& .MuiTab-wrapper": {
      textTransform: "initial"
    },
    "& .MuiTab-root": {
      minWidth: "33.33%"
    },
    "& .MuiTabs-root": {
      borderRadius: 30
    },
    "& .MuiPaper-root": {
      borderRadius: 30,
      boxShadow: "none",
      border: `1px solid ${theme.palette.sidebar.formBorderColor}`
    },
    "& .MuiTab-wrapper": {
      display: "contents",
      textAlign: "center",
      margin: "0!important"
    },
    "& .MuiTab-wrapper img": {
      width: 16,
      float: "left",
      margin: "4px 0px 4px 30px"
    },
    "& .MuiTab-root": {
      padding: 0,
      minHeight: 20
    },
    "& .MuiTabs-flexContainer": {
      height: 47
    },
    "& .MuiTab-root": {
      minWidth: "33.33%",
      minHeight: 40,
      fontSize: 12
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#f1f4f559",
      height: 48
    },
    "& .MuiTabs-fixed": {
      border: "none"
    }
  },
  appMainContent: {
    width: "100%",
    padding: 0
  }
}));
function TabPanel(props){
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index){
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const StatusTabCard = ({changeStatusTab}) => {
  const classes = useStyles();
  const [ value, setValue ] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    changeStatusTab(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.root_tabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            icon={<CmtImage src={active} />}
            label={<IntlMessages id="actions.approve" />}
            {...a11yProps(0)}
          />
          <Tab
            icon={<CmtImage src={normal} />}
            label={<IntlMessages id="contractors.pending" />}
            {...a11yProps(1)}
          />
          <Tab
            icon={<CmtImage src={normal} />}
            label={<IntlMessages id="pdf.rejected" />}
            {...a11yProps(2)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} />
      <TabPanel value={value} index={1} />
      <TabPanel value={value} index={2} />
    </div>
  );
};
export default StatusTabCard;
