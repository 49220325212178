import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../../@jumbo/components/PageComponents/PageLoader';
import { checkRouterUrl} from '../../../@jumbo/utils/commonHelper';

const Roles = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl('modules','view')&&<Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/modulesetting`} />}
        {checkRouterUrl('modules','view')&&<Route path={`${requestedUrl}/modules`} component={lazy(() => import('./ModuleSetting'))} />}
        {checkRouterUrl('modules','add')&&<Route path={`${requestedUrl}/addmodules`} component={lazy(() => import('./AddModuleSetting'))} />}
        {checkRouterUrl('modules','edit')&&<Route path={`${requestedUrl}/editmodules/:id`} component={lazy(() => import('./AddModuleSetting'))} />}
        {checkRouterUrl('rolespermissions','view')&&<Route path={`${requestedUrl}/rolespermissions`} component={lazy(() => import('./Permission'))} />}
        {checkRouterUrl('rolespermissions','edit')&&<Route path={`${requestedUrl}/editrolespermissions/:id`} component={lazy(() => import('./AddPermission'))} />}
        {checkRouterUrl('rolespermissions','add')&&<Route path={`${requestedUrl}/addrolespermissions`} component={lazy(() => import('./AddPermission'))} />}
        {checkRouterUrl('rolespermissions','add')&&<Route path={`${requestedUrl}/addaccesspermission`} component={lazy(() => import('./AddAccessPermission'))} />}
        {checkRouterUrl('rolespermissions','edit')&&<Route path={`${requestedUrl}/editaccesspermission/:id`} component={lazy(() => import('./AddAccessPermission'))} />}

        <Route component={lazy(() => import('../404'))} />

      </Switch>
    </Suspense>
  );
};

export default Roles;

