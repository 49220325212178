import React from "react";
import {Box, Typography} from "@material-ui/core";
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {AuhMethods} from "../../../../services/auth";
import {CurrentAuthMethod} from "../../../constants/AppConstants";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
// import Logo from './Logo';
const actionsList = [
  {
    icon: <PersonIcon />,
    label: "Account"
  },
  {
    icon: <ExitToAppIcon />,
    label: "Logout"
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    padding: "4px 66px 4px 66px"
    // marginBottom: '10px',
    // borderBottom: 'solid 1px #ccc'
    // borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: "all 0.1s ease",
    height: 75,
    opacity: 1,
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: "all 0.3s ease"
    }
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
    marginBottom: 8
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25
  }
}));

const SidebarHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onItemClick = item => {
    if (item.label === "Logout") {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    }
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <CmtAvatar src={"https://via.placeholder.com/150x150"} />
        {/* <Box className={classes.userInfo}>
           <CmtDropdownMenu
            onItemClick={onItemClick}
            TriggerComponent={
              <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                <Box mr={2}>
                  <Typography className={classes.userTitle} component="h3" variant="h6">
                    Robert Johnson
                  </Typography>
                  <Typography className={classes.userSubTitle}>robert.johnson@gmail.com</Typography>
                </Box>
                <ArrowDropDownIcon />
              </Box>
            }
            items={actionsList}
          /> 
        </Box> */}
      </Box>
      {/* <Logo ml={2} color="white" /> */}
    </React.Fragment>
  );
};

export default SidebarHeader;
