import React from "react";
import {
  Box,
  fade,
  IconButton,
  makeStyles,
  Popover,
  Tooltip,
  useTheme
} from "@material-ui/core";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import CmtCardHeader from "../../../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../../../@coremat/CmtCard/CmtCardContent";
import CmtList from "../../../../../../@coremat/CmtList";
import CmtCard from "../../../../../../@coremat/CmtCard";
import NotificationItem from "./NotificationItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import IntlMessages from "../../../../../utils/IntlMessages";
import {webSocket} from "../../../../../../@jumbo/constants/AppConstants";
import socketIOClient from "socket.io-client";
import {
  findConductorRoleId,
  getCompanyId,
  getSuperAdmin,
  getConductorRoleId,
  getRoleId,
  getUserId,
  getRouteUrl
} from "../../../../../../@jumbo/utils/commonHelper";
import {
  fetchSuccess,
  getAllData,
  updateData
} from "../../../../../../redux/actions/Common";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {useIntl} from "react-intl";
import notificationHelper from "../../../../../../@jumbo/utils/notificationHelper";
import Notification from "react-web-notification";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 8,
      paddingBottom: 8,
      textAlign: "center",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)"
    },
    "& .Cmt-card-content": {
      padding: "0 0 16px !important"
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  iconRoot: {
    position: "relative",
    color: theme.palette.sidebar.textColor,
    "&:hover, &.active": {
      color: theme.palette.sidebar.textColor
    }
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 30
  },
  scrollbarRoot: {
    height: 300,
    padding: 16,
    "& .ps__rail-x": {
      overflow: "hidden",
      height: "0!important"
    }
  },
  popoverRoot: {
    backgroundColor: "rgba(0,0,0,0.5)",
    "& .MuiPopover-paper": {
      width: 375,
      backgroundColor: "transparent",
      boxShadow: "none"
    }
  },
  arrowup: {
    width: 0,
    height: 0,
    borderLeft: "18px solid transparent",
    borderRight: "18px solid transparent",
    borderBottom: "14px solid white",
    marginLeft: 171
  },
  userNotification: {
    "@media screen and (max-width: 500px)": {
      width: "50px",
      float: "right"
    }
  }
}));

// const actions = [
//   {
//     label: 'More Detail',
//   },
//   {
//     label: 'Close',
//   },
// ];

// const headerNotifications = [];

let socket, notify;
let moduleName = "notifications";

const HeaderNotifications = () => {
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ counter, setCounter ] = React.useState(0);
  const [ page, setPage ] = React.useState(0);

  const {notifications} = useSelector(({common}) => common);

  const [ data, setData ] = React.useState([]);

  const [ ignore, toggleIgnore ] = React.useState(true);

  const [ socketInfo, setSocketInfo ] = React.useState({
    companyId: getCompanyId(),
    roleId: getRoleId(),
    userId: getUserId(),
    //companyId: 3,
    event: "notifications"
  });

  const [ titleNotification, setTitle ] = React.useState("");
  const [ subtitle, setSubtitle ] = React.useState("");
  const history = useHistory();

  const getNotifications = (pageFunc, lengthFunc) => {
    let filters = {
      // role: {value: getRoleId(), id: getRoleId()},
      user: {value: getUserId(), id: getUserId()}
    };

    dispatch(
      getAllData(
        moduleName,
        pageFunc,
        lengthFunc,
        "",
        filters,
        "&sort_createdAt=desc"
      )
    );
  };

  React.useEffect(
    () => {
      if (socketInfo) {
        socket = socketIOClient(webSocket, {
          path: "/websocket",
          transports: [ "websocket" ],
          query: {
            data: JSON.stringify(socketInfo)
          }
        });

        socket.on("notification", notification => {
          console.log("connected", notification);
          if (notification) {
            let titleFormatted = "";
            switch (notification.typeNotification) {
              case "alert":
                console.log("notification", notification.extra.name);
                titleFormatted = intl.formatMessage(
                  {
                    id: `notification.${notification.title}`
                  },
                  {equipmentName: notification.extra.name}
                );
                break;
              default:
                titleFormatted = notification.title;
                break;
            }

            if (notification.subTitle) {
              setSubtitle(notification.subTitle);
            }

            setTitle(titleFormatted);

            dispatch(fetchSuccess(titleFormatted));
          }
          getNotifications(0, data.length === 0 ? 8 : data.length + 1);
        });
      }
      getLanguage();

      if (!!!getConductorRoleId()) {
        findConductorRoleId();
      }

      return () => {
        socket.disconnect();
        setSocketInfo(null);
      };
      //
    },
    [ socketInfo ]
  );

  React.useEffect(
    () => {
      if (notifications) {
        let notificationsHandler = notifications.rows.map(notification => {
          switch (notification.typeNotification) {
            case "alert":
              return {
                ...notification,
                id: notification.id,
                seen: notification.seen,
                type: notification.typeNotification,
                title: (
                  <IntlMessages id={`notification.${notification.title}`} />
                ),
                description: (
                  <IntlMessages
                    id={`notification.${notification.subTitle}`}
                    values={{
                      equipmentName:
                        notification.extra && notification.extra.equipment
                          ? notification.extra.equipment.name
                          : ""
                    }}
                  />
                ),
                image: notification.typeNotification,
                clockicon: "clock",
                clock: moment(notification.createdAt).format("hh:mm a")
              };
            case "approve_management":
              let description = notification.subTitle.split("-");
              let subTitle = "";
              console.log("description=length====", description);
              if (description.length == 3) {
                subTitle =
                  intl.formatMessage({id: "sidebar." + description[0]}) +
                  "-" +
                  description[1] +
                  " " +
                  intl.formatMessage({id: "notification." + description[2]});
              }
              return {
                ...notification,
                id: notification.id,
                seen: notification.seen,
                title: intl.formatMessage({
                  id: "notification." + notification.title
                }),
                description: subTitle,
                image: notification.typeNotification,
                type: notification.typeNotification,
                clockicon: "clock",
                clock: moment(notification.createdAt).format("hh:mm a")
              };
            default:
              return {
                ...notification,
                id: notification.id,
                seen: notification.seen,
                title: notification.title,
                description: notification.subTitle,
                type: notification.typeNotification,
                image: "alert",
                clockicon: "clock",
                clock: moment(notification.createdAt).format("hh:mm a")
              };
          }
        });
        console.log("notificationsHandler", notificationsHandler);
        setData(notificationsHandler);
        // if (notificationsHandler.length > 8) {
        // 	setData(notificationsHandler);
        // } else {
        // 	setData([ ...data, ...notificationsHandler ]);
        // }

        setCounter(notifications.unseen);
      }
    },
    [ notifications ]
  );

  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
    // setCounter(0);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const loadMore = () => {
    console.log("data.length + 8", data.length + 8);
    getNotifications(0, data.length + 8);
  };

  // const loadMore = React.useCallback(() => {

  // 	setPage((old) => {
  // 		getNotifications(0, data.length + 8);
  // 		return old + 1;
  // 	});
  // }, []);

  const readNotification = (id, seen) => {
    let dataNotification = data.find(n => n.id === id);
    if (dataNotification && seen == false) {
      dataNotification.seen = true;
      dataNotification.readAt = new Date();
      delete dataNotification["title"];
      dispatch(
        updateData(moduleName, dataNotification, id, null, false, false)
      );
      // console.log('notifications.rows.length', notifications.rows.length);
      setTimeout(() => {
        getNotifications(0, data.length);
      }, 250);
    }
    if (dataNotification.type == "approve_management") {
      history.push(getRouteUrl("approve"));
    } else if (dataNotification.type == "alert") {
      history.push(
        getRouteUrl("alerts") + "/" + dataNotification.extra.alertsTypeId
      );
    }
    setAnchorEl(null);
  };

  const sendNotification = () => {
    notificationHelper.send(
      "Hei hei sexy boy",
      "this popup dont had virus wink wink ;)",
      getCompanyId(),
      null,
      getUserId()
    );
  };

  const getLanguage = () => {
    let language;
    if (localStorage.getItem("language")) {
      if (typeof localStorage.getItem("language") === "object") {
        language = localStorage.getItem("language");
      } else if (typeof localStorage.getItem("language") === "string") {
        language = JSON.parse(localStorage.getItem("language"));
      }
    }
    console.log(language, "lang");
    return language;
  };

  const isLastItem = index => {
    if (data.length - 1 === index) {
      return true;
    }

    if (data.length === notifications.count) {
      return false;
    }

    return false;
  };

  const handlePermissionGranted = () => {
    console.log("Permission Granted");
    toggleIgnore(false);
  };
  const handlePermissionDenied = () => {
    console.log("Permission Denied");
    toggleIgnore(true);
  };
  const handleNotSupported = () => {
    console.log("Web Notification not Supported");
    toggleIgnore(true);
  };

  const handleNotificationOnClick = (e, tag) => {
    console.log(e, "Notification clicked tag:" + tag);
    setTitle("");
    setSubtitle("");
  };

  const handleNotificationOnError = (e, tag) => {
    console.log(e, "Notification error tag:" + tag);
    setTitle("");
    setSubtitle("");
  };

  const handleNotificationOnClose = (e, tag) => {
    console.log(e, "Notification closed tag:" + tag);
    setTitle("");
    setSubtitle("");
  };

  const handleNotificationOnShow = (e, tag) => {
    console.log(e, "Notification shown tag:" + tag);
  };

  return (
    <React.Fragment>
      <Box pr={2} className={classes.userNotification}>
        <Tooltip title="Notifications">
          <IconButton
            onClick={onOpenPopOver}
            className={clsx(classes.iconRoot, "Cmt-appIcon", {
              active: counter > 0
            })}
          >
            <Badge
              badgeContent={counter}
              classes={{badge: classes.counterRoot}}
            >
              <NotificationsNoneOutlinedIcon />
            </Badge>
          </IconButton>
        </Tooltip>

        <Popover
          className={classes.popoverRoot}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={onClosePopOver}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div className={classes.arrowup} />
          <CmtCard className={classes.cardRoot}>
            {/* <CmtCardHeader
            title="Notifications"
            actionsPos="top-corner"
            actions={actions}
            separator={{
              color: theme.palette.borderColor.dark,
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          /> */}
            <CmtCardContent>
              <PerfectScrollbar className={classes.scrollbarRoot}>
                {notifications && (
                  <CmtList
                    data={data}
                    ListEmptyComponent={
                      <Box p={6}>
                        <Typography variant="body2">
                          <IntlMessages id={`notification.nonotification`} />
                        </Typography>
                      </Box>
                    }
                    renderRow={(item, index) => (
                      <NotificationItem
                        loadMore={loadMore}
                        readNotification={readNotification}
                        sendNotification={sendNotification}
                        isLast={isLastItem(index)}
                        key={index}
                        notification={item}
                        showLoadMore={
                          notifications && data.length !== notifications.count
                        }
                      />
                    )}
                  />
                )}
              </PerfectScrollbar>
            </CmtCardContent>
            {notifications &&
            data.length !== notifications.count && (
              <CmtCardHeader
                title={<IntlMessages id="livetracking.see_all_notification" />}
                actionsPos="top-center"
                onClick={() => {
                  if (notifications) {
                    getNotifications(0, notifications.count);
                  }
                }}
                // actions={actions}
                separator={{
                  color: theme.palette.borderColor.dark,
                  borderWidth: 1,
                  borderStyle: "solid",
                  textAlign: "center"
                }}
              />
            )}
          </CmtCard>
        </Popover>
      </Box>
      <Notification
        ignore={ignore && titleNotification !== ""}
        notSupported={handleNotSupported}
        onPermissionGranted={handlePermissionGranted}
        onPermissionDenied={handlePermissionDenied}
        onShow={handleNotificationOnShow}
        onClick={handleNotificationOnClick}
        onClose={handleNotificationOnClose}
        onError={handleNotificationOnError}
        timeout={5000}
        options={{
          tag: Date.now(),
          body: subtitle,
          icon:
            "http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png",
          lang: "en",
          dir: "ltr"
        }}
        title={titleNotification}
      />
    </React.Fragment>
  );
};

export default HeaderNotifications;
