import React from "react";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet
} from "@react-pdf/renderer";
import {getNumberFormat, getSixDigit} from "../../utils/commonHelper";
import {getYearFormat} from "../../utils/dateHelper";
// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey"
  },
  watermark: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%"
  },
  watermarkpending: {
    color: "#000",
    fontSize: 80,
    fontWeight: "bold",
    textTransform: "uppercase",
    transform: "rotate(-45deg)",
    userSelect: "none",
    opacity: 0.2
  },
  watermarkrejected: {
    color: "#000",
    fontSize: 80,
    fontWeight: "bold",
    textTransform: "uppercase",
    transform: "rotate(-45deg)",
    userSelect: "none",
    opacity: 0.2
  },

  headersection: {
    flexDirection: "row",
    width: "100%"
  },
  leftheadersection: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  image: {
    objectFit: "cover"
  },
  imagess: {
    objectFit: "cover",
    width: 80,
    height: 80,
    marginTop: 15,
    marginLeft: 25
  },
  leftinnerheadersection: {
    width: 80,
    height: 40,
    backgroundColor: "#ccc"
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    paddingTop: 10
  },
  sub_heading: {
    fontSize: 10,
    paddingTop: 10,
    fontWeight: 200
  },
  sub_sub_heading: {
    fontSize: 10,
    paddingTop: 10,
    fontWeight: 200,
    marginBottom: 10
  },
  bars: {
    width: "100%",
    height: 2,
    backgroundColor: "#000",
    marginTop: 1
  },
  rightheadersection: {
    width: "50%"
  },
  rightheadersectionbox: {
    width: "49%",
    marginLeft: "1%",
    marginTop: 5
  },
  header: {},
  leftheadersection: {
    width: "50%"
  },
  leftheadersectionbox: {
    width: "49%",
    marginRight: "1%",
    marginTop: 5
  },
  bars_div: {
    marginTop: 10
  },
  right_text: {
    fontSize: 18,
    paddingLeft: 10,
    fontWeight: "bold"
  },
  right_web: {
    paddingLeft: 35,
    fontSize: 12,
    paddingTop: 20
  },
  border_cover: {
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 10,
    padding: 15,
    height: 160
  },
  border_div: {
    flexDirection: "row",
    marginTop: 2,
    marginBottom: 2
  },
  border_left: {
    width: 120,
    fontSize: 12
  },
  border_left1: {
    width: 120,
    fontSize: 12
  },
  border_right: {
    fontSize: 10,
    marginRight: 50
  },

  border_right1: {
    fontSize: 10,
    marginRight: 50,
    marginTop: 1.5
  },
  bottomBox: {
    flexDirection: "row"
  },
  bottomBoxText: {
    fontSize: 12,
    padding: 5
  },
  headerTable: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#ccc",
    marginTop: 10
  },
  bodyTable: {
    width: "100%",
    flexDirection: "row"
  },
  headerTableTd: {
    width: "14.2%",
    fontSize: 12,
    elevation: 5,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  bodyTableTd: {
    width: "14.2%",
    fontSize: 10,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },

  headerDescriptionTd: {
    width: "25%",
    fontSize: 12,
    margin: 2
  },
  headerCostcenterTd: {
    width: "15%",
    fontSize: 12,
    margin: 2
  },
  headerTableTdtext: {
    textAlign: "center",
    textTransform: "capitalize"
  },
  bodyDescriptionTd: {
    width: "25%",
    fontSize: 10,
    margin: 2
  },
  bodyCostcenterTd: {
    width: "15%",
    fontSize: 10,
    margin: 2
  },
  subtotal_div: {
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  sub_total: {
    fontSize: 14,
    marginTop: 10,
    paddingLeft: 15,
    paddingRight: 15
  },
  bottom: {
    width: "100%",
    marginTop: 10,
    marginBottom: 20
  },
  footer: {
    position: "absolute",
    bottom: 5,
    left: 35,
    width: "100%"
  },
  footer_blacktext: {
    fontSize: 10,
    width: "100%"
  },
  footer_redtext: {
    fontSize: 10,
    color: "red",
    width: "100%"
  },

  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey"
  },

  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  headersection: {
    flexDirection: "row",
    width: "100%"
  },
  headersections: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between"
  },
  leftheadersection: {
    flexDirection: "row",
    justifyContent: "space-between"
  },
  image: {
    objectFit: "cover"
  },
  leftinnerheadersection: {
    width: 80,
    height: 40,
    backgroundColor: "#ccc"
  },
  heading: {
    fontSize: 14,
    fontWeight: "bold",
    paddingTop: 10
  },
  sub_heading: {
    fontSize: 10,
    paddingTop: 10,
    fontWeight: 200
  },
  sub_sub_heading: {
    fontSize: 10,
    paddingTop: 10,
    fontWeight: 200,
    marginBottom: 10
  },
  bars: {
    width: "100%",
    height: 2,
    backgroundColor: "#000",
    marginTop: 1
  },
  rightheadersection: {
    width: "50%"
  },
  rightheadersectionbox: {
    width: "49%",
    marginLeft: "1%",
    marginTop: 5
  },
  header: {},
  leftheadersection_div: {
    flexDirection: "row"
  },
  leftheadersection: {
    width: "50%"
  },
  leftheadersectionbox: {
    width: "49%",
    marginRight: "1%",
    marginTop: 5
  },
  bars_div: {
    marginTop: 10
  },
  right_text: {
    fontSize: 18,
    paddingLeft: 10,
    fontWeight: "bold"
  },
  right_web: {
    paddingLeft: 35,
    fontSize: 12,
    paddingTop: 20
  },
  border_cover: {
    borderWidth: 1,
    borderColor: "#000",
    borderRadius: 10,
    padding: 15,
    height: 160
  },
  border_cover1: {
    padding: 15
  },
  border_div: {
    flexDirection: "row",
    marginTop: 2,
    marginBottom: 2
  },
  border_divs: {
    flexDirection: "column",
    marginTop: 2,
    marginBottom: 2,
    width: "44%",
    marginLeft: "3%",
    marginRight: "3%"
  },
  border_left: {
    width: 120,
    fontSize: 12
  },
  border_left1: {
    width: 120,
    fontSize: 12
  },
  border_left11: {
    width: "100%",
    fontSize: 12,
    textAlign: "center"
  },
  border_left2: {
    width: 120,
    fontSize: 12
  },
  border_left_caps: {
    // textTransform: "uppercase",
    fontSize: 12,
    width: 120
  },
  border_right: {
    fontSize: 10,
    marginRight: 50
  },

  border_right1: {
    fontSize: 10,
    marginRight: 50,
    marginTop: 1.5,
    marginRight: 20,
    width: 120
  },
  headerTableTd1: {
    width: "25%",
    fontSize: 10,
    elevation: 5,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  headerTableSpareTd: {
    width: "25%",
    fontSize: 10,
    elevation: 5,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  headerTableSpareTd1: {
    width: "33%",
    fontSize: 10,
    elevation: 5,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  bodyTableSpareTd: {
    width: "25%",
    fontSize: 10,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  bodyTableSpareTd1: {
    width: "33%",
    fontSize: 10,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  headerTableimageTd: {
    width: "33%",
    fontSize: 10,
    elevation: 5,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  bodyTableimageTd: {
    width: "33.2%",
    fontSize: 10,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3,
    borderWidth: 0.3,
    borderColor: "#ccc"
  },

  bodyTableTd1: {
    width: "25%",
    fontSize: 10,
    textAlign: "center",
    justifyContent: "center",
    paddingTop: 3,
    paddingBottom: 3
  },
  headerTableTdtext: {
    textAlign: "center",
    fontSize: 10,
    textTransform: "capitalize"
  },
  bottom1: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50
  },
  bottom2: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 50
  }
});

// Create Document Component
export const Closedwodocument = props => {
  let {
    photo,
    selectedCompany,
    wodocumentNo,
    equipment,
    region,
    costCenter,
    mechanicCharge,
    maintenanceCompleteTypeItems,
    userWarehouseCompleteItems,
    visualImageCompleteItems,
    isValue,
    closeDate,
    intl
  } = props;
  let categoryItems = [];
  console.log("isValue=====", isValue);
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.header} fixed>
          <View style={styles.headersection}>
            <View style={styles.leftheadersection}>
              <View style={styles.leftinnerheadersection}>
                <Image
                  style={styles.image}
                  allowDangerousPaths={true}
                  src={"/images/flexlogo.jpg"}
                />
              </View>
              <View style={styles.rightinnerheadersection}>
                <Text style={styles.heading}>
                  {selectedCompany && selectedCompany.name}
                </Text>
                <Text style={styles.sub_heading}>
                  {selectedCompany && selectedCompany.address}
                </Text>
                <Text style={styles.sub_heading}>
                  {selectedCompany && selectedCompany.city}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headersection}>
          <View style={styles.leftheadersectionbox}>
            {/* <Text style={{ fontSize: 14 }}>{intl.formatMessage({ id: "contractors.supplier" })}</Text> */}
            <View style={styles.border_cover}>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "workorder.work_ordernumber"})}
                </Text>
                <Text style={styles.border_right}>: {wodocumentNo} </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "format.name"})}
                </Text>
                <Text style={styles.border_right}>
                  : {equipment ? equipment.name : ""}
                </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "format.registerNumber"})}
                </Text>
                <Text style={styles.border_right}>
                  : {equipment ? equipment.registerNumber : ""}
                </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "vehicle.brand"})}
                </Text>
                <Text style={styles.border_right}>
                  : {equipment ? equipment.make : ""}
                </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "vehicle.model"})}
                </Text>
                <Text style={styles.border_right}>
                  : {equipment ? equipment.model : ""}
                </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "workorder.YEAR"})}
                </Text>
                <Text style={styles.border_right}>
                  : {equipment ? getYearFormat(equipment.vehicleDate) : ""}{" "}
                </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "sidebar.wo_closedate"})}
                </Text>
                <Text style={styles.border_right}>
                  : {closeDate ? closeDate : ""}{" "}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.rightheadersectionbox}>
            <View style={styles.border_cover}>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "workorder.WORKSHOP"})}
                </Text>
                <Text style={styles.border_right1}>
                  : {region ? region.label : ""}
                </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "sidebar.costcenter"})}
                </Text>
                <Text style={{width: 5, fontSize: 11}}>:</Text>
                <Text style={styles.border_right1}>
                  {costCenter ? costCenter.label : ""}
                </Text>
              </View>
              <View style={styles.border_div}>
                <Text style={styles.border_left}>
                  {intl.formatMessage({id: "workorder.PERFORMED_BY"})}
                </Text>
                <Text style={styles.border_right1}>
                  : {mechanicCharge ? mechanicCharge.label : ""}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.headerTable}>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "sidebar.maintenancetype"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "users.Mechanic"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "maintenance.compliance_mark"})}
            </Text>
          </View>
          <View style={styles.headerTableTd1}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "sidebar.observation"})}
            </Text>
          </View>
        </View>
        {maintenanceCompleteTypeItems &&
          maintenanceCompleteTypeItems.map((item, i) => {
            return (
              <View style={styles.bodyTable} key={i}>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}> {item.name} </Text>
                </View>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}> {item.mechanic}</Text>
                </View>
                <View style={styles.bodyTableTd1}>
                  {item.compliance_mark != true && (
                    <Text style={styles.headerTableTdtext}>
                      {intl.formatMessage({id: "actions.yes"})}
                    </Text>
                  )}
                  {item.compliance_mark != false && (
                    <Text style={styles.headerTableTdtext}>
                      {intl.formatMessage({id: "actions.no"})}
                    </Text>
                  )}
                </View>
                <View style={styles.bodyTableTd1}>
                  <Text style={styles.headerTableTdtext}>
                    {" "}
                    {item.observation}
                  </Text>
                </View>
              </View>
            );
          })}

        <View style={styles.bars_div}>
          <View style={styles.bars} />
          <View style={styles.bars} />
        </View>
        {isValue && (
          <View style={styles.headerTable}>
            <View style={styles.headerTableSpareTd}>
              <Text style={styles.headerTableTdtext}>
                {intl.formatMessage({id: "spares.code"})}
              </Text>
            </View>
            <View style={styles.headerTableSpareTd}>
              <Text style={styles.headerTableTdtext}>
                {intl.formatMessage({id: "company.description"})}
              </Text>
            </View>
            <View style={styles.headerTableSpareTd}>
              <Text style={styles.headerTableTdtext}>
                {intl.formatMessage({id: "unit"})}
              </Text>
            </View>

            <View style={styles.headerTableSpareTd}>
              <Text style={styles.headerTableTdtext}>
                {intl.formatMessage({id: "workorder.AMOUNT"})}
              </Text>
            </View>

            {/* <View style={styles.headerTableTd}><Text style={styles.headerTableTdtext}>{intl.formatMessage({ id: "workorder.request_date" })}</Text></View>
                        <View style={styles.headerTableTd}><Text style={styles.headerTableTdtext}>{intl.formatMessage({ id: "workorder.departure_date" })}</Text></View>
                        <View style={styles.headerTableTd}><Text style={styles.headerTableTdtext}>{intl.formatMessage({ id: "actions.state" })}</Text></View> */}
          </View>
        )}

        {isValue &&
          userWarehouseCompleteItems &&
          userWarehouseCompleteItems.map((item, i) => {
            return (
              <View style={styles.bodyTable} key={i}>
                <View style={styles.bodyTableSpareTd}>
                  <Text style={styles.headerTableTdtext}> {item.code}</Text>
                </View>
                <View style={styles.bodyTableSpareTd}>
                  <Text style={styles.headerTableTdtext}>
                    {" "}
                    {item.description}
                  </Text>
                </View>
                <View style={styles.bodyTableSpareTd}>
                  <Text style={styles.headerTableTdtext}>
                    {" "}
                    {item.stock_sent}
                  </Text>
                </View>
                {isValue && (
                  <View style={styles.bodyTableSpareTd}>
                    <Text style={styles.headerTableTdtext}>{item.total}</Text>
                  </View>
                )}
              </View>
            );
          })}

        {isValue != true && (
          <View style={styles.headerTable}>
            <View style={styles.headerTableSpareTd1}>
              <Text style={styles.headerTableTdtext}>
                {intl.formatMessage({id: "spares.code"})}
              </Text>
            </View>
            <View style={styles.headerTableSpareTd1}>
              <Text style={styles.headerTableTdtext}>
                {intl.formatMessage({id: "company.description"})}
              </Text>
            </View>
            <View style={styles.headerTableSpareTd1}>
              <Text style={styles.headerTableTdtext}>
                {intl.formatMessage({id: "unit"})}
              </Text>
            </View>

            {/* <View style={styles.headerTableTd}><Text style={styles.headerTableTdtext}>{intl.formatMessage({ id: "workorder.request_date" })}</Text></View>
                        <View style={styles.headerTableTd}><Text style={styles.headerTableTdtext}>{intl.formatMessage({ id: "workorder.departure_date" })}</Text></View>
                        <View style={styles.headerTableTd}><Text style={styles.headerTableTdtext}>{intl.formatMessage({ id: "actions.state" })}</Text></View> */}
          </View>
        )}

        {isValue != true &&
          userWarehouseCompleteItems &&
          userWarehouseCompleteItems.map((item, i) => {
            return (
              <View style={styles.bodyTable} key={i}>
                <View style={styles.bodyTableSpareTd1}>
                  <Text style={styles.headerTableTdtext}> {item.code}</Text>
                </View>
                <View style={styles.bodyTableSpareTd1}>
                  <Text style={styles.headerTableTdtext}>
                    {" "}
                    {item.description}
                  </Text>
                </View>
                <View style={styles.bodyTableSpareTd1}>
                  <Text style={styles.headerTableTdtext}>
                    {" "}
                    {item.stock_sent}
                  </Text>
                </View>
              </View>
            );
          })}

        <View style={styles.bars_div}>
          <View style={styles.bars} />
          <View style={styles.bars} />
        </View>

        <View style={styles.headerTable}>
          <View style={styles.headerTableimageTd}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "workorder.GRAFICO"})}
            </Text>
          </View>
          <View style={styles.headerTableimageTd}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "sidebar.observation"})}
            </Text>
          </View>
          <View style={styles.headerTableimageTd}>
            <Text style={styles.headerTableTdtext}>
              {intl.formatMessage({id: "workorder.STATUS"})}
            </Text>
          </View>
        </View>
        {visualImageCompleteItems &&
          visualImageCompleteItems.map((item, i) => {
            return (
              <View style={styles.bodyTable} key={i}>
                <View style={styles.bodyTableimageTd}>
                  <Image
                    style={styles.imagess}
                    allowDangerousPaths={true}
                    src={item.image_url}
                  />
                </View>
                <View style={styles.bodyTableimageTd}>
                  <Text style={styles.headerTableTdtext}> {item.comments}</Text>
                </View>

                <View style={styles.bodyTableimageTd}>
                  <Text style={styles.headerTableTdtext}>
                    {item.modify_approval_status}
                  </Text>
                </View>
              </View>
            );
          })}
        {/* <View style={styles.bodyTable}>
          <View style={styles.bodyTableimageTd}>
            <Text style={styles.headerTableTdtext}> </Text>
          </View>
          <View style={styles.bodyTableimageTd}>
            <Text style={styles.headerTableTdtext}> </Text>
          </View>
          <View style={styles.bodyTableimageTd}>
            <Text style={styles.headerTableTdtext}> </Text>
          </View>
        </View> */}
      </Page>
    </Document>
  );
};
