import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router";
import PageLoader from "../../../@jumbo/components/PageComponents/PageLoader";
import {checkRouterUrl} from "../../../@jumbo/utils/commonHelper";

const Maintenance = ({match}) => {
  const requestedUrl = match.url.replace(/\/$/, "");

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl("maintenancegroup", "view") && (
          <Redirect
            exact
            from={`${requestedUrl}/`}
            to={`${requestedUrl}/maintenancegroup`}
          />
        )}

        {checkRouterUrl("maintenancegroup", "view") && (
          <Route
            path={`${requestedUrl}/maintenancegroup`}
            component={lazy(() => import("./Group"))}
          />
        )}
        {checkRouterUrl("maintenancegroup", "add") && (
          <Route
            path={`${requestedUrl}/addmaintenancegroup`}
            component={lazy(() => import("./AddGroup"))}
          />
        )}
        {checkRouterUrl("maintenancegroup", "edit") && (
          <Route
            path={`${requestedUrl}/editmaintenancegroup/:id`}
            component={lazy(() => import("./AddGroup"))}
          />
        )}

        {checkRouterUrl("resourcegroup", "view") && (
          <Route
            path={`${requestedUrl}/resourcegroup`}
            component={lazy(() => import("./Resource"))}
          />
        )}
        {checkRouterUrl("resourcegroup", "add") && (
          <Route
            path={`${requestedUrl}/addresourcegroup`}
            component={lazy(() => import("./AddResource"))}
          />
        )}
        {checkRouterUrl("resourcegroup", "edit") && (
          <Route
            path={`${requestedUrl}/editresourcegroup/:id`}
            component={lazy(() => import("./AddResource"))}
          />
        )}

        {checkRouterUrl("maintenancetype", "view") && (
          <Route
            path={`${requestedUrl}/maintenancetype`}
            component={lazy(() => import("./Type"))}
          />
        )}
        {checkRouterUrl("maintenancetype", "add") && (
          <Route
            path={`${requestedUrl}/addmaintenancetype`}
            component={lazy(() => import("./AddType"))}
          />
        )}
        {checkRouterUrl("maintenancetype", "edit") && (
          <Route
            path={`${requestedUrl}/editmaintenancetype/:id`}
            component={lazy(() => import("./AddType"))}
          />
        )}

        {checkRouterUrl("maintenanceform", "view") && (
          <Route
            path={`${requestedUrl}/maintenanceform`}
            component={lazy(() => import("./Forms"))}
          />
        )}
        {checkRouterUrl("maintenanceform", "add") && (
          <Route
            path={`${requestedUrl}/addmaintenanceform`}
            component={lazy(() => import("./AddForms"))}
          />
        )}
        {checkRouterUrl("maintenanceform", "edit") && (
          <Route
            path={`${requestedUrl}/editmaintenanceform/:id`}
            component={lazy(() => import("./AddForms"))}
          />
        )}

        {checkRouterUrl("maintenanceplan", "view") && (
          <Route
            path={`${requestedUrl}/maintenanceplan`}
            component={lazy(() => import("./Plan"))}
          />
        )}
        {checkRouterUrl("maintenanceplan", "add") && (
          <Route
            path={`${requestedUrl}/addmaintenanceplan`}
            component={lazy(() => import("./AddPlan"))}
          />
        )}
        {checkRouterUrl("maintenanceplan", "edit") && (
          <Route
            path={`${requestedUrl}/editmaintenanceplan/:id`}
            component={lazy(() => import("./AddPlan"))}
          />
        )}

        <Route component={lazy(() => import("../404"))} />
      </Switch>
    </Suspense>
  );
};

export default Maintenance;
