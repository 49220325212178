import React, {useState, useEffect} from "react";
import useStyles from "./index.style";
import CmtButtons from "./../CmtButtons";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import {makeStyles} from "@material-ui/core/styles";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import ViewWeekOutlinedIcon from "@material-ui/icons/ViewWeekOutlined";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {getRouteUrl} from "../../@jumbo/utils/commonHelper";
import {checkRouterUrl} from "../../@jumbo/utils/commonHelper";

const CmtTableToolBar = ({
  moduleName,
  onRefresh,
  onViewColumnsChange,
  displayData,
  isAdd,
  ...rest
}) => {
  useEffect(
    () => {
      if (moduleName === "trackhistory") {
        onRefresh();
      }
    },
    [ moduleName ]
  );
  let buttons = [
    {
      icon: <AddCircleOutlineOutlinedIcon classes={{root: "add_Btn_Icon"}} />,
      color: "add_Btn",
      title: <IntlMessages id="sidebar.create" />,
      tooltip: true,
      onClick: getRouteUrl(moduleName, "add")
    },
    {
      icon: <AutorenewOutlinedIcon classes={{root: "add_Btn_Icon"}} />,
      color: "add_Btn",
      title: <IntlMessages id="sidebar.refresh" />,
      tooltip: true,
      onClick: onRefresh
    }
    // { icon: <VisibilityIcon classes={{ root: 'add_Btn_Icon' }} />, color: 'add_Btn', title:'Refresh',tooltip:true, onClick: onRefresh },
  ];
  if (moduleName == "rolespermissions") {
    buttons = [
      {
        icon: <AddCircleOutlineOutlinedIcon classes={{root: "add_Btn_Icon"}} />,
        color: "add_Btn",
        title: <IntlMessages id="sidebar.create" />,
        tooltip: true,
        onClick: "/roles/addaccesspermission"
      },
      {
        icon: <AutorenewOutlinedIcon classes={{root: "add_Btn_Icon"}} />,
        color: "add_Btn",
        title: <IntlMessages id="sidebar.refresh" />,
        tooltip: true,
        onClick: onRefresh
      }
    ];
  }

  if (isAdd == false || !checkRouterUrl(moduleName, "add")) {
    buttons = [
      {
        icon: <AutorenewOutlinedIcon classes={{root: "add_Btn_Icon"}} />,
        color: "add_Btn",
        title: <IntlMessages id="sidebar.refresh" />,
        tooltip: true,
        onClick: onRefresh
      }
    ];
  }

  return <CmtButtons items={buttons} classes={{root: "cardCsvButtons"}} />;
};

export default CmtTableToolBar;
