import React, {useState, useEffect} from "react";
import {useDispatch} from "react-redux";

import useStyles from "./index.style";
import CmtButtons from "./../CmtButtons";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionIcon from "@material-ui/icons/Description";
import VisibilityIcon from "@material-ui/icons/Visibility";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteModalCard from "../../@coremat/DeleteModalCard";
import {fetchError} from "../../redux/actions/Common";
import {checkRouterUrl} from "../../@jumbo/utils/commonHelper";

import {useIntl} from "react-intl";

const CmtTableSelectToolBar = ({
  selectedRows,
  onRowsDelete,
  onRowsEdit,
  onRowsPdf,
  onRowsRequest,
  isEdit,
  isDelete,
  Ispdf,
  moduleName,
  onRowsApprove,
  onRowsObservation,
  onRowsView,
  isApprove,
  isApproveall,
  isApproveSelected,
  isRejectSelected,
  isRejectall,
  isRequest,
  isSendall,
  onlyShowIds,
  IsobservationButton,
  isView,
  isCopy,
  onCopy,
  ...rest
}) => {
  const dispatch = useDispatch();

  const [ Isdelete, setDeleteModal ] = useState(false);
  const [ arrayIndexSelected, setArrayIndex ] = useState(false);
  const intl = useIntl();
  const useStyles = makeStyles(theme => ({
    tableButtonsDiv: {
      margin: "3.5px"
    },
    tableButtons: {
      width: "100%",
      "& .CmtButtons-wrapper": {
        marginBottom: 0
      }
    }
  }));

  useEffect(
    () => {
      if (selectedRows && selectedRows.data && selectedRows.data.length > 0) {
        setArrayIndex(selectedRows.data.map(row => row.index));
      }
    },
    [ selectedRows ]
  );
  const onDelete = () => {
    setDeleteModal(true);
  };

  const onCancel = () => {
    console.log("on delete");
    setDeleteModal(false);
  };
  const onDeleteAction = () => {
    console.log("on delete");
    setDeleteModal(false);
    onRowsDelete(selectedRows);
  };

  const onEdit = () => {
    onRowsEdit(selectedRows.data[0].index);
  };

  const onPdf = () => {
    onRowsPdf(selectedRows.data);
  };

  const onApprove = () => {
    onRowsApprove(selectedRows.data);
  };

  const onRequest = () => {
    onRowsRequest(selectedRows.data);
  };

  const onCopyRequest = () => {
    onCopy(selectedRows.data);
  };

  const onObservation = () => {
    onRowsObservation(selectedRows.data);
  };

  const onView = () => {
    onRowsView(selectedRows.data);
  };

  const showInfo = () => {
    console.log(selectedRows);
  };

  let newEdit = isEdit;
  if (isEdit != false) {
    newEdit = checkRouterUrl(moduleName, "edit");
  }

  let newDelete = isDelete;
  if (isDelete != false) {
    newDelete = checkRouterUrl(moduleName, "remove");
  }

  let newPdf = Ispdf;
  if (Ispdf != undefined && newPdf != false) {
    newPdf = checkRouterUrl(moduleName, "download");
  }
  // console.log("arrayIndexSelected", arrayIndexSelected);

  let buttons = [];
  let newButtons = [];
  buttons = [
    newEdit != false &&
    selectedRows.data.length == 1 && {
      label: <IntlMessages id="actions.edit" />,
      color: "edit",
      icon: <EditIcon />,
      onClick: onEdit
    },
    newDelete != false && {
      label: <IntlMessages id="actions.delete" />,
      color: "remove",
      icon: <DeleteIcon />,
      onClick: onDelete
    },
    isRequest == true && {
      label: <IntlMessages id="purchaserequests.request" />,
      color: "add",
      icon: <DescriptionIcon />,
      onClick: onRequest
    },
    isCopy == true &&
    selectedRows.data.length == 1 && {
      label: <IntlMessages id="sidebar.copy" />,
      color: "add",
      icon: <DescriptionIcon />,
      onClick: onCopyRequest
    },

    isSendall == true && {
      label: <IntlMessages id="actions.send_all" />,
      color: "add",
      onClick: onRequest
    },
    newPdf == true && {
      label: <IntlMessages id="actions.pdf" />,
      color: "download",
      icon: <GetAppIcon />,
      onClick: onPdf
    },
    isApprove == true &&
    selectedRows.data.length == 1 && {
      label: <IntlMessages id="sidebar.approval" />,
      color: "download",
      onClick: onApprove
    },
    IsobservationButton == true &&
    selectedRows.data.length == 1 && {
      label: <IntlMessages id="sidebar.observation" />,
      color: "edit",
      icon: <DescriptionIcon />,
      onClick: onObservation
    },
    isView == true &&
    selectedRows.data.length == 1 && {
      label: <IntlMessages id="sidebar.view" />,
      color: "pdf",
      icon: <VisibilityIcon />,
      onClick: onView
    }
    // isApproveall == true &&
    // selectedRows.data.length == 1 && {
    //   label: <IntlMessages id="sidebar.approval_all" />,
    //   color: "add",
    //   onClick: onApprove
    // },
    // isRejectSelected == true &&
    // selectedRows.data.length == 1 && {
    //   label: <IntlMessages id="sidebar.reject_selected" />,
    //   color: "remove",
    //   onClick: onApprove
    // },
    // isApproveSelected == true &&
    // selectedRows.data.length == 1 && {
    //   label: <IntlMessages id="sidebar.approve_selected" />,
    //   color: "add",
    //   onClick: onApprove
    // },
    // isRejectall == true &&
    // selectedRows.data.length == 1 && {
    //   label: <IntlMessages id="sidebar.reject_all" />,
    //   color: "remove",
    //   onClick: onApprove
    // }
  ];
  // console.log("onlyShowIds", onlyShowIds);
  if (onlyShowIds.length > 0 && arrayIndexSelected.length > 0) {
    let showAllButtons = true;
    for (let i = 0; i < arrayIndexSelected.length; i++) {
      let arrayIndex = arrayIndexSelected[i];
      if (onlyShowIds.indexOf(arrayIndex) >= 0) {
        showAllButtons = false;
      }
    }
    if (showAllButtons) {
      for (let item of buttons) {
        if (item) {
          newButtons.push(item);
        }
      }
    } else {
      if (newPdf) {
        newButtons.push({
          label: <IntlMessages id="actions.pdf" />,
          icon: <GetAppIcon />,
          color: "pdf",
          onClick: onPdf
        });
      }
    }
  } else {
    for (let item of buttons) {
      if (item) {
        newButtons.push(item);
      }
    }
  }
  const classes = useStyles();
  return (
    <Grid className={classes.tableButtonsDiv}>
      <CmtButtons items={newButtons} className={classes.tableButtons} />
      <DeleteModalCard
        open={Isdelete}
        onDelete={onDeleteAction}
        onCancel={onCancel}
      />
    </Grid>
  );
};

export default CmtTableSelectToolBar;
