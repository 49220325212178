import React from "react";
import IntlMessages from "../../../../../utils/IntlMessages";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import WarningIcon from "@material-ui/icons/Warning";

export const headerNotifications = [
  {
    description:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy text,",
    title: <IntlMessages id="workorder.maintance" />,
    image: "maintenance",
    clockicon: "clock",
    clock: "12:21 pm"
  },
  {
    description:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy text,",
    title: <IntlMessages id="sidebar.Riskmanagement" />,
    image: "riskmanagement",
    clockicon: "clock",
    clock: "12:21 pm"
  },
  {
    description:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy text,",
    title: <IntlMessages id="sidebar.team" />,
    image: "team",
    clockicon: "clock",
    clock: "12:21 pm"
  },
  {
    description:
      "Lorem ipsum is a name for a common type of placeholder text. Also known as filler or dummy text,",
    title: <IntlMessages id="sidebar.settings" />,
    image: "settings",
    clockicon: "clock",
    clock: "12:21 pm"
  }
];

export const flags = {
  maintenance: "/images/maintenance.png",
  riskmanagement: "/images/riskmanagement.png",
  team: "/images/team.png",
  settings: "/images/settings.png",
  clock: <AccessTimeIcon fontSize="small" />,
  alert: <WarningIcon fontSize="small" />
};
