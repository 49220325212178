import firebase from 'firebase';

// Initialize Firebase
const config = {
  apiKey: "AIzaSyB_QLK_QllpgGJ30NYNBjVZ48EHXDiUzWI",
  authDomain: "intellitech-spa.firebaseapp.com",
  databaseURL: "https://intellitech-spa.firebaseio.com",
  projectId: "intellitech-spa",
  storageBucket: "intellitech-spa.appspot.com",
  messagingSenderId: "996256920419",
  appId: "1:996256920419:web:99874bc9522083fbd05956",
  measurementId: "G-MXE00K7FPN"
};

firebase.initializeApp(config);
const auth = firebase.auth();



const database = firebase.database();
export { auth, database };
