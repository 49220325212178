import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {getAllData} from "../../../redux/actions/Common";
import CmtTable from "../../../@coremat/CmtTable";

const moduleName = "company";

const Company = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {company} = useSelector(({common}) => common);

  useEffect(() => {
    dispatch(getAllData(moduleName, 0, 10, ""));
  }, []);

  const columns = [
    {label: "dashboard.name", name: "name", search: true, sort: true},
    {label: "users.alert_language", name: "language", sort: true},
    {label: "users.time_zone", name: "timeZone", sort: true},
    {label: "company.empresa", name: "description", search: true, sort: true},
    {label: "dashboard.address", name: "address", search: true, sort: true}
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid xs={12} className={classes.table}>
          <CmtTable
            title="sidebar.company"
            moduleName={moduleName}
            data={company}
            columns={columns}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Company;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));
