import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router";
import PageLoader from "../../../@jumbo/components/PageComponents/PageLoader";
import {checkRouterUrl} from "../../../@jumbo/utils/commonHelper";

const Team = ({match}) => {
  const requestedUrl = match.url.replace(/\/$/, "");

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl("inhouse", "view") && (
          <Redirect
            exact
            from={`${requestedUrl}/`}
            to={`${requestedUrl}/inhousepersonnel`}
          />
        )}
        {checkRouterUrl("inhouse", "view") && (
          <Route
            path={`${requestedUrl}/inhouse`}
            component={lazy(() => import("./InHousePersonnel"))}
          />
        )}
        {checkRouterUrl("inhouse", "add") && (
          <Route
            path={`${requestedUrl}/addinhouse`}
            component={lazy(() => import("./AddinHousePersonnel"))}
          />
        )}
        {checkRouterUrl("inhouse", "edit") && (
          <Route
            path={`${requestedUrl}/editinhouse/:id`}
            component={lazy(() => import("./AddinHousePersonnel"))}
          />
        )}

        {checkRouterUrl("providers", "view") && (
          <Route
            path={`${requestedUrl}/providers`}
            component={lazy(() => import("./Providers"))}
          />
        )}
        {checkRouterUrl("providers", "add") && (
          <Route
            path={`${requestedUrl}/addproviders`}
            component={lazy(() => import("./Addproviders"))}
          />
        )}
        {checkRouterUrl("providers", "edit") && (
          <Route
            path={`${requestedUrl}/editproviders/:id`}
            component={lazy(() => import("./Addproviders"))}
          />
        )}

        {checkRouterUrl("client", "view") && (
          <Route
            path={`${requestedUrl}/client`}
            component={lazy(() => import("./Clients"))}
          />
        )}
        {checkRouterUrl("client", "add") && (
          <Route
            path={`${requestedUrl}/addclient`}
            component={lazy(() => import("./Addclients"))}
          />
        )}
        {checkRouterUrl("client", "edit") && (
          <Route
            path={`${requestedUrl}/editclient/:id`}
            component={lazy(() => import("./Addclients"))}
          />
        )}

        <Route component={lazy(() => import("../404"))} />
      </Switch>
    </Suspense>
  );
};

export default Team;
