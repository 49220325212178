import React, {useContext, useEffect, useState} from "react";
import AppContext from "../contextProvider/AppContextProvider/AppContext";
import globalStyles from "../../../theme/GlobalCss";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import {AuhMethods} from "../../../services/auth";
import {CurrentAuthMethod} from "../../constants/AppConstants";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {LAYOUT_STYLES, LAYOUT_TYPES} from "../../constants/ThemeOptions";
import VerticalMinimal from "./VerticalLayouts/VerticalMinimal";
import {useLocation} from "react-router-dom";
import CmtImage from "../../../@coremat/CmtImage";
import {setTable} from "../../../redux/actions/Common";

const loading = "/images/loading.gif";

const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 1,
    width: "100%",
    height: "600px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  loading: {
    width: 40,
    height: 40,
    position: "fixed",
    left: 0,
    right: 0,
    margin: "auto",
    top: 0,
    bottom: 50
  }
}));

const AppLayout = ({children}) => {
  const [ isTemplateLoaded, setTemplateLoading ] = useState(false);
  const {layout, layoutStyle, themeType, updateThemeType} = useContext(
    AppContext
  );
  const {loadUser} = useSelector(({auth}) => auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  globalStyles();
  dispatch(setTable(null));

  useEffect(() => {
    dispatch(AuhMethods[CurrentAuthMethod].getAuthUser());
    updateThemeType(themeType);
    setTemplateLoading(true);
    console.log("reset layout====");
  }, []);

  useEffect(
    () => {
      setLayoutType();
    },
    [ layoutStyle ]
  );

  const setLayoutType = () => {
    if (layoutStyle === LAYOUT_STYLES.FULL_WIDTH) {
      document.body.classList.remove("layout-type-boxed");
      document.body.classList.remove("layout-type-framed");
      document.body.classList.add("layout-type-fullwidth");
    } else if (layoutStyle === LAYOUT_STYLES.BOXED) {
      document.body.classList.remove("layout-type-fullwidth");
      document.body.classList.remove("layout-type-framed");
      document.body.classList.add("layout-type-boxed");
    } else if (layoutStyle === LAYOUT_STYLES.FRAMED) {
      document.body.classList.remove("layout-type-boxed");
      document.body.classList.remove("layout-type-fullwidth");
      document.body.classList.add("layout-type-framed");
    }
  };

  if (!isTemplateLoaded || !loadUser) {
    return (
      <Box className={classes.circularProgressRoot}>
        {/* <CircularProgress /> */}
        <CmtImage src={loading} className={classes.loading} />
      </Box>
    );
  }

  if (
    location.pathname === "/signin" ||
    location.pathname === "/signup" ||
    location.pathname === "/forgot-password"
  ) {
    return (
      <Box display="flex" width={1} style={{height: "100vh"}}>
        {children}
      </Box>
    );
  }
  return <VerticalMinimal children={children} />;
};

export default AppLayout;
