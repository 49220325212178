import React, {useState, useEffect, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";

import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Grid from "@material-ui/core/Grid";
import {Box, fade, IconButton} from "@material-ui/core";
import Createcompletewo from "./Createcompletewo";
import Creditdebit from "./Creditdebit";
import Status from "./Status";
import Openclosedwo from "./Openclosedwo";
import Percentagecompliance from "./Percentagecompliance";
import Unitscustomermechanic from "./Unitscustomermechanic";
import Unitscustomernonmechanic from "./Unitscustomernonmechanic";

import CmtTable from "../../../@coremat/CmtTable";

import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";
import FilterCard from "../../../@jumbo/components/Common/FilterCard";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import RemoveIcon from "@material-ui/icons/Remove";
import {useIntl} from "react-intl";
import {
  getavailability,
  getEquipmentType
} from "../../../@jumbo/utils/commonHelper";
import {getAllData} from "../../../redux/actions/Common";

import {makeStyles} from "@material-ui/core/styles";

const hoursheaders = [
  {label: "format.name", name: "name", search: true, sort: true},
  {label: "vehicle.serial_no", name: "serialNumber", search: true, sort: true},
  {
    label: "equipment.total_hours",
    name: "horometer",
    search: true,
    sort: true
  },
  {label: "actions.state", name: "availability_name"},
  {label: "dashboard.client_region", name: "region_name"}
];
const kilometerheaders = [
  {label: "format.name", name: "name", search: true, sort: true},
  {label: "vehicle.serial_no", name: "serialNumber", search: true, sort: true},
  {
    label: "equipment.total_kms",
    name: "odometer",
    search: true,
    sort: true
  },
  {label: "actions.state", name: "availability_name"},
  {label: "dashboard.client_region", name: "region_name"}
];

const moduleName = "dashboard";

const DashBoard = () => {
  const classes = useStyles();
  const [ expanded1, setExpanded1 ] = React.useState(true);
  const [ expanded2, setExpanded2 ] = React.useState(true);
  const [ expanded3, setExpanded3 ] = React.useState(true);
  const [ expanded4, setExpanded4 ] = React.useState(true);
  const [ expanded5, setExpanded5 ] = React.useState(true);
  const [ expanded6, setExpanded6 ] = React.useState(true);
  const [ expanded7, setExpanded7 ] = React.useState(true);

  const {dashboard} = useSelector(({common}) => common);
  const {equipment} = useSelector(({common}) => common);
  const {equipmentKm} = useSelector(({common}) => common);

  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllData("equipment", 0, 10, ""));
    dispatch(getAllData("equipmentKm", 0, 10, ""));
  }, []);

  useEffect(
    () => {
      if (equipment && equipment.rows) {
        for (let item of equipment.rows) {
          let availability = getavailability().find(
            item1 => item1.value == item.availability
          );
          item.availability_name = availability
            ? intl.formatMessage({id: "availability." + item.availability})
            : "";
          let regionName = "";
          for (let item1 of item.region) {
            if (regionName == "") {
              regionName = item1.name;
            } else {
              regionName = regionName + "," + item1.name;
            }
          }
          item.region_name = regionName;

          let type = getEquipmentType().find(item1 => item1.value == item.type);
          item.type_name = type
            ? intl.formatMessage({id: "equipment." + item.type})
            : null;
        }
      }
      if (equipmentKm && equipmentKm.rows) {
        for (let item of equipmentKm.rows) {
          let availability = getavailability().find(
            item1 => item1.value == item.availability
          );
          item.availability_name = availability
            ? intl.formatMessage({id: "availability." + item.availability})
            : "";
          let regionName = "";
          for (let item1 of item.region) {
            if (regionName == "") {
              regionName = item1.name;
            } else {
              regionName = regionName + "," + item1.name;
            }
          }
          item.region_name = regionName;

          let type = getEquipmentType().find(item1 => item1.value == item.type);
          item.type_name = type
            ? intl.formatMessage({id: "equipment." + item.type})
            : null;
        }
      }
      console.log("equipment============", equipment);
    },
    [ equipment, equipmentKm ]
  );

  const handleExpand1Click = () => {
    setExpanded1(!expanded1);
  };
  const handleExpand2Click = () => {
    setExpanded2(!expanded2);
  };
  const handleExpand3Click = () => {
    setExpanded3(!expanded3);
  };
  const handleExpand4Click = () => {
    setExpanded4(!expanded4);
  };
  const handleExpand5Click = () => {
    setExpanded5(!expanded5);
  };
  const handleExpand6Click = () => {
    setExpanded6(!expanded6);
  };
  const handleExpand7Click = () => {
    setExpanded7(!expanded7);
  };

  return (
    <PageContainer>
      <FilterCard
        moduleName={"dashboard"}
        Iscompany={true}
        Ismultidatepicker={true}
      />

      <GridContainer container className={classes.container} spacing={3}>
        {dashboard &&
        dashboard.percentageComplianceChecked && (
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            classes={{root: "widgetGrid"}}
          >
            <Grid xs={12} classes={{root: "widgetBox_text"}}>
              <Grid>
                <Grid classes={{root: "widgetBoxHead"}}>
                  <Grid classes={{root: "widgetBoxHead_text"}}>
                    <IntlMessages id="sidebar.percentage_compliance" />
                  </Grid>
                  <IconButton classes={{root: "refreshIcon"}}>
                    {" "}
                    <AutorenewIcon />{" "}
                  </IconButton>
                  <IconButton
                    classes={{root: "minusIcon"}}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded1
                    })}
                    onClick={handleExpand1Click}
                    aria-expanded={expanded1}
                    aria-label="show more"
                  >
                    {" "}
                    <RemoveIcon />{" "}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expanded1} timeout="auto" unmountOnExit>
                <Percentagecompliance />
              </Collapse>
            </Grid>
          </Grid>
        )}
        {dashboard &&
        dashboard.createdFinishedChecked && (
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            classes={{root: "widgetGrid"}}
          >
            <Grid xs={12} classes={{root: "widgetBox_text"}}>
              <Grid>
                <Grid classes={{root: "widgetBoxHead"}}>
                  <Grid classes={{root: "widgetBoxHead_text"}}>
                    <IntlMessages id="sidebar.created_finished" />
                  </Grid>
                  <IconButton classes={{root: "refreshIcon"}}>
                    {" "}
                    <AutorenewIcon />{" "}
                  </IconButton>
                  <IconButton
                    classes={{root: "minusIcon"}}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded1
                    })}
                    onClick={handleExpand2Click}
                    aria-expanded={expanded2}
                    aria-label="show more"
                  >
                    {" "}
                    <RemoveIcon />{" "}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expanded2} timeout="auto" unmountOnExit>
                <Createcompletewo />
              </Collapse>
            </Grid>
          </Grid>
        )}
        {dashboard &&
        dashboard.creditVsDebitChecked && (
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            classes={{root: "widgetGrid"}}
          >
            <Grid xs={12} classes={{root: "widgetBox_text"}}>
              <Grid>
                <Grid classes={{root: "widgetBoxHead"}}>
                  <Grid classes={{root: "widgetBoxHead_text"}}>
                    <IntlMessages id="sidebar.credit_vs_debit" />
                  </Grid>
                  <IconButton classes={{root: "refreshIcon"}}>
                    {" "}
                    <AutorenewIcon />{" "}
                  </IconButton>
                  <IconButton
                    classes={{root: "minusIcon"}}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded2
                    })}
                    onClick={handleExpand3Click}
                    aria-expanded={expanded3}
                    aria-label="show more"
                  >
                    {" "}
                    <RemoveIcon />{" "}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expanded3} timeout="auto" unmountOnExit>
                <Creditdebit />
              </Collapse>
            </Grid>
          </Grid>
        )}
        {dashboard &&
        dashboard.statechecked && (
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            classes={{root: "widgetGrid"}}
          >
            <Grid xs={12} classes={{root: "widgetBox_text"}}>
              <Grid>
                <Grid classes={{root: "widgetBoxHead"}}>
                  <Grid classes={{root: "widgetBoxHead_text"}}>
                    <IntlMessages id="sidebar.state" />
                  </Grid>
                  <IconButton classes={{root: "refreshIcon"}}>
                    {" "}
                    <AutorenewIcon />{" "}
                  </IconButton>
                  <IconButton
                    classes={{root: "minusIcon"}}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded3
                    })}
                    onClick={handleExpand4Click}
                    aria-expanded={expanded4}
                    aria-label="show more"
                  >
                    {" "}
                    <RemoveIcon />{" "}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expanded4} timeout="auto" unmountOnExit>
                <Status />
              </Collapse>
            </Grid>
          </Grid>
        )}
        {dashboard &&
        dashboard.openVsClosedChecked && (
          <Grid
            item
            lg={4}
            md={4}
            sm={6}
            xs={12}
            classes={{root: "widgetGrid"}}
          >
            <Grid xs={12} classes={{root: "widgetBox_text"}}>
              <Grid>
                <Grid classes={{root: "widgetBoxHead"}}>
                  <Grid classes={{root: "widgetBoxHead_text"}}>
                    <IntlMessages id="sidebar.open_vs_closed" />
                  </Grid>
                  <IconButton classes={{root: "refreshIcon"}}>
                    {" "}
                    <AutorenewIcon />{" "}
                  </IconButton>
                  <IconButton
                    classes={{root: "minusIcon"}}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded4
                    })}
                    onClick={handleExpand5Click}
                    aria-expanded={expanded5}
                    aria-label="show more"
                  >
                    {" "}
                    <RemoveIcon />{" "}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expanded5} timeout="auto" unmountOnExit>
                <Openclosedwo />
              </Collapse>
            </Grid>
          </Grid>
        )}

        {dashboard &&
        dashboard.percentageClientNonmechanicalChecked && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            classes={{root: "widgetGrid"}}
          >
            <Grid xs={12} classes={{root: "widgetBox_text"}}>
              <Grid>
                <Grid classes={{root: "widgetBoxHead"}}>
                  <Grid classes={{root: "widgetBoxHead_text"}}>
                    <IntlMessages id="sidebar.percentage_client" />
                  </Grid>
                  <IconButton classes={{root: "refreshIcon"}}>
                    {" "}
                    <AutorenewIcon />{" "}
                  </IconButton>
                  <IconButton
                    classes={{root: "minusIcon"}}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded4
                    })}
                    onClick={handleExpand6Click}
                    aria-expanded={expanded6}
                    aria-label="show more"
                  >
                    {" "}
                    <RemoveIcon />{" "}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expanded6} timeout="auto" unmountOnExit>
                <Unitscustomermechanic />
              </Collapse>
            </Grid>
          </Grid>
        )}
        {dashboard &&
        dashboard.percentageClientChecked && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            classes={{root: "widgetGrid"}}
          >
            <Grid xs={12} classes={{root: "widgetBox_text"}}>
              <Grid>
                <Grid classes={{root: "widgetBoxHead"}}>
                  <Grid classes={{root: "widgetBoxHead_text"}}>
                    <IntlMessages id="sidebar.percentage_client_nonmechanic" />
                  </Grid>
                  <IconButton classes={{root: "refreshIcon"}}>
                    {" "}
                    <AutorenewIcon />{" "}
                  </IconButton>
                  <IconButton
                    classes={{root: "minusIcon"}}
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded4
                    })}
                    onClick={handleExpand7Click}
                    aria-expanded={expanded7}
                    aria-label="show more"
                  >
                    {" "}
                    <RemoveIcon />{" "}
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expanded7} timeout="auto" unmountOnExit>
                <Unitscustomernonmechanic />
              </Collapse>
            </Grid>
          </Grid>
        )}
        {dashboard &&
        dashboard.listAssetsChecked && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            classes={{root: "widgetGrid"}}
          >
            <Grid xs={12} classes={{root: "widgetBox_text"}}>
              {/* <Grid>
              <Grid classes={{ root: 'widgetBoxHead' }}>
                <Grid classes={{ root: 'widgetBoxHead_text' }}>
                  <IntlMessages id="dashboard.assets_list_hours" />
                </Grid>

              </Grid>
            </Grid> */}
              <Grid xs={12} classes={{root: "table"}}>
                <CmtTable
                  isAdd={false}
                  title="sidebar.assets_list_hours"
                  moduleName={"equipment"}
                  data={equipment}
                  columns={hoursheaders}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {dashboard &&
        dashboard.listkmsAssetsChecked && (
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            classes={{root: "widgetGrid"}}
          >
            <Grid xs={12} classes={{root: "widgetBox_text"}}>
              {/* <Grid>
              <Grid classes={{ root: 'widgetBoxHead' }}>
                <Grid classes={{ root: 'widgetBoxHead_text' }}>
                  <IntlMessages id="dashboard.assets_list_kms" />
                </Grid>

              </Grid>
            </Grid> */}
              <Grid xs={12} classes={{root: "table"}}>
                <CmtTable
                  isAdd={false}
                  title="sidebar.assets_list_kms"
                  moduleName={"equipmentKm"}
                  data={equipmentKm}
                  columns={kilometerheaders}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </GridContainer>
    </PageContainer>
  );
};

export default DashBoard;

const useStyles = makeStyles(theme => ({
  // container: {
  //   marginTop: '-80px!important'
  // }
}));
