import React from "react";
import api from "../../services/api";
import IntlMessages from "./IntlMessages";
import {
  getFilterQuery,
  getEquipmentType,
  getUserRegionFilterQuery,
  getUserWarehouseFilterQuery,
  getSixDigit
} from "./commonHelper";

const availabilityOptions = [];

let searchHelper = {
  async getOptionsSearchDetails(
    search,
    loadedOptions,
    type,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "createoption?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    console.log("selectedCompany", selectedCompany);
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (type != null) {
      console.log("type=====", type);
      apiPath = apiPath + getFilterQuery("name", type);
    }
    console.log(apiPath);
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        let count = 0;
        for (let option of data.option) {
          count = count + 1;
          searchList.push({
            value: option.value != "" ? option.value : count,
            key: count,
            label: option.name
          });
        }
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }

    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getCompanySearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "company?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    apiPath = apiPath + "&sort_name=asc";

    let response = await api.getApi(apiPath);
    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    console.log("searchList", searchList);
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getRoleSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "role?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log(rowData);
      for (let data of rowData) {
        if (
          data.value != "super_admin" &&
          data.value != "superadmin" &&
          data.value != "super admin" &&
          data.value != "Super Admin"
        ) {
          searchList.push({
            value: data.value,
            id: data.id,
            key: data.value,
            label: data.name
          });
        }
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getSearchDispatchGuideNo(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter,
    onlyMyDispatchGuide = false
  ) {
    console.log("loadedOptions==", loadedOptions);

    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "depaturetransferdispatch?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_dispatchGuideNo=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }

    if (onlyMyDispatchGuide) {
      apiPath =
        apiPath + getUserWarehouseFilterQuery("destinationWarehouseId", "main");
    }
    apiPath =
      apiPath + "&filter_IsTransfered=new&filter_approvalStatus=approve";
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log(rowData);
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          id: data.id,
          key: data.value,
          label: getSixDigit(data.dispatchGuideNo)
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getDigitalSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);

    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "digital?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log(rowData);
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          id: data.id,
          key: data.value,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getToolsSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);

    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "tools?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log(rowData);
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          id: data.id,
          key: data.value,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getMaintenanceGroupSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);

    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "maintenancegroup?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    apiPath = apiPath + "&filter_status=true";
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log(rowData);
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          id: data.id,
          key: data.value,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getCreditSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter,
    onlyMyCreditNote = false
  ) {
    console.log("loadedOptions==", loadedOptions);

    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath =
      "creditnote?page=" +
      page +
      "&limit=" +
      limit +
      "&filter_debitStatus=new&filter_approvalStatus=approve,rejected";
    if (search && search != "") {
      apiPath = apiPath + "&search_documentNo=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }

    if (onlyMyCreditNote) {
      apiPath =
        apiPath +
        getUserRegionFilterQuery("regionId", "main") +
        getUserWarehouseFilterQuery("warehouseId", "main");
    }
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log(rowData);
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          id: data.id,
          key: data.value,
          label: getSixDigit(data.documentNo)
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getProfessionalFees(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter,
    onlyMyElectronicInvoices = false
  ) {
    console.log("loadedOptions==", loadedOptions);

    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "professionalfees?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_documentNo=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (props && props.warehouseId) {
      apiPath = apiPath + "&filter_warehouseId=" + props.warehouseId;
    } else {
      if (onlyMyElectronicInvoices) {
        apiPath =
          apiPath +
          getUserRegionFilterQuery("regionId", "main") +
          getUserWarehouseFilterQuery("warehouseId", "main");
      }
    }
    if (props && props.code) {
      apiPath = apiPath + "&array_items_code=" + props.code;
    }
    if (props && props.transferDepatureStatus) {
      apiPath =
        apiPath +
        "&filter_transferDepatureStatus=" +
        props.transferDepatureStatus;
    }

    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log(rowData);
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          id: data.id,
          key: data.value,
          label: getSixDigit(data.documentNo)
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getElectronicInvoiceSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter,
    onlyMyElectronicInvoices = false
  ) {
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath =
      "electronicinvoice?page=" +
      page +
      "&limit=" +
      limit +
      "&filter_approvalStatus=approve,rejected";
    if (search && search != "") {
      apiPath = apiPath + "&search_documentNo=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (props && props.creditStatus) {
      apiPath = apiPath + "&filter_creditStatus=" + props.creditStatus;
    }
    if (props && props.code) {
      apiPath = apiPath + "&array_items_code=" + props.code;
    }
    if (props && props.service) {
      apiPath = apiPath + "&array_items_service=" + props.service;
    }

    if (props && props.transferDepatureStatus) {
      apiPath =
        apiPath +
        "&filter_transferDepatureStatus=" +
        props.transferDepatureStatus;
    }
    if (props && props.warehouseId) {
      apiPath = apiPath + "&filter_warehouseId=" + props.warehouseId;
    } else {
      if (onlyMyElectronicInvoices) {
        apiPath =
          apiPath +
          getUserRegionFilterQuery("regionId", "main") +
          getUserWarehouseFilterQuery("warehouseId", "main");
      }
    }

    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log(rowData);
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          id: data.id,
          key: data.value,
          label: getSixDigit(data.documentNo)
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getPurchaseOrderSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);

    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "purchaseorder?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_invoiceNo=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (props && props.service) {
      apiPath = apiPath + "&array_items_service=" + props.service;
    }
    apiPath = apiPath + getUserWarehouseFilterQuery("warehouseId", "main");
    apiPath =
      apiPath +
      "&filter_approvalStatus=approve&filter_purchaseStatus=new,progress";
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log(rowData);
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          id: data.id,
          key: data.value,
          label: getSixDigit(data.invoiceNo)
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getEquipmentFilterDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter,
    distinct
  ) {
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "equipmentfilter?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (props) {
      if (props.equipmentType && props.equipmentType.value != -1) {
        apiPath = apiPath + "&filter_type=" + props.equipmentType.value;
      }
      if (props.make && props.make.value != -1) {
        apiPath = apiPath + "&filter_make=" + props.make.value;
      }
      if (props.model && props.model.value != -1) {
        apiPath = apiPath + "&filter_model=" + props.model.value;
      }
    }

    if (distinct && distinct != null) {
      apiPath = apiPath + "&distinct=" + distinct;
    }
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        if (data[distinct]) {
          data.value = data[distinct];
          data.availability = availabilityOptions.find(
            item => item.value == data.availability
          );
          data.availability = data.availability ? data.availability : null;
          data.type = getEquipmentType().find(item => item.value == data.type);
          data.type = data.type ? data.type.label : null;
          searchList.push({
            ...data,
            value: data.value,
            id: data.id,
            key: data.value,
            label: data[distinct]
          });
        }
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getEquipmentSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("selectedCompany==", selectedCompany);

    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let format =
      selectedCompany && selectedCompany.format
        ? selectedCompany.format
        : "name";
    let apiPath = "equipment?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + `&search_${format}=` + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    apiPath = apiPath + `&sort_${format}=asc`;

    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        data.availability = availabilityOptions.find(
          item => item.value == data.availability
        );
        data.availability = data.availability ? data.availability : null;

        // data.type = equipmentType.find(item => item.value == data.type);
        // data.type = data.type?data.type:null;
        searchList.push({
          ...data,
          value: data.id,
          id: data.id,
          key: data.value,
          label: data[format]
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    console.log("searchList==", searchList);
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getInhouseSearchDetails(
    search,
    loadedOptions,
    type,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "inhouse?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath =
        apiPath + "&search_firstName=" + search + "&search_lastName=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (type != null) {
      apiPath = apiPath + getFilterQuery("roleId", type);
    }
    apiPath = apiPath + getUserWarehouseFilterQuery("warehouseId", "main");

    let response = await api.getApi(apiPath);
    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      console.log("type=====", rowData);
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.firstName + " " + data.lastName
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getRegionSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter,
    onlyMyRegion = false
  ) {
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "region?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    apiPath = apiPath + "&sort_name=asc";
    if (onlyMyRegion) {
      apiPath = apiPath + getUserRegionFilterQuery("id", "main");
    } else {
      apiPath = apiPath + getUserRegionFilterQuery("id");
    }

    console.log(apiPath);
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          value: data.id,
          key: data.id,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getGroupSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "groups?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    apiPath = apiPath + "&sort_name=asc";

    console.log(apiPath);
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          value: data.id,
          key: data.id,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getWarehouseSearchDetails(
    search,
    loadedOptions,
    state,
    selectedCompany,
    filter,
    onlyMyWarehouse = false,
    check = ""
  ) {
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "warehouse?page=" + page + "&limit=" + limit;

    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (state && state.region != null && state.region.value != -1) {
      apiPath = apiPath + getFilterQuery("regionId", state.region);
    }
    if (state && state.code) {
      apiPath = apiPath + "&code=" + state.code;
    }

    apiPath = apiPath + "&sort_name=asc";
    if (state && state.filter == false) {
      console.log("no need filter warehouse");
    } else {
      if (onlyMyWarehouse) {
        apiPath = apiPath + getUserWarehouseFilterQuery("id", "main");
      } else {
        apiPath = apiPath + getUserWarehouseFilterQuery("id");
      }
    }

    console.log("state.filter==");
    // if (state && state.filter == false) {
    //   // apiPath = apiPath + getUserWarehouseFilterQuery("id");
    //   console.log("userfilter===");
    // } else {
    //   apiPath = apiPath + getUserWarehouseFilterQuery("id");
    // }

    console.log("apiPath==", apiPath);
    let response = await api.getApi(apiPath);
    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.name
        });
      }
    }

    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    console.log("searchList===", searchList.length);
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getProviderSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "providers?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    apiPath = apiPath + "&sort_name=asc";

    let response = await api.getApi(apiPath);
    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.name,
          providerNumber: data.providerNumber
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getProviderRutSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "providers?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }

    let response = await api.getApi(apiPath);
    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.providerNumber,
          providerNumber: data.providerNumber,
          name: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getSupplierSearchGuide(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "supplierofficeguide?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_documentNo=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (props && props.providerId) {
      apiPath = apiPath + "&filter_providerId=" + props.providerId;
    }
    if (props && props.code) {
      apiPath = apiPath + "&array_items_code=" + props.code;
    }
    if (props && props.transferDepatureStatus) {
      apiPath =
        apiPath +
        "&filter_transferDepatureStatus=" +
        props.transferDepatureStatus;
    }
    if (props && props.warehouseId) {
      apiPath = apiPath + "&filter_warehouseId=" + props.warehouseId;
    } else {
      apiPath = apiPath + getUserWarehouseFilterQuery("warehouseId", "main");
    }
    apiPath =
      apiPath +
      "&filter_electronicStatus=pending&filter_approvalStatus=approve,rejected";
    let response = await api.getApi(apiPath);
    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.documentNo,
          name: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getRolePermissionsSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter,
    except = []
  ) {
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "rolespermissions?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    let response = await api.getApi(apiPath);
    console.log("response", response);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        if (
          data.role &&
          data.role.value != "super_admin" &&
          data.role.value != "superadmin" &&
          data.role.value != "super admin" &&
          data.role.value != "Super Admin"
        ) {
          searchList.push({
            value: data.role ? data.role.id : "",
            id: data.role ? data.role.id : "",
            key: data.role ? data.role.id : "",
            label: data.role ? data.role.name : ""
          });
        }
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getMaintanencePlanSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "maintenanceplan?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (props) {
      apiPath = apiPath + "&filter_equipmentId=" + props.value;
    }
    apiPath = apiPath + "&filter_status=true";
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getCostCenterSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "costcenter?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    apiPath = apiPath + "&sort_name=asc";

    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          value: data.id,
          key: data.id,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getServiceSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "service?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          value: data.id,
          key: data.id,
          label: data.name,
          region: data.region
            ? {label: data.region.name, value: data.region.id}
            : null
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getClientSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "client?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    apiPath = apiPath + "&sort_name=asc";
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getSupervisorSearchDetails(
    search,
    loadedOptions,
    type,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "inhouse?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    // if(type!=null){
    //     console.log('type=====',type);
    // 	apiPath = apiPath +  getFilterQuery('name',type)
    // }
    console.log(apiPath);
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.firstName + " " + data.lastName
        });
      }
    }

    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },

  async getSpareCodeSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter,
    onlyMyWarehouse = false
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "sparessupplies?page=" + page + "&limit=" + limit;
    if (props && props.code) {
      if (search && search != "") {
        search = search.toLowerCase();
        let splitCode = search.split("cdu");
        if (splitCode.length == 2) {
          apiPath = apiPath + "&search_code=" + search;
        } else {
          apiPath = apiPath + "&search_code=" + props.code + search;
        }
      } else {
        apiPath = apiPath + "&search_code=" + props.code;
      }
    } else if (search && search != "") {
      apiPath = apiPath + "&search_code=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (props && props.services) {
      apiPath = apiPath + "&filter_service=" + props.services;
    }
    if (props && props.warehouseId) {
      apiPath = apiPath + "&filter_warehouseId=" + props.warehouseId.id;
    }

    if (onlyMyWarehouse) {
      apiPath = apiPath + getUserWarehouseFilterQuery("warehouseId", "main");
    }
    //
    //apiPath = apiPath + getFilterQuery("service", "yes") + "&sort_code=asc";
    apiPath = apiPath + "&sort_code=asc";
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.code
        });
      }
    }

    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }

    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getResourceGroupSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "resourcegroup?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    if (props.maintanencePlan) {
      let resourceGroupId = [];
      for (let item of props.maintanencePlan) {
        resourceGroupId = resourceGroupId.concat(item.resourceGroupId);
      }
      console.log("resourceGroupId====", resourceGroupId);
      if (resourceGroupId == "") {
        apiPath = apiPath + "&filter_id=-1";
      } else {
        apiPath = apiPath + "&filter_id=" + resourceGroupId;
      }
    }
    apiPath = apiPath + "&filter_status=true";

    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: data.maintenanceId
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getMaintenanceFormSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "maintenanceform?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    apiPath = apiPath + "&filter_status=true";

    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          value: data.id,
          key: data.id,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getMaintenanceTypeSearchDetails(
    search,
    loadedOptions,
    props,
    selectedCompany,
    filter
  ) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath = "maintenancetype?page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }
    if (selectedCompany != null && selectedCompany.value != -1) {
      apiPath = apiPath + getFilterQuery("companyId", selectedCompany);
    }
    apiPath = apiPath + "&filter_status=true";
    let response = await api.getApi(apiPath);

    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          value: data.id,
          key: data.id,
          label: data.name
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  },
  async getAlertTypesDetails(search, loadedOptions, filter) {
    console.log("loadedOptions==", loadedOptions);
    let limit = 10;
    let page = Math.round(loadedOptions.length / limit);
    let hasMore = true;
    let apiPath =
      "alertsType?filter_type=tracking&page=" + page + "&limit=" + limit;
    if (search && search != "") {
      apiPath = apiPath + "&search_name=" + search;
    }

    let response = await api.getApi(apiPath);
    let searchList = [];

    if (response.status == "success") {
      let rowData = response.data.rows;
      for (let data of rowData) {
        searchList.push({
          ...data,
          value: data.id,
          key: data.id,
          label: <IntlMessages id={`alert.${data.name}`} />
        });
      }
    }
    let totalCount = loadedOptions.length + searchList.length;

    if (response.data.count <= totalCount) {
      hasMore = false;
    }
    if (filter == true && loadedOptions.length == 0) {
      searchList = [
        {value: -1, label: <IntlMessages id="table.all" />}
      ].concat(searchList);
    }
    return {
      options: searchList,
      hasMore: hasMore
    };
  }
};

export default searchHelper;
