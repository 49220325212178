import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router";
import PageLoader from "../../../@jumbo/components/PageComponents/PageLoader";
import {checkRouterUrl} from "../../../@jumbo/utils/commonHelper";

const WarehouseAdmission = ({match}) => {
  const requestedUrl = match.url.replace(/\/$/, "");

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl("purchaseorder", "view") && (
          <Redirect
            exact
            from={`${requestedUrl}/`}
            to={`${requestedUrl}/purchaseorders`}
          />
        )}

        {checkRouterUrl("purchaseorder", "view") && (
          <Route
            path={`${requestedUrl}/purchaseorder`}
            component={lazy(() => import("./PurchaseOrders"))}
          />
        )}
        {checkRouterUrl("purchaseorder", "add") && (
          <Route
            path={`${requestedUrl}/addpurchaseorder`}
            component={lazy(() => import("./AddPurchaseOrders"))}
          />
        )}
        {checkRouterUrl("purchaseorder", "edit") && (
          <Route
            path={`${requestedUrl}/editpurchaseorder/:id`}
            component={lazy(() => import("./AddPurchaseOrders"))}
          />
        )}

        {checkRouterUrl("electronicinvoice", "view") && (
          <Route
            path={`${requestedUrl}/electronicinvoice`}
            component={lazy(() => import("./ElectronicInvoice"))}
          />
        )}
        {checkRouterUrl("electronicinvoice", "add") && (
          <Route
            path={`${requestedUrl}/addelectronicinvoice`}
            component={lazy(() => import("./AddElectronicInvoice"))}
          />
        )}
        {checkRouterUrl("electronicinvoice", "edit") && (
          <Route
            path={`${requestedUrl}/editelectronicinvoice/:id`}
            component={lazy(() => import("./AddElectronicInvoice"))}
          />
        )}

        {checkRouterUrl("supplierofficeguide", "view") && (
          <Route
            path={`${requestedUrl}/supplierofficeguide`}
            component={lazy(() => import("./ProviderDispatchGuide"))}
          />
        )}
        {checkRouterUrl("supplierofficeguide", "add") && (
          <Route
            path={`${requestedUrl}/addsupplierofficeguide`}
            component={lazy(() => import("./AddProviderDispatchGuide"))}
          />
        )}
        {checkRouterUrl("supplierofficeguide", "edit") && (
          <Route
            path={`${requestedUrl}/editsupplierofficeguide/:id`}
            component={lazy(() => import("./AddProviderDispatchGuide"))}
          />
        )}

        {checkRouterUrl("professionalfees", "view") && (
          <Route
            path={`${requestedUrl}/professionalfees`}
            component={lazy(() => import("./ProfessionalFeesBill"))}
          />
        )}
        {checkRouterUrl("professionalfees", "add") && (
          <Route
            path={`${requestedUrl}/addprofessionalfees`}
            component={lazy(() => import("./AddProfessionalFeesBill"))}
          />
        )}
        {checkRouterUrl("professionalfees", "edit") && (
          <Route
            path={`${requestedUrl}/editprofessionalfees/:id`}
            component={lazy(() => import("./AddProfessionalFeesBill"))}
          />
        )}

        {checkRouterUrl("creditnote", "view") && (
          <Route
            path={`${requestedUrl}/creditnote`}
            component={lazy(() => import("./CreditNote"))}
          />
        )}
        {checkRouterUrl("creditnote", "add") && (
          <Route
            path={`${requestedUrl}/addcreditnote`}
            component={lazy(() => import("./AddCreditNote"))}
          />
        )}
        {checkRouterUrl("creditnote", "edit") && (
          <Route
            path={`${requestedUrl}/editcreditnote/:id`}
            component={lazy(() => import("./AddCreditNote"))}
          />
        )}

        {checkRouterUrl("debitnote", "view") && (
          <Route
            path={`${requestedUrl}/debitnote`}
            component={lazy(() => import("./DebitNote"))}
          />
        )}
        {checkRouterUrl("debitnote", "add") && (
          <Route
            path={`${requestedUrl}/adddebitnote`}
            component={lazy(() => import("./AddDebitNote"))}
          />
        )}
        {checkRouterUrl("debitnote", "edit") && (
          <Route
            path={`${requestedUrl}/editdebitnote/:id`}
            component={lazy(() => import("./AddDebitNote"))}
          />
        )}

        {checkRouterUrl("admissiontransferdispatch", "view") && (
          <Route
            path={`${requestedUrl}/admissiontransferdispatch`}
            component={lazy(() => import("./TransferDispatchGuide"))}
          />
        )}
        {checkRouterUrl("admissiontransferdispatch", "add") && (
          <Route
            path={`${requestedUrl}/addadmissiontransferdispatch`}
            component={lazy(() => import("./AddTransferDispatchGuide"))}
          />
        )}
        {checkRouterUrl("admissiontransferdispatch", "edit") && (
          <Route
            path={`${requestedUrl}/editadmissiontransferdispatch/:id`}
            component={lazy(() => import("./AddTransferDispatchGuide"))}
          />
        )}

        {checkRouterUrl("admissioninternalguide", "view") && (
          <Route
            path={`${requestedUrl}/admissioninternalguide`}
            component={lazy(() => import("./InternalGuide"))}
          />
        )}
        {checkRouterUrl("admissioninternalguide", "add") && (
          <Route
            path={`${requestedUrl}/addadmissioninternalguide`}
            component={lazy(() => import("./AddInternalGuide"))}
          />
        )}
        {checkRouterUrl("admissioninternalguide", "edit") && (
          <Route
            path={`${requestedUrl}/editadmissioninternalguide/:id`}
            component={lazy(() => import("./AddInternalGuide"))}
          />
        )}

        {checkRouterUrl("adjustment", "view") && (
          <Route
            path={`${requestedUrl}/adjustment`}
            component={lazy(() => import("./Adjustment"))}
          />
        )}
        {checkRouterUrl("adjustment", "add") && (
          <Route
            path={`${requestedUrl}/addadjustment`}
            component={lazy(() => import("./AddAdjustment"))}
          />
        )}
        {checkRouterUrl("adjustment", "edit") && (
          <Route
            path={`${requestedUrl}/editadjustment/:id`}
            component={lazy(() => import("./AddAdjustment"))}
          />
        )}

        <Route component={lazy(() => import("../404"))} />
      </Switch>
    </Suspense>
  );
};

export default WarehouseAdmission;
