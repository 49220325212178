import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router";
import PageLoader from "../../../@jumbo/components/PageComponents/PageLoader";
import {checkRouterUrl} from "../../../@jumbo/utils/commonHelper";

const BusinessIntelligence = ({match}) => {
  const requestedUrl = match.url.replace(/\/$/, "");

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl("routereport", "view") && (
          <Redirect
            exact
            from={`${requestedUrl}/`}
            to={`${requestedUrl}/routereport`}
          />
        )}

        {/* {checkRouterUrl("vehicleperformancereport", "view") && (
          <Route
            path={`${requestedUrl}/vehicleperformancereport`}
            component={lazy(() => import("./VehicleperformanceReport"))}
          />
        )} */}
        {checkRouterUrl("routereport", "view") && (
          <Route
            path={`${requestedUrl}/routereport`}
            component={lazy(() => import("./RouteReport"))}
          />
        )}
        {checkRouterUrl("detailedreport", "view") && (
          <Route
            path={`${requestedUrl}/detailedreport`}
            component={lazy(() => import("./DetailedReport"))}
          />
        )}
        {checkRouterUrl("costingreport", "view") && (
          <Route
            path={`${requestedUrl}/costingreport`}
            component={lazy(() => import("./CostingReport"))}
          />
        )}
        {checkRouterUrl("usagereport", "view") && (
          <Route
            path={`${requestedUrl}/usagereport`}
            component={lazy(() => import("./UsageReport"))}
          />
        )}
        {checkRouterUrl("maintenancereport", "view") && (
          <Route
            path={`${requestedUrl}/maintenancereport`}
            component={lazy(() => import("./MaintenanceReport"))}
          />
        )}
        {checkRouterUrl("insurancereport", "view") && (
          <Route
            path={`${requestedUrl}/insurancereport`}
            component={lazy(() => import("./InsuranceReport"))}
          />
        )}
        {checkRouterUrl("purchasereport", "view") && (
          <Route
            path={`${requestedUrl}/purchasereport`}
            component={lazy(() => import("./PurchaseReport"))}
          />
        )}
        {checkRouterUrl("workorderreport", "view") && (
          <Route
            path={`${requestedUrl}/workorderreport`}
            component={lazy(() => import("./WorkorderReport"))}
          />
        )}

        <Route component={lazy(() => import("../404"))} />
      </Switch>
    </Suspense>
  );
};

export default BusinessIntelligence;
