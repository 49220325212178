import React, { cloneElement, isValidElement } from 'react';
import { List } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  navMenuItem: {
    padding: '0 0 0 0',
    position: 'relative',
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  navMenuLink: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px 10px 25px',
    borderTopRightRadius: 7,
    borderBottomRightRadius: 7,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    color: theme.palette.sidebar.textInactiveColor,
    fontWeight:'bold',
    '&:hover, &:focus': {
      color: theme.palette.sidebar.textHoverColor,
      // backgroundColor: theme.palette.sidebar.navHoverBgColor,
      fontWeight:'bold',
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: theme.palette.sidebar.textHoverColor,
        paddingLeft:10,
      },
      '& .Cmt-icon-root, & .menu_icon': {
        width:20,
        height:20,
        display:'none'
      },
      '& .Cmt-icon-root, & .active_menu_icon': {
        width:20,
        height:20,
        display:'none'
      },
      '& .Cmt-icon-root, & .hover_menu_icon': {
        width:20,
        height:20,
        display:'block'
      },     
    },
    '&.active': {
      color: theme.palette.sidebar.textActiveColor,
      fontWeight:'bold',
      '& .Cmt-icon-root, & .Cmt-nav-text': {
        color: theme.palette.sidebar.textActiveColor,
        paddingLeft:10,
        fontWeight:'bold',
      },
      '&:hover, &:focus': {
        '& .Cmt-nav-text, & .Cmt-icon-root': {
          color: theme.palette.sidebar.textActiveColor,
          paddingLeft:10,
          fontWeight:'bold',
        },
      },
      '& .Cmt-icon-root, & .menu_icon': {
        width:20,
        height:20,
        display:'none'
      },
      '& .Cmt-icon-root, & .active_menu_icon': {
        width:20,
        height:20,
        display:'block'
      },
      '& .Cmt-icon-root, & .hover_menu_icon': {
        width:20,
        height:20,
        display:'none'
      },    
      '& .Cmt-icon-root, & .active_menu':{
        width:5,
        height:40,
        backgroundColor:theme.palette.sidebar.navActiveBgColor,
        position: 'absolute',
        left: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10
      },
      '& .Cmt-icon-root, & .active_menu_icon': {
        width:20,
        height:20,
        display:'block'
      },
      '& .Cmt-icon-root, & .hover_menu_icon': {
        width:20,
        height:20,
        display:'none'
      }
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      justifyContent: 'center',
      padding: 0,
      height: 40,
      width: 40,
      borderRadius: '50%',
      marginLeft: 4,
      marginRight: 4,
    },

    '.Cmt-icon-root, & .menu_icon': {
      width:20,
      height:20,
      display:'block'
    },
    '.Cmt-icon-root, & .active_menu_icon': {
      width:20,
      height:20,
      display:'none'
    },
    '.Cmt-icon-root, & .hover_menu_icon': {
      width:20,
      height:20,
      display:'none'
    },
  },
  navText: {
    flex: 1,
    fontSize: 13,
    paddingLeft:10,
    paddingTop:5,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      display: 'none',
    },
  },
  iconRoot: {
    marginRight: 16,
    fontSize: 20,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      marginRight: 0,
    },
  },
  '.menu_icon': {
    width:20,
    height:20
  },
  '.active_menu':{
    width:20,
    height:20,
    backgroundColor:'#000'
  }
}));

const NavMenuItem = props => {
  const { name, icon, link } = props;
  const classes = useStyles();



  return (
    <List component="div" className={clsx(classes.navMenuItem, 'Cmt-nav-menu-item')}>
      <NavLink className={clsx(classes.navMenuLink, 'Cmt-nav-menu-link')} to={link}>
        {/* Display an icon if any */}
        <span className="active_menu"></span>
        {!!icon && (
          <img className="size-30 menu_icon" src={'/images/menuicons/innactive/inactive' + icon + '.svg'} />
        )}
        {!!icon && (
          <img className="size-30 hover_menu_icon" src={'/images/menuicons/hovermenu/hover' + icon + '.svg'} />
        )}
        {!!icon && (
          <img className="size-30 active_menu_icon" src={'/images/menuicons/activemenu/active' + icon + '.svg'} />
        )}
        <Box component="span" className={clsx(classes.navText, 'Cmt-nav-text')}>
          {name}
        </Box>
      </NavLink>
    </List>
  );
};

export default NavMenuItem;
