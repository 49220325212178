import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../../utils/IntlMessages";
import {useDispatch} from "react-redux";
import Button from "@material-ui/core/Button";
import {Box, fade, Grid} from "@material-ui/core";
import {AuhMethods} from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CmtImage from "../../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {CurrentAuthMethod} from "../../../constants/AppConstants";
import {NavLink} from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import AppTextInput from "../../../components/Common/formElements/AppTextInput";
import CmtButtons from "../../../../@coremat/CmtButtons";
import {fetchError} from "../../../../redux/actions/Common";
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContentdiv: {
    textAlign: "center"
  },
  authThumbImg: {
    width: 150
  },
  authContent: {
    padding: "50px 40px!important",
    width: "350px!important",
    margin: "0 auto",
    height: 500,
    marginTop: "80px!important",
    borderRadius: 25,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    },
    "& .MuiInput-formControl": {
      marginTop: "20px!important"
    },
    "& .MuiFormLabel-root": {
      paddingLeft: 10
    },
    "& .MuiTypography-h1": {
      fontSize: 20
    },
    "& .MuiFormControl-fullWidth": {
      marginBottom: 15
    },
    "& .MuiInputBase-root": {
      padding: "10px 14px!important"
    }
  },
  titleRoot: {
    marginBottom: 33,
    color: theme.palette.text.primary,
    textAlign: "center"
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: fade(theme.palette.common.dark, 0.12)
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  },
  cardButtonsDiv: {
    width: "100%",
    float: "left",
    "& .CmtButtons-wrapper": {
      width: "100%"
    }
  }
}));
//variant = 'default', 'standard'
const SignIn = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const [ email, setEmail ] = useState(null);
  const [ password, setPassword ] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles({variant});

  const onSubmit = () => {
    if (email == null || email == "") {
      dispatch(fetchError(<IntlMessages id="error.email_required" />));
    } else if (password == null || password == "") {
      dispatch(fetchError(<IntlMessages id="error.password_required" />));
    } else {
      dispatch(AuhMethods[method].onLogin({email, password}));
    }
  };
  const buttons = [
    {
      label: <IntlMessages id="login.Login" />,
      color: "login",
      onClick: onSubmit
    }
    // { label: <IntlMessages id="login.Register" />, color: 'register' },
  ];
  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      console.log("e.keyCode ==", e.key);
      onSubmit();
    }
  };
  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CmtImage
            src={"/images/logo-symbol.png"}
            className={classes.authThumbImg}
          />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7} className={classes.authContentdiv}>
          <CmtImage
            src={"/images/logo-symbol.png"}
            className={classes.authThumbImg}
          />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id="login.Sign_In_to_your_account" />
        </Typography>
        <form>
          <Box mb={2}>
            <AppTextInput
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              label={<IntlMessages id="users.email" />}
              onKeyPress={handleKeypress}
              InputLabelProps={{shrink: true}}
            />
          </Box>
          <Box mb={2}>
            <AppTextInput
              label={<IntlMessages id="login.Password" />}
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              type="password"
              onKeyPress={handleKeypress}
              InputLabelProps={{shrink: true}}
            />
          </Box>

          <Grid mb={5} className={classes.cardButtonsDiv}>
            <CmtButtons items={buttons} className={classes.cardButtons} />
          </Grid>
        </form>
      </Box>
      <ContentLoader />
    </AuthWrapper>
  );
};

export default SignIn;
