import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router";
import PageLoader from "../../../@jumbo/components/PageComponents/PageLoader";
import {checkRouterUrl} from "../../../@jumbo/utils/commonHelper";

const Intelligen = ({match}) => {
  const requestedUrl = match.url.replace(/\/$/, "");

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        {checkRouterUrl("vehicleperformance", "view") && (
          <Redirect
            exact
            from={`${requestedUrl}/`}
            to={`${requestedUrl}/vehicleperformance`}
          />
        )}
        {checkRouterUrl("vehicleperformance", "view") && (
          <Route
            path={`${requestedUrl}/vehicleperformance`}
            component={lazy(() => import("./VehiclePerformance"))}
          />
        )}

        {checkRouterUrl("driverevaluation", "view") && (
          <Route
            path={`${requestedUrl}/driverevaluation`}
            component={lazy(() => import("./DriverEvaluation"))}
          />
        )}
        {checkRouterUrl("riskmanagement", "view") && (
          <Route
            path={`${requestedUrl}/riskmanagement`}
            component={lazy(() => import("./Risk"))}
          />
        )}
        {checkRouterUrl("riskmanagement", "add") && (
          <Route
            path={`${requestedUrl}/addriskmanagement`}
            component={lazy(() => import("./Addrisk"))}
          />
        )}
        {checkRouterUrl("riskmanagement", "edit") && (
          <Route
            path={`${requestedUrl}/editriskmanagement/:id`}
            component={lazy(() => import("./Addrisk"))}
          />
        )}
        <Route component={lazy(() => import("../404"))} />
      </Switch>
    </Suspense>
  );
};

export default Intelligen;
