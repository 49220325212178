import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import CmtButtons from "../CmtButtons";
import {fetchError} from "../../redux/actions/Common";
import AppMultiSelectBox from "../../@jumbo/components/Common/formElements/AppMultiSelectBox";
import searchHelper from "../../@jumbo/utils/searchHelper";
import Modal from "@material-ui/core/Modal";
import {getCompany} from "../../@jumbo/utils/commonHelper";
import AppTextInput from "../../@jumbo/components/Common/formElements/AppTextInput";

function rand(){
  return Math.round(Math.random() * 14) - 10;
}

function getModalStyle(){
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none!important",
    padding: 0,
    border: "2px solid #fff",
    outline: "none"
  },
  modal: {
    "&:focus": {
      outline: "none"
    }
  },
  modalh2: {
    borderBottom: "solid 1px #e1e8ea",
    textAlign: "center",
    padding: 20,
    fontSize: 16
  },
  dateFiltertitle: {
    textAlign: "center",
    padding: "20px 20px 34px 20px",
    width: "150px",
    fontSize: 16,
    display: "inline-block",
    margin: "0 auto",
    "& .MuiInputBase-input": {
      textAlign: "center"
    }
  },
  buttons: {
    width: "100%",
    textAlign: "center",
    marginBottom: 40,
    "& .MuiBox-root": {
      display: "inline",
      textAlign: "center"
    }
  }
}));

const WomechanicModalCard = props => {
  const classes = useStyles(props);
  // getModalStyle is not a pure function, we roll the style only on the first render

  const {
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    required,
    onChange,
    helperText,
    variant,
    error,
    valueKey,
    labelKey,
    backgroundColor,
    label,
    open,
    onDelete,
    onCancel,
    onSave,
    ...rest
  } = props;

  const [ modalStyle ] = useState(getModalStyle);
  const [ company, setCompany ] = useState(getCompany());
  const [ searchBy, setSearchBy ] = useState("");
  const [ unitValue, setUnitValue ] = useState();
  const [ mechanicCharge, setMechanicCharge ] = useState(null);
  const dispatch = useDispatch();

  const handleMechanicCharge = value => {
    setMechanicCharge(value);
  };

  const handleClose = () => {
    //setOpen(false);
  };
  const onSaveAction = () => {
    if (mechanicCharge == null) {
      dispatch(fetchError(<IntlMessages id="error.mechanicCharge" />));
    } else {
      onSave(mechanicCharge);
    }
  };

  const buttons = [
    {
      label: <IntlMessages id="actions.cancel" />,
      color: "fullcancel",
      onClick: onCancel
    },
    {
      label: <IntlMessages id="sidebar.save" />,
      color: "add",
      onClick: onSaveAction
    }
  ];
  const handleFocus = event => event.target.select();

  return (
    <div>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 className={classes.modalh2} id="simple-modal-title">
            <IntlMessages id="workorder.mechanic_charge" />
          </h2>
          <p id="simple-modal-description">
            <GridContainer container xs={12}>
              <Grid xs={12}>
                <AppMultiSelectBox
                  menuPlacement="auto"
                  onChange={value => handleMechanicCharge(value)}
                  value={mechanicCharge}
                  isMulti={false}
                  isDisabled={id}
                  key={searchBy}
                  loadOptions={(search, prevOptions) =>
                    searchHelper.getInhouseSearchDetails(
                      search,
                      prevOptions,
                      6, // mechanic role
                      company,
                      false
                    )}
                />
              </Grid>
            </GridContainer>
            <Grid xs={12} className={classes.buttons}>
              <CmtButtons items={buttons} />
            </Grid>
          </p>
        </div>
      </Modal>
    </div>
  );
};

WomechanicModalCard.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  backgroundColor: PropTypes.string
};

WomechanicModalCard.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: "small",
  border: "none",
  error: false,
  helperText: "",
  valueKey: "id",
  labelKey: "title",
  backgroundColor: "transparent"
};

export default WomechanicModalCard;
