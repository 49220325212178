import React from "react";
import {setAuthUser, updateLoadUser} from "../../../redux/actions/Auth";
import {Box} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {auth} from "./config";
import {fetchError, fetchStart, fetchSuccess} from "../../../redux/actions";
import api from "../../../services/api";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {findConductorRoleId} from "../../../@jumbo/utils/commonHelper";

const useStyles = makeStyles(theme => ({
  iconBtn: {
    "&:hover, &:focus": {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down("xs")]: {
      padding: 6
    }
  }
}));

const Firebase = {
  onRegister: ({email, password}) => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        auth
          .createUserWithEmailAndPassword(email, password)
          .then(data => {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data));
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogin: ({email, password}) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        auth
          .signInWithEmailAndPassword(email, password)
          .then(data => {
            let uid = data.uid ? data.uid : data.user.uid;
            if (uid) {
              Firebase.getProfile(uid, data.ya, dispatch);
            } else {
              dispatch(fetchError("Error during login"));
            }
          })
          .catch(error => {
            if (error.code == "auth/wrong-password") {
              dispatch(fetchError(<IntlMessages id="wrong_password" />));
            } else if (error.code == "auth/invalid-email") {
              dispatch(fetchError(<IntlMessages id="error.email_required" />));
            } else {
              dispatch(fetchError(error.message));
            }
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        auth
          .signOut()
          .then(data => {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(null));
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  getAuthUser: () => {
    return async dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(false));
      try {
        auth.onAuthStateChanged(authUser => {
          if (authUser) {
            Firebase.getProfile(authUser.uid, authUser.ya, dispatch);
          } else {
            dispatch(fetchSuccess());
            dispatch(setAuthUser(null));
            Firebase.onLogout();
          }
        });
      } catch (error) {
        dispatch(updateLoadUser(true));
        dispatch(fetchError(error.message));
      }
    };
  },

  getProfile: async (uid, token, dispatch) => {
    dispatch(fetchStart());
    dispatch(updateLoadUser(false));
    try {
      let response = await api.postApi("inhouseuid/" + uid, {token: token});
      dispatch(fetchSuccess());
      console.log("response==================================", response);
      if (response.status == "success" && response.data) {
        let rolesData = response.data;
        if (rolesData.role && rolesData.role.value == "super_admin") {
          localStorage.setItem("user", JSON.stringify(response.data));
          await findConductorRoleId();
          dispatch(setAuthUser(response.data));
        } else if (
          rolesData.rolespermissions == null ||
          rolesData.rolespermissions.roleStatus == false
        ) {
          dispatch(updateLoadUser(true));
          dispatch(fetchError(<IntlMessages id="error.role_permissions" />));
        } else {
          localStorage.setItem("user", JSON.stringify(response.data));
          await findConductorRoleId();
          dispatch(setAuthUser(response.data));
        }
      } else {
        dispatch(setAuthUser(null));
        //dispatch(fetchError(response.message));
        Firebase.onLogout();
      }
    } catch (error) {
      dispatch(updateLoadUser(true));
      dispatch(fetchError(error.message));
    }
  },

  onForgotPassword: () => {
    return dispatch => {};
  },

  getSocialMediaIcons: () => {
    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box component="p" fontSize={{xs: 13, sm: 16}}>
          Or Login with
        </Box>
      </Box>
    );
  }
};

export default Firebase;
