import React from "react";
import {
  getPurchaseCalculation,
  getTaxOptions,
  getSinglePurchaseCalculation
} from "./commonHelper";
import {pdf, PDFViewer} from "@react-pdf/renderer";
import {saveAs} from "file-saver";
import {Documents} from "../components/Document/Document";
import {Electonicbilldocument} from "../components/Electonicbilldocument/Electonicbilldocument";
import {Electronicdispatchdocument} from "../components/Electronicdispatchdocument/Electronicdispatchdocument";
import {Supplierofficeguidedocument} from "../components/Supplierofficeguidedocument/Supplierofficeguidedocument";
import {Creditnotedocument} from "../components/Creditnotedocument/Creditnotedocument";
import {Debitnotedocument} from "../components/Debitnotedocument/Debitnotedocument";
import {Admissioninternalguidedocument} from "../components/Admissioninternalguidedocument/Admissioninternalguidedocument";
import {Admissiontransferdispatchdocument} from "../components/Admissiontransferdispatchdocument/Admissiontransferdispatchdocument";
import {Professionalfeesdocument} from "../components/Professionalfeesdocument/Professionalfeesdocument";
import {Adjustmentdocument} from "../components/Adjustmentdocument/Adjustmentdocument";
import {Transferdispatchdocument} from "../components/Transferdispatchdocument/Transferdispatchdocument";
import {Internalguidedocument} from "../components/Internalguidedocument/Internalguidedocument";
import {Wastagedocument} from "../components/Wastagedocument/Wastagedocument";
import {Closedwodocument} from "../components/Closedwodocument/Closedwodocument";
// import {Devolutiondocument} from '../components/Devolutiondocument/Devolutiondocument';

export const downloadWoDocument = async (editData, intl) => {
  let selectedCompany = editData.company;
  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    selectedCompany: selectedCompany,
    ...editData
  };
  console.log(selectItem);

  const blob = await pdf(
    <Closedwodocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "sidebar.closed_WO"}) +
      "_" +
      editData.wodocumentNo +
      ".pdf"
  );
};

export const downloadWastage = async (editData, intl) => {
  let selectedCompany = editData.company;
  let totalInvoice = 0;
  for (let item in editData.items) {
    totalInvoice = Number(totalInvoice) + Number(editData.items[item].total);
  }
  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    selectedCompany: selectedCompany,
    rut: editData.company ? editData.company.companyRutId : "",
    createdDate: new Date(editData.createdAt),
    selectedRegion: editData.region,
    totalInvoice: totalInvoice,
    items: editData.items,
    warehouse: editData.warehouse ? editData.warehouse.name : "",
    documentNo: editData.invoiceNo,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    name: editData.company ? editData.company.name : "",
    approval_status_label: editData.approval_status_label
  };
  console.log("selectItem==", selectItem);
  //this.setState({"isMark":true,selectItem:selectItem})
  const blob = await pdf(
    <Wastagedocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "sidebar.wastage"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadDepatureInternalGuide = async (editData, intl) => {
  let selectedCompany = editData.company;

  let totalInvoice = 0;
  for (let item in editData.items) {
    totalInvoice = Number(totalInvoice) + Number(editData.items[item].total);
  }
  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    selectedCompany: selectedCompany,
    rut: editData.company ? editData.company.companyRutId : "",
    createdDate: new Date(editData.createdAt),
    selectedRegion: "",
    totalInvoice: totalInvoice,
    items: editData.items,
    warehouse: editData.warehouse ? editData.warehouse.name : "",
    documentNo: editData.invoiceNo,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    name: editData.company ? editData.company.name : "",
    approval_status_label: editData.approvalStatus
  };
  console.log("selectItem====", selectItem);
  //this.setState({"isMark":true,selectItem:selectItem})
  const blob = await pdf(
    <Internalguidedocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "sidebar.depatureinternalguide"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadDepatureTransferDispatch = async (editData, intl) => {
  let totalInvoice = 0;
  let selectedCompany = editData.company;
  console.log("editData===", editData);

  for (let item in editData.items) {
    totalInvoice = Number(totalInvoice) + Number(editData.items[item].total);
  }
  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    selectedCompany: selectedCompany,
    rut: editData.company ? editData.company.companyRutId : "",
    createdDate: new Date(editData.createdAt),
    selectedRegion: editData.region,
    totalInvoice: totalInvoice,
    items: editData.items,
    destinationWarehouse: editData.destinationWarehouse
      ? editData.destinationWarehouse.name
      : "",
    originWarehouse: editData.originWarehouse
      ? editData.originWarehouse.name
      : "",
    documentNo: editData.dispatchGuideNo,
    orderNo: editData.invoiceNo,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    name: editData.company ? editData.company.name : "",
    approval_status_label: editData.approval_status_label
  };
  console.log("selectItem==", selectItem);
  //this.setState({"isMark":true,selectItem:selectItem})
  const blob = await pdf(
    <Transferdispatchdocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "sidebar.transferdispatchguide_wd"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadAdjustment = async (editData, intl) => {
  let selectedCompany = editData.company;
  let totalInvoice = 0;
  for (let item in editData.items) {
    totalInvoice = Number(totalInvoice) + Number(editData.items[item].total);
  }
  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    selectedCompany: selectedCompany,
    rut: editData.company ? editData.company.companyRutId : "",
    createdDate: new Date(editData.createdAt),
    selectedRegion: editData.region,
    totalInvoice: totalInvoice,
    items: editData.items,
    warehouse: editData.warehouse ? editData.warehouse.name : "",
    documentNo: editData.invoiceNo,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    name: editData.company ? editData.company.name : "",
    approval_status_label: editData.approval_status_label
  };
  console.log("selectItem==", selectItem);
  //this.setState({"isMark":true,selectItem:selectItem})
  const blob = await pdf(
    <Adjustmentdocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "sidebar.adjustment"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadAdmissionTransferDispatch = async (editData, intl) => {
  let selectedCompany = editData.company;

  let totalInvoice = 0;
  for (let item in editData.items) {
    totalInvoice = Number(totalInvoice) + Number(editData.items[item].total);
  }
  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    selectedCompany: selectedCompany,
    rut: editData.company ? editData.company.companyRutId : "",
    createdDate: new Date(editData.createdAt),
    selectedRegion: editData.region,
    totalInvoice: totalInvoice,
    items: editData.items,
    warehouse: editData.warehouse ? editData.warehouse.name : "",
    documentNo: editData.invoiceNo,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    name: editData.company ? editData.company.name : "",
    approval_status_label: editData.approvalStatus
  };
  //this.setState({"isMark":true,selectItem:selectItem})
  const blob = await pdf(
    <Admissiontransferdispatchdocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "sidebar.admissiontransferdispatch"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadAdmissionInternalGuide = async (editData, intl) => {
  let selectedCompany = editData.company;

  let totalInvoice = 0;
  for (let item in editData.items) {
    totalInvoice = Number(totalInvoice) + Number(editData.items[item].total);
  }
  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    selectedCompany: selectedCompany,
    rut: editData.company ? editData.company.companyRutId : "",
    createdDate: new Date(editData.createdAt),
    selectedRegion: editData.region,
    totalInvoice: totalInvoice,
    items: editData.items,
    warehouse: editData.warehouse ? editData.warehouse.name : "",
    documentNo: editData.invoiceNo,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    name: editData.company ? editData.company.name : "",
    approval_status_label: editData.approvalStatus
  };
  console.log("selectItem====", selectItem);
  //this.setState({"isMark":true,selectItem:selectItem})
  const blob = await pdf(
    <Admissioninternalguidedocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "sidebar.admissioninternalguide"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadDebitNote = async (editData, intl) => {
  let purchaseOrderNo = editData.purchaseorder
    ? editData.purchaseorder.invoiceNo
    : "";
  let purchaseData = editData.purchaseorder;
  let selectedCompany = editData.company;
  let spareData = null;
  let totalReceiptTaxPrice = 0;

  for (let data in editData.items) {
    totalReceiptTaxPrice =
      Number(totalReceiptTaxPrice) + Number(editData.items[data].total);
  }

  let costCenter = editData.costcenter ? editData.costcenter.name : "";
  let receptionCostCenter = editData.costcenter ? editData.costcenter.name : "";
  let region = editData.region ? editData.region.name : "";
  let warehouse = editData.warehouse ? editData.warehouse.name : "";

  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    purchaseorderNo: purchaseOrderNo,
    items: editData.items,
    receiptItems: editData.items,
    totalInvoice: totalReceiptTaxPrice,
    emissionDate: editData.emissionDate ? new Date(editData.emissionDate) : "",
    selectedCompany: selectedCompany,
    selectOfficeguideno: editData.officeguideno,
    creditId: editData.creditnote,
    documentNo: editData.documentNo,
    expirationDate: editData.expirationDate
      ? new Date(editData.expirationDate)
      : "",
    entryDate: editData.entryDate ? new Date(editData.entryDate) : "",
    selectedRegion: editData.region,
    selectProductcode: editData.productcode,
    subTotalPrice: purchaseData.sub_total,
    selectTax: purchaseData.taxType,
    taxPrice: purchaseData.taxPrice,
    totalTaxPrice: purchaseData.totalTaxPrice,
    selectedCondition: purchaseData.condition,
    days: purchaseData.days,
    selectedProvider: editData.providers,
    selectedCostCenter: costCenter,
    selectedRegion: region,
    observation: editData.observation,
    spareData: spareData,
    orderNo: editData.invoiceNo,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    createdBy: editData.inhouse ? editData.inhouse.firstName : "",
    receptionCostCenter: receptionCostCenter,
    approval_status_label: editData.approvalStatus,
    warehouse: warehouse
  };
  console.log("selectItem==", selectItem);
  const blob = await pdf(
    <Debitnotedocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "pdf.debitnote"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadCreditNote = async (editData, intl) => {
  let purchaseOrderNo = editData.purchaseorder
    ? editData.purchaseorder.invoiceNo
    : "";
  let purchaseData = editData.purchaseorder;
  let selectedCompany = editData.company;

  console.log("electronicInvoiceId===", editData);
  let spareData = null;
  let totalReceiptTaxPrice = 0;

  for (let data in editData.items) {
    //editData.items[data] = getSinglePurchaseCalculation(editData.items[data], purchaseData.totalDiscount, purchaseData.taxType, editData.items[data].currentPurchaseQty);
    totalReceiptTaxPrice =
      Number(totalReceiptTaxPrice) + Number(editData.items[data].total);
  }

  let costCenter = editData.costcenter ? editData.costcenter.name : "";
  let receptionCostCenter = editData.costcenter ? editData.costcenter.name : "";
  let region = editData.region ? editData.region.name : "";
  let warehouse = editData.warehouse ? editData.warehouse.name : "";

  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    purchaseorderNo: purchaseOrderNo,
    receiptItems: editData.items,
    electronicInvoiceId: editData.electronicinvoice,
    emissionDate: editData.emissionDate ? new Date(editData.emissionDate) : "",
    selectedCompany: selectedCompany,
    documentNo: editData.documentNo,
    expirationDate: editData.expirationDate
      ? new Date(editData.expirationDate)
      : "",
    entryDate: editData.entryDate ? new Date(editData.entryDate) : "",
    selectedRegion: editData.region,
    totalInvoice: totalReceiptTaxPrice,
    selectProductcode: editData.productcode,
    subTotalPrice: purchaseData.sub_total,
    selectTax: purchaseData.taxType,
    taxPrice: purchaseData.taxPrice,
    totalTaxPrice: purchaseData.totalTaxPrice,
    selectedCondition: purchaseData.condition,
    days: purchaseData.days,
    selectedProvider: editData.providers,
    selectedCostCenter: costCenter,
    selectedRegion: region,
    observation: editData.observation,
    spareData: spareData,
    orderNo: editData.invoiceNo,
    observationBy: purchaseData.observation ? editData.observation : "",
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    createdBy: editData.inhouse ? editData.inhouse.firstName : "",
    receptionCostCenter: receptionCostCenter,
    approval_status_label: editData.approvalStatus,
    warehouse: warehouse
  };
  console.log("electronicInvoiceId==", selectItem);
  const blob = await pdf(
    <Creditnotedocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "pdf.creditnote"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadProfessionalFees = async (editData, intl) => {
  let selectedCompany = editData.company;
  let purchaseOrderNo = editData.purchaseorder
    ? editData.purchaseorder.invoiceNo
    : "";
  let purchaseData = editData.purchaseorder;

  let spareData = null;

  let subTotalPrice = 0;
  let totalTaxPrice = 0;
  let totalReceiptTaxPrice = 0;

  console.log("editData.items=111=", editData);

  for (let data in purchaseData.items) {
    //purchaseData.items[data] = getSinglePurchaseCalculation(purchaseData.items[data], purchaseData.totalDiscount, purchaseData.taxType, purchaseData.items[data].unit);
    subTotalPrice =
      Number(subTotalPrice) +
      Number(purchaseData.items[data].discount_sub_total);
    totalTaxPrice =
      Number(totalTaxPrice) + Number(purchaseData.items[data].total);
  }

  for (let data in editData.items) {
    // editData.items[data] = getSinglePurchaseCalculation(editData.items[data], purchaseData.totalDiscount, purchaseData.taxType, editData.items[data].tempPurchaseQty);
    totalReceiptTaxPrice =
      Number(totalReceiptTaxPrice) + Number(editData.items[data].total);
  }

  let costCenter = editData.costcenter ? editData.costcenter.name : "";
  let receptionCostCenter = editData.costcenter ? editData.costcenter.name : "";
  let region = editData.region ? editData.region.name : "";
  let warehouse = editData.warehouse ? editData.warehouse.name : "";

  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    purchaseorderNo: purchaseOrderNo,
    items: purchaseData.items,
    receiptItems: editData.items,
    emissionDate: editData.emissionDate ? new Date(editData.emissionDate) : "",
    selectedCompany: selectedCompany,
    selectOfficeguideno: editData.officeguideno,
    documentNo: editData.documentNo,
    expirationDate: editData.expirationDate
      ? new Date(editData.expirationDate)
      : "",
    entryDate: editData.entryDate ? new Date(editData.entryDate) : "",
    selectedRegion: editData.region,
    totalInvoice: totalReceiptTaxPrice,
    selectProductcode: editData.productcode,
    subTotalPrice: subTotalPrice,
    selectTax: purchaseData.taxType,
    taxPrice: purchaseData.taxPrice,
    totalTaxPrice: totalTaxPrice,
    selectedCondition: purchaseData.condition,
    days: purchaseData.days,
    selectedProvider: editData.providers,
    selectedCostCenter: costCenter,
    selectedRegion: region,
    observation: purchaseData.observation,
    spareData: spareData,
    orderNo: editData.invoiceNo,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    createdBy: editData.inhouse ? editData.inhouse.firstName : "",
    receptionCostCenter: receptionCostCenter,
    approval_status_label: editData.approvalStatus,
    warehouse: warehouse
  };
  console.log("selectItem==", selectItem);
  const blob = await pdf(
    <Professionalfeesdocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "pdf.professionalfees"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadSuplierOfficeGuide = async (editData, intl) => {
  let selectedCompany = editData.company;
  let purchaseOrderNo = editData.purchaseorder
    ? editData.purchaseorder.invoiceNo
    : "";
  let purchaseData = editData.purchaseorder;
  let spareData = null;

  let subTotalPrice = 0;
  let totalTaxPrice = 0;
  let totalReceiptTaxPrice = 0;

  purchaseData.taxType = getTaxOptions().find(
    item => item.value == purchaseData.taxType
  );

  // editData.items = getPurchaseCalculation(editData.items, purchaseData.totalDiscount, purchaseData.taxType);

  for (let data in editData.items) {
    //editData.items[data] = getSinglePurchaseCalculation(editData.items[data], purchaseData.totalDiscount, purchaseData.taxType, editData.items[data].tempPurchaseQty);
    totalReceiptTaxPrice =
      Number(totalReceiptTaxPrice) + Number(editData.items[data].total);
  }

  console.log("editData.items==", editData);

  let costCenter = editData.costcenter ? editData.costcenter.name : "";
  let receptionCostCenter = editData.costcenter ? editData.costcenter.name : "";
  let region = editData.region ? editData.region.name : "";
  let warehouse = editData.warehouse ? editData.warehouse.name : "";

  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    purchaseorderNo: purchaseOrderNo,
    items: editData.items,
    receiptItems: editData.items,
    selectAssociateType: editData.select_associate,
    emissionDate: editData.emissionDate ? new Date(editData.emissionDate) : "",
    selectedCompany: selectedCompany,
    selectOfficeguideno: editData.officeguideno,
    documentNo: editData.documentNo,
    expirationDate: editData.expirationDate
      ? new Date(editData.expirationDate)
      : "",
    entryDate: editData.entryDate ? new Date(editData.entryDate) : "",
    selectedRegion: editData.region,
    totalInvoice: totalReceiptTaxPrice,
    selectProductcode: editData.productcode,
    subTotalPrice: subTotalPrice,
    selectTax: purchaseData.taxType,
    taxPrice: purchaseData.taxPrice,
    totalTaxPrice: totalTaxPrice,
    selectedCondition: purchaseData.condition,
    days: purchaseData.days,
    selectedProvider: editData.providers,
    selectedCostCenter: costCenter,
    selectedRegion: region,
    observation: editData.observation,
    spareData: spareData,
    orderNo: editData.invoiceNo,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    createdBy: editData.inhouse ? editData.inhouse.firstName : "",
    receptionCostCenter: receptionCostCenter,
    approval_status_label: editData.approvalStatus,
    warehouse: warehouse
  };
  console.log("selectItem==", selectItem);
  const blob = await pdf(
    <Supplierofficeguidedocument intl={intl} {...selectItem} />
  ).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "pdf.providerdispatch"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadElectronicInvoice = async (editData, intl) => {
  let selectedCompany = editData.company;
  let purchaseOrderNo = editData.purchaseorder
    ? editData.purchaseorder.invoiceNo
    : "";
  let purchaseData = editData.purchaseorder;
  let spareData = null;

  let subTotalPrice = 0;
  let totalTaxPrice = 0;
  let totalReceiptTaxPrice = 0;

  purchaseData.taxType = getTaxOptions().find(
    item => item.value == purchaseData.taxType
  );
  purchaseData.items = getPurchaseCalculation(
    purchaseData.items,
    purchaseData.totalDiscount,
    purchaseData.taxType
  );

  for (let data in purchaseData.items) {
    subTotalPrice =
      Number(subTotalPrice) +
      Number(purchaseData.items[data].discount_sub_total);
    totalTaxPrice =
      Number(totalTaxPrice) + Number(purchaseData.items[data].total);
  }

  for (let data in editData.items) {
    //editData.items[data] = getSinglePurchaseCalculation(editData.items[data], purchaseData.totalDiscount, purchaseData.taxType, editData.items[data].tempPurchaseQty);
    totalReceiptTaxPrice =
      Number(totalReceiptTaxPrice) + Number(editData.items[data].total);
  }

  console.log("editData.items===", editData);

  let costCenter = editData.costcenter ? editData.costcenter.name : "";
  let region = editData.region ? editData.region.name : "";
  let receptionCostCenter = editData.costcenter ? editData.costcenter.name : "";
  let warehouse = editData.warehouse ? editData.warehouse.name : "";

  let dispatchGuideList = editData.dispatchGuideList
    ? editData.dispatchGuideList
    : [];
  let newDispatchGuideList = [];
  let electronicItems = [];
  let electronicInvoiceTotal = 0;

  for (let data in dispatchGuideList) {
    let totalInvoice = 0;
    if (dispatchGuideList[data].selectOfficeguideno) {
      let receptionCostCenter = "";

      let costCenterList =
        dispatchGuideList[data].selectOfficeguideno.costcenter;
      let regionList = dispatchGuideList[data].selectOfficeguideno.region;

      receptionCostCenter = costCenterList ? costCenterList.name : "";
      let region = regionList ? regionList.name : "";

      let purchaseItems = dispatchGuideList[data].selectOfficeguideno
        ? dispatchGuideList[data].selectOfficeguideno.items
        : [];

      let categoryItems = [];

      for (let data1 in purchaseItems) {
        totalInvoice =
          Number(totalInvoice) + Number(purchaseItems[data1].total);
        electronicInvoiceTotal =
          Number(electronicInvoiceTotal) + Number(purchaseItems[data1].total);
        electronicItems = electronicItems.concat(purchaseItems[data1]);

        if (
          purchaseItems[data] &&
          purchaseItems[data].category &&
          categoryItems.indexOf(purchaseItems[data].category) == -1
        ) {
          categoryItems.push(purchaseItems[data].category);
        }
      }

      dispatchGuideList[data].purchase_items = purchaseItems;
      dispatchGuideList[data].total_invoice = totalInvoice;

      newDispatchGuideList.push({
        category: categoryItems,
        region: region,
        costcenter: receptionCostCenter,
        items: dispatchGuideList[data].items,
        selectOfficeguideno: dispatchGuideList[data].selectOfficeguideno,
        isPurchase: true,
        total_invoice: totalInvoice
      });
      newDispatchGuideList.push({
        category: categoryItems,
        region: region,
        costcenter: receptionCostCenter,
        items: dispatchGuideList[data].purchase_items,
        selectOfficeguideno: dispatchGuideList[data].selectOfficeguideno,
        isPurchase: false,
        total_invoice: totalInvoice
      });
    }
  }

  dispatchGuideList = [];
  let purchaseId = [];
  console.log("electronicItems==", newDispatchGuideList);

  newDispatchGuideList.sort(function(x, y){
    return (
      x.selectOfficeguideno.purchaseorder.id -
      y.selectOfficeguideno.purchaseorder.id
    );
  });

  for (let item in newDispatchGuideList) {
    if (newDispatchGuideList[item].isPurchase == false) {
      dispatchGuideList.push(newDispatchGuideList[item]);
    } else if (
      purchaseId.indexOf(
        newDispatchGuideList[item].selectOfficeguideno.purchaseorder.id
      ) == -1
    ) {
      purchaseId.push(
        newDispatchGuideList[item].selectOfficeguideno.purchaseorder.id
      );
      dispatchGuideList.push(newDispatchGuideList[item]);
    }
  }

  console.log("editData.approvalStatus==", editData.approvalStatus);

  let selectItem = {
    photo: selectedCompany ? selectedCompany.photo : "",
    electronicItems: electronicItems,
    electronicInvoiceTotal: electronicInvoiceTotal,
    purchaseorderNo: purchaseOrderNo,
    items: purchaseData.items,
    receiptItems: editData.items,
    selectAssociateType: editData.associateType,
    emissionDate: editData.emissionDate ? new Date(editData.emissionDate) : "",
    selectedCompany: selectedCompany,
    selectOfficeguideno: editData.officeguideno ? editData.officeguideno : "",
    documentNo: editData.documentNo,
    expirationDate: editData.expirationDate
      ? new Date(editData.xpirationDate)
      : "",
    entryDate: editData.entryDate ? new Date(editData.entryDate) : "",
    selectedRegion: region,
    totalInvoice: totalReceiptTaxPrice,
    selectProductcode: editData.productcode ? editData.productcode : "",
    subTotalPrice: subTotalPrice,
    selectTax: purchaseData.taxType,
    taxPrice: purchaseData.taxPrice,
    totalTaxPrice: totalTaxPrice,
    selectedCondition: purchaseData.condition,
    days: purchaseData.days,
    selectedProvider: editData.providers,
    selectedCostCenter: costCenter,
    observation: editData.observation ? editData.observation : "",
    spareData: spareData,
    ElectronicorderNo: editData.invoiceNo,
    receptionCostCenter: receptionCostCenter,
    purchaseCreatedBy: editData.inhouse ? editData.inhouse.firstName : "",
    dispatchGuideList: dispatchGuideList,
    createdBy: editData.inhouse ? editData.inhouse.firstName : "",
    approval_status_label: editData.approvalStatus,
    warehouse: warehouse
  };
  console.log("selectItem===", selectItem);
  let blob;
  if (editData.associateType == "supplier_guide") {
    blob = await pdf(
      <Electronicdispatchdocument intl={intl} {...selectItem} />
    ).toBlob();
  } else {
    blob = await pdf(
      <Electonicbilldocument intl={intl} {...selectItem} />
    ).toBlob();
  }

  saveAs(
    blob,
    intl.formatMessage({id: "pdf.electronic_invoice"}) +
      "_" +
      editData.invoiceNo +
      ".pdf"
  );
};

export const downloadPurchaseRequest = async (editData, intl) => {
  let subTotalPrice = 0;
  let totalTaxPrice = 0;

  editData.taxType = getTaxOptions().find(
    item => item.value == editData.taxType
  );
  // editData.items = getPurchaseCalculation(editData.items, editData.totalDiscount, editData.taxType);

  for (let data in editData.items) {
    subTotalPrice =
      Number(subTotalPrice) + Number(editData.items[data].discount_sub_total);
    totalTaxPrice = Number(totalTaxPrice) + Number(editData.items[data].total);
  }

  let costCenter = editData.costcenter ? editData.costcenter.name : "";
  let region = editData.region ? editData.region.name : "";

  let selectItem = {
    photo: editData.company ? editData.company.photo : "",
    items: editData.items,
    orderId: editData.invoiceNo,
    subTotalPrice: subTotalPrice,
    selectTax: editData.taxType,
    taxPrice: editData.taxPrice,
    totalTaxPrice: totalTaxPrice,
    selectedCondition: editData.condition,
    days: editData.days,
    selectedCompany: editData.company,
    selectedProvider: editData.providers,
    selectedCostCenter: costCenter,
    selectedRegion: region,
    observation: editData.observation,
    dateEmission: new Date(editData.dateEmission),
    createdBy: editData.inhouse ? editData.inhouse.firstName : "",
    approval_status_label: editData.approvalStatus
  };
  console.log("selectItem===", selectItem);
  const blob = await pdf(<Documents intl={intl} {...selectItem} />).toBlob();
  saveAs(
    blob,
    intl.formatMessage({id: "pdf.purchaserequest"}) + "_" + editData.id + ".pdf"
  );
};
