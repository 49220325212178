import React from "react";
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles(theme => ({
  root: props => ({
    "& .MuiInputBase-root": {
      border: `1px solid ${theme.palette.sidebar.formBorderColor}`,
      borderRadius: 30,
      padding: "6.5px 10px",
      backgroundColor: props.disabled ? "#f2f2f2" : "#fff"
    },
    "& .MuiInput-underline:before": {
      border: "none"
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none"
    },
    "& .MuiInput-formControl": {
      marginTop: 25,
      padding: props.little
        ? "8px 20px"
        : props.morelittle ? "6px 20px" : "12.5px 20px"
    },
    "& .MuiFormLabel-root": {
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.text.black
    },
    "& .MuiInputBase-input": {
      fontSize: 14,
      padding: "3px 0 0 0",
      color: props.disabled ? "#808080" : theme.palette.text.black,
      fontWeight: 600
      // height: '1.35em',
      // padding: '7px 10px 3px 10px'
    }
  })
}));

const AppTextInput = ({
  type,
  name,
  id,
  fullWidth,
  size,
  value,
  onChange,
  helperText,
  variant,
  error,
  little,
  morelittle,
  disabled,
  ...rest
}) => {
  const classes = useStyles({little, morelittle, disabled});

  if (type == "date") {
    return (
      <DatePicker
        dateFormat="dd/MM/yyyy"
        {...rest}
        selected={value}
        onChange={onChange}
      />
    );
  } else if (type == "datetime") {
    return (
      <DatePicker
        {...rest}
        dateFormat="dd/MM/yyyy hh:mm a"
        // locale={window.localStorage.language}
        selected={value}
        onChange={onChange}
      />
    );
  } else if (type == "time") {
    return (
      <DatePicker
        {...rest}
        dateFormat="hh:mm a"
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={1}
        timeCaption="Time"
        // locale={window.localStorage.language}
        selected={value}
        onChange={onChange}
      />
    );
  } else if (type == "image") {
    return (
      <TextField
        {...rest}
        inputProps={{accept: "image/*"}}
        type="file"
        name={name}
        id={id || name}
        size={size}
        fullWidth={fullWidth}
        value={value}
        variant={variant}
        onChange={onChange}
        multiple="false"
        autoComplete="off"
        error={error || helperText !== ""}
        helperText={helperText}
        className={classes.root}
      />
    );
  } else {
    return (
      <TextField
        {...rest}
        type={type}
        name={name}
        id={id || name}
        size={size}
        fullWidth={fullWidth}
        value={value}
        variant={variant}
        onChange={onChange}
        autoComplete="off"
        error={error || helperText !== ""}
        helperText={helperText}
        className={classes.root}
      />
    );
  }
};

AppTextInput.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  little: PropTypes.bool
};

AppTextInput.defaultProps = {
  type: "text",
  fullWidth: true,
  size: "small",
  error: false,
  helperText: "",
  inputProps: {autoComplete: "new-password"},
  little: false
};

export default AppTextInput;
