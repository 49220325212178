import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import Error404 from "./Pages/404";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import Dashboard from "./Pages/Dashboard";
import Assets from "./Pages/Assets";
import Approve from "./Pages/Approve";
import Company from "./Pages/Company";
import Addcompany from "./Pages/Addcompany";
import Maintenance from "./Pages/Maintenance";
import Profile from "./Pages/Profile";
import Intelligen from "./Pages/intelligen";
import Roles from "./Pages/Roles";
import Service from "./Pages/Service";
import Settings from "./Pages/Settings";
import Team from "./Pages/Team";
import Tracking from "./Pages/Tracking";
import WarehouseAdmission from "./Pages/WarehouseAdmission";
import WarehouseDepartures from "./Pages/WarehouseDepartures";
import BusinessIntelligence from "./Pages/BusinessIntelligence";
import {checkRouterUrl} from "../@jumbo/utils/commonHelper";

const Routes = () => {
  const {authUser} = useSelector(({auth}) => auth);
  const location = useLocation();
  let rolesPermission =
    authUser && authUser.rolespermissions ? authUser.rolespermissions : null;
  let homepage = "/dashboard";
  if (rolesPermission) {
    if (
      rolesPermission.homePage &&
      rolesPermission.homePage.value == "Servicedashboard"
    ) {
      homepage = "service/dashboard";
    } else if (
      rolesPermission.homePage &&
      rolesPermission.homePage.value == "Companydashboard"
    ) {
      homepage = "dashboard";
    }
  }
  if (location.pathname === "" || location.pathname === "/") {
    return <Redirect to={"/signin"} />;
  } else if (authUser && location.pathname === "/signin") {
    return <Redirect to={homepage} />;
  } else if (authUser == null && location.pathname != "/signin") {
    return <Redirect to={"/signin"} />;
  }

  return (
    <React.Fragment>
      <Switch>
        {checkRouterUrl("dashboard", "view") && (
          <Route path="/dashboard" component={Dashboard} />
        )}
        <Route path="/assets" component={Assets} />
        {checkRouterUrl("approval", "view") && (
          <Route path="/approve" component={Approve} />
        )}
        {checkRouterUrl("company", "view") && (
          <Route path="/company" component={Company} />
        )}
        <Route path="/maintenance" component={Maintenance} />
        {checkRouterUrl("profile", "view") && (
          <Route path="/profile" component={Profile} />
        )}

        {checkRouterUrl("company", "add") && (
          <Route path="/addcompany" component={Addcompany} />
        )}
        {checkRouterUrl("company", "view") && (
          <Route
            path="/editcompany/:id"
            exact={true}
            component={Addcompany}
            name="company"
          />
        )}
        <Route path="/roles" component={Roles} />
        <Route path="/service" component={Service} />
        <Route path="/settings" component={Settings} />
        <Route path="/team" component={Team} />
        <Route path="/tracking" component={Tracking} />
        <Route path="/intelligen" component={Intelligen} />

        <Route path="/warehouseadmission" component={WarehouseAdmission} />
        <Route path="/warehousedepartures" component={WarehouseDepartures} />
        <Route path="/businessintelligence" component={BusinessIntelligence} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
