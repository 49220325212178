import React from "react";
import {ConnectedRouter} from "connected-react-router";
import {Provider} from "react-redux";
import {Switch} from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import configureStore, {history} from "./redux/store";
import AppWrapper from "./@jumbo/components/AppWrapper";
import AppContextProvider from "./@jumbo/components/contextProvider/AppContextProvider";
import Routes from "./routes";
import {ToastContainer} from "react-toastify";
import {makeStyles} from "@material-ui/core/styles";
import "./theme/ReactToastify.css";

export const store = configureStore();
const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ToastContainer />
        <AppWrapper>
          <Switch>
            <Routes />
          </Switch>
        </AppWrapper>
      </ConnectedRouter>
    </Provider>
  </AppContextProvider>
);

export default App;
