import React, {useState, useEffect} from "react";
import {Box, makeStyles, Typography} from "@material-ui/core";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Grid from "@material-ui/core/Grid";
import CmtButtons from "../../../@coremat/CmtButtons";
import {getRouteUrl} from "../../../@jumbo/utils/commonHelper";
import ObservationAddModalCard from "./../../../@coremat/ObservationAddModalCard";
import {useParams} from "react-router";

const useStyles = makeStyles(theme => ({
  PageHeaderBackRoot: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      flexDirection: "row"
    }
  },
  titleRoot: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 10
    }
  }
}));

const PageHeaderBack = ({
  onSubmit,
  moduleName,
  children,
  Isobservation,
  Iscancel,
  ...rest
}) => {
  const classes = useStyles();
  const [ observationModal, setObservationModal ] = useState(false);
  const {id} = useParams();

  const onSubmitAction = () => {
    if (Isobservation) {
      setObservationModal(true);
    } else {
      onSubmit();
    }
  };

  const onSaveObservation = params => {
    console.log("params==", params);
    onSubmit(params.observation);
  };

  const onCancel = () => {
    setObservationModal(false);
  };
  let buttons = [];
  if (id) {
    buttons = [
      Iscancel != false && {
        label: <IntlMessages id="actions.cancel" />,
        color: "cancel",
        onClick: getRouteUrl(moduleName)
      },
      {
        label: <IntlMessages id="sidebar.update" />,
        color: "save",
        onClick: onSubmitAction
      }
    ];
  } else {
    buttons = [
      Iscancel != false && {
        label: <IntlMessages id="actions.cancel" />,
        color: "cancel",
        onClick: getRouteUrl(moduleName)
      },
      {
        label: <IntlMessages id="sidebar.save" />,
        color: "save",
        onClick: onSubmitAction
      }
    ];
  }

  const backButton = [
    {
      icon: <ArrowBackIosIcon style={{fontSize: "12"}} />,
      color: "back",
      onClick: getRouteUrl(moduleName)
    }
  ];
  return (
    <Grid classes={{root: "filterContainerheader"}}>
      <Grid item xs={12}>
        {Iscancel != false && (
          <CmtButtons items={backButton} classes={{root: "cardBackButtons"}} />
        )}
        <Grid style={{float: "left", padding: "19px 10px", fontSize: "16px"}}>
          {Iscancel != false &&
          id == undefined && <IntlMessages id="add_page" />}
          {Iscancel != false && id && <IntlMessages id="edit_page" />}
        </Grid>
        <CmtButtons items={buttons} classes={{root: "cardButtons"}} />
      </Grid>
      {observationModal && (
        <ObservationAddModalCard
          onCancel={onCancel}
          onAction={onSaveObservation}
        />
      )}
    </Grid>
  );
};

export default PageHeaderBack;
