import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import {useDispatch, useSelector} from "react-redux";
import AppSwitch from "../../@jumbo/components/Common/formElements/AppSwitch";
import api from "../../services/api";
import GetAppIcon from "@material-ui/icons/GetApp";

import {
  getTable,
  getAvailableClassName,
  getStatusClassName,
  getSearchQuery,
  getNumberFormat,
  getSixDigit,
  getSortQuery,
  checkDateType,
  getDocumentList,
  isUrlValid,
  getRolesPermissionId,
  getModuleName
} from "../../@jumbo/utils/commonHelper";

import {
  getFormatEnglishSpanishDate,
  getFormatDate,
  isDateValid
} from "../../@jumbo/utils/dateHelper";

import {
  downloadPurchaseRequest,
  downloadElectronicInvoice,
  downloadSuplierOfficeGuide,
  downloadProfessionalFees,
  downloadCreditNote,
  downloadDebitNote,
  downloadAdmissionInternalGuide,
  downloadAdmissionTransferDispatch,
  downloadAdjustment,
  downloadDepatureTransferDispatch,
  downloadDepatureInternalGuide,
  downloadWastage
} from "../../@jumbo/utils/pdfHelper";
import {
  updateData,
  resetData,
  deleteAction,
  copyResource,
  fetchError,
  deleteApproveData,
  deleteWarehouseApproveData,
  setTable,
  fetchStart,
  fetchSuccess
} from "../../redux/actions/Common";

import CmtTableFooter from "../CmtTableFooter";
import CmtTableSelectToolBar from "../CmtTableSelectToolBar";
import CmtTableToolBar from "../CmtTableToolBar";
import EditunitModalCard from "../EditunitModalCard";
import WomechanicModalCard from "../WomechanicModalCard";
import WoobservationModalCard from "../WoobservationModalCard";
import {useIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AppTextInput from "../../@jumbo/components/Common/formElements/AppTextInput";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import SortIcon from "@material-ui/icons/Sort";
import CmtButtons from "../CmtButtons";
import {
  checkRouterUrl,
  requestPermission,
  getModulePermission,
  getModuleDeletePermission
} from "../../@jumbo/utils/commonHelper";

import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";

let timer_id = undefined;
let deleteKey = [];
let page1 = 0;
let rowsPerPage1 = 10;
let editIndex = 0;
let actionType = "";
let searchText = "";
const checkPriceFormat = key => {
  let keyList = [
    "price_per_unit",
    "half_price",
    "price",
    "hourly_rate",
    "total_discount_price",
    "discount_price",
    "total_price",
    "unit_price",
    "discounted_price",
    "total_tax_price",
    "cost_today",
    "total_today",
    "stock_at",
    "cost_at",
    "warehouse_cost",
    "total_at",
    "sub_total",
    "discount_sub_total",
    "temp_unit_price",
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
    "total",
    "wap_amount",
    "total_cost",
    "total_invoice",
    "medium_price",
    "rate",
    "totaltraffic",
    "bonus_value",
    "totalInvoice",
    "lastWapAmount",
    "wap",
    "totalCost",
    "costAt",
    "totalAt",
    "costToday",
    "totalToday",
    "costAt",
    "totalAt",
    "costWarehouse"
  ];
  return keyList.indexOf(key) != -1 ? true : false;
};

const CmtTable = ({
  data,
  columns,
  title,
  moduleName,
  server,
  isEdit,
  isDelete,
  isAdd,
  isSearch,
  isRequest,
  isSendall,
  onRowsSelect,
  deleteClientAction,
  rowsSelected,
  actionQty,
  onApprovalAction,
  actionDiscountQty,
  Ispdf,
  isApprove,
  isApproveall,
  isApproveSelected,
  isRejectSelected,
  isRejectall,
  isCopy,
  onRowsApprove,
  onRowsObservation,
  IseditQty,
  IsselectableRows,
  IsobservationButton,
  isView,
  onRowsRequest,
  onlyShowIds,
  onSendStock,
  onReceived,
  onToggleDevolutionUserModal,
  onDevolutionStatus,
  onRowClick,
  onChangeActiveStatus,
  onChangeComplianceMark,
  onChangeMaintenancetypeAction,
  onChangeMaintenancegroupAction,
  onChangeMaintenanceresourceAction,
  onChangeMaintenanceformAction,
  onChangeMaintenanceplanAction,
  onTableInit,
  onSendService,
  onConfirmStockAction,
  tableId,
  onDownload,
  onRowsView,
  onSearchChange,
  responsivePagination = false,
  arrowByText = false,
  showPointer = false,
  showDownload = true,
  showColumns = true,
  fixDropdownZIndex = false,
  fixArrowText = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [ IsUnitEdit, setUnitEditModal ] = useState(false);
  const [ IsMechanic, setMechanicModal ] = useState(false);
  const [ IsObservation, setObservationModal ] = useState(false);
  const [ IsUnitEditValue, setUnitEditValue ] = useState(false);
  const [ unitEditId, setUnitEditId ] = useState(0);
  const [ rowItemsTable, setRowItemsTable ] = useState([]);
  const intl = useIntl();
  const classes = useStyles({showPointer, fixDropdownZIndex});
  const {authUser} = useSelector(({auth}) => auth);
  const {table} = useSelector(({common}) => common);

  const [ emailNotification, setEmailNotification ] = useState(true);

  let rowsName = intl.formatMessage({id: "table.rows"});

  useEffect(
    () => {
      if (data) {
        setRowItemsTable(data);
      }
    },
    [ data ]
  );

  useEffect(
    () => {
      if (table) {
        if (table.search == "") {
          searchText = "";
        }
      }
    },
    [ table ]
  );

  const editUnitModal = (value, editIndex, type) => {
    editIndex = editIndex;
    actionType = type;
    setUnitEditId(editIndex);
    setUnitEditValue(value);
    setUnitEditModal(true);
  };

  const mechanicModal = index => {
    editIndex = index;
    setMechanicModal(true);
  };

  const onSaveMechanicModel = value => {
    handleMechanicCharge(editIndex, value);
    setMechanicModal(false);
  };

  const onMechanicCancel = () => {
    setMechanicModal(false);
  };

  const observationModal = () => {
    setObservationModal(true);
  };

  const onObservationCancel = () => {
    setObservationModal(false);
  };

  const handleChangeObservation = (index, value) => {
    let rowsTable = rowItemsTable;
    rowsTable.rows[index].addObservation = value;
    setRowItemsTable({...rowsTable});
  };

  const handleMechanicCharge = (index, value) => {
    let rowsTable = rowItemsTable;
    rowsTable.rows[index].addMechanic = value;
    setRowItemsTable({...rowsTable});
  };

  const onChangeStatuseAction = index => {
    let rows = rowItemsTable.rows[index];
    rows.status = rows.status == undefined ? true : !rows.status;
    let item = {};
    if (moduleName == "rolespermissions") {
      rows.roleStatus = rows.status;
      item = {
        roleStatus: rows.status,
        companyId: rows.companyId,
        role: rows.role,
        updatedUid: authUser.id
      };
    } else {
      item = {
        status: rows.status,
        updatedUid: authUser.id
      };
    }

    let rowsTable = rowItemsTable;
    rowsTable.rows[index] = rows;
    console.log("rowsTable.rows[index]===", rows, rows.status);
    setRowItemsTable({...rowsTable});
    dispatch(updateData(moduleName, item, rows.id, history, false));
  };

  const customOrderNoRender = (value, meta, update) => {
    return <Grid>{getSixDigit(value)}</Grid>;
  };

  const customerAlertTypeName = (value, meta, update) => {
    return (
      <Grid>
        <IntlMessages
          id={`alert.${rowItemsTable.rows[meta.rowIndex].alertsType.name}`}
        />
      </Grid>
    );
  };

  const customVehicleName = (value, meta, update) => {
    return <Grid>{rowItemsTable.rows[meta.rowIndex].equipment.name}</Grid>;
  };

  const customLocation = (value, meta, update) => {
    return (
      <Grid>
        {rowItemsTable.rows[meta.rowIndex].infoData.city} -{" "}
        {rowItemsTable.rows[meta.rowIndex].infoData.country}
      </Grid>
    );
  };

  const customDateOccurrence = (value, meta, _update) => {
    return <Grid>{moment(value).format("MM-DD-YYYY hh:mm a")}</Grid>;
  };

  const customAlertsValue = (value, meta, update) => {
    let valueTable = rowItemsTable.rows[meta.rowIndex].value;
    if (valueTable) {
      let unit =
        rowItemsTable.rows[meta.rowIndex].alertsType.name === "battery_low"
          ? "V"
          : "km/h";
      return (
        <Grid>{`${rowItemsTable.rows[meta.rowIndex].value} ${unit}`}</Grid>
      );
    }
    return (
      <Grid>
        <IntlMessages id="alert.non_value" />
      </Grid>
    );
  };

  const customInfoKm = (value, meta, update) => {
    if (parseInt(rowItemsTable.rows[meta.rowIndex].info.kilometers) > 0) {
      return (
        <Grid>{rowItemsTable.rows[meta.rowIndex].info.kilometers} KM</Grid>
      );
    }
    return <Grid>-</Grid>;
  };

  const customGpsInfoSpeed = (value, meta, update) => {
    if (parseInt(rowItemsTable.rows[meta.rowIndex].gpsInfo.speed) > 0) {
      return (
        <Grid>{rowItemsTable.rows[meta.rowIndex].gpsInfo.speed} KM/H</Grid>
      );
    }
    return <Grid>-</Grid>;
  };

  const customDateRegisterNumber = (value, meta, update) => {
    return (
      <Grid>
        {/* {rowItemsTable.rows[meta.rowIndex].registerNumber}
				<br /> */}
        {rowItemsTable.rows[meta.rowIndex].date}
        <br />
        {`${rowItemsTable.rows[meta.rowIndex].startHour} - ${rowItemsTable.rows[
          meta.rowIndex
        ].endHour}`}
      </Grid>
    );
  };

  const customRunTime = (value, meta, update) => {
    if (
      rowItemsTable.rows[meta.rowIndex].gpsInfo.ignitionStatus === "On" &&
      rowItemsTable.rows[meta.rowIndex].gpsInfo.run_time_engine_start > 0
    ) {
      let d = rowItemsTable.rows[meta.rowIndex].gpsInfo.run_time_engine_start;
      let h = Math.floor(d / 3600);
      let m = Math.floor((d % 3600) / 60);
      let s = Math.floor((d % 3600) % 60);

      let hDisplay = h > 0 ? h : "";
      let mDisplay = m > 0 ? `:${m}` : "";
      let sDisplay = s > 0 ? `:${s}` : "";

      return <Grid>{`${hDisplay + mDisplay + sDisplay}`}</Grid>;
    }
    return <Grid>-</Grid>;
  };

  const customCity = (value, meta, update) => {
    return <Grid>{rowItemsTable.rows[meta.rowIndex].gpsInfo.city}</Grid>;
  };

  const customAvailability = (value, meta, update) => {
    return (
      <Grid>
        <IntlMessages
          id={`availability.${rowItemsTable.rows[meta.rowIndex].info
            .availability}`}
        />
      </Grid>
    );
  };

  const customNumberRender = (value, meta, update) => {
    return getNumberFormat(value);
  };
  const customDateTimeRender = (value, meta, update) => {
    return <Grid>{getFormatEnglishSpanishDate(value)}</Grid>;
  };

  const customStatusRender = (value, meta, update) => {
    return (
      <Grid
        className={
          "btn_status btn_status_approval pending_status " +
          getAvailableClassName(value)
        }
      >
        {getStatusClassName(value)}
      </Grid>
    );
  };
  const customDevolutionStatusRender = (value, meta, update) => {
    return (
      <Grid
        onClick={() => onDevolutionStatus(meta.rowIndex)}
        className={
          "btn_status btn_status_approval pending_status " +
          getAvailableClassName(value)
        }
      >
        {getStatusClassName(value)}
      </Grid>
    );
  };

  const customAddObservationRender = (value, meta, update) => {
    let observation = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].addObservation
      : 0;
    return (
      <AppTextInput
        onChange={event =>
          handleChangeObservation(meta.rowIndex, event.target.value)}
        value={observation}
        type="text"
        InputLabelProps={{shrink: true}}
      />
    );
  };

  const customAddMechanicRender = (value, meta, update) => {
    let mechanicCharge = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].addMechanic
      : 0;
    return (
      <Grid spacing={3} className={classes.mechanicinput}>
        <AppTextInput
          type="text"
          InputLabelProps={{shrink: true}}
          disabled={true}
          value={mechanicCharge ? mechanicCharge.label : ""}
          onClick={() => mechanicModal(meta.rowIndex)}
        />
      </Grid>
    );
  };

  const customPermissionRender = (value, meta, update) => {
    let id = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].id
      : 0;
    return (
      <Grid className={"btn_permission"} style={{display: "inline-flex"}}>
        <CmtButtons
          items={[
            {
              icon: <SortIcon />,
              color: "add",
              onClick: "/roles/editrolespermissions/" + id
            }
          ]}
          classes={{root: "addButton"}}
        />
      </Grid>
    );
  };

  const customModifyApprovalStatusRender = (value, meta, update) => {
    let showModal = true;
    let find;
    let availableClassName = getAvailableClassName(value);
    let statusClassName = getStatusClassName(value);
    // if (value === "partially_approve") {
    //   let item = rowItemsTable.rows[meta.rowIndex];
    //   let permissions = [];
    //   if (item.approvalFlow) {
    //     item.approvalFlow.forEach(e => {
    //       e.permissions.forEach(p => {
    //         p.forEach(i => {
    //           permissions = permissions.concat(i);
    //         });
    //       });
    //     });
    //   }

    //   find = permissions.find(
    //     e => e.id.toString() === getRoleId().toString() && e.status
    //   );
    //   showModal = !!!find;
    //   if (showModal) {
    //     availableClassName = getAvailableClassName("pending");
    //     statusClassName = getStatusClassName("pending");
    //   }
    // }

    return (
      <Grid
        onClick={() => onApprovalAction(meta.rowIndex, showModal)}
        className={
          "btn_status btn_status_approval pending_status " +
          getAvailableClassName(value)
        }
      >
        {getStatusClassName(value)}
      </Grid>
    );
  };

  const customSendStockRender = (value, meta, update) => {
    return (
      <div style={{marginTop: "-12px"}}>
        <CmtButtons
          style={{marginTop: 0}}
          items={[
            {
              label: <IntlMessages id="actions.send_stock" />,
              color: "save",
              onClick: onSendStock,
              params: {index: meta.rowIndex}
            }
          ]}
        />
      </div>
    );
  };

  const customSendServiceRender = (value, meta, update) => {
    return (
      <div style={{marginTop: "-12px"}}>
        <CmtButtons
          items={[
            {
              label: <IntlMessages id="actions.send_service" />,
              color: "save",
              onClick: onSendService,
              params: {index: meta.rowIndex}
            }
          ]}
        />
      </div>
    );
  };

  const customSendRender = (value, meta, update) => {
    return (
      <CmtButtons
        items={[
          {
            label: <IntlMessages id="actions.sent" />,
            color: "save"
          }
        ]}
      />
    );
  };

  const customDevolutionRender = (value, meta, update) => {
    return (
      <div style={{marginTop: "-12px"}}>
        <CmtButtons
          items={[
            {
              label: <IntlMessages id="sidebar.devolution" />,
              color: "save",
              onClick: onToggleDevolutionUserModal,
              params: {index: meta.rowIndex}
            }
          ]}
        />
      </div>
    );
  };

  const customReceivedRender = (value, meta, update) => {
    return (
      <CmtButtons
        items={[
          {
            label: <IntlMessages id="actions.received" />,
            color: "save",
            onClick: onReceived,
            params: {index: meta.rowIndex}
          }
        ]}
      />
    );
  };

  const customApprovalStatusRender = (value, meta, update) => {
    return (
      <Grid
        onClick={() => onApprovalAction(meta.rowIndex)}
        className={
          "btn_status btn_status_approval pending_status " +
          getAvailableClassName(value)
        }
      >
        {getStatusClassName(value)}
      </Grid>
    );
  };

  const customConfirmStockStatusRender = (value, meta, update) => {
    return (
      <Grid
        onClick={() => onConfirmStockAction(meta.rowIndex)}
        className={
          "btn_status btn_status_approval pending_status " +
          getAvailableClassName(value)
        }
      >
        {getStatusClassName(value)}
      </Grid>
    );
  };

  const customDiscountRender = (value, meta, update) => {
    let tempUnit = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].discount
      : 0;
    return (
      <Grid container spacing={3} className={classes.unitchange_unit}>
        <Grid className={classes.unitminus}>
          <Button
            className={classes.unitminusbutton}
            onClick={() => actionDiscountQty("sub", meta.rowIndex)}
          >
            <RemoveIcon className={classes.iconStyle} />
          </Button>
        </Grid>
        <span className={classes.unitinput}>
          <AppTextInput
            type="text"
            InputLabelProps={{shrink: true}}
            value={tempUnit + "%"}
            disabled={true}
            onClick={() => editUnitModal(value, meta.rowIndex, "discount")}
          />
        </span>
        <Grid className={classes.unitplus}>
          <Button
            className={classes.unitaddbutton}
            onClick={() => actionDiscountQty("add", meta.rowIndex)}
          >
            <AddIcon className={classes.iconStyle} />
          </Button>
        </Grid>
      </Grid>
    );
  };

  const customMechanicRender = () => {
    return (
      <Grid spacing={3} className={classes.mechanicinput}>
        <AppTextInput
          type="text"
          InputLabelProps={{shrink: true}}
          disabled={true}
          onClick={() => mechanicModal()}
        />
      </Grid>
    );
  };

  const customObservationRender = () => {
    return (
      <Grid spacing={3} className={classes.mechanicinput}>
        <AppTextInput
          type="text"
          InputLabelProps={{shrink: true}}
          disabled={true}
          onClick={() => observationModal()}
        />
      </Grid>
    );
  };

  const customImageRender = (value, meta, update) => {
    let image = rowItemsTable.rows[meta.rowIndex].image_url;
    return (
      <Grid spacing={3} className={classes.unitchangeactionimg}>
        <img
          src={image}
          style={{width: 150, height: 150, position: "relative"}}
        />
      </Grid>
    );
  };

  const customComplianceMarkRender = (value, meta, update) => {
    let active =
      rowItemsTable.rows[meta.rowIndex].compliance_mark == undefined
        ? false
        : rowItemsTable.rows[meta.rowIndex].compliance_mark;

    return (
      <Grid spacing={3} className={classes.unitchangeaction}>
        <AppSwitch
          style={{width: "60px", display: "inline-flex"}}
          onChange={() => {
            onChangeComplianceMark(meta.rowIndex);
          }}
          checked={active}
        />
        <Grid item className={classes.unitchangeaction_left}>
          {active ? "Yes" : "No"}
        </Grid>
      </Grid>
    );
  };

  const customActiveActionRender = (value, meta, update) => {
    let active =
      rowItemsTable.rows[meta.rowIndex].activeaction == undefined
        ? false
        : rowItemsTable.rows[meta.rowIndex].activeaction;

    return (
      <Grid spacing={3} className={classes.unitchangeaction}>
        <AppSwitch
          style={{width: "auto"}}
          onChange={() => {
            onChangeActiveStatus(meta.rowIndex);
          }}
          checked={active}
        />
      </Grid>
    );
  };

  const statuseActionRender = (value, meta, update) => {
    let active =
      rowItemsTable.rows[meta.rowIndex].status == undefined
        ? false
        : rowItemsTable.rows[meta.rowIndex].status;

    return (
      <Grid spacing={3} className={classes.unitchangeaction}>
        <AppSwitch
          style={{width: "auto"}}
          onChange={() => {
            onChangeStatuseAction(meta.rowIndex);
          }}
          checked={active}
        />
      </Grid>
    );
  };

  const customViewObservationRender = (value, meta, update) => {
    let observationArray = rowItemsTable.rows[meta.rowIndex].observation;
    if (observationArray) {
      observationArray = observationArray.filter(function(item, pos){
        return observationArray.indexOf(item) == pos;
      });
      observationArray = observationArray.length;
    } else {
      observationArray = 0;
    }
    return (
      <CmtButtons
        className={classes.obsbutton}
        items={[
          {
            label:
              (observationArray != 0 ? observationArray : " ") +
              " " +
              intl.formatMessage({id: "sidebar.obs"}),
            color: "observation",
            onClick: onRowsObservation,
            params: {index: meta.rowIndex}
          }
        ]}
      />
    );
  };

  const customPdfRender = (value, meta, update) => {
    let status = rowItemsTable.rows[meta.rowIndex].modify_approval_status;
    if (status != "delete") {
      return (
        <CmtButtons
          className={classes.obsbutton}
          items={[
            {
              label: <IntlMessages id="actions.pdf" />,
              color: "pdf",
              onClick: onRowsApprovePdf,
              // icon: <GetAppIcon />,
              params: {index: meta.rowIndex}
            }
          ]}
        />
      );
    }
  };

  const customPurchaseUnitRender = (value, meta, update) => {
    let tempUnit = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].purchase_unit
      : 0;

    return (
      <Grid container spacing={3} className={classes.unitchange_unit}>
        <Grid className={classes.unitminus}>
          <Button
            className={classes.unitminusbutton}
            onClick={() => actionQty("sub", meta.rowIndex)}
          >
            <RemoveIcon className={classes.iconStyle} />
          </Button>
        </Grid>
        <span className={classes.unitinput}>
          <AppTextInput
            type="text"
            InputLabelProps={{shrink: true}}
            value={tempUnit}
            disabled={true}
            onClick={() => editUnitModal(value, meta.rowIndex, "unit")}
          />
        </span>
        <Grid className={classes.unitplus}>
          <Button
            className={classes.unitaddbutton}
            onClick={() => actionQty("add", meta.rowIndex)}
          >
            <AddIcon className={classes.iconStyle} />
          </Button>
        </Grid>
      </Grid>
    );
  };
  const renderModify = (value, meta, update) => {
    if (typeof value == "string" && isUrlValid(value)) {
      return <img src={value} style={{width: 110}} />;
    } else if (
      typeof value == "string" &&
      isDateValid(value) &&
      10 < value.length
    ) {
      return (
        <div>
          <p>{getFormatEnglishSpanishDate(value)}</p>
        </div>
      );
    } else if (typeof value == "boolean") {
      return (
        <div>
          <p>{value ? "true" : "false"}</p>
        </div>
      );
    } else if (value && value.label) {
      return (
        <div>
          <p>{value.label}</p>
        </div>
      );
    } else if (typeof value == "object" && value) {
      let findUrl = value.find(item => item && item.url);

      if (findUrl) {
        return (
          <div>
            {value.map((file, i) => {
              return <img src={file.url} style={{width: 110}} />;
            })}
          </div>
        );
      } else {
        return (
          <div>
            <p>{JSON.stringify(value)}</p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <p>{value}</p>
        </div>
      );
    }
  };

  const customPurchaseUnitRequestRender = (value, meta, update) => {
    let tempUnit = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].purchase_unit_request
      : 0;

    let isDisabled = false;
    return (
      <Grid container spacing={3} className={classes.unitchange}>
        <Grid className={classes.unitminus}>
          <Button
            className={classes.unitminusbutton}
            onClick={() => actionQty("sub", meta.rowIndex)}
            disabled={isDisabled}
          >
            <RemoveIcon className={classes.iconStyle} />
          </Button>
        </Grid>
        <span className={classes.unitinput}>
          <AppTextInput
            type="text"
            InputLabelProps={{shrink: true}}
            value={tempUnit}
            disabled={true}
            onClick={() =>
              !isDisabled && editUnitModal(value, meta.rowIndex, "unit")}
          />
        </span>

        <Grid className={classes.unitplus}>
          <Button
            className={classes.unitaddbutton}
            disabled={isDisabled}
            onClick={() => actionQty("add", meta.rowIndex)}
          >
            <AddIcon className={classes.iconStyle} />
          </Button>
        </Grid>
      </Grid>
    );
  };

  const customWarehouseUnitRender = (value, meta, update) => {
    let tempUnit = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].tempPurchaseQty
      : 0;
    let warehouseUnit =
      rowItemsTable.rows[meta.rowIndex] &&
      rowItemsTable.rows[meta.rowIndex].warehouse_unit
        ? rowItemsTable.rows[meta.rowIndex].warehouse_unit
        : "";
    let isDisabled = rowsSelected
      ? rowsSelected.indexOf(meta.rowIndex) == -1
      : false;
    isDisabled = IseditQty == true ? true : isDisabled;

    return (
      <Grid container spacing={3} className={classes.unitchange}>
        <Grid className={classes.unitminus}>
          <Button
            className={classes.unitminusbutton}
            onClick={() => actionQty("sub", meta.rowIndex)}
            disabled={isDisabled}
          >
            <RemoveIcon className={classes.iconStyle} />
          </Button>
        </Grid>
        <span className={classes.unitinput}>
          <AppTextInput
            type="text"
            InputLabelProps={{shrink: true}}
            value={tempUnit}
            disabled={true}
            onClick={() =>
              !isDisabled && editUnitModal(tempUnit, meta.rowIndex, "unit")}
          />
        </span>

        <Grid className={classes.unitplus}>
          <Button
            className={classes.unitaddbutton}
            disabled={isDisabled}
            onClick={() => actionQty("add", meta.rowIndex)}
          >
            <AddIcon className={classes.iconStyle} />
          </Button>
        </Grid>
        {warehouseUnit && (
          <span className={classes.unitslash}>/{warehouseUnit}</span>
        )}
      </Grid>
    );
  };

  const customCreditUnitRender = (value, meta, update) => {
    let tempUnit = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].tempCreditPurchaseQty
      : 0;
    let isDisabled = rowsSelected
      ? rowsSelected.indexOf(meta.rowIndex) == -1
      : false;
    let creditUnit = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].credit_unit
      : 0;

    return (
      <Grid container spacing={3} className={classes.unitchange}>
        <Grid className={classes.unitminus}>
          <Button
            className={classes.unitminusbutton}
            onClick={() => actionQty("sub", meta.rowIndex)}
            disabled={isDisabled}
          >
            <RemoveIcon className={classes.iconStyle} />
          </Button>
        </Grid>
        <span className={classes.unitinput}>
          <AppTextInput
            type="text"
            InputLabelProps={{shrink: true}}
            value={tempUnit}
            disabled={true}
            onClick={() =>
              !isDisabled && editUnitModal(value, meta.rowIndex, "unit")}
          />
        </span>
        <Grid className={classes.unitplus}>
          <Button
            className={classes.unitaddbutton}
            onClick={() => actionQty("add", meta.rowIndex)}
            disabled={isDisabled}
          >
            <AddIcon className={classes.iconStyle} />
          </Button>
        </Grid>
        <span className={classes.unitslash}>/{creditUnit}</span>
      </Grid>
    );
  };

  const customDebitUnitRender = (value, meta, update) => {
    let tempUnit = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].tempDebitPurchaseQty
      : 0;
    let isDisabled = rowsSelected
      ? rowsSelected.indexOf(meta.rowIndex) == -1
      : false;
    let debitUnit = rowItemsTable.rows[meta.rowIndex]
      ? rowItemsTable.rows[meta.rowIndex].debit_unit
      : 0;

    return (
      <Grid container spacing={3} className={classes.unitchange}>
        <Grid className={classes.unitminus}>
          <Button
            className={classes.unitminusbutton}
            onClick={() => actionQty("sub", meta.rowIndex)}
            disabled={isDisabled}
          >
            <RemoveIcon className={classes.iconStyle} />
          </Button>
        </Grid>
        <span className={classes.unitinput}>
          <AppTextInput
            type="text"
            InputLabelProps={{shrink: true}}
            value={tempUnit}
            disabled={true}
            onClick={() =>
              !isDisabled && editUnitModal(value, meta.rowIndex, "unit")}
          />
        </span>
        <Grid className={classes.unitplus}>
          <Button
            className={classes.unitaddbutton}
            onClick={() => actionQty("add", meta.rowIndex)}
            disabled={isDisabled}
          >
            <AddIcon className={classes.iconStyle} />
          </Button>
        </Grid>
        <span className={classes.unitslash}>/{debitUnit}</span>
      </Grid>
    );
  };

  const rowsPerPageOptions = [
    {label: "5" + " " + rowsName, value: 5},
    {label: "10" + " " + rowsName, value: 10},
    {label: "20" + " " + rowsName, value: 20},
    {label: "100" + " " + rowsName, value: 100}
  ];
  let resData = getTable(rowItemsTable.rows ? rowItemsTable.rows : [], columns);

  const onRefresh = () => {
    onDispatch(moduleName, page1, rowsPerPage1, "");
  };

  const onSort = (key, value) => {
    let sortQuery = getSortQuery(key, value, columns);
    onDispatch(moduleName, page1, rowsPerPage1, "", sortQuery);
  };

  const onDispatch = (moduleName, page, rowsPerPage, search, sort) => {
    console.log(
      "searchText==========",
      moduleName,
      searchText,
      page,
      rowsPerPage
    );

    page1 = page;
    rowsPerPage1 = rowsPerPage;
    let searchQuery = "";
    if (searchText && searchText != "") {
      searchQuery = getSearchQuery(searchText, columns);
    }
    dispatch(
      setTable({
        page: page,
        moduleName: moduleName,
        rowsPerPage: rowsPerPage,
        search: searchQuery,
        sort: sort ? sort : ""
      })
    );
  };

  const onCopy = rows => {
    console.log("rows==", rowItemsTable.rows[rows[0].index]);
    dispatch(
      copyResource(
        moduleName,
        {
          id: rowItemsTable.rows[rows[0].index].id,
          companyId: rowItemsTable.rows[rows[0].index].companyId
        },
        {
          page: 0,
          moduleName: moduleName,
          rowsPerPage: 10
        }
      )
    );
  };

  const onRowsDelete = deleteRows => {
    deleteKey = [];
    var deletePermission = [];
    var deleteItem = [];

    var findWarehouseModule = getDocumentList().find(
      item => item.name == moduleName
    );
    findWarehouseModule = findWarehouseModule
      ? findWarehouseModule.value
      : null;
    let warehouseId = null;
    let isPending = false;

    for (let item of deleteRows.data) {
      if (server == false) {
        deleteKey.push(item.index);
      } else {
        deleteKey.push(rowItemsTable.rows[item.index].id);
      }
      warehouseId = rowItemsTable.rows[item.index].warehouseId
        ? rowItemsTable.rows[item.index].warehouseId
        : null;
      if (
        moduleName == "depatureinternalguide" ||
        moduleName == "admissioninternalguide" ||
        moduleName == "depaturetransferdispatch"
      ) {
        warehouseId = rowItemsTable.rows[item.index].originWarehouseId;
      } else if (moduleName == "admissiontransferdispatch") {
        warehouseId = rowItemsTable.rows[item.index].destinationWarehouseId;
      }
      console.log("moduleName===", moduleName, rowItemsTable.rows[item.index]);

      let documentNo = rowItemsTable.rows[item.index].documentNo
        ? rowItemsTable.rows[item.index].documentNo
        : null;

      if (
        moduleName == "purchaseorder" ||
        moduleName == "adjustment" ||
        moduleName == "admissiontransferdispatch" ||
        moduleName == "admissioninternalguide" ||
        moduleName == "depaturetransferdispatch" ||
        moduleName == "depatureinternalguide" ||
        moduleName == "wastage"
      ) {
        documentNo = rowItemsTable.rows[item.index].invoiceNo;
      }
      deletePermission.push({
        moduleId: rowItemsTable.rows[item.index].id,
        orderId: rowItemsTable.rows[item.index].id,
        companyId: rowItemsTable.rows[item.index].companyId,
        moduleName: moduleName,
        regionId: rowItemsTable.rows[item.index].regionId
          ? rowItemsTable.rows[item.index].regionId
          : null,
        warehouseId: warehouseId,
        document: findWarehouseModule,
        emissionDate: new Date(),
        documentNo: documentNo,
        modifyStatus: "request_delete",
        approvalStatus: "request_delete",
        modify: [],
        rolePermissionId: getRolesPermissionId(moduleName, "remove"),
        createdUid: authUser.id
      });
      deleteItem.push(rowItemsTable.rows[item.index]);
      if (
        findWarehouseModule &&
        rowItemsTable.rows[item.index].approval_status_label == "pending"
      ) {
        isPending = true;
      }
    }

    if (server == false) {
      deleteClientAction(deleteKey);
    } else {
      if (getModulePermission(moduleName, deleteItem)) {
        let isAssetsobservationModel = requestPermission(moduleName, "remove");
        if (isPending) {
          dispatch(
            fetchError(
              intl.formatMessage({id: "error.delete_item_pending"}) +
                intl.formatMessage({
                  id: "actions.pending"
                })
            )
          );
        } else if (isAssetsobservationModel) {
          if (findWarehouseModule) {
            console.log("deletePermission===", deletePermission, moduleName);
            dispatch(
              deleteWarehouseApproveData({deleteapprove: deletePermission})
            );
          } else {
            dispatch(deleteApproveData({deleteapprove: deletePermission}));
          }
        } else {
          dispatch(deleteAction(moduleName, {id: deleteKey}));
        }
      } else {
        dispatch(
          fetchError(
            intl.formatMessage({
              id: "error.permisson_region_error"
            })
          )
        );
      }
    }
  };
  const onUnitDelete = () => {
    setUnitEditModal(false);
  };

  const onRowsEdit = dataIndex => {
    if (checkRouterUrl(moduleName, "edit")) {
      if (getModulePermission(moduleName, rowItemsTable.rows[dataIndex])) {
        var findWarehouseModule = getDocumentList().find(
          item => item.name == moduleName
        );
        if (
          moduleName == "purchaseorder" &&
          resData[dataIndex].purchaserequests_status != "new"
        ) {
          dispatch(
            fetchError(
              intl.formatMessage({id: "error.edit_item"}) +
                intl.formatMessage({
                  id: "actions." + resData[dataIndex].purchaserequests_status
                })
            )
          );
        } else if (
          findWarehouseModule &&
          resData[dataIndex].approval_status_label == "pending"
        ) {
          dispatch(
            fetchError(
              intl.formatMessage({id: "error.edit_item"}) +
                intl.formatMessage({
                  id: "actions." + resData[dataIndex].approval_status_label
                })
            )
          );
        } else {
          dispatch(resetData());
          if (moduleName == "rolespermissions") {
            history.push("editaccesspermission" + "/" + resData[dataIndex].id);
          } else {
            history.push("edit" + moduleName + "/" + resData[dataIndex].id);
          }
        }
      } else {
        dispatch(
          fetchError(
            intl.formatMessage({
              id: "error.permisson_region_error"
            })
          )
        );
      }
    }
  };

  const onRowsApprovePdf = async item => {
    let newItems = rowItemsTable.rows[item.index];
    let newModuleName = getModuleName(newItems.document);
    dispatch(fetchStart());
    let response = await api.getApi(newModuleName + "/" + newItems.orderId);
    if (response.status == "success") {
      dispatch(fetchSuccess());
      if (response.data) {
        onRowsPdf(
          [ {index: 0, data: response.data, moduleName: newModuleName} ],
          true
        );
      }
    } else {
      dispatch(fetchError(response.message));
    }
  };

  const onRowsPdf = async (rowItems, IsapproveDocument) => {
    for (let item of rowItems) {
      let pdfItems = IsapproveDocument
        ? item.data
        : rowItemsTable.rows[item.index];
      let newModuleName = IsapproveDocument ? item.moduleName : moduleName;
      if (newModuleName == "purchaseorder") {
        await downloadPurchaseRequest(pdfItems, intl);
      } else if (newModuleName == "electronicinvoice") {
        await downloadElectronicInvoice(pdfItems, intl);
      } else if (newModuleName == "supplierofficeguide") {
        await downloadSuplierOfficeGuide(pdfItems, intl);
      } else if (newModuleName == "professionalfees") {
        await downloadProfessionalFees(pdfItems, intl);
      } else if (newModuleName == "creditnote") {
        await downloadCreditNote(pdfItems, intl);
      } else if (newModuleName == "debitnote") {
        await downloadDebitNote(pdfItems, intl);
      } else if (newModuleName == "admissioninternalguide") {
        await downloadAdmissionInternalGuide(pdfItems, intl);
      } else if (newModuleName == "admissiontransferdispatch") {
        await downloadAdmissionTransferDispatch(pdfItems, intl);
      } else if (newModuleName == "adjustment") {
        await downloadAdjustment(pdfItems, intl);
      } else if (newModuleName == "depaturetransferdispatch") {
        await downloadDepatureTransferDispatch(pdfItems, intl);
      } else if (newModuleName == "depatureinternalguide") {
        await downloadDepatureInternalGuide(pdfItems, intl);
      } else if (newModuleName == "wastage") {
        await downloadWastage(pdfItems, intl);
      }
    }
  };

  const onUnitCancel = () => {
    setUnitEditModal(false);
  };
  const onSaveUnit = value => {
    if (actionType == "discount") {
      actionDiscountQty("enter", unitEditId, value);
    } else {
      actionQty("enter", unitEditId, value);
    }
    setUnitEditModal(false);
  };

  let options = {};
  let newColumns = [];
  for (let item of columns) {
    if (item.name == "warehouse_unit") {
      item.options = {customBodyRender: customWarehouseUnitRender};
    } else if (
      item.name == "approval_status_label" ||
      item.name == "purchaserequests_status"
    ) {
      item.options = {customBodyRender: customStatusRender};
    } else if (item.name == "permission") {
      item.options = {customBodyRender: customPermissionRender};
    } else if (item.name == "purchase_unit") {
      item.options = {customBodyRender: customPurchaseUnitRender};
    } else if (item.name == "purchase_unit_request") {
      item.options = {customBodyRender: customPurchaseUnitRequestRender};
    } else if (item.name == "credit_unit") {
      item.options = {customBodyRender: customCreditUnitRender};
    } else if (item.name == "debit_unit") {
      item.options = {customBodyRender: customDebitUnitRender};
    } else if (item.name == "modify_approval_status") {
      item.options = {customBodyRender: customModifyApprovalStatusRender};
    } else if (item.name == "send_stock") {
      item.options = {customBodyRender: customSendStockRender};
    } else if (item.name == "send_service") {
      item.options = {customBodyRender: customSendServiceRender};
    } else if (item.name == "send") {
      item.options = {customBodyRender: customSendRender};
    } else if (item.name == "received") {
      item.options = {customBodyRender: customReceivedRender};
    } else if (item.name == "devolution") {
      item.options = {customBodyRender: customDevolutionRender};
    } else if (item.name == "devolution_status") {
      item.options = {customBodyRender: customDevolutionStatusRender};
    } else if (item.name == "addObservation") {
      item.options = {customBodyRender: customAddObservationRender};
    } else if (item.name == "addMechanic") {
      item.options = {customBodyRender: customAddMechanicRender};
    } else if (item.name == "approval_status") {
      item.options = {customBodyRender: customApprovalStatusRender};
    } else if (item.name == "confirm_status") {
      item.options = {customBodyRender: customConfirmStockStatusRender};
    } else if (item.name == "maintenancetype_mechanic") {
      item.options = {customBodyRender: customMechanicRender};
    } else if (item.name == "maintenancetype_observation") {
      item.options = {customBodyRender: customObservationRender};
    } else if (item.name == "discount") {
      item.options = {customBodyRender: customDiscountRender};
    } else if (item.name == "status") {
      item.options = {customBodyRender: statuseActionRender};
    } else if (item.name == "view_observation") {
      item.options = {customBodyRender: customViewObservationRender};
    } else if (item.name == "approve_pdf") {
      item.options = {customBodyRender: customPdfRender};
    } else if (item.name == "activeaction") {
      item.options = {customBodyRender: customActiveActionRender};
    } else if (item.name == "compliance_mark") {
      item.options = {customBodyRender: customComplianceMarkRender};
    } else if (item.name == "image_url") {
      item.options = {customBodyRender: customImageRender};
    } else if (
      item.name == "id" ||
      item.name == "orderId" ||
      item.name == "documentNo" ||
      item.name == "dispatchGuideNo" ||
      item.name == "invoiceNo"
    ) {
      item.options = {customBodyRender: customOrderNoRender};
    } else if (item.name == "alertsType.name") {
      item.options = {customBodyRender: customerAlertTypeName};
    } else if (checkPriceFormat(item.name)) {
      item.options = {customBodyRender: customNumberRender};
    } else if (checkDateType(item.name)) {
      item.options = {customBodyRender: customDateTimeRender};
    } else if (item.name == "modified" || item.name == "previous") {
      item.options = {customBodyRender: renderModify};
    } else if (item.name == "info.kilometers") {
      item.options = {customBodyRender: customInfoKm};
    } else if (item.name == "gpsInfo.speed") {
      item.options = {customBodyRender: customGpsInfoSpeed};
    } else if (item.name == "dateRegisterNumber") {
      item.options = {customBodyRender: customDateRegisterNumber};
    } else if (item.name == "gpsInfo.run_time_engine_start") {
      item.options = {customBodyRender: customRunTime};
    } else if (item.name == "gpsInfo.city") {
      item.options = {customBodyRender: customCity};
    } else if (item.name == "info.availability") {
      item.options = {customBodyRender: customAvailability};
    } else if (item.name == "equipment.name") {
      item.options = {customBodyRender: customVehicleName};
    } else if (item.name == "location") {
      item.options = {customBodyRender: customLocation};
    } else if (item.name == "alerts.value") {
      item.options = {customBodyRender: customAlertsValue};
    } else if (item.name === "dateOcurrence") {
      item.options = {customBodyRender: customDateOccurrence};
    }

    item.options = {...item.options, sort: false};
    if (item.sort == true) {
      item.options = {...item.options, sort: true};
    }
    if (item.date) {
      newColumns.push({
        ...item,
        label:
          intl.formatMessage({id: item.label}) + "\n" + getFormatDate(item.date)
      });
    } else {
      newColumns.push({...item, label: intl.formatMessage({id: item.label})});
    }
  }

  if (server == false) {
    options = {
      filters: "false",
      // responsive: "stacked",
      filterType: "dropdown",
      responsive: "scrollMaxHeight",
      // tableBodyMinHeight: "0px",
      // tableBodyMaxHeight: "270px",
      textLabels: {
        body: {
          noMatch: <IntlMessages id="table.sorry_no_row" />
        },
        toolbar: {
          search: <IntlMessages id="sidebar.search" />,
          downloadCsv: <IntlMessages id="util.download_csv" />,
          viewColumns: <IntlMessages id="util.view_columns" />
        }
      },
      filter: false,
      serverSide: false,
      fixedHeader: true,
      search: isSearch == undefined ? false : isSearch,
      selectableRows: IsselectableRows == undefined ? true : IsselectableRows,
      print: false,
      viewColumns: false,
      download: false,
      count: rowItemsTable.count,
      // responsive: "stacked",
      rowsSelected: rowsSelected ? rowsSelected : [],
      downloadOptions: {
        filename: moduleName + ".csv"
      },
      onRowsDelete: deleteRows => {
        return true;
      },
      onRowSelectionChange: (
        currentRowsSelected,
        allRowsSelected,
        rowsSelected
      ) => {
        if (onRowsSelect) {
          onRowsSelect(rowsSelected);
        }
      },
      onRowClick: onRowClick ? onRowClick : null,
      customToolbarSelect: selectedRows => (
        <CmtTableSelectToolBar
          isRequest={isRequest}
          isSendall={isSendall}
          isApprove={isApprove}
          onRowsView={onRowsView}
          isApproveall={isApproveall}
          isApproveSelected={isApproveSelected}
          isRejectSelected={isRejectSelected}
          isRejectall={isRejectall}
          IsobservationButton={IsobservationButton}
          isView={isView}
          isApproveSelected={isApproveSelected}
          isRejectSelected={isRejectSelected}
          onRowsObservation={onRowsObservation}
          onRowsApprove={onRowsApprove}
          onRowsRequest={onRowsRequest}
          onRowsPdf={onRowsPdf}
          isEdit={isEdit}
          isDelete={isDelete}
          moduleName={moduleName}
          onRowsEdit={onRowsEdit}
          onRowsDelete={onRowsDelete}
          selectedRows={selectedRows}
          onlyShowIds={onlyShowIds}
          classes={{root: "cardCsvButtons"}}
        />
      ),
      customFooter: (
        count,
        page,
        rowsPerPage,
        onChangeRowsPerPage,
        onChangePage
      ) => (
        <CmtTableFooter
          count={count}
          page={page}
          onChangePage={onChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          responsivePagination={responsivePagination}
          arrowByText={arrowByText}
          fixArrowText={fixArrowText}
        />
      ),
      onTableInit: onTableInit ? onTableInit : null,
      tableId: tableId ? tableId : null
    };
  } else {
    options = {
      filters: "false",
      responsive: "scrollMaxHeight",
      selectableRows: IsselectableRows == undefined ? true : IsselectableRows,
      textLabels: {
        body: {
          noMatch: <IntlMessages id="table.sorry_no_row" />
        },
        toolbar: {
          search: <IntlMessages id="sidebar.search" />,
          downloadCsv: <IntlMessages id="util.download_csv" />,
          viewColumns: <IntlMessages id="util.view_columns" />
        }
      },
      filter: false,
      search: isSearch == undefined ? true : isSearch,
      serverSide: server == undefined ? true : server,
      fixedHeader: true,
      print: false,
      viewColumns: true,
      download: checkRouterUrl(moduleName, "download"),
      count: rowItemsTable.count,
      downloadOptions: {
        filename: moduleName + ".csv"
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        for (let item1 in data) {
          for (let item2 in data[item1].data) {
            if (columns[item2].name == "status") {
              if (data[item1].data[item2]) {
                data[item1].data[item2] = intl.formatMessage({
                  id: "status.active"
                });
              } else {
                data[item1].data[item2] = intl.formatMessage({
                  id: "status.not_active"
                });
              }
            } else if (
              columns[item2].name == "approval_status_label" ||
              columns[item2].name == "purchaserequests_status" ||
              columns[item2].name == "modify_approval_status" ||
              columns[item2].name == "devolution_status" ||
              columns[item2].name == "approval_status" ||
              columns[item2].name == "confirm_status" ||
              columns[item2].name == "status"
            ) {
              data[item1].data[item2] = getStatusClassName(
                data[item1].data[item2],
                intl
              );
            } else if (checkPriceFormat(columns[item2].name)) {
              data[item1].data[item2] = getNumberFormat(
                data[item1].data[item2]
              );
            } else if (checkDateType(columns[item2].name)) {
              data[item1].data[item2] = getFormatEnglishSpanishDate(
                data[item1].data[item2]
              );
            } else if (
              columns[item2].name == "id" ||
              columns[item2].name == "orderId" ||
              columns[item2].name == "documentNo" ||
              columns[item2].name == "dispatchGuideNo" ||
              columns[item2].name == "invoiceNo"
            ) {
              data[item1].data[item2] = String(
                getSixDigit(data[item1].data[item2])
              );
            }
          }
        }
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      onRowClick: onRowClick ? onRowClick : null,
      onRowSelectionChange: (
        currentRowsSelected,
        allRowsSelected,
        rowsSelected
      ) => {
        if (onRowsSelect) {
          onRowsSelect(allRowsSelected);
        }
      },
      // onRowsSelect: (rowData, rowMeta) => {
      //   if (onRowsSelect) {
      //     onRowsSelect(rowData);
      //   }
      // },
      onColumnSortChange: (changedColumn, direction) => {
        onSort(changedColumn, direction);
      },
      setRowProps: (row, dataIndex) => ({
        onDoubleClick: event => {
          if (isEdit != false) {
            onRowsEdit(dataIndex);
          }
        }
      }),
      onTableChange: (action, tableState) => {
        if (action == "search") {
          clearTimeout(timer_id);
          timer_id = setTimeout(() => {
            searchText = tableState.searchText;
            onDispatch(
              moduleName,
              tableState.page,
              tableState.rowsPerPage,
              tableState.searchText
            );
          }, 500);
        } else if (action == "changeRowsPerPage" || action == "changePage") {
          onDispatch(moduleName, tableState.page, tableState.rowsPerPage, "");
        }
      },
      onRowsDelete: deleteRows => {
        console.log("deleteRows===", deleteRows);
        return true;
      },
      customToolbar: (displayData, onViewColumnsChange) => (
        <CmtTableToolBar
          isAdd={isAdd}
          displayData={displayData}
          onViewColumnsChange={onViewColumnsChange}
          onRefresh={onRefresh}
          moduleName={moduleName}
          classes={{root: "cardCsvButtons"}}
        />
      ),
      customToolbarSelect: selectedRows => (
        <CmtTableSelectToolBar
          isApprove={isApprove}
          isApproveall={isApproveall}
          isApproveSelected={isApproveSelected}
          isRejectSelected={isRejectSelected}
          onRowsView={onRowsView}
          isRejectall={isRejectall}
          onRowsObservation={onRowsObservation}
          onRowsApprove={onRowsApprove}
          IsobservationButton={IsobservationButton}
          isView={isView}
          onRowsPdf={onRowsPdf}
          onRowsEdit={onRowsEdit}
          isEdit={isEdit}
          isDelete={isDelete}
          Ispdf={Ispdf}
          isCopy={isCopy}
          onCopy={onCopy}
          moduleName={moduleName}
          onRowsDelete={onRowsDelete}
          selectedRows={selectedRows}
          onlyShowIds={onlyShowIds}
          classes={{root: "cardCsvButtons"}}
        />
      ),
      customFooter: (
        count,
        page,
        rowsPerPage,
        onChangeRowsPerPage,
        onChangePage
      ) => (
        <CmtTableFooter
          count={count}
          page={page}
          onChangePage={onChangePage}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          responsivePagination={responsivePagination}
          arrowByText={arrowByText}
          fixArrowText={fixArrowText}
        />
      ),
      onTableInit: onTableInit ? onTableInit : null,
      tableId: tableId ? tableId : null
    };
  }

  if (onDownload && typeof onDownload === "function") {
    options["download"] = true;
    options["onDownload"] = onDownload;
  }

  if (!showDownload) {
    options["download"] = false;
    options["onDownload"] = null;
  }

  if (!showColumns) {
    options["viewColumns"] = false;
  }

  if (onSearchChange && typeof onSearchChange === "function") {
    options["onSearchChange"] = onSearchChange;
  }

  return (
    <div>
      <MUIDataTable
        title={title && title != "" ? intl.formatMessage({id: title}) : ""}
        data={resData}
        columns={newColumns}
        options={options}
        className={classes.datatables}
      />
      <EditunitModalCard
        open={IsUnitEdit}
        onDelete={onUnitDelete}
        onCancel={onUnitCancel}
        value={IsUnitEditValue}
        onSaveUnit={onSaveUnit}
      />
      {IsMechanic && (
        <WomechanicModalCard
          open={IsMechanic}
          onCancel={onMechanicCancel}
          onSave={onSaveMechanicModel}
        />
      )}
      <WoobservationModalCard
        open={IsObservation}
        onCancel={onObservationCancel}
      />
    </div>
  );
};

CmtTable.prototype = {
  rowItemsTable: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired
};

CmtTable.defaultProps = {
  rowItemsTable: [], // format: [{icon: "text or node", label: "button name", onClick: functionHandler, tooltip, ...}]
  columns: [],
  title: "",
  moduleName: "",
  onlyShowIds: [],
  responsivePagination: false,
  arrowByText: false,
  fixArrowText: false
};

export default CmtTable;

const useStyles = makeStyles(theme => ({
  datatables: {
    "& .MuiTableCell-head": {
      "& div": {
        width: "100%",
        textAlign: "center",
        display: "unset!important",
        fontSize: "11px",
        margin: 0,
        letterSpacing: "normal"
      },
      "& .MuiButton-text": {
        width: "100%",
        textAlign: "center",
        display: "unset!important",
        fontSize: "11px",
        letterSpacing: "normal"
      }
    },
    "& .MuiToolbar-root": {
      "@media screen and (max-width: 500px)": {
        minHeight: "85px"
      }
    },
    "& .MuiInput-formControl": {
      marginTop: "0px!important",
      zIndex: "0!important"
    },
    MUIDataTableBodyCell: {
      stackedCommon: {
        height: "270px"
      }
    },
    "& .MuiCheckbox-root": {
      float: "left"
    },
    "& .MuiTableCell-paddingCheckbox": {
      "& div": {
        display: "block!important"
      }
      // zIndex: 'auto!important'
    },
    // "& .MuiTableCell-body": {
    //   '& .datatables-noprint': {
    //     "& div": {
    //       float: 'left',
    //       marginLeft: '19%'
    //     }
    //   }

    // },
    "& .MuiTableCell-root": {
      borderBottom: "solid 1px #fafbfb",
      // padding: "2px 7px!important",
      paddingLeft: "7px!important",
      paddingRight: "7px!important",
      backgroundColor: "#fff",
      fontSize: 12,
      padding: "3px!important",
      // zIndex: 0,
      textAlign: "center",
      fontWeight: "bold"
    },
    "& .MuiTableHead-root": {
      padding: "7px!important"
    },
    "& .MuiTableBody-root": {
      cursor: props => (props.showPointer ? "pointer" : "inherit")
    },
    "& .MuiTableRow-head": {
      "& .MuiTableCell-root": {
        borderBottom: "none",
        fontSize: 11,
        fontWeight: "bold",
        width: "126px!important",
        zIndex: props => (props.fixDropdownZIndex ? 0 : "inherit"),
        minWidth: "126px!important",
        "& .MuiButton-text": {
          fontSize: "11px",
          margin: 0,
          letterSpacing: "normal"
        }
      },
      "& .MuiTableCell-paddingCheckbox": {
        width: "48px!important",
        minWidth: "48px!important"
      }
    },

    "& .MuiPaper-rounded": {
      boxShadow: "none",
      borderBottom: "solid 1px #fafbfb",
      // zIndex: "0!important",
      "& .MuiPaper-rounded": {
        // zIndex: "0!important"
      }
    },
    "& .MuiPaper-elevation1": {
      paddingTop: "0px",
      paddingBottom: "0px",
      height: 64
    }
  },
  unitchangeaction: {
    "& .MuiSwitch-root": {
      margin: "0 auto"
    }
  },
  unitchangeaction_left: {
    // float: "left",
    marginTop: "-27px",
    marginLeft: "67px"
  },
  unitchangeaction_right: {
    float: "right",
    marginTop: "10px",
    marginRight: "67px"
  },
  unitchangeactionimg: {
    position: "revert",
    height: "100px",
    margin: "0px 0px 0px -105px!important",
    "& img": {
      maxWidth: "175px!important",
      maxHeight: "175px!important",
      position: "absolute!important"
    },
    "& .MuiSwitch-root": {
      margin: "0 auto"
    }
  },
  unitchange: {
    padding: "0px!important",
    width: "170px!important",
    margin: "2px auto!important",
    "& .MuiGrid-root": {
      width: "24px",
      height: "24px",
      float: "left",
      marginTop: 3,
      display: "inline-block",
      margin: "0 auto",
      "& .MuiButton-root": {
        padding: "0px",
        minWidth: "20px",
        color: "#fff"
      }
    },
    "& span": {
      // display: "inline-block",
      margin: "0 auto"
    },
    "& .MuiTextField-root": {
      width: "67px!important",
      "& .MuiInputBase-root": {
        margin: "0px 3px!important",
        padding: "0px 2px!important",
        width: "60px!important"
      }
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      paddingTop: 7,
      paddingBottom: 2,
      fontSize: "14px!important"
    }
  },
  obsbutton: {
    width: "100%",
    "& .CmtButtons-wrapper": {
      width: "100%"
    }
  },
  unitchange_unit: {
    padding: "20px!important",
    width: "157px!important",
    margin: "2px auto 2px 13px!important",
    "& .MuiGrid-root": {
      width: "24px",
      height: "24px",
      float: "left",
      marginTop: 3,
      "& .MuiButton-root": {
        padding: "0px",
        minWidth: "20px",
        color: "#fff"
      }
    },
    "& .MuiTextField-root": {
      width: "67px!important",
      "& .MuiInputBase-root": {
        margin: "0px 3px!important",
        padding: "0px 2px!important",
        width: "60px!important"
      }
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
      paddingTop: 7,
      paddingBottom: 2,
      fontSize: "14px!important"
    }
  },
  unitslash: {
    float: "right",
    paddingTop: "12px",
    paddingLeft: "3px",
    fontSize: "11px"
  },
  unitminusbutton: {
    backgroundColor: "#e0433b!important"
  },
  unitaddbutton: {
    backgroundColor: "#4caf50!important"
  },
  mechanicinput: {
    "& .MuiInputBase-root": {
      marginTop: "0px!important"
    }
  }
}));
