import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "../../@jumbo/utils/IntlMessages";
import CmtButtons from "../CmtButtons";
import ApprovestatusModalCard from "../../@coremat/ApprovestatusModalCard";
import CmtTable from "../CmtTable";
import Modal from "@material-ui/core/Modal";
import {useDispatch, useSelector} from "react-redux";
import api from "../../services/api";
import {fetchError, fetchStart, fetchSuccess} from "../../redux/actions/Common";
import {useIntl} from "react-intl";

const moduleName = "digital";
function rand(){
  return Math.round(Math.random() * 18) - 10;
}

function getModalStyle(){
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `5%`,
    left: `5%`
    // transform: `translate(-${top}%, -50%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    border: "none!important",
    padding: 0,
    border: "2px solid #fff",
    outline: "none",
    textTransform: "capitalize"
  },
  modal: {
    "&:focus": {
      outline: "none"
    }
  },
  container: {
    padding: "5px!important"
  },
  modalh2: {
    borderBottom: "solid 1px #e1e8ea",
    // textAlign: "center",
    padding: 20,
    fontSize: 16
  },
  dateFiltertitle: {
    textAlign: "center",
    padding: "20px 20px 34px 20px",
    width: "100%",
    fontSize: 16
  },
  buttons: {
    float: "right",
    marginTop: "-25px",
    position: "absolute",
    right: "75px",
    top: "30px",
    zIndex: "9999",
    "& .MuiBox-root": {
      display: "inline",
      textAlign: "center"
    }
  },
  filterbuttons: {
    float: "right",
    marginTop: "-25px",
    position: "absolute",
    left: "150px",
    top: "30px",
    zIndex: "9999",
    "& .MuiBox-root": {
      display: "inline",
      textAlign: "center"
    }
  }
}));

const columns = [
  {label: "approve_management.field_modified", name: "modify_changes"},
  {label: "approve_management.previous", name: "previous"},
  {label: "approve_management.modified", name: "modified"},
  {name: "approval_status", label: "actions.action"}
];
let editIndex = 0;
const ModificationapprovalModalCard = props => {
  const classes = useStyles(props);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [ modalStyle ] = React.useState(getModalStyle);
  const [ open, setOpen ] = React.useState(true);
  const [ items, setItems ] = React.useState(true);
  const [ approveModal, setApproveModal ] = React.useState(false);
  const intl = useIntl();

  const {
    data,
    renderRow,
    placeholder,
    name,
    id,
    fullWidth,
    displayEmpty,
    size,
    value,
    required,
    onChange,
    helperText,
    variant,
    error,
    valueKey,
    labelKey,
    backgroundColor,
    label,
    onDelete,
    onSaveModify,
    onCancel,
    pendingItems,
    ...rest
  } = props;

  const handleOpen = () => {
    //setOpen(true);
  };

  const handleClose = () => {
    //setOpen(false);
  };

  const dispatch = useDispatch();

  const buttons = [
    {
      label: <IntlMessages id="actions.cancel" />,
      color: "fullcancel",
      onClick: onSaveModify,
      params: {action: "cancel"}
    },
    {
      label: <IntlMessages id="actions.save" />,
      color: "add",
      onClick: onSaveModify,
      params: {action: "save", items: items}
    }
  ];

  const modifiedbuttons = [
    {label: <IntlMessages id="sidebar.approval_all" />, color: "add"},
    {label: <IntlMessages id="sidebar.reject_all" />, color: "remove"},
    {label: <IntlMessages id="sidebar.approve_selected" />, color: "add"},
    {label: <IntlMessages id="sidebar.reject_selected" />, color: "remove"}
  ];

  useEffect(
    () => {
      if (pendingItems) {
        let regionId = [];
        let warehouseId = [];
        let providerId = [];
        let equipmentId = [];

        for (let item of pendingItems) {
          if (
            item.modify_key == "equipmentId" ||
            item.modify_key == "equipmentId"
          ) {
            if (typeof item.previous == "number") {
              item.previous = [ item.previous ];
            }
            if (typeof item.modified == "number") {
              item.modified = [ item.modified ];
            }
            if (item.previous == "" || item.previous == null) {
              item.previous = [];
            }
            if (item.modified == "" || item.modified == null) {
              item.modified = [];
            }
            equipmentId = equipmentId.concat(item.previous);
            equipmentId = equipmentId.concat(item.modified);
          }
          if (
            item.modify_key == "regionId" ||
            item.modify_key == "otherRegionId"
          ) {
            if (typeof item.previous == "number") {
              item.previous = [ item.previous ];
            }
            if (typeof item.modified == "number") {
              item.modified = [ item.modified ];
            }
            if (item.previous == "" || item.previous == null) {
              item.previous = [];
            }
            if (item.modified == "" || item.modified == null) {
              item.modified = [];
            }
            regionId = regionId.concat(item.previous);
            regionId = regionId.concat(item.modified);
          }
          if (item.modify_key == "providerId") {
            if (typeof item.previous == "number") {
              item.previous = [ item.previous ];
            }
            if (typeof item.modified == "number") {
              item.modified = [ item.modified ];
            }
            if (item.previous == "" || item.previous == null) {
              item.previous = [];
            }
            if (item.modified == "" || item.modified == null) {
              item.modified = [];
            }
            providerId = providerId.concat(item.previous);
            providerId = providerId.concat(item.modified);
          }
          if (
            item.modify_key == "warehouseId" ||
            item.modify_key == "otherWarehouseId"
          ) {
            if (typeof item.previous == "number") {
              item.previous = [ item.previous ];
            }
            if (typeof item.modified == "number") {
              item.modified = [ item.modified ];
            }
            if (item.previous == "" || item.previous == null) {
              item.previous = [];
            }
            if (item.modified == "" || item.modified == null) {
              item.modified = [];
            }
            warehouseId = warehouseId.concat(item.previous);
            warehouseId = warehouseId.concat(item.modified);
          }
        }
        if (
          0 < regionId.length ||
          0 < warehouseId.length ||
          0 < providerId.length ||
          0 < equipmentId.length
        ) {
          console.log("providerId", providerId, warehouseId);
          getRegionWarehouse(
            pendingItems,
            regionId,
            warehouseId,
            providerId,
            equipmentId
          );
        } else {
          setItems([ ...pendingItems ]);
        }
      }
    },
    [ pendingItems ]
  );

  const getRegionWarehouse = async (
    pendingItems,
    regionId,
    warehouseId,
    providerId,
    equipmentId
  ) => {
    dispatch(fetchStart());
    let data = {
      regionId: regionId,
      warehouseId: warehouseId,
      providerId: providerId,
      equipmentId: equipmentId
    };
    let response = await api.postApi("regionwarehouse", data);
    if (response.status == "success") {
      dispatch(fetchSuccess());
      console.log("response=====", response);
      for (let item of pendingItems) {
        if (item.modify_key == "equipmentId" && response.equipment) {
          let previousEquipment = response.equipment.filter(
            item1 => item.previous && item.previous.indexOf(item1.id) != -1
          );
          if (previousEquipment.length == 0 && item.previous[0] == -1) {
            item.previous = intl.formatMessage({id: "table.all"});
          } else {
            item.previous = "";
          }

          for (let item2 of previousEquipment) {
            item.previous =
              item.previous == ""
                ? item2.name
                : item.previous + " , " + item2.name;
          }
          let modifiedProviders = response.equipment.filter(
            item1 => item.modified && item.modified.indexOf(item1.id) != -1
          );

          if (modifiedProviders.length == 0 && item.modified[0] == -1) {
            item.modified = intl.formatMessage({id: "table.all"});
          } else {
            item.modified = "";
          }
          for (let item2 of modifiedProviders) {
            item.modified =
              item.modified == ""
                ? item2.name
                : item.modified + " , " + item2.name;
          }
        } else if (item.modify_key == "providerId" && response.providers) {
          let previousProviders = response.providers.filter(
            item1 => item.previous && item.previous.indexOf(item1.id) != -1
          );
          if (previousProviders.length == 0 && item.previous[0] == -1) {
            item.previous = intl.formatMessage({id: "table.all"});
          } else {
            item.previous = "";
          }

          for (let item2 of previousProviders) {
            item.previous =
              item.previous == ""
                ? item2.name
                : item.previous + " , " + item2.name;
          }
          let modifiedProviders = response.providers.filter(
            item1 => item.modified && item.modified.indexOf(item1.id) != -1
          );

          if (modifiedProviders.length == 0 && item.modified[0] == -1) {
            item.modified = intl.formatMessage({id: "table.all"});
          } else {
            item.modified = "";
          }
          for (let item2 of modifiedProviders) {
            item.modified =
              item.modified == ""
                ? item2.name
                : item.modified + " , " + item2.name;
          }
        } else if (
          (item.modify_key == "regionId" ||
            item.modify_key == "otherRegionId") &&
          response.region
        ) {
          let previousRegion = response.region.filter(
            item1 => item.previous && item.previous.indexOf(item1.id) != -1
          );
          if (previousRegion.length == 0 && item.previous[0] == -1) {
            item.previous = intl.formatMessage({id: "table.all"});
          } else {
            item.previous = "";
          }

          for (let item2 of previousRegion) {
            item.previous =
              item.previous == ""
                ? item2.name
                : item.previous + " , " + item2.name;
          }
          let modifiedRegion = response.region.filter(
            item1 => item.modified && item.modified.indexOf(item1.id) != -1
          );

          if (modifiedRegion.length == 0 && item.modified[0] == -1) {
            item.modified = intl.formatMessage({id: "table.all"});
          } else {
            item.modified = "";
          }
          for (let item2 of modifiedRegion) {
            item.modified =
              item.modified == ""
                ? item2.name
                : item.modified + " , " + item2.name;
          }
        } else if (
          (item.modify_key == "warehouseId" ||
            item.modify_key == "otherWarehouseId") &&
          response.warehouse
        ) {
          let previousWarehouse = response.warehouse.filter(
            item1 => item.previous && item.previous.indexOf(item1.id) != -1
          );

          if (previousWarehouse.length == 0 && item.previous[0] == -1) {
            item.previous = intl.formatMessage({id: "table.all"});
          } else {
            item.previous = "";
          }
          for (let item2 of previousWarehouse) {
            item.previous =
              item.previous == ""
                ? item2.name
                : item.previous + " , " + item2.name;
          }
          let modifiedWarehouse = response.warehouse.filter(
            item1 => item.modified && item.modified.indexOf(item1.id) != -1
          );

          if (modifiedWarehouse.length == 0 && item.modified[0] == -1) {
            item.modified = intl.formatMessage({id: "table.all"});
          } else {
            item.modified = "";
          }
          for (let item2 of modifiedWarehouse) {
            item.modified =
              item.modified == ""
                ? item2.name
                : item.modified + " , " + item2.name;
          }
        }
      }
      setItems([ ...pendingItems ]);
    } else {
      dispatch(fetchError(response.message));
    }
  };

  const onSaveWarehouse = async params => {
    setApproveModal(false);
    if (params.action == "save") {
      let newItems = items;
      if (newItems[editIndex].approval_status != "approve") {
        newItems[editIndex].approval_status = params.status.value;
      } else {
        dispatch(fetchError(<IntlMessages id="error.already_approve" />));
      }
    }
  };
  const onApprovalAction = rowIndex => {
    console.log("item==", rowIndex);
    let newItems = items;
    if (newItems[rowIndex].approval_status != "approve") {
      editIndex = rowIndex;
      setApproveModal(true);
    } else {
      dispatch(fetchError(<IntlMessages id="error.already_approve" />));
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <h2 className={classes.modalh2} id="simple-modal-title">
            <IntlMessages id="approve_management.modification" />

            {/* <Grid xs={12} className={classes.filterbuttons}>
              <CmtButtons items={modifiedbuttons} />
            </Grid> */}
          </h2>
          <p id="simple-modal-description">
            <GridContainer container className={classes.container} spacing={3}>
              <Grid xs={12} className={classes.buttons}>
                <CmtButtons items={buttons} />
              </Grid>
              <Grid xs={12} classes={{root: "table"}}>
                <CmtTable
                  title="approve_management.modification"
                  isApproveSelected={true}
                  isApproveall={true}
                  isRejectSelected={true}
                  isRejectall={true}
                  onApprovalAction={onApprovalAction}
                  moduleName={moduleName}
                  server={false}
                  isEdit={false}
                  isDelete={false}
                  data={{rows: pendingItems}}
                  columns={columns}
                />
              </Grid>
            </GridContainer>
          </p>
        </div>
      </Modal>
      {approveModal && <ApprovestatusModalCard onAction={onSaveWarehouse} />}
    </div>
  );
};

ModificationapprovalModalCard.prototype = {
  data: PropTypes.array.isRequired,
  renderRow: PropTypes.node,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  backgroundColor: PropTypes.string
};

ModificationapprovalModalCard.defaultProps = {
  data: [],
  fullWidth: true,
  displayEmpty: true,
  required: false,
  size: "small",
  border: "none",
  error: false,
  helperText: "",
  valueKey: "id",
  labelKey: "title",
  backgroundColor: "transparent"
};

export default ModificationapprovalModalCard;
