import {makeStyles} from "@material-ui/core";
import {hexToRgba} from "../CmtHelpers/JssHelper";
import {
  amber,
  blue,
  brown,
  deepOrange,
  green,
  grey,
  purple,
  red,
  yellow
} from "@material-ui/core/colors";

const hoverOpacity = 0.7;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  tooltip: {
    // padding:'0px!important'
    backgroundColor: "#ccc"
  },
  buttonRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0px 4px!important"
    // width:'100%'
  },
  MUIDataTableBodyCell: {
    "& .buttonRoot": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "0px 4px!important",
      width: "100%"
    }
  },
  labelRoot: {
    width: "100%",
    overflow: "hidden",
    textAlign: "center"
  },
  primary: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.primary.main) +
        ", " +
        hoverOpacity +
        ")"
    }
  },
  add: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.buttons.add,
    borderRadius: 30,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.add) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 12,
    paddingLeft: 8,
    paddingRight: 8
  },
  add_Btn: {
    color: "#757575",
    minWidth: 48,
    height: 48,
    borderRadius: 30,
    padding: 0,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#f5f5f5"
    }
  },

  edit: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.buttons.edit,
    borderRadius: 30,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.edit) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 12,
    paddingLeft: 25,
    paddingRight: 25,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  remove: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.buttons.remove,
    borderRadius: 30,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.remove) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 12,
    paddingLeft: 25,
    paddingRight: 25,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  pdf: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.buttons.pdf,
    borderRadius: 8,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.pdf) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 5,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 11,
    width: 80,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  observation: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.buttons.observation,
    borderRadius: 8,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.observation) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 5,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 11,
    width: 80,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  download: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.buttons.download,
    borderRadius: 30,
    textTransform: "capitalize",
    width: 120,
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.download) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 12,
    paddingLeft: 8,
    paddingRight: 8
  },
  takephoto: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.buttons.takephoto,
    borderRadius: 30,
    textTransform: "capitalize",
    width: 200,
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.takephoto) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 12,
    paddingLeft: 8,
    paddingRight: 8
  },
  save: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.buttons.add,
    borderRadius: 30,
    textTransform: "capitalize",
    marginTop: 12,
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.add) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 7,
    paddingLeft: 20,
    paddingRight: 20,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  cancel: {
    color: theme.palette.buttons.edit,
    backgroundColor: theme.palette.buttons.cancel,
    borderRadius: 30,
    textTransform: "capitalize",
    marginTop: 12,
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.cancel) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 7,
    paddingLeft: 20,
    paddingRight: 20,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  back: {
    color: theme.palette.buttons.edit,
    backgroundColor: theme.palette.buttons.cancel,
    borderRadius: 30,
    textTransform: "capitalize",
    marginTop: 12,
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.cancel) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 2,
    paddingLeft: 8,
    paddingRight: 8
  },
  fullcancel: {
    color: theme.palette.buttons.edit,
    backgroundColor: theme.palette.buttons.cancel,
    borderRadius: 30,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.cancel) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 12,
    paddingLeft: 25,
    paddingRight: 25,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  next: {
    color: theme.palette.buttons.edit,
    backgroundColor: theme.palette.buttons.cancel,
    borderRadius: 30,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.cancel) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 6,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 12,
    marginBottom: 12,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  login: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.buttons.add,
    borderRadius: 30,
    textTransform: "capitalize",
    marginTop: 12,
    width: 150,
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.add) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 10,
    paddingLeft: 18,
    paddingRight: 18,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  register: {
    color: theme.palette.buttons.edit,
    backgroundColor: theme.palette.common.white,
    borderRadius: 30,
    textTransform: "capitalize",
    marginTop: 12,
    border: "solid 1px #213a52",
    width: 150,
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.buttons.add) +
        ", " +
        hoverOpacity +
        ")"
    },
    padding: 10,
    paddingLeft: 18,
    paddingRight: 18,
    "@media screen and (max-width: 500px)": {
      paddingLeft: 10,
      paddingRight: 10
    }
  },
  secondary: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor:
        "rgba(" +
        hexToRgba(theme.palette.secondary.main) +
        ", " +
        hoverOpacity +
        ")"
    }
  },
  amber: {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: amber[500],
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgba(amber[500]) + ", " + hoverOpacity + ")"
    }
  },
  brown: {
    color: "#ffffff",
    backgroundColor: brown[500],
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgba(brown[500]) + ", " + hoverOpacity + ")"
    }
  },
  red: {
    color: "#ffffff",
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: "rgba(" + hexToRgba(red[500]) + ", " + hoverOpacity + ")"
    }
  },
  green: {
    color: "#ffffff",
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgba(green[500]) + ", " + hoverOpacity + ")"
    }
  },
  blue: {
    color: "#ffffff",
    backgroundColor: blue[500],
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgba(blue[500]) + ", " + hoverOpacity + ")"
    }
  },
  yellow: {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: yellow[500],
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgba(yellow[500]) + ", " + hoverOpacity + ")"
    }
  },
  grey: {
    color: "#ffffff",
    backgroundColor: grey[400],
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgba(grey[400]) + ", " + hoverOpacity + ")"
    }
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgba(deepOrange[500]) + ", " + hoverOpacity + ")"
    }
  },
  purple: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgba(purple[500]) + ", " + hoverOpacity + ")"
    }
  }
}));
export default useStyles;
